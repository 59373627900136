export const eventMetadataMap = {
  // VEHICLE EVENTS
  "DiagnosticLowPriorityWarningLightId": {
    name: "Low Priority Warning Light On",
    description: "Pending Fault Code being generated.",
    category: "VEHICLE",
    riskLevel: 5,
    deviceEventCode: "GO-175"
  },
  "apKlYlK6B5UKemXM3WE5CqA": {
    name: "Odometer Invalid",
    description: "Missing Odometer Data.",
    category: "VEHICLE",
    riskLevel: 3,
    deviceEventCode: "U1123"
  },
  "DiagnosticVehicleBatteryLowVoltageId": {
    name: "Vehicle Battery Low Voltage",
    description: "Vehicle Battery is below 12.4 volts.",
    category: "VEHICLE",
    riskLevel: 3,
    deviceEventCode: "GO-135"
  },

  // DRIVER EVENTS
  "DiagnosticAccidentLevelAccelerationEventId": {
    name: "Collision Limit for Acceleration Exceeded",
    description: "Potential collision: Accelerometer reading exceeds 2.6 G.",
    category: "DRIVER",
    riskLevel: 1,
    deviceEventCode: "GO-166"
  },
  "DiagnosticExcessiveAccelerometerEventsOverAccidentThresholdId": {
    name: "Excessive Accelerometer Events Over Collision Threshold",
    description: "Potential collision: g-force exceeds thresholds.",
    category: "DRIVER",
    riskLevel: 1,
    deviceEventCode: "GO-174"
  },
  "aKIT5YrtELUuKNJG35RgaJg": {
    name: "Vehicle Deceleration Control",
    description: "Monitors possible aggressive braking, cornering, and acceleration events.",
    category: "DRIVER",
    riskLevel: 3,
    deviceEventCode: "GO-1070"
  },

  // DEVICE EVENTS
  "awtSaua-9c06ewJ__HdXlBQ": {
    name: "Accelerometer Calibration In Progress",
    description: "Device calibration in progress, more driving required.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-281"
  },
  "DiagnosticDeviceRestartedBecauseAllPowerWasRemovedId": {
    name: "Telematics Device Fault: All Power Removed and Restarted",
    description: "Device experienced a complete power loss and restarted.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-130"
  },
  "DiagnosticDeviceRestartedBecauseOfLowVoltageInPowerSupplyId": {
    name: "Telematics Device Fault: Low Voltage Power Supply",
    description: "Device is not receiving enough power.",
    category: "DEVICE",
    riskLevel: 3,
    deviceEventCode: "GO-131"
  },
  "DiagnosticDeviceHasBeenUnpluggedId": {
    name: "Telematics Device Fault: Device Unplugged",
    description: "Device lost power due to disconnection or a vehicle power issue.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-136"
  },
  "DiagnosticDeviceRestartedBecauseOfFirmwareUpdateId": {
    name: "Firmware Update Applied",
    description: "A firmware update has been successfully applied to the telematics device.",
    category: "DEVICE",
    riskLevel: 5,
    deviceEventCode: "GO-132"
  },
  "aipGZwHSihU2SLOhKLrjpwA": {
    name: "Telematics Device Fault: Collision Data Unavailable",
    description: "Collision data is unavailable for 10 minutes due to excessive logging of Collision events. If this fault occurs three consecutive times, the GO device disables logging any suspected Collisions until the device is reset.",
    category: "DEVICE",
    riskLevel: 5,
    deviceEventCode: "GO-616"
  },
  "DiagnosticCanBusExcessiveListenId": {
    name: "CAN Bus Excessive Listen Mode",
    description: "Device detected excessive CAN Bus errors and is now in listen-only mode.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "GO-287"
  },
  "DiagnosticDeviceRestartedDueToInternalResetConditionId": {
    name: "Telematics Device Fault: Internal Reset Condition",
    description: "Potential defective device.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "GO-134"
  },
  "aNv4akT-hHk2f7x5Qz5Gjww": {
    name: "Fault: Invalid Baseline Acceleration",
    description: "Device detecting unusually high acceleration values.",
    category: "DEVICE",
    riskLevel: 5,
    deviceEventCode: "GO-448"
  },
  "DiagnosticThirdPartyBufferOverrunId": {
    name: "Log Data Buffer Overrun",
    description: "Device local memory is full while pending reconnection to servers.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-449"
  },
  "aZdGIwiTWFEaceSA64d1qQQ": {
    name: "Lost Communication with Alternative Fuel Control Module",
    description: "Issue in communication with AFCM, potential for inaccurate fuel reading.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "U0108"
  },
  "ahfAWyIyE8kmVW87n0gmyqA": {
    name: "Network Communication Fault Codes Detected",
    description: "Issues in data communication with Telematics Device; most likely a bad install.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "GO-461"
  },
  "aBtpIDsyMjkKlRr0Crs2BZQ": {
    name: "Serial Communication Link",
    description: "Poor to no communication with Telematics Device.",
    category: "DEVICE",
    riskLevel: 1,
    deviceEventCode: "GO-1536"
  },
  "aEFDC1HkYDU-YvD-ENOyzIw": {
    name: "Telematics Device Fault: Disabled Due to Production Test Firmware",
    description: "Telematics device fault: Disabled due to production test firmware.",
    category: "DEVICE",
    riskLevel: 1,
    deviceEventCode: "GO-477"
  }
};
