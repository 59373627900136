import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from "../ContextHandler";

import { getUsers, addUser, updateUser, deleteUser } from '../api/user';
import { kmToMiles } from '../util/formulas';
import ItemListPage from "./ItemListPage";
import { timezones } from './unitFields';

function UserPage({ token }) {
  const context = useContext(Context);

  const columns = [ { key: 'edit', sortable: false },
                    { key: 'name' }, { key: 'email' }, { key: 'username' }, { key: 'role' },
		    { key: 'rootUnitId', header: 'Enterprise' },
                    { key: 'unitId', header: 'Business Unit' },
		    { key: 'timeZone', header: 'Time Zone' },
		    { key: 'verified' } ];
  const fields = [ { key: 'name' },
		   { key: 'email' }, { key: 'username' }, { key: 'password' },
                   { key: 'role', choices: [
                      { id: 'SuperAdmin', name: 'Super Admin' },
                      { id: 'PartnerAdmin', name: 'Partner Admin' },
                      { id: 'EnterpriseAdmin', name: 'Enterprise Admin' },
                      { id: 'DashboardUser', name: 'Dashboard User' }] },
                   { key: 'unitId', label: 'Enterprise(s)',
                     choices: context.enterprises?.map(x => ({ name: x.name, id: x.unitId })),
                     showCondition: x => x.role == 'EnterpriseAdmin' },
		   { key: 'timeZone', label: 'Time Zone', choices: timezones },
                 ];

  const round  = x => x?.toFixed(2);

  const getUserInfo = (asset, key) => {
    if (key == 'name')
      return asset.name || asset.licensePlate || asset.vin || asset.id.toString();
    if (key == 'makeModel')
      return asset.make + ' ' + asset.model;
    if (key == 'group')
      return asset.groups?.map(x => x.name).join(', ')
    if (key == 'unitId' || key == 'rootUnitId')
      return context.units?.find(x => x.id == asset[key])?.name;
    if (key == 'mileage')
      return kmToMiles(asset.odometer);
    return asset[key];
  }

  const getUserCell = (asset, key) => {
    let val = getUserInfo(asset, key);
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td class="number">{val}</td>;
    }
    return <td class="text">{val}</td>;
  }

  const validateUser = user => user.name && (user.password || user.id) && user.role;

  return <ItemListPage columns={columns} fields={fields} addItem={addUser} updateItem={updateUser} getItems={getUsers}
                       validateItem={validateUser} deleteItem={deleteUser} getItemInfo={getUserInfo} getItemCell={getUserCell}
		       selectedTab="users" addItemText="Add User" editHoverText="Edit User" itemType="Users" />;
}

export default UserPage;

