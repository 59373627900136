import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';
import Form from './Form';
import { getUser, updateUser } from '../api/user';
import { timezones } from './unitFields';

function AccountPage({ token }) {
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const [user, setUser] = useState();

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current) {
      lastRefreshCount.current = context.refreshCount;
      const id = parseInt(localStorage.getItem('userId'));
      getUser(id).then(response => setUser(response.data));
    }
  }, [context.refreshCount]);

  console.log(user);

  const fields = [
    { key: 'name', label: 'Your Name' },
    { key: 'role', custom: (
             <tr>
               <td><label>Your Role</label></td>
               <td>{user?.role}</td>
            </tr> ) },
    { key: 'email', label: 'Your Email' },
    { key: 'password' },
    { key: 'password2', label: 'Verify Password' },
    { key: 'timeZone', label: 'Time Zone', choices: timezones },
  ];

  const validateItem = item => (item.password || '') == (item.password2 || '');

  return user &&
         <div id="focus">
           <Tabs selected="account" />
             <div id="content">
               <Form fields={fields} updateItem={updateUser} validateItem={validateItem}
                     headerText="Account Info" initialData={user} />
             </div>
         </div>;
}

export default AccountPage;

