import React, { useState, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Context } from "../ContextHandler";

import { getUsers, addUser, updateUser, deleteUser } from '../api/user';
import { getUnitsShort } from '../api/unit';
import { kmToMiles } from '../util/formulas';
import ItemListPage from "./ItemListPage";
import { timezones } from './unitFields';
import { customFilter, toggleFilter } from '../util/filter';
import { roleChoices } from './roleChoices';
import { isSuperAdmin, canViewMultipleEnterprises } from '../util/security';

function UserPage({ token }) {
  const context = useContext(Context);
  const currentUnitId = localStorage.getItem('unitId');
  const currentRole = localStorage.getItem('role');
  const currentUserId = localStorage.getItem('userId');
  const [units, setUnits] = React.useState([]);
  const { rid, id } = useParams();
  const [popupVisible, setPopupVisible] = useState(-1);
  const [userRoleFilter, setUserRoleFilter] = useState([]);
  const [timeZoneFilter, setTimeZoneFilter] = useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
     if (rid && context.rootId != rid)
        context.dispatch({ type: 'setEnterprise', payload: parseInt(rid) });
     else if (id  && !(context.groupFilter?.length == 1 && context.groupFilter[0] == id))
        context.dispatch({ type: 'setState', attribute: 'groupFilter', payload: [parseInt(id)] });
     else if (rid)
        navigate('/settings/users');
  });

  // show list of choices without anything more powerful than current user
  const getRoleChoices = () => {
     const ix = roleChoices.findIndex(x => x.id == currentRole);
     if (ix < 0)
       return [];
     return roleChoices.slice(ix);
  }

  const enterpriseChanged = ent => getUnitsShort(ent).then(result => setUnits(result.data));
 
  const superAdminOrCreating = x => !x.id || isSuperAdmin()

  const columns = [ { key: 'edit', sortable: false },
                    { key: 'name' }, { key: 'email' }, { key: 'username' }, { key: 'role' },
		    { key: 'rootUnitId', header: 'Enterprise' },
                    { key: 'unitId', header: 'Business Unit' },
		    { key: 'timeZone', header: 'Time Zone' },
		    { key: 'verified' } ];
  const fields = [ { key: 'name' },
		   { key: 'email' }, { key: 'username' }, { key: 'password' },
                   { key: 'role', choices: getRoleChoices() },
                   { key: 'rootAccessList', label: 'Enterprise Access', multiple: true,
                     choices: context.enterprises?.map(x => ({ name: x.name, id: x.unitId })),
                     showCondition: x => x.role == 'PartnerAdmin' && superAdminOrCreating(x) },
                   { key: 'rootUnitId', label: 'Enterprise',
                     choices: context.enterprises?.map(x => ({ name: x.name, id: x.unitId })),
                     showCondition: x => x.role != 'SuperAdmin' && canViewMultipleEnterprises() && superAdminOrCreating(x),
		     valueChanged: enterpriseChanged },
                   { key: 'unitId', label: 'Business Unit',
                     choices: units,
		     makeChoices: () => enterpriseChanged(context.rootId),
                     showCondition: x => (x.role == 'UnitAdmin' || x.role == 'User') && superAdminOrCreating(x) },
		   { key: 'timeZone', label: 'Time Zone', choices: timezones },
                 ];

  const round  = x => x?.toFixed(2);

  const getUserInfo = (user, key) => {
    if (key == 'name')
      return user.name || user.licensePlate || user.vin || user.id.toString();
    if (key == 'makeModel')
      return user.make + ' ' + user.model;
    if (key == 'group')
      return user.groups?.map(x => x.name).join(', ')
    if (key == 'unitId' || key == 'rootUnitId')
      return context.units?.find(x => x.id == user[key])?.name;
    if (key == 'mileage')
      return kmToMiles(user.odometer);
    if (key == 'role')
      return roleChoices.find(x => x.id == user[key])?.name;
    return user[key];
  }

  const getUserCell = (user, key) => {
    let val = getUserInfo(user, key);
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number">{val}</td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const validateUser = user => {
     if (!(user.name && (user.password || user.id) && user.role))
       return false;
     //if ((user.role == 'EnterpriseAdmin' || user.role == 'UnitAdmin') && !user.rootUnitId) return false;
     if (user.role == 'PartnerAdmin' && !user.rootAccessList)
       return false;
     return true;
  }

  const addUserHook = user => {
    if (user.unitId === undefined) {
      user.unitId = (user.rootUnitId) ? user.rootUnitId : currentUnitId;
    }
    return addUser(user);
  };

  const updateUserHook = (id, user) => {
    // handle case where superadmin is changing root id (enterprise) of user that doesn't also have a unit ID specified.
    // in that case we need to change the unit ID too.
    if (isSuperAdmin() && !(user.role == 'UnitAdmin' || user.role == 'User'))
      user.unitId = user.rootUnitId;
    return updateUser(id, user);
  };

  const clearUserRoles = () => setUserRoleFilter([]);
  const addUserRole = role => setUserRoleFilter(toggleFilter(userRoleFilter, role));

  const clearTimeZones = () => setTimeZoneFilter([]);
  const addTimeZone = z => setTimeZoneFilter(toggleFilter(timeZoneFilter, z));

  const customFilters = [
      customFilter(userRoleFilter, 'user-role-filter', 'User Roles', 1, 'All User Roles', roleChoices, clearUserRoles, addUserRole,
	     popupVisible, setPopupVisible),
      customFilter(timeZoneFilter, 'time-zone-filter', 'Time Zones', 2, 'All Time Zones', timezones, clearTimeZones, addTimeZone,
	     popupVisible, setPopupVisible)
  ];

  const canEditUsers = !(currentRole == 'UnitAdmin' || currentRole == 'User');

  const canEditUser = user => {
    if (canEditUsers)
      return true;
    return currentUserId == user.id;
  }

  const filterFunc = x => (userRoleFilter.length == 0 || userRoleFilter.includes(x.role)) &&
                          (timeZoneFilter.length == 0 || timeZoneFilter.includes(x.timeZone)) &&
			  (context.groupFilter === undefined || context.groupFilter.length == 0 ||
			   context.groupFilter?.includes(x.unitId));

  return <ItemListPage columns={columns} fields={fields} addItem={addUserHook} updateItem={updateUserHook} getItems={getUsers}
                       validateItem={validateUser} deleteItem={deleteUser} getItemInfo={getUserInfo} getItemCell={getUserCell}
		       selectedTab="users" addItemText={canEditUsers && "Add User"}
                       editHoverText="Edit User" itemType="Users" objectName="user"
                       customFilters={customFilters} filter={filterFunc} canEditItem={canEditUser} />;
}

export default UserPage;

