import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';

import { getFilters } from '../api/vehicle';
import FilterPanel from "../FilterPanel";
import PagedList from "../PagedList";
import Form from "./Form";

function ItemListPage(props) {
  const { getItems, getItemCell, selectedTab, getItem, addItemText, canEditItem, getItemClass, editHoverText,
          editingStarted, onAddItem, fields } = props;
  const [items, setItems] = useState();
  const [showModal, setShowModal] = useState(false);
  const [initialData, setInitialData] = useState();
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current) {
      lastRefreshCount.current = context.refreshCount;
      const filters = getFilters(context);
      getItems(context.rootId, filters).then(response => setItems(response.data));
    }
  }, [context.refreshCount]);

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const handleAddItem = () => {
    if (onAddItem) {
      onAddItem();
      return;
    }
    setInitialData({});
    setShowModal(true);
    editingStarted && editingStarted();
  };

  const editItem = item => {
    const handleItem = item => {
      setInitialData(item);
      setShowModal(true);
      editingStarted && editingStarted();
    }
    console.log(item, item.id);
    if (getItem) {
      getItem(item.id).then(result => handleItem(result.data));
    } else {
      handleItem(item);
    }
  };

  const formFinished = () => {
    setShowModal(false);
  }

  const getItemCellWithEdit = (item, key) => {
    if (key == 'edit')
        return <td key={key} className="edit"><a data-title={editHoverText} href="#" onClick={ev => editItem(item)}></a></td>
    return getItemCell(item, key);
  }

  const getItemClassWithEdit = (item) => {
    if (getItemClass)
      return getItemClass(item);
    if (canEditItem && !canEditItem(item))
      return 'no-edit';
  }

  const filters = props.filters === undefined ? ['enterpriseFilter', 'groupFilter'] : props.filters;

  const tableActions = addItemText && <a href="#" className="add-new" onClick={ev => handleAddItem()}>{addItemText}</a>;

  // run makeChoices for all fields
  React.useEffect(() => {
     if (showModal)
       fields.forEach(field => {
	 // don't check showCondition here cause then biz unit field on user page doesn't get populated
	 //if (field.showCondition && !field.showCondition(initialData)) return;
	 if (field.makeChoices && field.choices.length == 0)
	   field.makeChoices();
       });
  }, [showModal]);
  
  const handleVeilClick = ev => {
    if (ev.target.id == 'modal')
      setShowModal(false);
  };

  return <>
            <div id="focus">
                <Tabs selected={selectedTab} />
		<FilterPanel {...props} filters={filters} allowAllEnterprises={true} />
                <div id="content">
                    <PagedList {...props} tableActions={tableActions} items={items} getItemCell={getItemCellWithEdit}
                                          getItemClass={getItemClassWithEdit} />
                </div>
            </div>
        {showModal && <div id="modal" className="veil" style={{ display: 'block' }} onClick={handleVeilClick}>
            <div className={"modal-popup " + selectedTab}>
                <div className="modal-content">
                    <Form {...props} formFinished={formFinished} initialData={initialData} />
                </div>
            </div>
          </div>}
     </>;
}

export default ItemListPage;

