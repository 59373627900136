import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';

import { getFilters, getDevices, addDevice, updateDevice, deleteDevice } from '../api/device';
import { getVehicles } from '../api/vehicle';
import { kmToMiles, getVehicleName } from '../util/formulas';
import PagedList from "../PagedList";
import ItemListPage from "./ItemListPage";
import { customFilter, toggleFilter } from '../util/filter';
import { canViewMultipleEnterprises } from '../util/security';

function DevicePage({ token }) {
  const context = useContext(Context);
  const [vehicles, setVehicles] = useState([]);
  const [popupVisible, setPopupVisible] = useState(-1);
  const [typeFilter, setTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const columns = [ { key: 'edit', sortable: false },
                    { key: 'id' }, { key: 'serial' },
		    { key: 'rootUnitId', header: 'Enterprise' },
                    { key: 'type' }, { key: 'mac', header: 'MAC' },
                    { key: 'sim', header: 'SIM' },
                    { key: 'vehicle' }, { key: 'vin', header: 'VIN' }, { key: 'status' } ];

  const enterpriseChanged = ent => {
    getVehicles(ent).then(result => setVehicles(result.data.sort((a, b) => a.name?.localeCompare(b.name))));
  };

  const typeChoices = [{ id: 'GO9', name: 'Geotab GO-9' }, { id: 'VCG', name: 'Accelevate Nexus' }];
  const fields = [ { key: 'serial' },
		   { key: 'enterpriseId', label: 'Enterprise',
                     choices: context.enterprises?.map(x => ({ name: x.name, id: x.unitId })),
		     valueChanged: enterpriseChanged, showCondition: x => !x.id && canViewMultipleEnterprises() },
		   { key: 'vehicle', label: 'Associated Vehicle', idObject: true, choices: vehicles, showCondition: x => !x.id,
		     makeChoices: () => enterpriseChanged(context.rootId) },
                   { key: 'type', choices: typeChoices },
                   { key: 'mac' }, { key: 'sim' }];

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getDeviceInfo = (device, key) => {
    const value = device[key];
    if (key == 'vehicle')
      return device[key] && getVehicleName(device[key]);
    if (key == 'vin')
      return device.vehicle?.vin;
    if (key == 'type' && value)
      return value == 'GO9' ? 'Geotab GO-9' : 'Accelevate Nexus';
    if (key == 'unitId' || key == 'rootUnitId')
      return context.units?.find(x => x.id == device[key])?.name;
    return device[key];
  }

  const getDeviceCell = (device, key) => {
    let val = getDeviceInfo(device, key);
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td className="number">{val}</td>;
    }
    if (key == 'status' && val)
      return <td key={key} className={"text " + val.toLowerCase()}>{val.substring(0, 1) + val.substring(1).toLowerCase()}</td>;
    return <td key={key} className="text">{val}</td>;
  }

  const canEditDevice = device => !context.units?.find(x => x.id == device.vehicle?.unitId)?.readOnly;

  const filters = ['enterpriseFilter'];

  const clearTypeFilter = () => setTypeFilter([]);
  const addTypeFilter = type => setTypeFilter(toggleFilter(typeFilter, type));

  const clearStatusFilter = () => setStatusFilter([]);
  const addStatusFilter = status => setStatusFilter(toggleFilter(statusFilter, status));

  const statusList = [{ id: 'OFFLINE', name: 'Offline' }, { id: 'ONLINE', name: 'Online' }];

  const customFilters = [
      customFilter(typeFilter, 'type-filter', 'Types', 1, 'All Types', typeChoices, clearTypeFilter, addTypeFilter,
             popupVisible, setPopupVisible),
      customFilter(statusFilter, 'status-filter', 'Status', 2, 'All Statuses', statusList, clearStatusFilter, addStatusFilter,
             popupVisible, setPopupVisible)
  ];

  const filterFunc = d => (statusFilter.length == 0 || statusFilter.includes(d.status)) &&
                          (  typeFilter.length == 0 ||   typeFilter.includes(d.type));

  return <ItemListPage columns={columns} fields={fields} addItem={addDevice} updateItem={updateDevice} deleteItem={deleteDevice}
                       getItems={getDevices} getItemInfo={getDeviceInfo} getItemCell={getDeviceCell} selectedTab="devices"
		       objectName="device" addItemText="Add Device" editHoverText="Edit Device" itemType="Devices"
		       filter={filterFunc} filters={filters} customFilters={customFilters} canEditItem={canEditDevice} />;
}

export default DevicePage;

