import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';
import Form from './Form';
import { getRole } from '../util/security';
import { getEnterprise, updateEnterprise } from '../api/enterprise';

function EnterpriseFormPage({ token }) {
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const [enterprise, setEnterprise] = useState();

  const enterpriseId = context.enterprises?.find(e => e.unitId == context.rootId)?.id;

  useEffect(() => {
     if (enterpriseId && context.refreshCount != lastRefreshCount.current) {
        getEnterprise(enterpriseId).then(result => setEnterprise(result.data));
     }
  }, [context.refreshCount, enterpriseId]);

  const fields = [
                { key: 'name', label: 'Enterprise Name' },
                { key: 'subscriptionTier', label: 'Subscription Tier', readonly: true },
                { key: 'address', label: 'Enterprise Address', multiline: true },
                { key: 'userCount', label: 'Enterprise Users', readonly: true },
                { key: 'opCenterCount', label: 'Enterprise Op Centers', readonly: true },
                { key: 'vehicleCount', label: 'Enterprise Vehicles', readonly: true },

                { key: 'contactInfo', heading: 'Contact Info' },
                { key: 'contactName', label: 'Contact Name' },
                { key: 'contactTitle', label: 'Contact Title' },
                { key: 'contactEmail', label: 'Contact Email' },
                { key: 'contactPhone', label: 'Contact Phone' },
                { key: 'contactAddress', label: 'Contact Address', multiline: true },

                { key: 'billingInfo', heading: 'Billing Info' },
                { key: 'billingContactName', label: 'Contact Name' },
                { key: 'billingEmail', label: 'Billing Email' },
                { key: 'billingPhone', label: 'Billing Phone' },
                { key: 'billingAddress', label: 'Billing Address', multiline: true },
  ];

  const role = getRole();
  var readonly = false;
  if (role == 'UnitAdmin' || role == 'User') {
     fields.forEach(x => x.readonly = true);
     readonly = true;
  }

  return <>
            <div id="focus">
                <Tabs selected="enterprise" />
                <div id="content">
		    {enterprise && <Form fields={fields} updateItem={readonly ? null : updateEnterprise}
                                         headerText={"Enterprise Info"} initialData={enterprise} />}
                </div>
            </div>
         </>;
}

export default EnterpriseFormPage;

