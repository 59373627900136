import React, { useState, useEffect } from 'react';
import moment from "moment";
const RiskComparisonChart = ({ priorPeriod, currentPeriod, date1, date2, date3, date4 }) => {
  const [loaded, setLoaded] = useState(false);
  const containerHeight = 250;

  // Convert YYYY-MM-DD to MMM DD for display
  const displayDate1 = moment(date1).format("MMM DD");
  const displayDate2 = moment(date2).format("MMM DD");
  const displayDate3 = moment(date3).format("MMM DD");
  const displayDate4 = moment(date4).format("MMM DD");

  useEffect(() => {
    const timeout = setTimeout(() => setLoaded(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      gap: '2px', 
      width: '100%', 
      height: '98%', 
      maxHeight: '620px',
      opacity: loaded ? 1 : 0,
      transform: loaded ? 'translateY(0)' : 'translateY(-20px)',
      transition: 'opacity 1s ease-out, transform 1s ease-out'
    }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '6px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ width: '15px', height: '15px', backgroundColor: '#d67740', marginRight: '5px' }}></div>
          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Driver</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '15px', height: '15px', backgroundColor: '#7C7C7F', marginRight: '5px' }}></div>
          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Vehicle</span>
        </div>
      </div>
      {/* Prior Period */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ 
          width: '5vw', 
          maxWidth: '110px',
          height: 'min(20vh, 280px)', 
          display: 'flex', 
          flexDirection: 'column', 
          borderRadius: '5px', 
          overflow: 'hidden',
          transition: 'height 1s ease-in-out' 
        }}>
          <div style={{
            height: loaded ? `${(priorPeriod?.driverPercent / 100) * containerHeight || 0}px` : '0px',
            backgroundColor: '#d67740',
            transition: 'height 1s ease-in-out',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'clamp(14px, 2vw, 17px)',
            padding: '2px',
            minHeight: 0
          }}>
            {priorPeriod?.driverPercent}%
          </div>
          <div style={{
            height: loaded ? `${(priorPeriod?.vehiclePercent / 100) * containerHeight || 0}px` : '0px',
            backgroundColor: '#7C7C7F',
            transition: 'height 1s ease-in-out',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'clamp(14px, 2vw, 17px)',
            padding: '2px',
            minHeight: 0
          }}>
            {priorPeriod?.vehiclePercent}%
          </div>
        </div>
        <div style={{ 
          marginTop: '10px', 
          backgroundColor: '#ba23f6', 
          padding: '1vh', 
          borderRadius: '1.5vh', 
          opacity: loaded ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          textAlign: 'center'
        }}>
          <span style={{ color: 'white', fontSize: 'clamp(14px, 2vw, 15px)', fontWeight: 'bold' }}>
            Risk / Day: {priorPeriod.riskPerDay}
          </span>
          <br />
          <span style={{ color: 'white', fontSize: 'clamp(10px, 1.5vw, 13px)' }}>
          {displayDate3} - {displayDate4}
          </span>
        </div>

      </div>

      {/* Trending Arrow */}
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        opacity: loaded ? 1 : 0,
        transform: loaded ? 'scale(1)' : 'scale(0.8)',
        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out'
      }}>
        <div style={{
          width: 0,
          height: 0,
          borderLeft: '20px solid transparent',
          borderRight: '20px solid transparent',
          borderBottom: currentPeriod.riskPerDay > priorPeriod.riskPerDay ? '40px solid pink' : '0',
          borderTop: currentPeriod.riskPerDay < priorPeriod.riskPerDay ? '40px solid pink' : '0',
        }}></div>
        <span style={{ marginTop: '5px', fontWeight: 'bold' }}>Trending</span>
      </div>

      {/* Current Period */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ 
          width: '5vw', 
          maxWidth: '110px',
          height: 'min(20vh, 280px)', 
          display: 'flex', 
          flexDirection: 'column', 
          borderRadius: '5px', 
          overflow: 'hidden',
          transition: 'height 1s ease-in-out' 
        }}>
          <div style={{
            height: loaded ? `${(currentPeriod?.driverPercent / 100) * containerHeight || 0}px` : '0px',
            backgroundColor: '#d67740',
            transition: 'height 1s ease-in-out',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'clamp(14px, 2vw, 17px)',
            padding: '2px',
            minHeight: 0
          }}>
            {currentPeriod?.driverPercent}%
          </div>
          <div style={{
          height: loaded ? `${(currentPeriod?.vehiclePercent / 100) * containerHeight || 0}px` : '0px',
            backgroundColor: '#7C7C7F',
            transition: 'height 1s ease-in-out',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'clamp(14px, 2vw, 17px)',
            padding: '2px',
            minHeight: 0
          }}>
             {currentPeriod?.vehiclePercent}%
          </div>
        </div>
        <div style={{ 
          marginTop: '10px', 
          backgroundColor: '#ba23f6', 
          padding: '1vh', 
          borderRadius: '1.5vh', 
          opacity: loaded ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          textAlign: 'center',
        }}>
          <span style={{ color: 'white', fontSize: 'clamp(14px, 2vw, 15px)', fontWeight: 'bold' }}>
            Risk / Day: {currentPeriod.riskPerDay}
          </span>
          <br />
          <span style={{ color: 'white', fontSize: 'clamp(10px, 1.5vw, 13px)' }}>
          {displayDate1} - {displayDate2}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RiskComparisonChart;
