import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Slider, Typography } from '@mui/material';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Configuration for OpenSearch
const OPENSEARCH_HOST = 'https://search-telemetry-mipyooekmhli6lpyrmxhfpjluq.us-west-2.es.amazonaws.com'
const OPENSEARCH_USERNAME = 'admin'
const OPENSEARCH_PASSWORD = 'xocdYj-haqdyz-5gojfo'

// Mapbox token
const MAPBOX_TOKEN = 'pk.eyJ1IjoicGZhY2NlbCIsImEiOiJjbHV1a3Y3YmkwMDRpMmlwYm9xN3AwNDI0In0.DgtjwUVyRSS7vq3jaXV4Jg';

const RoutePage = () => {
  // State for month, day, start time, time range length, and path data
  let { m, d, st, tl, ind } = useParams();
  const index = ind || 'geotab-b9-';
  const [month, setMonth] = useState(m);
  const [day, setDay] = useState(d);
  const [startTime, setStartTime] = useState(parseInt(st)); // Start time in minutes
  const [timeRangeLength, setTimeRangeLength] = useState(parseInt(tl) || 10); // Length in minutes
  const [pathData, setPathData] = useState(null);
  const [currentPosition, setCurrentPosition] = useState();
  const [currentColor, setCurrentColor] = useState('black');
  const [telemetry, setTelemetry] = useState();
  const [playOffset, setPlayOffset] = useState(0);

  // Marks for the slider, showing every 4 hours
  const marks = [
    { value: 0, label: '00:00' },
    { value: 240, label: '04:00' },
    { value: 480, label: '08:00' },
    { value: 720, label: '12:00' },
    { value: 960, label: '16:00' },
    { value: 1200, label: '20:00' },
    { value: 1440, label: '24:00' },
  ];

  // Handle slider change for start time
  const handleStartTimeChange = (event, newValue) => {
    setStartTime(newValue);
    setPlayOffset(0);
  };

  // Handle input change for time range length
  const handleTimeRangeLengthChange = (event) => {
    const newValue = event.target.value;
    if (!isNaN(newValue) && newValue >= 1) { // Ensure positive integer
      setTimeRangeLength(parseInt(newValue));
    }
  };

  // Skip button handler to move slider ahead by the range length
  const handleSkip = () => {
    setStartTime((prevStartTime) => Math.min(prevStartTime + timeRangeLength, 1440));
    setPlayOffset(0);
  };

  // Convert Pacific Time to GMT for the specified date and time
  const convertTimeToGMT = (year, month, day, startMinutes, lengthMinutes) => {
    const startHours = Math.floor(startMinutes / 60);
    const startMins = startMinutes % 60;
    const endMinutes = startMinutes + lengthMinutes;
    const endHours = Math.floor(endMinutes / 60);
    const endMins = endMinutes % 60;

    // Pacific Time offset (e.g., UTC-7 in PDT or UTC-8 in PST)
    const pacificOffset = new Date().getTimezoneOffset() === 420 ? -7 : -8;

    // Create Date objects in Pacific Time
    const startTime = new Date(Date.UTC(year, month - 1, day, startHours - pacificOffset, startMins, 0));
    const endTime = new Date(Date.UTC(year, month - 1, day, endHours - pacificOffset, endMins, 0));

    return {
      gmtStartTime: startTime.toISOString(),
      gmtEndTime: endTime.toISOString(),
    };
  };

  // Fetch data from OpenSearch with Basic Authentication
  const fetchData = async (year, month, day, startMinutes, lengthMinutes) => {
    try {
      const { gmtStartTime, gmtEndTime } = convertTimeToGMT(year, month, day, startMinutes, lengthMinutes);

      console.log('fetching', year, month, day, startMinutes, lengthMinutes);

      const response = await axios.post(
        `${OPENSEARCH_HOST}/${index}/_search`,
        {
          size: 1000,
	  query: {
	    bool: {
	      must: [
		{
		  range: {
		    dateTime: {
		      gte: gmtStartTime,
		      lt: gmtEndTime,
		    },
		  },
		},
	      ],
	      should: [
		{ exists: { field: 'latitude' } },
		{ exists: { field: 'ignition' } },
	      ],
	      minimum_should_match: 1, // Ensures at least one "should" condition is met
	    },
	  },
          sort: [{ dateTime: { order: 'asc' } }],
        },
        {
          auth: {
            username: OPENSEARCH_USERNAME,
            password: OPENSEARCH_PASSWORD,
          },
        }
      );

      const hits = response.data.hits.hits;
      let latitude  = hits.find(hit => 'latitude'  in hit._source)?._source.latitude;
      let longitude = hits.find(hit => 'longitude' in hit._source)?._source.longitude;
      let ignition  = undefined;
      hits.forEach(hit => {
        if ('latitude' in hit._source) {
          latitude  = hit._source.latitude;
          longitude = hit._source.longitude;
        }
        if ('ignition' in hit._source)
          ignition = hit._source.ignition;
	hit._source.latitude  = latitude;
	hit._source.longitude = longitude;
	hit._source.ignition  = ignition;
      });
      setTelemetry(hits.map(hit => hit._source));
      console.log(hits.map(hit => hit._source));

      const coordinates = hits.filter(hit => 'latitude' in hit).map((hit) => [
        hit._source.longitude,
        hit._source.latitude,
      ]);

      setPathData({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      });
    } catch (error) {
      console.error('Error fetching data from OpenSearch:', error);
    }
  };

  // Effect to fetch data when the month, day, or time range changes
  useEffect(() => {
    if (month && day && startTime !== null && timeRangeLength > 0) {
      const delayDebounceFn = setTimeout(() => {
        fetchData(2024, parseInt(month), parseInt(day), startTime, timeRangeLength);
      }, 200)
      return () => clearTimeout(delayDebounceFn);
    }
  }, [month, day, startTime, timeRangeLength]);

  useEffect(() => {
    if (!telemetry) {
      if (currentPosition)
        setCurrentPosition(null);
      return;
    }
    if (playOffset >= telemetry.length) {
      setPlayOffset(0);
      return;
    }
    const pos = { latitude: telemetry[playOffset].latitude, longitude: telemetry[playOffset].longitude };
    setCurrentPosition(pos);
    setCurrentColor(telemetry[playOffset].ignition == 1 ? 'red' :
                    telemetry[playOffset].ignition == 0 ? 'black' : 'yellow');
    //const dt = telemetry[playOffset].dateTime;
    setTimeout(() => {
      setPlayOffset(playOffset+1);
    }, 1000/60);
  });

  // Function to convert minutes to HH:MM format
  const formatMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">Select Date and Time</Typography>
      
      {/* Month Input */}
      <TextField
        label="Month"
        value={month}
        onChange={(e) => setMonth(e.target.value)}
        type="number"
        inputProps={{ min: 1, max: 12 }}
        sx={{ margin: 1 }}
      />

      {/* Day Input */}
      <TextField
        label="Day"
        value={day}
        onChange={(e) => setDay(e.target.value)}
        type="number"
        inputProps={{ min: 1, max: 31 }}
        sx={{ margin: 1 }}
      />

      {/* Slider for Start Time */}
      <Typography gutterBottom>Start Time: {formatMinutesToTime(startTime)}</Typography>
      <Slider
        value={startTime}
        onChange={handleStartTimeChange}
        min={0}
        max={1440}
        marks={marks}
        step={1} // Allow minute precision
        valueLabelDisplay="auto"
        valueLabelFormat={formatMinutesToTime}
      />

      {/* Input for Time Range Length */}
      <TextField
        label="Time Range Length (minutes)"
        value={timeRangeLength}
        onChange={handleTimeRangeLengthChange}
        type="number"
        inputProps={{ min: 1 }}
        sx={{ margin: 1 }}
      />

      {/* Skip Button */}
      <Button variant="contained" onClick={handleSkip} sx={{ margin: 1 }}>
        Skip {timeRangeLength} minutes
      </Button>

      {/* Mapbox Map */}
      <Map
        initialViewState={{
          latitude: 33.945,
          longitude: -118.4,
          zoom: 14,
        }}
        style={{ width: '100%', height: 600 }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {currentPosition && <Marker latitude={currentPosition.latitude} longitude={currentPosition.longitude}>
                        <div style={{ width: '20px', height: '20px', borderRadius: '50%',
                                      backgroundColor: currentColor, border: '2px solid white' }} />
         </Marker>}
        {pathData && (
          <Source id="pathData" type="geojson" data={pathData}>
            <Layer
              id="pathLayer"
              type="line"
              paint={{
                'line-color': '#ff0000',
                'line-width': 4,
              }}
            />
          </Source>
        )}
      </Map>
      {telemetry && playOffset < telemetry.length && telemetry[playOffset].dateTime}
    </Box>
  );
};

export default RoutePage;

