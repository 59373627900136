import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, PointElement, LineElement, Tooltip, Legend, Title } from 'chart.js';

Chart.register(RadialLinearScale, PointElement, LineElement, Tooltip, Legend, Title);

function EventRadarChart({ events, trips }) {
    if (!events || events.length === 0) {
        return <p>No event data available.</p>;
    }

    // Define the data map (categories)
    const dataMap = {
        "DEVICE": [
            "DiagnosticDeviceHasBeenUnpluggedId",
            "DiagnosticDeviceRestartedBecauseAllPowerWasRemovedId",
            "DiagnosticDeviceRestartedBecauseOfLowVoltageInPowerSupplyId",
            "awtSaua-9c06ewJ__HdXlBQ",
            "aNv4akT-hHk2f7x5Qz5Gjww",
            "aipGZwHSihU2SLOhKLrjpwA"
        ],
        "DRIVER": [
            "DiagnosticAccidentLevelAccelerationEventId",
            "DiagnosticExcessiveAccelerometerEventsOverAccidentThresholdId",
            "aKIT5YrtELUuKNJG35RgaJg"
        ],
        "VEHICLE": [
            "DiagnosticVehicleBatteryLowVoltageId",
            "DiagnosticLowPriorityWarningLightId",
            "apKlYlK6B5UKemXM3WE5CqA"
        ]
    };
    

    // Group events by vehicle make + model
    const groupedData = events.reduce((acc, event) => {
        const vehicleKey = `${event.vehicle?.make || "Unknown"} ${event.vehicle?.model || "Unknown"}`;
        const category = Object.keys(dataMap).find(key => dataMap[key].includes(event.id));

        if (!acc[vehicleKey]) {
            acc[vehicleKey] = { DEVICE: 0, DRIVER: 0, VEHICLE: 0, trips: 0 };
        }

        if (category) {
            acc[vehicleKey][category]++;
        }

        acc[vehicleKey].trips++;
        return acc;
    }, {});

    console.log(groupedData);

    // Calculate average counts per trip
    const averageData = Object.entries(groupedData).map(([vehicle, data]) => ({
        vehicle,
        DEVICE: data.trips > 0 ? data.DEVICE / data.trips : 0,
        DRIVER: data.trips > 0 ? data.DRIVER / data.trips : 0,
        VEHICLE: data.trips > 0 ? data.VEHICLE / data.trips : 0
    }));

    // Radar chart labels (Vehicle Make + Model)
    const labels = averageData.map(item => item.vehicle);

    // Radar chart datasets
    const datasets = [
        {
            label: "Driver Events/Trip",
            data: averageData.map(item => item.DRIVER),
            borderColor: "#FF5733",
            backgroundColor: "rgba(255, 87, 51, 0.4)",
            borderWidth: 2,
            pointRadius: 4
        },
        {
            label: "Device Events/Trip",
            data: averageData.map(item => item.DEVICE),
            borderColor: "#33A1FF",
            backgroundColor: "rgba(51, 161, 255, 0.4)",
            borderWidth: 2,
            pointRadius: 4
        },
        {
            label: "Vehicle Events/Trip",
            data: averageData.map(item => item.VEHICLE),
            borderColor: "#4CAF50",
            backgroundColor: "rgba(76, 175, 80, 0.4)",
            borderWidth: 2,
            pointRadius: 4
        }
    ];

    // Define the chart data
    const chartData = {
        labels,
        datasets
    };

    // Chart options
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 20,
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            },
            tooltip: {
                enabled: true,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                titleFont: {
                    size: 14,
                    weight: "bold"
                },
                bodyFont: {
                    size: 12
                },
                padding: 10,
                cornerRadius: 6,
            }
        },
        scales: {
            r: {
                angleLines: {
                    display: true
                },
                suggestedMin: 0,
                suggestedMax: Math.max(...datasets.flatMap(d => d.data)) + 1,
                pointLabels: {
                    font: {
                        size: 13,
                        weight: 'bold'
                    }
                },
                ticks: {
                    display: false
                }
            }
        }
    };

    return (
        <div style={{ width: '100%', height: '98%', maxHeight: '620px', margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Radar data={chartData} options={chartOptions} />
        </div>
    );
}

export default EventRadarChart;
