import React from 'react';
import { co2PerMile, mpge, mpg, mpkwh, kmToMiles, litersToGallons } from "./util/formulas";
import { FuelTypes } from './util/fuelTypes';
function Scorecard({ wtdData, mtdData }) {
    // Format the data for display
    console.log(wtdData);
    const isIce = (index) => index === FuelTypes.Gas || index === FuelTypes.Diesel;

    const formatData = (data, days = 7) => {
        if (!data) return null;

        // Ensure data arrays exist
        const fuelByType = data.fuelConsumedByType || [];
        const distanceByType = data.distanceByType || [];
        const kwhByType = data.kwhConsumedByType || [];

        // Sum ICE distance (Gas + Diesel) and convert km to miles
        const iceDistanceKm = distanceByType.reduce((sum, val, idx) => sum + (isIce(idx) ? val : 0), 0);
        const iceDistanceMiles = kmToMiles(iceDistanceKm);

        // Sum ICE fuel consumption (convert liters to gallons)
        const iceFuelLiters = fuelByType.reduce((sum, val, idx) => sum + (isIce(idx) ? val : 0), 0);
        const iceFuelGallons = litersToGallons(iceFuelLiters);

        // Sum EV distance and kWh consumption
        const evDistanceKm = distanceByType.reduce((sum, val, idx) => sum + (idx === FuelTypes.EV ? val : 0), 0);
        const evDistanceMiles = kmToMiles(evDistanceKm);
        const evKwh = kwhByType.reduce((sum, val, idx) => sum + (idx === FuelTypes.EV ? val : 0), 0);

        // Efficiency Calculations
        const mpgIce = iceFuelGallons > 0 ? (iceDistanceMiles / iceFuelGallons).toFixed(1) : '-';
        const miPerKwhEv = evKwh > 0 ? (evDistanceMiles / evKwh).toFixed(1) : '-';
        const kWhToGal = evKwh > 0 ? (evKwh / 33.7).toFixed(2) : '-';

        // Fuel Cost Per Mile
        const totalDistanceMiles = kmToMiles(data.distanceDriven || 0);

        return {
            distance: `${totalDistanceMiles.toFixed(1)} mi`,
            timeActive: `${((data.uptimeMinutes || 0) / 60).toFixed(1)} hr`,
            timeIdle: `${((data.idleMinutes || 0) / 60).toFixed(1)} hr`,
            timeOffline: `${((data.offlineMinutes || 0) / 60).toFixed(1)} hr`,
            fuel: `${iceFuelGallons.toFixed(1)} gal`,
            milesPerDay: `${(totalDistanceMiles / days).toFixed(1)} mi`,
            tripsPerDay: `${((data.tripCount || 0) / days).toFixed(1)}`,
            uptimePerDay: `${((data.uptimeMinutes || 0) / 60 / days).toFixed(1)} hr`,
            downtimePerDay: `${((data.offlineMinutes || 0) / 60 / days).toFixed(1)} hr`,
            mpgIce,
            miPerKwhEv,
            kWhToGal,
        };
    };

    if (!wtdData || !mtdData) {
        return (
            <p style={{ color: '#777', textAlign: 'center', fontSize: '18px' }}>
                No data available.
            </p>
        );
    }

    const formattedWtdData = formatData(wtdData);
    const formattedMtdData = formatData(mtdData);

    // Helper function to render a single scorecard
    const renderCard = (title, data) => (
        <div className="scorecard">
            <h3 className="scorecard-title">{title}</h3>
            <div className="scorecard-section">
                <div className="section-title">Totals</div>
                {renderRow('Distance', data.distance)}
                {renderRow('Time Active', data.timeActive)}
                {renderRow('Time Idle', data.timeIdle)}
                {renderRow('Time Offline', data.timeOffline)}
                {renderRow('Fuel', data.fuel)}
            </div>
            <div className="scorecard-section">
                <div className="section-title">Daily Avg</div>
                {renderRow('mi/day - ALL', data.milesPerDay)}
                {renderRow('Trips/Day - ALL', data.tripsPerDay)}
                {renderRow('Uptime/Day', data.uptimePerDay)}
                {renderRow('Downtime/Day', data.downtimePerDay)}
                {renderRow('MPG - ICE', data.mpgIce)}
                {renderRow('mi/kWh - EV', data.miPerKwhEv)}
            </div>
        </div>
    );

    // Render a single row with label and value
    const renderRow = (label, value) => (
        <div className="row">
            <span className="row-label">{label}</span>
            <span className="row-value">{value}</span>
        </div>
    );

    return (
        <div className="scorecard-container">
            {renderCard('Last 7 Days - Scorecard', formattedWtdData)}
            {renderCard('Last 30 Days - Scorecard', formattedMtdData)}
        </div>
    );
}

export default Scorecard;
