import React, { useState, useContext } from 'react';
import { Context } from "../ContextHandler";
import moment from 'moment';

function Form({ fields, addItem, updateItem, validateItem, headerText, formFinished, objectName,
                initialData, extraData, deleteItem, refresh }) {
  const context = useContext(Context);
  const [editItemData, setEditItemData] = useState(initialData);
  const [errorMessage, setErrorMessage] = useState();
  const [showConfirm, setShowConfirm] = useState(false);

  function capitalize(string) {
     return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditItemData({ ...editItemData, [name]: value });
    setErrorMessage();
  };

  const getReadonlyField = field => {
    return <td className="read-only">{editItemData[field.key]}</td>;
  }

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    let hour = editItemData[name] ? parseInt(editItemData[name].substr(0, 2)) : 0;
    const pm = hour > 12;

    // hour changed
    if (parseInt(value) > 0) {
      hour = parseInt(value);
      if (pm)
        hour += 12;
    }

    // am/pm changed
    if (value == 'am' && hour > 12)
      hour -= 12;
    if (value == 'pm' && hour <= 12)
      hour += 12;

    const val = ('00' + hour).slice(-2) + ':00:00';
    setEditItemData({ ...editItemData, [name]: val });
    setErrorMessage();
  };

  const onSelectChange = (ev, field) => {
    if (field.multiple !== true) {
      setEditItemData({ ...editItemData, [field.key]: ev.target.value });
      if (field.valueChanged)
        field.valueChanged(ev.target.value);
      return;
    }
 
    const options = ev.target.options;
    const values = Array.from(options)
	.filter((option) => option.selected)
	.map((option) => option.value);
    setEditItemData({ ...editItemData, [field.key]: values });
  };

  const getFieldInput = field => {
    if (field.multiline) {
       return <textarea id={field.key} name={field.key} onChange={handleInputChange}
                        value={editItemData[field.key] === undefined ? '' : editItemData[field.key]} />;
    }
    if (field.key.endsWith('Time')) {
       const timeMoment = moment(editItemData[field.key], 'HH:mm:ss');
       const ampm = timeMoment.format('a');
       return <td key={field.key} className="time">
                <select name={field.key} id={field.key} required="" value={timeMoment.format('h')} onChange={handleTimeChange}>
                  <option value="" selected="selected">Choose one...</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => <option value={x}>{x}:00</option>)}
                </select>
              <input type="radio" id="start-am" name={field.key} value="am" onChange={handleTimeChange} checked={ampm == 'am'}/>
                <label for="start-am" checked={ampm == 'am'}>am</label>
              <input type="radio" id="start-pm" name={field.key} value="pm" onChange={handleTimeChange} checked={ampm == 'pm'}/>
                <label for="start-pm" checked={ampm == 'pm'}>pm</label>
            </td>;
    }
    if (!field.choices) {
       const type = (field.key.startsWith('password')) ? 'password' : 'text';
       return <input key={field.key} type={type} id={field.key} name={field.key}
                     value={editItemData[field.key] === undefined ? '' : editItemData[field.key]}
                     onChange={handleInputChange} />
    }
    if (field.idObject) {
      const id = editItemData[field.key]?.id;
      return <select key={field.key} value={id}
                     onChange={ev => setEditItemData({ ...editItemData, [field.key]: { id: ev.target.value }})}>
               <option value="">Choose one...</option>
               {field.choices.map(x => <option value={x.id}>{x.name === undefined ? x.id : x.name}</option>)}
             </select>;
    }

    return <select key={field.key} value={editItemData[field.key]} onChange={ev => onSelectChange(ev, field)} multiple={field.multiple}>
             <option value="">Choose one...</option>
               {field.choices.map(x => <option key={x.id} value={x.id}>{x.name === undefined ? x.id : x.name}</option>)}
           </select>;
  }

  const getField = field => {
     if (field.showCondition && !field.showCondition(editItemData))
        return;
     if (field.heading)
        return <tr><td colspan="2" className="header">{field.heading}</td></tr>;
     if (field.custom)
        return field.custom;
     return <tr>
               <td><label>{field.label || capitalize(field.key)}</label></td>
               {field.readonly ? getReadonlyField(field) : <td>{getFieldInput(field)}</td>}
            </tr>
  };

  const handleCancel = ev => {
    ev.preventDefault();
    formFinished && formFinished();
  }

  const handleSubmitItem = async () => {
    try {
      const data = { ...editItemData, ...extraData };
      const promise = (editItemData.id) ? updateItem(editItemData.id, data) : addItem(data);
      promise.then(result => {
         console.log(result);
         if (refresh)
           refresh();
         else
           context.dispatch({ type: 'refresh' });
         if (formFinished)
           formFinished();
         else
           setErrorMessage('Account info updated.');
      }).catch(error => {
	 console.error(error);
         if (error.response?.data)
           setErrorMessage(error.response.data);
         else if (error.message)
           setErrorMessage(error.message);
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = ev => {
    ev.preventDefault();
    handleSubmitItem();
  }

  const handleDelete = ev => {
    ev.preventDefault();
    setShowConfirm(true);
  }

  const confirmDelete = ev => {
    ev.preventDefault();
    try {
      deleteItem(editItemData.id).then(result => {
         setShowConfirm(false);
         console.log(result);
         if (refresh)
           refresh();
         else
           context.dispatch({ type: 'refresh' });
         formFinished && formFinished();
      }).catch(error => { console.error(error); });
    } catch (error) {
      console.error(error);
    }
  }

  const disabled = (validateItem) ? !validateItem(editItemData) : false;
  const cancelDelete = ev => setShowConfirm(false);

  return ( <>
                    <form className="settings-form">
                        <table>
                            <tr>
                                <td colspan="2" className="header">{headerText}</td>
                            </tr>
                            {fields.map(field => getField(field))}
                            {updateItem && <tr>
                                <td />
                                <td className="submit">
                                   <button className="modal-cancel" onClick={handleCancel}>Cancel</button>
                                   {deleteItem && editItemData.id && <button className="secondary" onClick={handleDelete}>Delete</button>}
                                   <button onClick={handleSubmit} disabled={disabled}>Submit</button></td>
                            </tr>}
			    {errorMessage && <tr><td style={{ color: 'red' }}>{errorMessage}</td></tr>}
                        </table>
                    </form> 
           {showConfirm && <div id="confirm" className="veil" style={{ display: 'block' }}>
            <div className="modal-popup">
                <div className="modal-content">
                    <form id="confirm" className="settings-form">
                        <table>
                            <tr>
                                <td className="header">Are you sure?</td>
                            </tr>
                            <tr>
                                <td>You're about to delete a {objectName}.<br/><br/>Do you wish to continue?</td>
                            </tr> 
                            <tr>
                                <td className="submit"><button type="submit" onClick={confirmDelete}>Yes, delete the {objectName}</button>
                                        <button className="modal-cancel" onClick={cancelDelete}>Cancel</button></td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
           </div>}
             </> );
}

export default Form;

