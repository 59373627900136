import React, { useEffect, useRef, useState } from 'react';

const AutoHideText = ({
  text,
  style = {},
  ...props
}) => {
  const containerRef = useRef(null);
  const [isFullyVisible, setIsFullyVisible] = useState(true);

  // Checks if the text overflows its container.
  const checkOverflow = () => {
    const container = containerRef.current;
    if (container) {
      // If the scrollWidth exceeds the clientWidth, some text is hidden.
      setIsFullyVisible(container.scrollWidth <= container.clientWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [text]);

  return (
    <div
      ref={containerRef}
      style={{
        // Use the provided width/height values unless overridden by style.
        whiteSpace: 'nowrap', // keep text on a single line
        overflow: 'hidden',   // hide text if it overflows
        ...style,             // spread any additional styles
      }}
      {...props}
    >
      <span style={{ visibility: isFullyVisible ? 'visible' : 'hidden', ...style }}>{text}</span>
    </div>
  );
};

export default AutoHideText;

