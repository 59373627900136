import React, { useState, useRef, useEffect, useContext } from 'react';
import moment from "moment-timezone";
import { Context } from "../ContextHandler";
import Tabs from './Tabs';
import { getOpCenters } from '../api/unit';
import { updateUnit, } from '../api/unit';
import { useNavigate } from 'react-router-dom';
import PagedList from "../PagedList";
import ItemListPage from "./ItemListPage";
import { timezones, unitFields } from './unitFields';
import { customFilter, toggleFilter } from '../util/filter';
import { isAdmin } from '../util/security';

function OpCenterPage({ token }) {
  const context = useContext(Context);
  const [vehicleChoices, setVehicleChoices] = useState();
  const navigate = useNavigate();
  const [timeZoneFilter, setTimeZoneFilter] = useState([]);
  const [popupVisible, setPopupVisible] = useState(-1);

  const columns = [ { key: 'edit', sortable: false },
                    { key: 'name' },
		    { key: 'rootId', header: 'Enterprise' },
                    { key: 'address', multiline: true },
                    { key: 'wifiSSID', header: 'WiFi SSID' },
                    { key: 'vehicleCount', header: 'Vehicles' },
                    { key: 'licenses' },
                    { key: 'timeZone', header: 'Time Zone' },
                    { key: 'currentTime', header: 'Current Time' },
                    { key: 'dayStartTime', header: 'Day Start Time' },
                    { key: 'dayEndTime', header: 'Day End Time' }
  ];

  const getCenterInfo = (device, key) => {
    if (key.includes('unused')) return 0;
    if (key.includes('licensed')) return 0;
    if (key == 'rootId')
      return context.enterprises?.find(x => x.unitId == device[key])?.name;
    if (key == 'currentTime') {
      const tz = device['timeZone'];
      return tz && moment.tz(tz).format('H:mm A');
    }
    if (key.startsWith('day') && device[key])
      return moment(device[key], 'HH:mm:ss').format('h:mm A');
    return device[key];
  }

  const getCenterCell = (device, key) => {
    let val = getCenterInfo(device, key);
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      return <td key={key} className="number">{val}</td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const canEditItem = (unit) => !unit.readOnly && isAdmin()

  const onAddItem = () => navigate('/settings/units');

  const clearTimeZones = () => setTimeZoneFilter([]);
  const addTimeZone = z => setTimeZoneFilter(toggleFilter(timeZoneFilter, z));

  const customFilters = [
      customFilter(timeZoneFilter, 'time-zone-filter', 'Time Zones', 2, 'All Time Zones', timezones, clearTimeZones, addTimeZone,
             popupVisible, setPopupVisible)
  ];

  const filterFunc = x => (timeZoneFilter.length == 0 || timeZoneFilter.includes(x.timeZone));

  return <ItemListPage columns={columns} fields={unitFields} headerText={"Op Center"} updateItem={updateUnit}
		       getItems={getOpCenters} getItemInfo={getCenterInfo} onAddItem={onAddItem} canEditItem={canEditItem}
		       getItemCell={getCenterCell} selectedTab="opcenters" addItemText="Add Op Center" 
		       itemType="OpCenters" editHoverText="Edit Op Center" filters={['enterpriseFilter']}
                       customFilters={customFilters} filter={filterFunc} />;
}

export default OpCenterPage;

