import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, annotationPlugin);

const DashboardChart = ({ width, height, data, func }) => {
    if (!data)
	return;

    const dataPoints = data.map(func);
    const avg = dataPoints.reduce((a, b) => typeof b === 'number' ? a+b : a, 0) / dataPoints.length;

    const chartData = {
        labels: data.map(x => x.date.substring(5)),
        datasets: [
            {
                data: dataPoints,
                fill: false,
                borderColor: '#F85E7D',
                borderWidth: 2,
                tension: 0.1,
                pointStyle: 'circle',
                pointRadius: 1,
                pointBorderColor: '#F85E7D',
                backgroundColor: '#F85E7D'
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
	  legend: { display: false },
	  annotation: {
	    annotations: {
	      line1: {
		type: 'line',
		yMin: avg,
		yMax: avg,
		borderColor: 'gray',
		borderWidth: 2,
	      },
	    },
	  }
        }
    };

    return <Line data={chartData} options={options} width={width} height={height} />;
};

export default DashboardChart;

