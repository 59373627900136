import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, annotationPlugin);

const nonan = x => Number.isNaN(x) || x === undefined ? 0 : x

const FuelCostsChart = ({ width, height, data, previousData, func, dateSpan1, dateSpan2 }) => {

	if (!data || data.length == 0 || !previousData || previousData.length == 0)
	   return;
	const line_labels = data.map(x => moment(x.date).format('D-MMM'));

        const fuel_cost_line_data = {
            labels: line_labels,
              datasets: [{
                label: dateSpan1 + ' - Actual',
                data: data.map(x => nonan(x.fuelSpend/x.distanceDriven)),
                fill: 1,
                borderColor: 'rgba(248, 94, 125, 0.5)',
                borderWidth: 2,
                tension: 0.1,
                pointStyle: 'circle',
                pointRadius: 2,
                pointBorderColor: 'rgba(248, 94, 125, 0.5)',
                backgroundColor: 'rgba(248, 94, 125, 0.3)'
              },{
                label: dateSpan1 + ' - Optimum',
                data: data.map(x => nonan(x.idealFuelSpend/x.distanceDriven)),
                fill: false,
                borderColor: '#F85E7D',
                borderWidth: 2,
                tension: 0.1,
                pointStyle: 'circle',
                pointRadius: 2,
                pointBorderColor: '#F85E7D',
                backgroundColor: '#F85E7D'
              },{
                label: dateSpan2 + ' - Actual',
                data: previousData.map(x => nonan(x.fuelSpend/x.distanceDriven)),
                fill: 3,
                borderColor: 'rgba(177, 78, 197, 0.5)',
                borderWidth: 2,
                tension: 0.1,
                pointStyle: 'circle',
                pointRadius: 2,
                pointBorderColor: 'rgba(177, 78, 197, 0.5)',
                backgroundColor: 'rgba(177, 78, 197, 0.3)'
              },{
                label: dateSpan2 + ' - Optimum',
                data: previousData.map(x => nonan(x.idealFuelSpend/x.distanceDriven)),
                fill: false,
                borderColor: '#B14EC5',
                borderWidth: 2,
                tension: 0.1,
                pointStyle: 'circle',
                pointRadius: 2,
                pointBorderColor: '#B14EC5',
                backgroundColor: '#B14EC5'
              }]
        }

        const line_options = {
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        boxHeight: 15,
                        boxWidth: 15
                    }
                },
            },
            scales: {
                y: {
                    beginAtZero: false,
                    ticks: {    
                        font: {
                            size: 10
                        }
                    }
                },
                x: {
                    ticks: {
                        font: {
                            size: 10
                        }
                    }
                }
            }
        }

    return <Line data={fuel_cost_line_data} options={line_options} width={width} height={height} />;
};

export default FuelCostsChart;

