import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getVehicles, getFilters, getTripsForVehicle, getDailyTotalByDate, getDailyTotal } from './api/vehicle';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DashboardDonut from "./DashboardDonut";
import FuelCostsChart from "./FuelCostsChart";
import MileageEmissionsChart from "./MileageEmissionsChart";
import FleetEfficiencyDonut from "./FleetEfficiencyDonut";
import EnergyBlendGraph from "./EnergyBlendGraph";
import FilterPanel from "./FilterPanel";
import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { lastSevenDays } from "./util/dates";
import { co2PerMile, mpge, mpg, mpkwh, kmToMiles, litersToGallons } from "./util/formulas";
import { Context } from "./ContextHandler";
import mapboxgl from 'mapbox-gl';
import mapBus from './images/map-bus.svg';
import mapBusBlue from './images/map-bus-blue.svg';
import mapBusPink from './images/map-bus-pink.svg';
import mapBusPurple from './images/map-bus-purple.svg';
import mapBusOrange from './images/map-bus-orange.svg';
import { FuelTypes, classifyFuelTypes, fuelTypesList } from './util/fuelTypes';
import { mapboxToken } from './util/mapbox.js';
//import './css/styles-v2.css';

function getPreviousDateRange(moments) {
  if (!moments)
     return;
  const date1 = moment(moments[0]);
  const date2 = moment(moments[1]);

  // Calculate the number of days between
  const daysBetween = Math.abs(date2.diff(date1, 'days'));

  // Calculate the previous interval
  const previousStart = date1.clone().subtract(daysBetween+1, 'days');
  const previousEnd = date2.clone().subtract(daysBetween+1, 'days');

  return [previousStart, previousEnd];
}

function getDateSpan(dates) {
  if (!dates)
    return;
  const fmt = 'MMM D';
  return moment(dates[0]).format(fmt) + ' - ' + moment(dates[1]).format(fmt);
}

function padDates(array, date1, date2) {
    const start = moment(date1);
    const end = moment(date2);

    const paddedArray = [];
    let current = start.clone();
    let arrayIndex = 0;

    while (current.isSameOrBefore(end, 'day')) {
        const currentDate = current.format("YYYY-MM-DD");

        if (arrayIndex < array.length && array[arrayIndex].date === currentDate) {
            // Add the existing object from the array
            paddedArray.push(array[arrayIndex]);
            arrayIndex++; // Move to the next item in the array
        } else {
            // Add a placeholder for the missing date
            paddedArray.push({ date: currentDate });
        }

        current.add(1, 'day'); // Move to the next day
    }

    return paddedArray;
}

const AnimatedSpan = ({ dataWidth, className }) => {
  const [width, setWidth] = useState("0%");
  const pct = dataWidth ? (Math.round(1000*dataWidth)/10) + '%' : '0%';

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWidth(pct);
    }, 100); // Delay before animation starts

    return () => clearTimeout(timeout);
  }, [dataWidth]);

  return (
    <span
      className={className}
      style={{ width,
               transition: "width 1s ease-in-out"
	    }}
    ></span>
  );
};

function Trending({ token }) {

    const [dailyGraph, setDailyGraph] = useState();
    const [previousDailyGraph, setPreviousDailyGraph] = useState();
    const [assets, setAssets] = useState();
    const [totals, setTotals] = useState();
    const [previousTotals, setPreviousTotals] = useState();
    const context = useContext(Context);
    const dateRange = context.dateRangeFilter;
    const previousDateRange = getPreviousDateRange(context.dateRangeFilter);
    const lastRefreshCount = useRef(-1);
    const [mapRefValue, setMapRefValue] = useState();
    const dateSpan1 = getDateSpan(dateRange);
    const dateSpan2 = getDateSpan(previousDateRange);
    const [hoveredAsset, setHoveredAsset] = useState(null);

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 32, // latitude[0],
        longitude: -117, // longitude[0],
        zoom: 10
    });

    const fuelTypeInfo = [
        { id: 'GAS', index: 0, name: 'Gas', color: 'pink', bus: mapBusPink },
        { id: 'LPG', index: 1, name: 'LPG', color: 'blue', bus: mapBusBlue },
        { id: 'EV', index: 2, name: 'EV', color: 'purple', bus: mapBusPurple },
        { id: 'CNG', index: 3, name: 'CNG', color: 'magenta', bus: mapBus },
        { id: 'PROPANE', index: 4, name: 'Propane', color: 'orange', bus: mapBusOrange },
        { id: 'DIESEL', index: 5, name: 'Diesel', color: 'pink', bus: mapBusPink },
    ];

    useEffect(() => {
	if (lastRefreshCount.current != context.refreshCount && dateRange) {
	    lastRefreshCount.current = context.refreshCount;
            const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
            const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
            const date3 = (previousDateRange[0]).format('YYYY-MM-DD')
            const date4 = (previousDateRange[1]).format('YYYY-MM-DD')
	    const filters = getFilters(context);
            getVehicles(context.rootId, filters).then(response => setAssets(response.data));
	    getDailyTotalByDate(context.rootId, date1, date2, filters).then(result => setDailyGraph(padDates(result.data, date1, date2)));
	    getDailyTotal(context.rootId, date1, date2, filters)      .then(result => setTotals(result.data));
	    getDailyTotalByDate(context.rootId, date3, date4, filters).then(result => setPreviousDailyGraph(padDates(result.data, date3, date4)));
	    getDailyTotal(context.rootId, date3, date4, filters)      .then(result => setPreviousTotals(result.data));
	}
    }, [context.refreshCount, dateRange]);

    React.useEffect(() => {
      // don't allow "All Enterprises"
      if (context.rootId === -1) {
	 context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
      }
    }, []);

    // need this because we want to calculate bounding box after mapRef is set
    const handleMapRef = map => {
      setMapRefValue(map);
    }

    const fuelTypes = new Set(totals?.activeVehicleCountByFuelType?.
		map((value, index) => value === 0 ? null : fuelTypesList[index]).filter(x => x));
    const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
    const energyWord = onlyIce ? 'Fuel' : 'Energy';
    const isIce = x => x == FuelTypes.Gas || x == FuelTypes.Diesel;

    // Calculate bounding box and fit map to marker
    useEffect(() => {
      if (!assets)
	 return;
      let lon1 = 180, lat1 = 90, lon2 = -180, lat2 = -90;
      assets.forEach(asset => {
         if (!asset.latitude) return;
         lon1 = Math.min(lon1, asset.longitude);
         lat1 = Math.min(lat1, asset.latitude);
         lon2 = Math.max(lon2, asset.longitude);
         lat2 = Math.max(lat2, asset.latitude);
      });
      const pad = .03;
      const bound1 = [lon1-pad, lat1-pad];
      const bound2 = [lon2+pad, lat2+pad];
      const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

      if (mapRefValue) {
	mapRefValue.fitBounds(bounds, { duration: 0 });
      }
    }, [assets, mapRefValue]);

    const filters = ['enterpriseFilter', 'fuelTypeFilter', 'groupFilter', 'modelFilter', 'assetFilter', 'dateFilter'];
    if (!totals)
	return <FilterPanel filters={filters} />;

    const round = x => nonan(x).toFixed(0);
    const round2 = x => nonan(x).toFixed(2);

    const nonan = x => Number.isNaN(x) || x === undefined ? 0 : x
    const fuelCost = nonan(Math.round(100 * totals.fuelSpend / kmToMiles(totals.distanceDriven)));
    const co2Value = round2(co2PerMile(totals)/1000);
    const uptimeValue = round(nonan(totals.uptimeMinutes/60 / dailyGraph?.length));
    const mpgValue = round2(mpkwh(totals, onlyIce));
    const totalDistanceInKm = dailyGraph?.reduce((tot, x) => tot+x.distanceDriven, 0);
    const distanceValue = round(nonan(kmToMiles(totalDistanceInKm/dailyGraph?.length)));
    const chargingValue = round(nonan(totals.chargingMinutes/60 / dailyGraph?.length));
    const idleValue = round(nonan(totals.idleMinutes/60 / dailyGraph?.length));
    const eff = nonan(Math.round(totals?.efficiency*100))
    const previousEff = nonan(Math.round(previousTotals?.efficiency*100))
    const activeCount = assets?.length;
    const addFuels = ice => nonan(totals?.fuelConsumedByType?.reduce((tot, x, index) => isIce(index) == ice ? x+tot : tot, 0));
    const summaryFuelConsumed = addFuels(true);
    const summaryEnergyConsumed = addFuels(false);

    const dollars = x => {
        if (x > 1000) return '$' + nonan(x/1000).toFixed(1) + 'K';
        return '$' + nonan(x).toFixed(2);
    }

    const updown = (a, b) => (a > b) ? 'number up' : 'number down';

    const getTransform = asset => {
      const bearing = asset.bearing;
      if (bearing == -1)
        return;
      if (bearing >= 0 && bearing < 180) {
        // Flip and rotate
        return `scaleX(-1) rotate(${90-bearing}deg)`;
      } else {
        // Rotate only
        return `rotate(${bearing-270}deg)`;
      }
    }

    const STATUS = {
        ACTIVE: "ACTIVE",
        IDLE: "IDLE",
        OFFLINE: "OFFLINE"
    };
    
    const OFFLINE_THRESHOLD_HOURS = 24;

    const statusIcons = {
        [STATUS.ACTIVE]: { name: "Active", color: "green", bus: mapBusBlue },
        [STATUS.IDLE]: { name: "Idle", color: "yellow", bus: mapBusPink },
        [STATUS.OFFLINE]: { name: "Offline", color: "gray", bus: mapBusOrange },
    };

    const getVehicleStatus = (asset) => {
        if (!asset.lastUpdate) return STATUS.OFFLINE;
    
        const lastUpdateTime = moment(asset.lastUpdate);
        const hoursSinceUpdate = moment().diff(lastUpdateTime, 'hours');
    
        if (hoursSinceUpdate > OFFLINE_THRESHOLD_HOURS) {
            return STATUS.OFFLINE;
        }
    
        return asset.speed === 0 ? STATUS.IDLE : STATUS.ACTIVE;
    };

    const updatedAssets = assets?.map(asset => ({
        ...asset,
        status: getVehicleStatus(asset)
    }));

    const maxCost = (totals && previousTotals && Math.max(nonan(totals.fuelSpend), nonan(previousTotals.fuelSpend)));
    const costBarLen = (totals && totals.fuelSpend/maxCost);
    const prevCostBarLen = (previousTotals && previousTotals.fuelSpend/maxCost);
    const idealCostBarLen = (totals && totals.idealFuelSpend/maxCost);
    const prevIdealCostBarLen = (previousTotals && previousTotals.idealFuelSpend/maxCost);
    const savingsBarLen = (totals && (totals.fuelSpend-totals.idealFuelSpend)/maxCost);
    const prevSavingsBarLen = (previousTotals && (previousTotals.fuelSpend-previousTotals.idealFuelSpend)/maxCost);

    return <>
	<FilterPanel filters={filters} />
        <div id="content">
                    <div className="trending-combined">
			<div className="top-row" style={{ display: 'flex', gap: '2vw' }} >
			    <div id="ops-costs" style={{ flex: 2.5 }}>
				<h3>Operating Costs (USD)<span>Selected vs Previous</span></h3>
				<div className="cost-numbers">
				    <div className="cost-date"></div>
				    <div className="total cost-number">
					<div className="current">
					    <h4><span className="date">{dateSpan1}</span><br />Actual Operating Cost</h4>
					    <div className={updown(totals?.fuelSpend, previousTotals?.fuelSpend)}>{dollars(totals?.fuelSpend)}</div>
					    <div className="number-line"><AnimatedSpan dataWidth={costBarLen} /></div>
					</div>
					<div className="previous">
					    <h4><span className="date">{dateSpan2}</span><br />Actual Operating Cost</h4>
					    <div className="number">{dollars(previousTotals?.fuelSpend)}</div>
					    <div className="number-line"><AnimatedSpan dataWidth={prevCostBarLen} /></div>
					</div>
				    </div>
				    <div className="ideal cost-number">
					<div className="current">
					    <h4><span className="date">{dateSpan1}</span><br />Optimum Operating Cost</h4>
					    <div className={updown(totals?.idealFuelSpend, previousTotals?.idealFuelSpend)}>{dollars(totals?.idealFuelSpend)}</div>
					    {totals && <div className="number-line"><AnimatedSpan dataWidth={idealCostBarLen} /></div>}
					</div>
					<div className="previous">
					    <h4><span className="date">{dateSpan2}</span><br />Optimum Operating Cost</h4>
					    <div className="number">{dollars(previousTotals?.idealFuelSpend)}</div>
					    {previousTotals && <div className="number-line"><AnimatedSpan dataWidth={prevIdealCostBarLen} /></div>}
					</div>
				    </div>
				    <div className="loss cost-number">
					<div className="current">
					    <h4><span className="date">{dateSpan1}</span><br />Potential Savings</h4>
					    <div className="number">{dollars(totals?.fuelSpend-totals?.idealFuelSpend)}</div>
					    <div className="number-line"><AnimatedSpan dataWidth={savingsBarLen} /></div>
					</div>
					<div className="previous">
					    <h4><span className="date">{dateSpan2}</span><br />Potential Savings</h4>
					    <div className="number">{dollars(previousTotals?.fuelSpend-previousTotals?.idealFuelSpend)}</div>
					    {previousTotals && <div className="number-line"><AnimatedSpan dataWidth={prevSavingsBarLen} /></div>}
					</div>
				    </div>
				</div>
			    </div>
			    <div id="op-efficiency" style={{ flex: 1 }}>
				<h3>Operational Efficiency (%) {/*<span>Selected vs Previous</span>*/} </h3>
				<FleetEfficiencyDonut circleText={eff + '%'} percent={eff} previousPercent={previousEff} />
				<ul className='legend'>
				    <li className="magenta">Efficiency {dateSpan1}</li>
				    <li className="purple">Efficiency {dateSpan2}</li>
				    {/*<li className="green">Driver Loss</li>
				    <li className="blue">Ambient Loss</li>
				    <li className="orange">Vehicle Degredation</li>*/}
				</ul>
			    </div>
			</div>

			<div className="cost-graph">
			  <div className="cost-graph-group" style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5vi' }} >
			    <span className="label" style={{ display: 'inline-block', width: '13vh', fontSize: '1.3vh', textAlign: 'right', marginRight: '10px' }} >
			      Selected Period Cost
			    </span>
			    <div className="cost-graph-line" style={{ flex: 1 }}>
				<AnimatedSpan className="total current" dataWidth={costBarLen} />
				<AnimatedSpan className="ideal current" dataWidth={idealCostBarLen} />
				<AnimatedSpan className="loss current" dataWidth={savingsBarLen} />
			    </div>
			  </div>
                          <div className="cost-graph-group" style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5vi' }} >
                            <span className="label" style={{ display: 'inline-block', width: '13vh', fontSize: '1.3vh', textAlign: 'right', marginRight: '10px' }} >
			      Previous Period Cost
			    </span>
			    <div className="cost-graph-line" style={{ flex: 1 }}>
				<AnimatedSpan className="total previous" dataWidth={prevCostBarLen} />
				<AnimatedSpan className="ideal previous" dataWidth={prevIdealCostBarLen} />
				<AnimatedSpan className="loss previous" dataWidth={prevSavingsBarLen} />
			    </div>
			  </div>
			</div>

                        <div id="daily-ops-costs" className="stacked forty">
                            <h3>Operating Costs (USD/mile) <span>Selected vs Previous</span></h3>
			    <FuelCostsChart data={dailyGraph} previousData={previousDailyGraph} dateSpan1={dateSpan1} dateSpan2={dateSpan2} width={600} height={190} />
                        </div>
                    </div>

                    <div className="right">
                        <div id="mileage-emissions" className="stacked third">
                            <h3>Mileage &amp; Emissions (miles &amp; kg/mile) {/*<span>Selected vs Previous</span>*/}</h3>
			    <MileageEmissionsChart data={dailyGraph} previousData={previousDailyGraph} dateSpan1={dateSpan1} dateSpan2={dateSpan2} width={600} height={180} />
                            <ul className='legend'>
                                <li className="magenta-line">Emissions {dateSpan1}</li>
                                <li className="purple-line">Emissions {dateSpan2}</li>
                                <li className="magenta-bar">Mileage {dateSpan1}</li>
                                <li className="purple-bar">Mileage {dateSpan2}</li>
                            </ul>
                        </div>
			<div id="energy-efficiency" className="stacked two-thirds" style={{ flex: '1 1 auto', height: '59%' }}>
                            <h3>Energy Blend (USD/mile)<span>Selected vs Previous</span></h3>
			    <EnergyBlendGraph data={totals} previousData={previousTotals} />
                            <ul className='legend'>
                                <li className="magenta-line">{dateSpan1}</li>
                                <li className="purple-line">{dateSpan2}</li>
                            </ul>
			</div>
                    </div>
    </div></>;
}

export default Trending;

