export const eventMetadataMap = {
  // VEHICLE EVENTS
  "DiagnosticLowPriorityWarningLightId": {
    name: "Low Priority Warning Light On",
    description: "Pending Fault Code being generated.",
    category: "VEHICLE",
    riskLevel: 5,
    deviceEventCode: "GO-175"
  },
  "apKlYlK6B5UKemXM3WE5CqA": {
    name: "Odometer Invalid",
    description: "Missing Odometer Data.",
    category: "VEHICLE",
    riskLevel: 3,
    deviceEventCode: "U1123"
  },
  "DiagnosticVehicleBatteryLowVoltageId": {
    name: "Vehicle Battery Low Voltage",
    description: "Vehicle Battery is below 12.4 volts.",
    category: "VEHICLE",
    riskLevel: 3,
    deviceEventCode: "GO-135"
  },
  "arezmrttkpE-kydCqklvX3w": {
    name: "Small EVAP Leak",
    description: "EVAP system leak detected (very small leak).",
    category: "VEHICLE",
    riskLevel: 2,
    deviceEventCode: "P0456"
  },
  // DRIVER EVENTS
  "DiagnosticAccidentLevelAccelerationEventId": {
    name: "Collision Limit for Acceleration Exceeded",
    description: "Potential collision: Accelerometer reading exceeds 2.6 G.",
    category: "DRIVER",
    riskLevel: 1,
    deviceEventCode: "GO-166"
  },
  "DiagnosticExcessiveAccelerometerEventsOverAccidentThresholdId": {
    name: "Excessive Accelerometer Events Over Collision Threshold",
    description: "Potential collision: g-force exceeds thresholds.",
    category: "DRIVER",
    riskLevel: 1,
    deviceEventCode: "GO-174"
  },
  "aKIT5YrtELUuKNJG35RgaJg": {
    name: "Vehicle Deceleration Control",
    description: "Monitors possible aggressive braking, cornering, and acceleration events.",
    category: "DRIVER",
    riskLevel: 3,
    deviceEventCode: "GO-1070"
  },

  // DEVICE EVENTS
  "awtSaua-9c06ewJ__HdXlBQ": {
    name: "Accelerometer Calibration In Progress",
    description: "Device calibration in progress, more driving required.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-281"
  },
  "DiagnosticDeviceRestartedBecauseAllPowerWasRemovedId": {
    name: "Telematics Device Fault: All Power Removed and Restarted",
    description: "Device experienced a complete power loss and restarted.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-130"
  },
  "DiagnosticDeviceRestartedBecauseOfLowVoltageInPowerSupplyId": {
    name: "Telematics Device Fault: Low Voltage Power Supply",
    description: "Device is not receiving enough power.",
    category: "DEVICE",
    riskLevel: 3,
    deviceEventCode: "GO-131"
  },
  "DiagnosticDeviceHasBeenUnpluggedId": {
    name: "Telematics Device Fault: Device Unplugged",
    description: "Device lost power due to disconnection or a vehicle power issue.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-136"
  },
  "DiagnosticDeviceRestartedBecauseOfFirmwareUpdateId": {
    name: "Firmware Update Applied",
    description: "A firmware update has been successfully applied to the telematics device.",
    category: "DEVICE",
    riskLevel: 5,
    deviceEventCode: "GO-132"
  },
  "aipGZwHSihU2SLOhKLrjpwA": {
    name: "Telematics Device Fault: Collision Data Unavailable",
    description: "Collision data is unavailable for 10 minutes due to excessive logging of Collision events. If this fault occurs three consecutive times, the GO device disables logging any suspected Collisions until the device is reset.",
    category: "DEVICE",
    riskLevel: 5,
    deviceEventCode: "GO-616"
  },
  "DiagnosticCanBusExcessiveListenId": {
    name: "CAN Bus Excessive Listen Mode",
    description: "Device detected excessive CAN Bus errors and is now in listen-only mode.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "GO-287"
  },
  "DiagnosticDeviceRestartedDueToInternalResetConditionId": {
    name: "Telematics Device Fault: Internal Reset Condition",
    description: "Potential defective device.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "GO-134"
  },
  "aNv4akT-hHk2f7x5Qz5Gjww": {
    name: "Fault: Invalid Baseline Acceleration",
    description: "Device detecting unusually high acceleration values.",
    category: "DEVICE",
    riskLevel: 5,
    deviceEventCode: "GO-448"
  },
  "DiagnosticThirdPartyBufferOverrunId": {
    name: "Log Data Buffer Overrun",
    description: "Device local memory is full while pending reconnection to servers.",
    category: "DEVICE",
    riskLevel: 4,
    deviceEventCode: "GO-449"
  },
  "aZdGIwiTWFEaceSA64d1qQQ": {
    name: "Lost Communication with Alternative Fuel Control Module",
    description: "Issue in communication with AFCM, potential for inaccurate fuel reading.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "U0108"
  },
  "ahfAWyIyE8kmVW87n0gmyqA": {
    name: "Network Communication Fault Codes Detected",
    description: "Issues in data communication with Telematics Device; most likely a bad install.",
    category: "DEVICE",
    riskLevel: 2,
    deviceEventCode: "GO-461"
  },
  "aBtpIDsyMjkKlRr0Crs2BZQ": {
    name: "Serial Communication Link",
    description: "Poor to no communication with Telematics Device.",
    category: "DEVICE",
    riskLevel: 1,
    deviceEventCode: "GO-1536"
  },
  "aEFDC1HkYDU-YvD-ENOyzIw": {
    name: "Telematics Device Fault: Disabled Due to Production Test Firmware",
    description: "Telematics device fault: Disabled due to production test firmware.",
    category: "DEVICE",
    riskLevel: 1,
    deviceEventCode: "GO-477"
  },
  "aysJxXoc3v0-Y6PGVSjoOxA": {
    name: "Fault - Engine Hours Stale",
    description: "Engine hours data is stale or not updating correctly.",
    category: "VEHICLE",
    riskLevel: 3,
    deviceEventCode: "466"
  },
  "DiagnosticGeneralVehicleWarningLightId": {
    name: "General Vehicle Warning Light On",
    description: "Vehicle warning light is on.",
    category: "VEHICLE",
    riskLevel: 3,
    deviceEventCode: "168"
  },
  "a3VZO6_9eW0CduN3qT21_eg": {
    name: "Bank 2 Air-Fuel Ratio Imbalance",
    description: "Air-fuel mixture imbalance detected on bank 2.",
    category: "VEHICLE",
    riskLevel: 3,
    deviceEventCode: "8603"
  },
  "a84mBHb9yjkGYzwzv7UyzNw": {
    name: "Cylinder 1 Misfire Detected",
    description: "Misfire detected in cylinder 1.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "769"
  },
  "aBp6GC53thki3SdIY6l60ag": {
    name: "Cylinder 2 Misfire Detected",
    description: "Misfire detected in cylinder 2.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "770"
  },
  "aXHp4ucVtN0CKiKauf4_0Rw": {
    name: "Cylinder 3 Injector A",
    description: "Cylinder 3 injector A issue detected.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "515"
  },
  "aF46bMZUQuEmW7nMuNuig4Q": {
    name: "Random Misfire Detected",
    description: "Random cylinder misfires detected.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "768"
  },
  "aOEgMuXotlEKo_TWD2NZyFQ": {
    name: "Cylinder 10 Misfire Detected",
    description: "Misfire detected in cylinder 10.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "784"
  },
  "aRbmDlP0lUk2knANrZ7pmvw": {
    name: "System Too Lean Bank 2",
    description: "Fuel mixture too lean in bank 2.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "372"
  },
  "awE-8vCcTR0S4hxLcKqAviA": {
    name: "System Too Lean Bank 1",
    description: "Fuel mixture too lean in bank 1.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "369"
  },
  "am67cEYRleEaeWTlZRA2JXA": {
    name: "Cylinder 9 Misfire Detected",
    description: "Misfire detected in cylinder 9.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "777"
  },
  "ap_ZtkfI5j0yvIW3_ac2frA": {
    name: "Cylinder 7 Misfire Detected",
    description: "Misfire detected in cylinder 7.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "775"
  },
  "aMnY8Qur2fkaA_kOEUDO67w": {
    name: "Cylinder 6 Misfire Detected",
    description: "Misfire detected in cylinder 6.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "774"
  },
  "aQX-QpNV-0ESy5jOhw5cSgg": {
    name: "Cylinder 4 Misfire Detected",
    description: "Misfire detected in cylinder 4.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "772"
  },
  "a_z5LVdLDv02tI03qeQURUQ": {
    name: "Cylinder 5 Misfire Detected",
    description: "Misfire detected in cylinder 5.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "773"
  },
  "aKaZPQpPkR0C-JTX4ADU0kA": {
    name: "Cylinder 3 Misfire Detected",
    description: "Misfire detected in cylinder 3.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "771"
  },
  "a3djJYPXz6U66KKJOZNR-Vg": {
    name: "Cylinder 8 Misfire Detected",
    description: "Misfire detected in cylinder 8.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "776"
  },
  "aM-P0w824akW9ZbMQVR0ZDQ": {
    name: "O2 Sensor Signal Biased/Stuck Lean Bank 1 Sensor 1",
    description: "O2 sensor signal is biased or stuck lean.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "8597"
  },
  "arW2yYfnMZUi01w5v0JGokw": {
    name: "O2 Sensor Signal Biased/Stuck Lean Bank 2 Sensor 1",
    description: "O2 sensor signal is biased or stuck lean.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "8599"
  },
  "aaUc24J9ftUytRwPxsiGEJw": {
    name: "Ignition Coil B Primary Control",
    description: "Issue detected with ignition coil B primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "850"
  },
  "a4h5MoPJVfUGG7otcyUXMhg": {
    name: "Ignition Coil E Primary Control",
    description: "Issue detected with ignition coil E primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "853"
  },
  "av_9CCBtg1EysvrIJOh065Q": {
    name: "Ignition Coil G Primary Control",
    description: "Issue detected with ignition coil G primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "855"
  },
  "amVK3uCTvH0mEv7HQ-BuX-A": {
    name: "Ignition Coil D Primary Control",
    description: "Issue detected with ignition coil D primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "852"
  },
  "aDHjGiD6e4E-bqvpx3N8xTg": {
    name: "Ignition Coil I Primary Control",
    description: "Issue detected with ignition coil I primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "857"
  },
  "a6l84GVB_DkmgUNXAyK_rwg": {
    name: "Ignition Coil F Primary Control",
    description: "Issue detected with ignition coil F primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "854"
  },
  "aghyNbe_lV06xVCFWF64liA": {
    name: "Ignition Coil C Primary Control",
    description: "Issue detected with ignition coil C primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "851"
  },
  "alma_aYcOV0Ke6ymJXkwTng": {
    name: "Ignition Coil H Primary Control",
    description: "Issue detected with ignition coil H primary control.",
    category: "POWERTRAIN",
    riskLevel: 3,
    deviceEventCode: "856"
  },
  "ac6izgCDNSUmB1dGxAW47gQ": {
    name: "Device Reset Due to Parameter Change",
    description: "Device has reset due to a change in configuration parameters.",
    category: "DEVICE",
    riskLevel: 3,
    deviceEventCode: "377"
  }


};
