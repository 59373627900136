import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

function AlertIdQuantityDonut({ alerts }) {
    if (!alerts || alerts.length === 0) {
        return <p></p>;
    }

    // Group alerts by alertId and count occurrences
    const alertIdCounts = alerts.reduce((acc, alert) => {
        const alertId = alert.alertId || 'Unknown';
        acc[alertId] = (acc[alertId] || 0) + 1;
        return acc;
    }, {});

    // Sort alertIds by count and get the top 10
    const sortedAlertIds = Object.entries(alertIdCounts)
        .sort(([, aCount], [, bCount]) => bCount - aCount)
        .slice(0, 5); // Top 10 alertIds

    // Extract labels and data for the chart
    const labels = sortedAlertIds.map(([alertId]) => `${alertId}`);
    const data = sortedAlertIds.map(([, count]) => count);

    // Define chart data
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Quantity by Alert ID',
                data: data,
                backgroundColor: ['#5c496c', '#c35c8b', '#e75b75', '#d67740', '#ffcc00', '#3e78b2'],
                borderColor: '#fff',
                borderWidth: 2,
            },
        ],
    };

    // Chart options
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 20,
                    font: {
                        size: 16,
                        weight: 'bold',
                    },
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                titleFont: {
                    size: 18,
                    weight: 'bold',
                },
                bodyFont: {
                    size: 16,
                },
                padding: 10,
                cornerRadius: 8,
            },
            title: {
                display: true,
                text: 'Alert ID Quantity',
                font: {
                    size: 20,
                    weight: 'bold',
                },
            },
        },
    };

    return (
        <div style={{ width: '80%', height: '100%', position: 'relative' }}>
            <Doughnut data={chartData} options={chartOptions} />
        </div>
    );
}

export default AlertIdQuantityDonut;
