import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Context } from "../ContextHandler";
import moment from "moment";
import { getAlertConfigs, addAlertConfig, updateAlertConfig, deleteAlertConfig, getVehicles, getFaults } from '../api/vehicle';
import ItemListPage from "./ItemListPage";
import { getUsers } from '../api/user';
import { eventMetadataMap } from '../util/events';
import { getRiskLevelText, riskLevels } from '../util/riskLevels';
import { customFilter, toggleFilter } from '../util/filter';

function AlertPage({ token }) {
  const context = useContext(Context);
  const { rid } = useParams();
  const [popupVisible, setPopupVisible] = useState(-1);
  const [severityFilter, setSeverityFilter] = useState([]);
  const [alertTypeFilter, setAlertTypeFilter] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [timeWindow, setTimeWindow] = useState(12);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [filteredEventOptions, setFilteredEventOptions] = useState([]); // Store filtered events
  const [frequency, setFrequency] = useState(0);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(null); // Track selected risk level
  const currentUnitId = localStorage.getItem('unitId');

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const rootId = context.rootId === -1 ? null : context.rootId;
        const today = moment();
        const oneWeekBefore = moment().subtract(7, 'days');
        const date2 = today.format('YYYY-MM-DD');
        const date1 = oneWeekBefore.format('YYYY-MM-DD');
        const [vehiclesResponse, usersResponse, eventsResponse] = await Promise.all([
          getVehicles(context.rootId, null),
          getUsers(rootId),
          getFaults(context.rootId, date1, date2, null),
        ]);
  
        // Set user options after fetching users
        setUserOptions(usersResponse.data.map(user => ({ name: user.name, id: user.id })));
        setVehicleOptions([
          ...vehiclesResponse.data.map(vehicle => ({
            name: vehicle.name,
            id: vehicle.id,
            make: vehicle.make,
            model: vehicle.model,
            year: vehicle.year,
            licensePlate: vehicle.licensePlate,
            status: vehicle.status
          }))
        ]);
        const uniqueEvents = Array.from(
          new Map(eventsResponse.data.map(event => [event.id, event])).values()
        );
        setEventOptions(
          Object.entries(eventMetadataMap).map(([id, event]) => ({
            id: id,
            name: event.name || `Event ${id}`,
            riskLevel: event.riskLevel,
          }))
        );        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  
    // Handle rootId update
    if (rid && context.rootId !== rid) {
      context.dispatch({ type: 'setEnterprise', payload: parseInt(rid) });
    }
  }, [rid, context]);

  const riskLevelChoices = [ 1, 2, 3, 4, 5 ].map(level => ({
    name: getRiskLevelText(level),
    id: level
  }));
  
  

  // Helper function for Super Admin or Creating
  const superAdminOrCreating = (x) => !x.id || context.isSuperAdmin;

  // Columns displayed in the table
  const columns = [
    { key: 'edit', sortable: false },
    { key: 'filterId', header: 'Name' },
    { key: 'riskLevel', header: 'Risk Level' },
    { key: 'frequency', header: 'Event Frequency' },
    { key: 'timeWindow', header: 'Time Window (hours)' },
    { key: 'vehicleIds', header: 'Vehicles' },
    { key: 'contactUserIds', header: 'Clients to receive alerts' },
    { key: 'alertMethods', header: 'Alert Methods' },
  ];
  

  // Fields for creating or editing an alert
  const severityChoices = [
    { name: 'Low', id: 1 },
    { name: 'Medium', id: 2 },
    { name: 'High', id: 3 },
  ];
  
  const alertTypeChoices = [
    { name: 'Type A', id: 'type_a' },
    { name: 'Type B', id: 'type_b' },
    { name: 'Type C', id: 'type_c' },
  ];

  const alertVariableChoices = Object.entries(eventMetadataMap).map(([id, event]) => ({
    name: event.name || id,
    id: id,
  }));

  React.useEffect(() => {
    if (!selectedRiskLevel) {
      setFilteredEventOptions(eventOptions);
      return;
    }

    const filteredEvents = eventOptions.filter(event =>
      eventMetadataMap[event.id]?.riskLevel == selectedRiskLevel
    );

    setFilteredEventOptions(filteredEvents);
  }, [selectedRiskLevel, eventOptions]);
  
  // Fields for creating or editing an alert
  const fields = [
    { key: 'filterId', label: 'Filter Name' },
    { key: 'riskLevel', label: 'Risk Level', choices: riskLevelChoices, onChange: (selected) => setSelectedRiskLevel(selected) },
    { key: 'alertIds', label: 'Event ID', choices: filteredEventOptions, multiple: true },
    {
      key: 'frequency',
      label: 'Event Frequency (# of times over selected period)',
      type: 'number',
      type: 'slider',
      defaultValue: 1
    },
    {
      key: 'timeWindow',
      label: 'Time Window (hours)',
      type: 'slider',
      defaultValue: 1
    },
    {
      key: 'vehicleIds',
      label: 'Vehicle',
      choices: vehicleOptions, // Select individual vehicle,
      placeholder: 'All',
      multiple: true,
      type: 'number'

    },
    {
      key: 'contactUserIds',
      label: 'Clients to Receive Alerts',
      multiple: true,
      choices: userOptions, // Dynamically populated user options
    },
    {
      key: 'sendEmail',
      label: 'Alert By Email',
      type: 'checkbox'
    },
    {
      key: 'sendSms',
      label: 'Alert By SMS',
      type: 'checkbox'
    },
  ];

   const addAlertFilterHook = alertFilter => {
      if (alertFilter.rootUnitId === undefined) {
        alertFilter.rootUnitId =  context.rootId;
      }
      if (alertFilter.unitId === undefined) {
        alertFilter.unitId =  context.rootId;
      }
      return addAlertConfig(alertFilter);
    };
  

  

  const getAlertConfigInfo = (alertConfig, key) => {
    if (key === 'name') {
      return alertConfig.name || alertConfig.id.toString();
    }
    if (key === 'type') {
      return alertConfig.type;
    }
    if (key === 'riskVariable') {
      return alertConfig.riskVariable;
    }    
    if (key === 'severityLevel') {
      const matchingSeverity = severityChoices.find(choice => choice.id === alertConfig.severityLevel);
  
      // Return the name if a match is found, or a default value if no match is found
      return matchingSeverity ? matchingSeverity.name : 'Unknown Severity';
    }
    if (key === 'rootUnitId' || key === 'unitId') {
      return context.units?.find((x) => x.id === alertConfig[key])?.name;
    }
    if (key === 'alertMethods') {
      let methods = [];
      if (alertConfig.sendEmail)
        methods.push('Email');
      if (alertConfig.sendSms)
        methods.push('SMS');
      return methods.join(', ');
    }
    if (key === 'contactUserIds') {
      const userIdToNameMap = Object.fromEntries(userOptions.map(user => [user.id, user.name])); // Create a map of ID -> Name
      const userIds = alertConfig[key];
    
      if (!userIds || userIds.length === 0 || userIds.includes("")) {
        return alertConfig[key] || alertConfig['contactInfo'];
      }
    
      return userIds.filter(id => id).map(id => userIdToNameMap[id] || `Unknown (${id})`).join(', '); // Replace IDs with names
    }
    if (key === 'timeCreated') {
      return new Date(alertConfig.timeCreated).toLocaleString();
    }
    if (key === 'riskLevel') {
      return getRiskLevelText(alertConfig[key]);
    }
    if (key === 'vehicleIds') {
      if (!alertConfig[key] || !Array.isArray(alertConfig[key])) {
        return "No Vehicles Selected";
      }
    
      // If vehicleIds contains `null`, treat it as "All Vehicles"
      if (alertConfig[key].includes(null)) {
        return "All Vehicles";
      }
    
      // Map vehicle IDs to their names using vehicleOptions
      const vehicleNames = alertConfig[key]
        .map(vehicleId => {
          const vehicle = vehicleOptions.find(v => v.id === vehicleId);
          return vehicle ? vehicle.name : `Unknown Vehicle (${vehicleId})`; // Fallback if not found
        })
        .join(", ");
    
      return vehicleNames || "No Vehicles Found";
    }    
    return alertConfig[key];
  };
  
  const getAlertConfigCell = (alertConfig, key) => {
    let val = getAlertConfigInfo(alertConfig, key);
    return <td key={key} className="text">{val}</td>;
  };
  
  

  // Validate the alert filter before submission
  const validateAlertConfig = (alertConfig) => {
    if (!alertConfig.filterId || typeof alertConfig.filterId !== "string") {
      //console.error("Validation Error: 'filterId' is required and must be a string.");
      return false;
    }
  
    if (!alertConfig.riskLevel) {
      //console.error("Validation Error: 'riskVariable' is required and must be a string.");
      return false;
    }
  
    if (!Array.isArray(alertConfig.vehicleIds)) {
      //console.error("Validation Error: 'vehicleIds' must be an array.");
      return false;
    }
  
/*
    if (!alertConfig.contactUserIds || alertConfig.contactUserIds?.length === 0 || alertConfig.contactUserIds?.every(contact => contact.trim() === "")) {
      //console.error("Validation Error: 'contactUserIds' is required and must be a non-empty array without empty values.");
      return false;
    }
*/
  
    if (!alertConfig.sendEmail && !alertConfig.sendSms) {
      //console.error("Validation Error: 'alertMethods' is required and must be a non-empty array without empty values.");
      return false;
    }
  
    if (!alertConfig.frequency || isNaN(alertConfig.frequency) || alertConfig.frequency < 1) {
      //console.error("Validation Error: 'frequency' must be a number greater than or equal to 1.");
      return false;
    }

  
    if (!alertConfig.timeWindow || isNaN(alertConfig.timeWindow) || alertConfig.timeWindow < 1) {
      //console.error("Validation Error: 'timeWindow' must be a number greater than or equal to 1.");
      return false;
    }
  
    //console.log("Validation Passed:", alertConfig);
    return true;
  };
  
  

  // Filters for severity and alert type
  const clearSeverityFilters = () => setSeverityFilter([]);
  const addSeverityFilter = (severity) => setSeverityFilter(toggleFilter(severityFilter, severity));

  const clearAlertTypeFilters = () => setAlertTypeFilter([]);
  const addAlertTypeFilter = (type) => setAlertTypeFilter(toggleFilter(alertTypeFilter, type));

  // Custom filters for severity and alert type
  const customFilters = [
    customFilter(
      severityFilter,
      'severity-filter',
      'Severity',
      1,
      'All Severities',
      severityChoices,
      clearSeverityFilters,
      (severity) => setSeverityFilter(toggleFilter(severityFilter, severity.id)), // Use `id` for filtering
      popupVisible,
      setPopupVisible
    ),
    customFilter(
      alertTypeFilter,
      'alert-type-filter',
      'Alert Type',
      2,
      'All Alert Types',
      alertTypeChoices,
      clearAlertTypeFilters,
      (type) => setAlertTypeFilter(toggleFilter(alertTypeFilter, type.id)), // Use `id` for filtering
      popupVisible,
      setPopupVisible
    ),
  ];
  

  // Filtering logic for the table
  const filterFunc = (x) => 
    (severityFilter.length === 0 || severityFilter.includes(x.severity)) &&
    (alertTypeFilter.length === 0 || alertTypeFilter.includes(x.type));

  return (
    <ItemListPage
      columns={columns}
      fields={fields}
      addItem={addAlertFilterHook}
      updateItem={updateAlertConfig}
      getItems={getAlertConfigs}
      validateItem={validateAlertConfig}
      deleteItem={deleteAlertConfig}
      selectedTab="alerts"
      addItemText="Add Alert Filter"
      editHoverText="Edit Alert Filter"
      itemType="Alerts"
      objectName="alertConfig"
      getItemCell={getAlertConfigCell}
      getItemInfo={getAlertConfigInfo}
      customFilters={customFilters}
      filter={filterFunc}
      headerText={"Alert Filter"}
    />
  );
}

export default AlertPage;
