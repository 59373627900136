import { useControl } from 'react-map-gl';

export const mapboxToken = 'pk.eyJ1IjoicGZhY2NlbCIsImEiOiJjbHV1a3Y3YmkwMDRpMmlwYm9xN3AwNDI0In0.DgtjwUVyRSS7vq3jaXV4Jg';

// Mapbox style URLs
export const MAP_STYLES = {
   navNight: {
     url: 'mapbox://styles/mapbox/navigation-night-v1',
     label: 'Streets (dark)',
   },
  satellite: {
    url: 'mapbox://styles/mapbox/satellite-v9',
    label: 'Satellite',
  },
  streets: {
    url: 'mapbox://styles/mapbox/streets-v12',
    label: 'Streets (light)',
  },
   /*dark: {
     url: 'mapbox://styles/mapbox/dark-v11',
     label: 'Dark',
   },*/
};

// Custom control for style toggle
export const StyleControl = ({ onStyleChange, currentStyle }) => {
  // Implement Mapbox control interface
  class StyleToggleControl {
    onAdd(map) {
      this._map = map;
      this._container = document.createElement('div');
      this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
      this._container.style.padding = '.2vh';
      this._container.style.background = '#fff';
      this._container.style.fontSize = '.8vi';

      Object.entries(MAP_STYLES).forEach(([key, { url, label }]) => {
        const labelElement = document.createElement('label');
        labelElement.style.marginRight = '10px';

        const inputElement = document.createElement('input');
        inputElement.type = 'radio';
        inputElement.name = 'map-style'; // Same name ensures mutual exclusivity
        inputElement.value = key;
        inputElement.checked = currentStyle === url;
        inputElement.onchange = (e) => onStyleChange(e.target.value);

        labelElement.appendChild(inputElement);
        labelElement.append(` ${label}`);

        this._container.appendChild(labelElement);
      });

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  // Add the custom control using useControl hook
  useControl(() => new StyleToggleControl(), { position: 'top-left' });

  return null; // This component doesn't render anything itself
};

