import React, { useState, useEffect, useContext, useRef } from 'react';
import { getVehicle, getDailyTotalByDateForVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';
import { getDuration } from './util/dates';
import DateRangeFilter from "./DateRangeFilter";
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";
import Map, { Marker, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import mapBus from './images/map-bus.svg';
import DashboardDonut from './DashboardDonut';
import { mapboxToken } from './util/mapbox.js';

import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement );

function AssetDetails({ token }) {
  const { id } = useParams();
  const [asset, setAsset] = useState();
  const [trips, setTrips] = useState();
  const navigate = useNavigate();
  const context = useContext(Context);
  const dateRange = context.dateRange;
  const lastRefreshCount = useRef(-1);
  const mapRef = useRef(null);

  // Mapbox GL initial viewport settings
  const [viewState, setViewState] = useState({
      latitude: 32, // latitude[0],
      longitude: -117, // longitude[0],
      zoom: 10
  });

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!asset?.latitude)
       return;
    const pad = .01;
    const bound1 = [asset.longitude-pad, asset.latitude-pad];
    const bound2 = [asset.longitude+pad, asset.latitude+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds, { duration: 0 });
    }
  }, [asset]);

  useEffect(() => {
     if (context.refreshCount != lastRefreshCount.current && dateRange) {
	getVehicle(id).then(result => setAsset(result.data));
        const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
        const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
        getDailyTotalByDateForVehicle(id, date1, date2).then(result => setTrips(result.data));
     }
  }, [context.refreshCount, dateRange]);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    return txt + ": " + mins.toFixed(0) + " min";
  };

  const columns = [
    { header: 'Date', key: 'date' },
    { header: 'Trips', key: 'tripCount' },
    { header: 'Distance Traveled', key: 'milesDriven' },
    { header: 'Time Active', key: 'timeActive' },
    { header: 'Utilization', key: 'utilization', sortable: false, displayOnly: true },
    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
    { header: 'Fuel Consumed', key: 'fuelConsumed' },
    { header: 'Gallons per Minute', key: 'galPerMinute' },
    { header: 'MPG', key: 'mpg' },
    { header: 'Fuel Cost', key: 'fuelSpend' },
    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
    { header: <>CO<sub>2</sub>/Mile (g)</>, downloadHeader: 'CO2e/mile (g)', key: 'co2' },
    { header: 'Efficiency', key: 'efficiency' },
  ];

  const getTripInfo = (trip, key) => {
    if (key == 'upTimeMinutes')
      return trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
    if (key == 'milesDriven')
      return kmToMiles(trip.distanceDriven);
    if (key == 'fuelConsumed')
      return litersToGallons(trip.fuelConsumed);
    if (key == 'galPerMinute') {
      const active = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
      return active ? litersToGallons(trip.fuelConsumed)/active : null;
    }
    if (key == 'mpge')
      return mpge(trip);
    if (key == 'mpg')
      return mpg(trip);
    if (key == 'fuelSpend')
      return trip.fuelSpend;
    if (key == 'costPerMile')
      return trip.distanceDriven > 0 && fuelSpendPerMile(trip);
    if (key == 'co2e')
      return co2eInKg(trip);
    if (key == 'co2')
      return trip.distanceDriven > 0 && co2PerMile(trip);
    if (key == 'averageSpeed')
      return kmToMiles(trip.averageSpeed);
    if (key == 'elevGain')
      return 10.7;
    if (key == 'elevLoss')
      return 10.7;
    return trip[key];
  }

  const round = x => x?.toFixed(2);

  const getTripCell = (trip, key) => {
    if (key == 'utilization') {
      let totalMins = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes + trip.offlineMinutes;
      let offWidth = 100*trip.offlineMinutes / totalMins;
      let idleWidth = 100*trip.idleMinutes / totalMins;
      let decWidth = 100*trip.deceleratingMinutes / totalMins;
      let accWidth = 100*trip.acceleratingMinutes / totalMins;
      return <td className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, trip.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, trip.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, trip.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, trip.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    let val = getTripInfo(trip, key);
    if (key == 'timeActive')
      return <td className="number">{getDuration(60*(trip.idleMinutes+trip.deceleratingMinutes+trip.acceleratingMinutes))}</td>;
    if (key == 'galPerMinute')
      return <td className="number">{val && val.toFixed(4)}</td>;
    if (key == 'efficiency')
      return <td className="number">{(val * 100).toFixed(0)}%</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td className="number">{val}</td>;
    }
    return <td className="text">{val}</td>;
  }

  let eff = trips && (100*(trips.reduce((total, trip) => trip.efficiency + total, 0) / trips.length)).toFixed(0);

  return <>
            <div id="focus">
                <div id="filters">
                    <div id="company" onClick={ev => navigate('/assets')}><a href="#" className="back-button"></a> {asset?.licensePlate}</div>
                    <DateRangeFilter />
                </div>
                <div id="content">
                    <div id="asset-info">
                        <div className="info-content">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Name:</td>
                                        <td>{asset?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Year:</td>
                                        <td>{asset?.year}</td>
                                    </tr>
                                    <tr>
                                        <td>Make:</td>
                                        <td>{asset?.make}</td>
                                    </tr>
                                    <tr>
                                        <td>Model:</td>
                                        <td>{asset?.model}</td>
                                    </tr>
                                    <tr>
                                        <td>VIN:</td>
                                        <td>{asset?.vin}</td>
                                    </tr>
                                    <tr>
                                        <td>Plate:</td>
                                        <td>{asset?.licensePlateState} {asset?.licensePlate}</td>
                                    </tr>
                                    <tr>
                                        <td>Fuel:</td>
                                        <td>{asset?.fuelType}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Weight:</td>
                                        <td>? lbs</td>
                                    </tr>
                                    <tr>
                                        <td>VCG:</td>
                                        <td>?</td>
                                    </tr>
                                    <tr>
                                        <td>GeoTab:</td>
                                        <td>{asset?.geotabId}</td>
                                    </tr>
                                    <tr>
                                        <td>Group:</td>
                                        <td>{context.units?.find(x => x.id == asset?.unitId)?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Mileage:</td>
                                        <td>{asset?.odometer && kmToMiles(asset.odometer / 1000).toFixed(0)}</td>
                                    </tr>
                                    <tr>
                                        <td>Up Time:</td>
                                        <td>52%</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>Operational</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="overall-grade">
                            <h4>Overall Asset Performance</h4>
                            <div className="doughnut">
				<DashboardDonut circleText={eff + '%'} percent={eff} />
                            </div>
                        </div>
                        <div className="asset-photo" />
                        <div id='map' className="horizontal">
			    <Map
				    {...viewState}
				    mapboxAccessToken={mapboxToken} ref={mapRef}
				    onMove={evt => setViewState(evt.viewState)}
				    mapStyle="mapbox://styles/mapbox/streets-v9"
				>
				    {asset?.latitude && <Marker key={asset.id} latitude={asset.latitude} longitude={asset.longitude}>
                                        <div style={{ width: '36px', height: '18px', backgroundImage: 'url(' + mapBus + ')' }} />
				    </Marker>}
			    </Map>
                        </div>
                    </div>
		    <PagedList items={trips} columns={columns} getItemInfo={getTripInfo} getItemCell={getTripCell} itemType="Trips" />
                </div>
            </div>
         </>;
}

export default AssetDetails;

