import ram1500 from '../images/ram1500.png';
import ram3500 from '../images/ram3500.png';
import fordTransit from '../images/ford-transit.png';
import promaster from '../images/ram-promaster.png';
import fordE350 from '../images/ford-e350.png';
import chevyEquinox from '../images/chevy-equinox.png';
import fordETransit from '../images/ford-etransit.png';
import freightlinerM2 from '../images/freightliner-m2.png';

export const carImage = asset => {
  let car;
  if (asset) {
    switch (asset.make+' '+asset.model) {
    case 'Ram 1500':           car = ram1500; break;
    case 'Ram 3500':           car = ram3500; break;
    case 'Ram ProMaster 3500': car = promaster; break;
    case 'Ford E-350':         car = fordE350; break;
    case 'Ford E-450':         car = fordE350; break;
    case 'Chevrolet Equinox EV':   car = chevyEquinox; break;
    case 'Ford E-Transit':     car = fordETransit; break;
    case 'Freightliner M2':    car = freightlinerM2; break;
    }
  }
  return car;
}
