import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getTrips, getTripsForZone, getDailyTotalAll, getFaults } from './api/vehicle';
import { useParams, Link, useNavigate } from 'react-router-dom';
import EventGraph from "./EventGraph";
import EventRadarChart from "./EventRadarChart";
import EventRiskChart from "./EventRiskChart";
import RiskComparisonChart from "./RiskComparisonChart";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import { eventMetadataMap } from './util/events';
import { getRiskLevelText, riskLevels } from './util/riskLevels';
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, mpkwh, co2eInKg, co2PerMile, getVehicleName } from './util/formulas';
import { Context } from "./ContextHandler";
import { Select, MenuItem } from '@mui/material';
import PagedList from "./PagedList";
import { classifyFuelTypes } from './util/fuelTypes';

function getPreviousDateRange(moments) {
  if (!moments)
     return;
  const date1 = moment(moments[0]);
  const date2 = moment(moments[1]);

  // Calculate the number of days between
  const daysBetween = Math.abs(date2.diff(date1, 'days'));

  // Calculate the previous interval
  const previousStart = date1.clone().subtract(daysBetween+1, 'days');
  const previousEnd = date2.clone().subtract(daysBetween+1, 'days');

  return [previousStart, previousEnd];
}

function getDateSpan(dates) {
  if (!dates)
    return;
  const fmt = 'MMM D';
  return moment(dates[0]).format(fmt) + ' - ' + moment(dates[1]).format(fmt);
}

function padDates(array, date1, date2) {
    const start = moment(date1);
    const end = moment(date2);

    const paddedArray = [];
    let current = start.clone();
    let arrayIndex = 0;

    while (current.isSameOrBefore(end, 'day')) {
        const currentDate = current.format("YYYY-MM-DD");

        if (arrayIndex < array.length && array[arrayIndex].date === currentDate) {
            paddedArray.push(array[arrayIndex]);
            arrayIndex++;
        } else {
            paddedArray.push({ date: currentDate });
        }

        current.add(1, 'day');
    }

    return paddedArray;
}


function EventList({ token }) {
  const { zid } = useParams();
  const [events, setEvents] = useState();
  const [trips, setTrips] = useState([]);
  const [totals, setTotals] = useState();
  const [previousEvents, setPreviousEvents] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRangeFilter;
  const previousDateRange = getPreviousDateRange(context.dateRangeFilter);
  const lastRefreshCount = useRef(-1);
  const [definedFilter, setDefinedFilter] = useState(0);
  const [routeFilter, setRouteFilter] = useState([]);
  const [hoursFilter, setHoursFilter] = useState(0);
  const [windowFilter, setWindowFilter] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState(0);
  const [riskLevelFilter, setRiskLevelFilter] = useState([]);
  const [eventCodeFilter, setEventCodeFilter] = useState([]);
  const [popupVisible, setPopupVisible] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [showStationaryTrips, setShowStationaryTrips] = useState(false);
  const navigate = useNavigate();
  const categoryChoices = ["All Categories", "DEVICE", "VEHICLE", "DRIVER"];
  const riskLevelChoices = ["All Severity Levels", "Level 1", "Level 2", "Level 3", "Level 4", "Level 5"];
  const totalDays = moment(dateRange[1]).diff(moment(dateRange[0]), 'days') + 1; // Include both start and end dates


  const toggleRouteFilter = route => {
    if (routeFilter.includes(route))
       setRouteFilter(routeFilter.filter(y => route !== y));
    else
       setRouteFilter([...routeFilter, route]);
  }

  const fixTrips = trips => {
    trips.forEach(t => {
      if (t.fuelConsumed === 0) {
        t.fuelConsumed = t.fuelSpend = t.kwhConsumed = t.efficiency = t.co2Generated = t.co2eGenerated = undefined;
      }
    });
    return trips;
  }

  const fixFaults = (faults) => {
    let filteredFaults = faults;
  
    // Apply groupFilter (unitId)
    if (context.groupFilter && context.groupFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => 
        context.assets.some(vehicle => 
          vehicle.id === fault.vehicleId && context.groupFilter.includes(vehicle.unitId)
        )
      );
    }
  
    // Apply assetFilter (vehicle.id)
    if (context.assetFilter && context.assetFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => context.assetFilter.includes(fault.vehicleId));
    }
  
    // Apply modelFilter (vehicle.model)
    if (context.modelFilter && context.modelFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => 
        context.assets.some(vehicle => 
          vehicle.id === fault.vehicleId && context.modelFilter.includes(vehicle.model)
        )
      );
    }
  
    // Apply fuelTypeFilter (vehicle.fuelType)
    if (context.fuelTypeFilter && context.fuelTypeFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => 
        context.assets.some(vehicle => 
          vehicle.id === fault.vehicleId && context.fuelTypeFilter.includes(vehicle.fuelType)
        )
      );
    }
  
    return filteredFaults;
  };

  useEffect(() => {
    if (context.refreshCount !== lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      
      // Get current period date range
      const date1 = moment(dateRange[0]).format('YYYY-MM-DD');
      const date2 = moment(dateRange[1]).format('YYYY-MM-DD');
      
      // Get previous period date range
      const date3 = moment(previousDateRange[0]).format('YYYY-MM-DD');
      const date4 = moment(previousDateRange[1]).format('YYYY-MM-DD');
      console.log(date3);
      console.log(date4);
  
      const filters = getFilters(context);
  
      Promise.all([
        // Current period data
        getVehicles(context.rootId, filters),
        getTrips(context.rootId, date1, date2, filters),
        getFaults(context.rootId, date1, date2, filters),
  
        // Previous period data
        getTrips(context.rootId, date3, date4, filters),
        getFaults(context.rootId, date3, date4, filters),
      ])
        .then(([vehiclesResponse, tripsResponse, faultsResponse, prevTripsResponse, prevFaultsResponse]) => {
          const vehicles = vehiclesResponse.data;
          const trips = fixTrips(tripsResponse.data);
          const faults = faultsResponse.data;
  
          const prevTrips = fixTrips(prevTripsResponse.data);
          const prevFaults = prevFaultsResponse.data;
  
          setVehicles(vehicles);
  
          // Attach trip & vehicle details to faults
          const faultsWithDetails = faults.map((fault) => {
            const trip = trips.find((trip) => trip.id === fault.tripId);
            const vehicle = vehicles.find((vehicle) => vehicle.id === fault.vehicleId);
            return { ...fault, trip: trip || null, vehicle: vehicle || null };
          });
  
          const prevFaultsWithDetails = prevFaults.map((fault) => {
            const trip = prevTrips.find((trip) => trip.id === fault.tripId);
            const vehicle = vehicles.find((vehicle) => vehicle.id === fault.vehicleId);
            return { ...fault, trip: trip || null, vehicle: vehicle || null };
          });
  
          setEvents(fixFaults(faultsWithDetails));
          setPreviousEvents(fixFaults(prevFaultsWithDetails)); // Store previous period faults separately
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [context.refreshCount, dateRange]);

  console.log(previousEvents);
  console.log(events);
  

  React.useEffect(() => {
    // don't allow "All Enterprises"
    if (context.rootId === -1) {
      context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
    }
  }, []);


  const assetMap = context.assets?.reduce((acc, asset) => { acc[asset.id] = asset; return acc; }, {});

  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;
  if (totals) {
    totals.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }
  // Start with all events
  let filteredEvents = events || [];
  let filteredPreviousEvents = previousEvents || [];

  // Apply Category Filter
  if (categoryFilter > 0) {
    const selectedCategory = categoryChoices[categoryFilter];
    filteredEvents = filteredEvents.filter((x) => eventMetadataMap[x.id]?.category === selectedCategory);
    filteredPreviousEvents = filteredPreviousEvents.filter((x) => eventMetadataMap[x.id]?.category === selectedCategory);
  }

  // Apply Risk Level Filter
  if (riskLevelFilter.length > 0) {
    filteredEvents = filteredEvents.filter((x) => riskLevelFilter.includes(eventMetadataMap[x.id]?.riskLevel));
    filteredPreviousEvents = filteredPreviousEvents.filter((x) => riskLevelFilter.includes(eventMetadataMap[x.id]?.riskLevel));
  }

  // Apply Event Code Filter
  if (eventCodeFilter.length > 0) {
    filteredEvents = filteredEvents.filter((x) => eventCodeFilter.includes(eventMetadataMap[x.id]?.deviceEventCode));
    filteredPreviousEvents = filteredPreviousEvents.filter((x) => eventCodeFilter.includes(eventMetadataMap[x.id]?.deviceEventCode));
  }

  // Apply Hours Filter
  if (hoursFilter === 1) {
    filteredEvents = filteredEvents.filter((x) => x.trip?.openHours === true);
    filteredPreviousEvents = filteredPreviousEvents.filter((x) => x.trip?.openHours === true);
  } else if (hoursFilter === 2) {
    filteredEvents = filteredEvents.filter((x) => x.trip?.openHours === false);
    filteredPreviousEvents = filteredPreviousEvents.filter((x) => x.trip?.openHours === false);
  }
  const windowFunctions = [
    null,
    h => (h >= 6 && h < 10),
    h => (h >= 10 && h < 14),
    h => (h >= 14 && h < 18),
    h => (h >= 18 && h < 22),
    h => (h >= 22 || h < 2),
    h => (h >= 2 && h < 6)
  ];


  // Apply Time Window Filter
  if (windowFilter > 0) {
    filteredEvents = filteredEvents.filter((x) => windowFunctions[windowFilter](moment(x.timestamp).hour()));
    filteredPreviousEvents = filteredPreviousEvents.filter((x) => windowFunctions[windowFilter](moment(x.timestamp).hour()));
  }

  // Apply Route Filter
  if (routeFilter.length > 0) {
    filteredEvents = filteredEvents.filter((x) => x.trip && routeFilter.includes(x.trip.route));
    filteredPreviousEvents = filteredPreviousEvents.filter((x) => x.trip && routeFilter.includes(x.trip.route));
  }



  const hoursChoices = ["All Hours", "Op Hours", "Non-Op Hours"];

  let avgFuelCost, co2PerMile, avgMpg, avgMikwh, avgDistance, distPerDay, avgEff, totalDistance, totalFuelConsumed, chargingMinutes;
  let totalKwhConsumed;

  const noNaN = x => (x === undefined || Number.isNaN(x) ? 0 : x)
  // if (totals && events) {
  //   // use daily totals if there are no trip filters.  otherwise use filtered trips
  //   const list = (filteredEvents == allEvents) ? totals : filteredEvents;
  //   const assetCount = list.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
  //   avgFuelCost = list.reduce((part, a) => part+noNaN(a.fuelSpend),    0)/assetCount;
  //   const totalCo2 = list.reduce((part, a) => part+noNaN(a.co2Generated),    0);
  //   totalDistance = list.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
  //   co2PerMile = totalDistance ? totalCo2/kmToMiles(totalDistance) : 0;
  //   totalFuelConsumed = list.reduce((part, a) => part+noNaN(a.fuelConsumed), 0);
  //   totalKwhConsumed = list.reduce((part, a) => part+noNaN(a.kwhConsumed), 0);
  //   avgDistance = totalDistance/assetCount;
  //   if (list == totals) {
  //     distPerDay  = list.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
  //     chargingMinutes = totals.reduce((part, a) => part+noNaN(a.chargingMinutesPerDay), 0);
  //   } else {
  //     const uniqueDays = new Set()
  //     list.forEach(trip => uniqueDays.add(moment(trip.startTime).format('M-D')))
  //     distPerDay  = totalDistance/uniqueDays.size;
  //     chargingMinutes = list.reduce((part, a) => part+noNaN(a.chargingMinutes), 0) / uniqueDays.size;
  //   }
  //   const assetCountEff = list.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
  //   avgEff      = list.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
  //   avgMpg   = noNaN(kmToMiles(totalDistance)/(litersToGallons(totalFuelConsumed)));
  //   avgMikwh = noNaN(kmToMiles(totalDistance)/(totalKwhConsumed));
  // }
  const eff = noNaN(avgEff*100).toFixed(0);


  const dateFormat = (val, trip, col) => {
      return moment(val).toISOString(true);
  }

  const columns = [
    { header: 'Accelevate Vehicle ID', key: 'vehicleId', width: '5%' },
    { header: 'Event Name', key: 'eventName', width: '5%'},
    { header: 'Category', key: 'category', width: '5%' },
    { header: 'Event Code', key: 'deviceEventCode', width: '5%',  },
    { header: 'Description', key: 'name', width: '20%' },
    { header: 'Device Event Name', key: 'type', width: '20%', downloadOnly: true, extended: true },
    { header: 'Event Description', key: 'eventDescription', width: '30%', downloadOnly: true, extended: true },
    { header: 'Device Event Code', key: 'deviceEventCode', width: '20%', downloadOnly: true, extended: true },
    { header: 'Severity Level', key: 'riskLevel', width: '5%' },
    { header: 'Trip ID', key: 'tripId', width: '5%' },
    { header: 'Timestamp', key: 'timestamp', width: '10%', downloadFormat: dateFormat },
  ];
  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getTripInfo = (trip, key) => {
    if (key == 'name' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && getVehicleName(asset);
    }
    if (key == 'type' && assetMap)
      return assetMap[trip.vehicleId]?.type;
    if (key == 'primaryUse' && assetMap)
      return assetMap[trip.vehicleId]?.primaryUse;
    if (key == 'year' && assetMap)
      return assetMap[trip.vehicleId]?.year;
    if (key == 'fuel' && assetMap)
      return assetMap[trip.vehicleId]?.fuelType;
    if (key == 'makeModel' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && (asset.make + ' ' + asset.model);
    }
    if (key == 'startTime')
      return moment(trip.startTime).toISOString();
    if (key == 'endTime')
      return moment(trip.endTime).toISOString();
    if (key == 'duration')
      return moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    if (key == 'unitId') {
      const asset = assetMap[trip.vehicleId];
      return context.units?.find(x => x.id == asset?.unitId)?.name;
    }
    if (key == 'upTimeMinutes')
      return trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
    if (key == 'galPerMinute')
      return litersToGallons(trip.fuelConsumed) / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'kwhPerMinute')
      return trip.kwhConsumed / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'milesDriven')
      return kmToMiles(trip.distanceDriven);
    if (key == 'fuelConsumed')
      return litersToGallons(trip.fuelConsumed);
    if (key == 'mpge')
      return mpge(trip);
    if (key == 'mpg')
      return mpg(trip);
    if (key == 'mikwh')
      return mpkwh(trip, false);
    if (key == 'fuelSpend')
      return trip.fuelSpend;
    if (key == 'costPerMile')
      return trip.distanceDriven > 0 && fuelSpendPerMile(trip);
    if (key == 'co2e')
      return co2eInKg(trip);
    if (key == 'co2')
      return trip.distanceDriven > 0 && co2PerMile(trip);
    if (key == 'averageSpeed')
      return kmToMiles(trip.averageSpeed);
    if (key == 'maxSpeed')
      return kmToMiles(trip.maxSpeed);
    if (key == 'openHours')
      return trip[key] === undefined ? '' : trip[key] ? 'yes' : 'no';
    return trip[key];
  }
  
  const getEventInfo = (event, key) => {
    const metadata = eventMetadataMap[event.type] || {};

    if (key === 'timestamp') return moment(event.timestamp).toISOString();
    if (key === 'eventName') return metadata.name || "Unknown Event";
    if (key === 'eventDescription') return metadata.description || "No description available";
    if (key === 'category') return metadata.category || "UNKNOWN";
    if (key === 'deviceEventCode') return metadata.deviceEventCode || "UNKNOWN";
    if (key === 'riskLevel') return getRiskLevelText(metadata.riskLevel);
    if (key === 'vehicleId') return event?.vehicle ? event?.vehicle?.name : event.vehicleId;
    if (key === 'description') {
      return metadata.description || "Unknown Description";
    }
  
    return event[key];
  };
  


  const getEventCell = (event, key) => {
    let value = getEventInfo(event, key);
    if (key === 'timestamp') {
      const format = 'M-D h:mma'; // Desired format
      const hoverText = moment(value).utcOffset(0).format('M-D H:mm:ss') + ' GMT'; // Hover text format
      value = moment(value).utcOffset(0).format(format); // Format value to 'M-D h:mma'
      return <td key={key} className="text" title={hoverText}>{value}</td>;
    }    
    if (key === 'category') {
      return <td key={key} className="text">{eventMetadataMap[event.type]?.category || "UNKNOWN"}</td>;
    }    
    if (key == 'tripId') {
      const tripClick = ev => { if (ev.shiftKey) { ev.preventDefault(); navigate('/trips/more/' + value); } }
      return <td key={key} className="text"><Link to={'/trips/' + value} onClick={tripClick}>{value}</Link></td>;
    }
    return <td key={key} className="text">{value}</td>;
  };

  const getDuration = val => {
    const asMin = Math.floor(val/60);
    const hr = Math.floor(asMin/60)
    const min = asMin-hr*60;
    if (hr == 0)
      return `${min}m`;
    return `${hr}h ${min}m`;
  }

  const getTripCell = (trip, key) => {
    if (key == 'utilization') {
      let totalMins = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes + trip.offlineMinutes;
      let offWidth = 100*trip.offlineMinutes / totalMins;
      let idleWidth = 100*trip.idleMinutes / totalMins;
      let decWidth = 100*trip.deceleratingMinutes / totalMins;
      let accWidth = 100*trip.acceleratingMinutes / totalMins;
      return <td key={key} className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, trip.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, trip.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, trip.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, trip.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    let val = getTripInfo(trip, key);
    if (key == 'duration')
      return <td key={key} className="number">{getDuration(val)}</td>;
    if (key == 'efficiency' && val !== undefined)
      return <td key={key} className="number">{(val * 100).toFixed(0)}%</td>;
    if ((key == 'fuelConsumed' || key == 'kwhConsumed') && trip['estimated'])
      return <td key={key} className="number interpolated">{round(val)}</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number">{val}</td>;
    }
    if (key == 'startTime' || key == 'endTime') {
      const format = 'M-D h:mma';
      const hoverText = moment(val).utcOffset(0).format('M-D H:mm:ss') + ' GMT';
      val = moment(val).utcOffset(trip.zoneOffset).format(format);
      return <td key={key} className="text" title={hoverText}>{val}</td>;
    }
    if (key == 'name') {
      const hoverText = trip?.vehicleId;
      const tripClick = ev => { if (ev.shiftKey) { ev.preventDefault(); navigate('/trips/more/' + trip.id); } }
      return <td key={key} className="text" title={hoverText}><Link to={'/trips/' + trip.id} onClick={tripClick}>{val}</Link></td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const selectedIf = x => x ? 'selected' : undefined;

  const routeFilterClicked = ev => {
    if (ev.shiftKey)
      setShowStationaryTrips(!showStationaryTrips);
  }

  const calculateRiskStats = (riskEvents) => {
    if (!riskEvents || riskEvents.length === 0) return { vehiclePercent: 0, driverPercent: 0, riskPerDay: 0, numberOfDays: 0, totalRiskScore: 0 };
  
    const riskScoreMapping = {
      1: 32, // Level 1 - Critical
      2: 16, // Level 2 - Urgent
      3: 8,  // Level 3 - Concern
      4: 4,  // Level 4 - Aware
      5: 1   // Level 5 - Info
    };
  
    let vehicleCount = 0;
    let driverCount = 0;
    let totalRiskScore = 0;
    const uniqueDays = new Set();
  
    riskEvents.forEach(event => {
      const metadata = eventMetadataMap[event.id] || {}; // Get event metadata
      const category = metadata.category || "UNKNOWN";
      const riskLevel = metadata.riskLevel || 5; // Default to Level 5
      const riskScore = riskScoreMapping[riskLevel] || 1; // Get risk score
      const eventDate = moment(event.timestamp).format("YYYY-MM-DD"); // Extract date
  
      uniqueDays.add(eventDate); // Track unique days
  
      if (category === "DRIVER") {
        driverCount++;
      } else if (category === "VEHICLE") {
        vehicleCount++;
      }
  
      totalRiskScore += riskScore; // Accumulate raw risk score
    });
  
    const numberOfDays = uniqueDays.size || 1; // Prevent division by zero
  
    // Calculate total risk events per day
    const totalEvents = vehicleCount + driverCount;
    const riskPerDay = totalRiskScore / numberOfDays; // Use raw risk score instead of count
  
    // Calculate percentage distribution
    const vehiclePercent = totalEvents > 0 ? Math.round((vehicleCount / totalEvents) * 100) : 0;
    const driverPercent = totalEvents > 0 ? Math.round((driverCount / totalEvents) * 100) : 0;
  
    return {
      vehiclePercent,  // Percentage of vehicle risk events
      driverPercent,   // Percentage of driver risk events
      riskPerDay: riskPerDay.toFixed(2), // Total raw risk score per day
      numberOfDays: numberOfDays,
      totalRiskScore // Raw risk score
    };
  };
  
  
  // Compute risk stats for current and previous periods
  const currentRiskStats = calculateRiskStats(filteredEvents);
  const previousRiskStats = calculateRiskStats(filteredPreviousEvents);
  
  

  
  const toggleEventCodeFilter = (code) => {
    if (eventCodeFilter.includes(code)) {
      setEventCodeFilter(eventCodeFilter.filter((x) => x !== code));
    } else {
      setEventCodeFilter([...eventCodeFilter, code]);
    }
  };

  const toggleRiskLevelFilter = (level) => {
    if (riskLevelFilter.includes(level)) {
      setRiskLevelFilter(riskLevelFilter.filter((x) => x !== level));
    } else {
      setRiskLevelFilter([...riskLevelFilter, level]);
    }
  };

  React.useEffect(() => {
    // Remove selected event codes that are no longer available
    setEventCodeFilter(prevFilter => prevFilter.filter(code => eventCodeChoices.includes(code)));
  }, [categoryFilter, riskLevelFilter]);
  

  
  // Extract unique event codes for filtering
// Dynamically filter event codes based on category & risk level selections
const filteredEventCodes = events?.filter(event => {
  const metadata = eventMetadataMap[event.id] || {};
  
  // Apply Category Filter
  const categoryMatch = categoryFilter === 0 || metadata.category === categoryChoices[categoryFilter];

  // Apply Risk Level Filter
  const riskMatch = riskLevelFilter.length === 0 || riskLevelFilter.includes(metadata.riskLevel);

  return categoryMatch && riskMatch;
}).map(event => eventMetadataMap[event.id]?.deviceEventCode)
  .filter(Boolean); // Remove undefined/null values

const eventCodeChoices = ["All Event Codes", ...Array.from(new Set(filteredEventCodes)).sort()];


  
  const tableActions = (
    <>
      {/* Category Filter */}
      <div
        id="category-filter"
        className="dropdown asset-filter"
        onMouseEnter={() => setPopupVisible(1)}
        onMouseLeave={() => setPopupVisible(0)}
      >
        {categoryChoices[categoryFilter]}
        {popupVisible === 1 && (
          <div className="dropdown-content">
            <span>Category</span>
            {categoryChoices.map((x, ix) => (
              <a key={ix} href="#" className={selectedIf(categoryFilter === ix)} onClick={() => setCategoryFilter(ix)}>
                {x}
              </a>
            ))}
          </div>
        )}
      </div>
  
      {/* Risk Level Filter */}
      <div
  id="risk-level-filter"
  className="dropdown asset-filter"
  onMouseEnter={() => setPopupVisible(2)}
  onMouseLeave={() => setPopupVisible(0)}
>
  {riskLevelFilter.length === 0 ? "All Severity Levels" : riskLevelFilter.map(level => `Level ${level}`).join(", ")}
  
  {popupVisible === 2 && (
    <div className="dropdown-content">
      <span>Severity Level</span>
      
      {/* "All Risk Levels" Option */}
      <a 
        href="#" 
        className={selectedIf(riskLevelFilter.length === 0)} 
        onClick={() => setRiskLevelFilter([])}
      >
        All Severity Levels
      </a>

      {/* Individual Risk Level Options */}
      {riskLevelChoices.slice(1).map((level, index) => (
        <a 
          key={index} 
          href="#" 
          className={selectedIf(riskLevelFilter.includes(index + 1))} 
          onClick={() => toggleRiskLevelFilter(index + 1)}
        >
          Level {index + 1}
        </a>
      ))}
    </div>
  )}
</div>

  
      {/* Event Code Filter */}
      <div
        id="event-code-filter"
        className="dropdown asset-filter"
        style={{ width: 250 }}
        onMouseEnter={() => setPopupVisible(3)}
        onMouseLeave={() => setPopupVisible(0)}
      >
        {eventCodeFilter.length === 0 ? "All Event Codes" : eventCodeFilter.join("; ")}
        {popupVisible === 3 && (
          <div className="dropdown-content">
            <span>Event Codes</span>
            <a href="#" className={selectedIf(eventCodeFilter.length === 0)} onClick={() => setEventCodeFilter([])}>
              All Event Codes
            </a>
            {eventCodeChoices.map(
              (code) =>
                code !== "All Event Codes" && (
                  <a key={code} href="#" className={selectedIf(eventCodeFilter.includes(code))} onClick={() => toggleEventCodeFilter(code)}>
                    {code}
                  </a>
                )
            )}
          </div>
        )}
      </div>

    </>
  );
  

  return <>
<div id="focus">
<FilterPanel filters={['enterpriseFilter', 'fuelTypeFilter', 'groupFilter', 'modelFilter', 'assetFilter', 'dateFilter']} />
    <div id="content">
    <div id="charts" style={{ display: 'flex', height: '40%', justifyContent: 'space-between' }}>
            <div className="individual-chart" style={{ width: '55%', height: '100%' }}>
                <div style={{ width: '115%', height: '100%' }}>
                    <EventRiskChart events={filteredEvents} totalDays={totalDays} />
                </div>
            </div>
            <div className="individual-chart" style={{ width: '45%', height: '100%' }}>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <RiskComparisonChart
                  priorPeriod={previousRiskStats}
                  currentPeriod={currentRiskStats}
                  date1={moment(dateRange[0]).format('YYYY-MM-DD')}
                  date2={moment(dateRange[1]).format('YYYY-MM-DD')}
                  date3={moment(previousDateRange[0]).format('YYYY-MM-DD')}
                  date4={moment(previousDateRange[1]).format('YYYY-MM-DD')}
                />

                </div>
            </div>
        </div>
        <PagedList
            items={filteredEvents?.map((event, index) => ({
                ...event,
                type: event.id,
                id: `${index}`
            }))}
            tableActions={tableActions}
            columns={columns}
            getItemInfo={getEventInfo}
            getItemCell={getEventCell}
            itemType="Events"
            extendedDownload={true}
        />
    </div>
</div>
         </>;
}

export default EventList;

