import React, { useState, useEffect, useContext } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Dashboard from './Dashboard';
import axios from 'axios';
import logo from './images/Accelevate_Logo.svg';
import help from './images/help.svg';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Context } from "./ContextHandler";
import DateRangeFilter from './DateRangeFilter';
import { canViewMultipleEnterprises } from './util/security';
import { customFilter } from './util/filter';

function FilterPanel({ filters, customFilters, allowAllEnterprises, title, rightSide }) {

    const context = useContext(Context);
    const [popupVisible, setPopupVisible] = useState(-1);

    if (!filters)
        return;

    const contextFilter = (attrib, id, header, popup, allChoice, choices) => {
        if (!filters.includes(attrib))
	    return;
	const currentFilter = context[attrib] === undefined ? [] : context[attrib];
	const setFilter = x => {
	     context.dispatch({ type: 'setState', attribute: attrib, payload: x });
	     context.dispatch({ type: 'refresh' });
	};
        const clearFilter = () => setFilter([]);
	const addFilter = x => {
	    if (currentFilter.includes(x))
	       setFilter(currentFilter.filter(y => x !== y));
	    else
	       setFilter([...currentFilter, x]);
	}
        return customFilter(currentFilter, id, header, popup, allChoice, choices, clearFilter, addFilter, popupVisible, setPopupVisible);
    }

    const groups = context.units || [];

    const fuelTypes = [ { name: 'Gas', id: 'GAS' }, { name: 'LPG', id: 'LPG' }, { name: 'Electric', id: 'EV' },
                        { name: 'CNG', id: 'CNG' }, { name: 'Propane', id: 'PROPANE' }, { name: 'Diesel', id: 'DIESEL' } ];

    const enterpriseId = context.rootId;
    const setEnterprise = e => context.dispatch({ type: 'setEnterprise', payload: e });
    const clearEnterprises = () => setEnterprise(-1);
    const enterprises = context.enterprises?.map(x => ({ id: x.unitId, name: x.name }));
    const enterpriseFilter = enterpriseId == -1 ? [] : [parseInt(enterpriseId)];
    const getCompanyName = () => {
        const rootUnit = parseInt(localStorage.getItem('rootUnitId'))
        return context.enterprises?.find(x => x.unitId == rootUnit)?.name;
    }

    const applyFilter = (attrib, val) => {
	const currentFilter = context[attrib] === undefined ? [] : context[attrib];
	return currentFilter.length == 0 || currentFilter.includes(val);
    };

    const modelAssets = context.assets?.filter(asset => applyFilter('groupFilter', asset.unitId) &&
						        applyFilter('fuelTypeFilter', asset.fuelType));
    const models = [...new Set(modelAssets?.map(x => x.make + ' ' + x.model))].sort().map(x => ({name: x, id: x }));
    const assets = modelAssets?.filter(asset => applyFilter('modelFilter', asset.make + ' ' + asset.model));

    return <div id="filters">
	       <div id="company">{title === undefined ? getCompanyName() : title}</div>
	       {filters.includes('enterpriseFilter') && canViewMultipleEnterprises() &&
                  customFilter(enterpriseFilter, 'enterprise-filter', 'Enterprise', 5,
			 allowAllEnterprises && 'All Enterprises', enterprises,
                         clearEnterprises, setEnterprise, popupVisible, setPopupVisible)}

	       {contextFilter('groupFilter', 'group-customer-filter', 'Business Unit', 2, 'All Business Units', groups)}

               {contextFilter('fuelTypeFilter', 'fuel-type-filter', 'Fuel Type', 1, 'All Fuel Types', fuelTypes)}

	       {contextFilter('modelFilter', 'vehicle-type-filter', 'Make / Model', 3, 'All Makes / Models', models)}

	       {contextFilter('assetFilter', 'vehicle-filter', 'Vehicles', 4, 'All Vehicles', assets)}
	       {customFilters}

	       {filters.includes('dateFilter') && <DateRangeFilter />}
	       {rightSide}
	  </div>;
}

export default FilterPanel;

