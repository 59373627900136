export const FuelTypes = {
  Gas: 0,
  EV: 2,
  Diesel: 5
};

export const fuelTypesList = [ 'GAS', 1, 'EV', 3, 4, 'DIESEL' ];

export const classifyFuelTypes = fuelTypes => {
  let onlyIce = false, onlyEv = false, mixed = false;
  if (fuelTypes.size === 1 && fuelTypes.has('EV')) {
    onlyEv = true;
  } else if ([...fuelTypes].every(type => type === 'GAS' || type === 'DIESEL')) {
    onlyIce = true;
  } else if (fuelTypes.size === 1) {
    onlyIce = true;
  } else {
    mixed = true;
  }
  return { onlyIce: onlyIce, onlyEv: onlyEv, mixed: mixed };
}

