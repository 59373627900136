import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';

import { getFilters, getVehicles, addVehicle, updateVehicle } from '../api/vehicle';
import Filter from "../Filter";
import { kmToMiles, getVehicleName } from '../util/formulas';
import PagedList from "../PagedList";
import ItemListPage from "./ItemListPage";

function AssetPage({ token }) {
  const context = useContext(Context);

  const columns = [
		    { header: 'Edit', key: 'edit', sortable: false },
		    { header: 'Asset Name', key: 'name', width: '12%' },
		    { header: 'Business Unit', key: 'unitId' },
		    { header: 'Installed Devices', key: 'devices' },
		    { header: 'Geotab ID', key: 'geotabId' },
		    { header: 'Make / Model', key: 'makeModel' },
		    { header: 'Year', key: 'year' },
		    { header: 'Fuel', key: 'fuelType' },
		    { header: 'License Plate', key: 'licensePlate' },
		    { header: 'VIN', key: 'vin' },
		    { header: 'Mileage', key: 'mileage' },
		    { header: 'Accelevate Licenses', key: 'licenses' },
		    { header: 'Status', key: 'status' },
  ];

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getAssetInfo = (asset, key) => {
    if (key == 'name')
      return getVehicleName(asset);
    if (key == 'unitId')
      return context.units.find(x => x.id == asset[key])?.name;
    if (key == 'makeModel')
      return asset.make + ' ' + asset.model;
    if (key == 'group')
      return asset.groups?.map(x => x.name).join(', ')
    if (key == 'mileage')
      return kmToMiles(asset.odometer/1000);
    return asset[key];
  }

  const getAssetCell = (asset, key) => {
    let val = getAssetInfo(asset, key);
    if (key == 'name')
      return <td class="text"><Link to={'/assets/' + asset.id}>{val}</Link></td>
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td class="number">{val}</td>;
    }
    return <td class="text">{val}</td>;
  }

  const fields = [
       { key: 'name' },
       { key: 'make' },
       { key: 'model' },
       { key: 'year' },
       { key: 'fuelType', choices: [
         { id: 'GAS', name: 'Gas' },
         { id: 'LPG', name: 'LPG' },
         { id: 'EV', name: 'EV' },
         { id: 'CNG', name: 'CNG' },
         { id: 'PROPANE', name: 'Propane' },
         { id: 'DIESEL', name: 'Diesel' }
       ]},
       { key: 'licensePlate' },
       { label: 'VIN', key: 'vin' },
       { label: 'VCG ID', key: 'vcgId' },
       { label: 'Geotab Id', key: 'geotabId' },
       { key: 'indexName' },
       { label: 'Business Unit', key: 'unitId', choices: context.units }
  ];

  return <ItemListPage columns={columns} fields={fields} addItem={addVehicle} updateItem={updateVehicle} getItems={getVehicles}
                       getItemInfo={getAssetInfo} getItemCell={getAssetCell} selectedTab="vehicles" addItemText="Add Vehicle" 
                       editHoverText="Edit Vehicle" itemType="Assets" />;

//function ItemListPage({ columns, fields, addItem, updateItem, getItems, getItemInfo, getItemCell, selectedTab, headerText, addItemText, itemType }) {

}

export default AssetPage;

