import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { getTrip, getTelemetry, getTelemetrySignals, downloadTelemetry } from './api/trip';
import { LineChart, ResponsiveContainer, Line, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { kmToMiles, litersToGallons, co2InKg, mpkwh, mpge, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';
import { Select, MenuItem } from '@mui/material';
import DateRangeFilter from "./DateRangeFilter";
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";
import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import { getFeatures } from './util/zones';
import { getVehicleZones } from './api/vehicle';
import { mapboxToken } from './util/mapbox.js';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement );

function TripMoreDetails({ token }) {
  const { id } = useParams();
  const [trip, setTrip] = useState();
  const [gpsTelemetry, setGpsTelemetry] = useState();
  const [zones, setZones] = useState([]);
  const navigate = useNavigate();
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const [hoverInfo, setHoverInfo] = useState();
  const mapRef = useRef(null);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v9');
  const fetchState = useRef({});
  const [telemetry, setTelemetry] = useState();
  const [selectedTelemetry, setSelectedTelemetry] = useState();
  const [selectedSignal, setSelectedSignal] = useState('speed');
  const [signals, setSignals] = useState(['speed']);

  const handleStyleChange = (event) => {
    const selectedStyle = event.target.value;
    setMapStyle(selectedStyle);
  };

  const latitudes = gpsTelemetry && (gpsTelemetry.items[0].values);
  const longitudes = gpsTelemetry && (gpsTelemetry.items[1].values);
  const minGpsTimestamp = gpsTelemetry && gpsTelemetry.timestamps[0];
  const maxGpsTimestamp = gpsTelemetry && gpsTelemetry.timestamps[gpsTelemetry.timestamps.length-1];
  const xDomainMax = maxGpsTimestamp - minGpsTimestamp;
  let timestamps = [];
  let output = [];

    const telemetryLoaded = () => {
      if (!selectedTelemetry)
        return false;
      return selectedTelemetry.items.find(x => x.signal === selectedSignal);
    };

    React.useEffect(() => {
      if (trip && !telemetryLoaded() && fetchState.current.fetchingSignal !== selectedSignal) {
        fetchState.current.fetchingSignal = selectedSignal;
        getTelemetry(trip.id, [selectedSignal]).then(response => setSelectedTelemetry(response.data));
      }
    });

    let ymin = 1e10;
    let ymax = -1e10;

    if (selectedTelemetry && selectedTelemetry.items.length == 1) {
      const items = selectedTelemetry.items;
      timestamps = selectedTelemetry.timestamps;
      for (let i = 0; i != selectedTelemetry.timestamps.length; i++) {
        const val = items[0].values[i];
        if (val < ymin) ymin = val;
        if (val > ymax) ymax = val;
	output.push(val);
      }
    }

    // State for storing mouse position
    const [mousePosition, setMousePosition] = useState(null);

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 34, // latitude[0],
        longitude: -118.4, // longitude[0],
        zoom: 10
    });

    // Handler for mouse move event on the graph
    const handleMouseMove = (event) => {
	const activePayload = event.activePayload;
	if (activePayload && activePayload.length > 0) {
           const timestamp = Math.round(activePayload[0].payload.index) + minGpsTimestamp;
           const realIndex = timestamps.findIndex(x => x == timestamp);
	   let nearestIndex = -1;
	   let minDifference = Infinity;
	   gpsTelemetry.timestamps?.forEach((value, index) => {
		const difference = Math.abs(value - timestamp);
		if (difference < minDifference) {
		  minDifference = difference;
		  nearestIndex = index;
		}
	   });
            if (nearestIndex >= 0 && latitudes.length > 0)
             setMousePosition({ latitude: latitudes[nearestIndex], longitude: longitudes[nearestIndex], value: output[realIndex], index: timestamp });
	    else
	      setMousePosition(null); // No data point hovered over, clear mouse position
	} else {
	    setMousePosition(null); // No data point hovered over, clear mouse position
	}
    };

    const handleSignalChange = event => { setSelectedSignal(event.target.value); setSelectedTelemetry(); }

    // Custom formatter function to convert index to time format
    const formatTime = (index) => {
        // Generate time format based on index (example implementation)
        const mom = moment.unix(index + minGpsTimestamp);
        // const formattedString = mom.format('YYYY-MM-DD h:mm:ssa');
        const formattedString = mom.format('h:mm:ssa');
        return formattedString;
    };

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!gpsTelemetry || latitudes.length == 0)
       return;
    const pad = .01;
    const bound1 = [Math.min(...longitudes)-pad, Math.min(...latitudes)-pad];
    const bound2 = [Math.max(...longitudes)+pad, Math.max(...latitudes)+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds, { duration: 0 });
    }
  }, [gpsTelemetry]);

  useEffect(() => {
     if (context.refreshCount != lastRefreshCount.current) {
	getTrip(id).then(result => {
            const t = result.data;
            setTrip(t);
            getTelemetrySignals(t.id).then(result => {
		setSignals(result.data);
            });
            getTelemetry(t.id, ['latitude', 'longitude']).then(result => {
               setGpsTelemetry(result.data);
            });
            getVehicleZones(t.vehicleId).then(response => setZones(response.data));
        });
     }
  }, [context.refreshCount]);

  const getPopupInfo = () => {
    const getType = prop => {
      if (prop.type == 'OpCenter') return "Op Center";
      if (prop.type == 'RemoteHub') return "Remote Hub";
      if (prop.defined) return "Tracking";
      return "Undefined";
    };
    return <div style={{ fontSize: 15 }}>
             {hoverInfo.properties.name}<br />
             {getType(hoverInfo.properties)}
           </div>;
  }

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    return txt + ": " + mins.toFixed(0) + " min";
  };

  const round = x => x?.toFixed(2);
  const round1 = x => x?.toFixed(1);

  const avgCo2 = 0, avgMpge = 0, avgDistance = 0;
  const round0 = x => x?.toFixed(0)

  const tripTime = trip && moment(trip.startTime).utcOffset(trip.zoneOffset).format('M-D @ h:mmA')
  const tripTimeUTC = trip && moment(trip.startTime).utcOffset(0).format('M-D @ H:mm:ss')
  const tripEndTimeUTC = trip && moment(trip.endTime).utcOffset(0).format('H:mm:ss')
  let tripLength;
  if (trip) {
    const dur = moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    tripLength = (dur / 60).toFixed(0) + 'm ' + (dur % 60).toFixed(0) + 's';
  }

  const geojson: FeatureCollection = gpsTelemetry && {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: {type: 'LineString', coordinates: latitudes.map((lat, index) => [longitudes[index], lat]) }}
    ]
  };

  const layerStyle: LineLayer = {
    id: 'route',
    type: 'line',
    source: 'route',
	      'layout': {
		  'line-join': 'round',
		  'line-cap': 'round'
	      },

    paint: {
      'line-width': 3,
      'line-color': '#007cbf'
    }
  };

  const features = getFeatures(zones);
 
  const zone_geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: features
  };

  const onHover = useCallback(e => {
    const features = e.features;
    const hoveredFeature = features && features[0];
    if (hoveredFeature)
      setHoverInfo({
	longitude: e.lngLat.lng,
	latitude: e.lngLat.lat,
	properties: hoveredFeature.properties
      });
    else
      setHoverInfo(null);
  }, []);

  const rounded = val => Math.round(val*1000)/1000;

  const ice = trip?.fuelType != 'EV';

  const doDownload = () => {
    downloadTelemetry(id).then(response => {

	// Create a Blob from the response data
	const blob = new Blob([response.data], { type: response.headers['content-type'] });

	// Generate a download link
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
        link.download = 'trip' + id + '.jsonl';
	document.body.appendChild(link);

	// Programmatically click the link to trigger the download
	link.click();

	// Clean up
	link.remove();
	window.URL.revokeObjectURL(link.href);
    });
  }

  return <>
            <div id="focus">
                <div id="filters">
                    <div id="company"><a href="#" className="back-button" onClick={ev => navigate('/trips')} />{trip?.vehicleName} ({trip?.vehicleId}) Trip {tripTime}, {tripLength} ({tripTimeUTC} - {tripEndTimeUTC} UTC)
                      (<a href='#' onClick={doDownload}>Download</a>)<br/>
                    <span>Route: {trip?.route}</span>
                    </div>
                </div>
                <div id="content">
        {signals && <Select
          labelId="select-label"
          id="select"
          value={selectedSignal}
          onChange={handleSignalChange}
          label="Select an option"
        >
          {signals.sort().map(x => <MenuItem value={x}>{x}</MenuItem>)}
        </Select>}
	    <ResponsiveContainer width="95%" height={300}>
            <LineChart data={output.map((value, index) => ({ index: timestamps[index]-minGpsTimestamp, value: rounded(value) }))} onMouseMove={handleMouseMove}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis domain={[0, xDomainMax]} dataKey="index" type="number" tickFormatter={formatTime} />
                <YAxis domain={[rounded(ymin), rounded(ymax)]} />
                <Tooltip labelFormatter={formatTime} isAnimationActive={false} />
                <Line type="linear" dataKey="value" stroke="#ff7300" strokeWidth={2} dot={true} />
            </LineChart>
	    </ResponsiveContainer>
                    <div id="map" style={{ position: 'relative' }}>
		      <div style={{ position: 'absolute', zIndex: 100, backgroundColor: 'white', borderRadius: 5, padding: 3 }}>
			<label>
			  <input
			    type="radio"
			    value="mapbox://styles/mapbox/streets-v9"
			    checked={mapStyle === 'mapbox://styles/mapbox/streets-v9'}
			    onChange={handleStyleChange}
			  />
			  Streets
			</label>
			<label>
			  <input
			    type="radio"
			    value="mapbox://styles/mapbox/satellite-v9"
			    checked={mapStyle === 'mapbox://styles/mapbox/satellite-v9'}
			    onChange={handleStyleChange}
			  />
			  Satellite
			</label>
		      </div>

			<Map
				{...viewState}
				mapboxAccessToken={mapboxToken} ref={mapRef}
				onMove={evt => setViewState(evt.viewState)}
				mapStyle={mapStyle} interactiveLayerIds={['polygon-layer']}
      				onMouseMove={onHover}>
			  <Source id="route" type="geojson" data={geojson}>
			    <Layer {...layerStyle} />
			  </Source>
			  <Source id="polygon-data" type="geojson" data={zone_geojson}>
                            <Layer id="polygon-layer" type="fill" paint={{ 'fill-color': ['get', 'color'], 'fill-opacity': 0.9, }} />
                            <Layer id="polygon-outline" type="line" paint={{ 'line-color': '#000', 'line-width': 2, }} />
			  </Source>
                    {mousePosition && (
                        <Marker key={-1} latitude={mousePosition.latitude} longitude={mousePosition.longitude}>
                            <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'yellow', border: '2px solid white' }} />
                        </Marker>
                    )}
			    {hoverInfo && (
			      <Popup
				longitude={hoverInfo.longitude}
				latitude={hoverInfo.latitude}
				closeButton={false}
				closeOnClick={false}
				anchor="top"
			      >
                                {getPopupInfo()}
			      </Popup>
			    )}
			</Map>
		    </div>
                </div>
            </div>
         </>;
}

export default TripMoreDetails;

