import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, annotationPlugin);

const DashboardChart = ({ width, height, data, func }) => {
    if (!data)
	return;

    const dataPoints = data.map(func);
    const avg = dataPoints.reduce((a, b) => typeof b === 'number' ? a+b : a, 0) / dataPoints.length;

    const chartData = {
        labels: data.map(x => x.date.substring(5)),
        datasets: [
   {
            label: "Data",
            data: dataPoints,
            fill: 1, // Fill between this dataset and the constant average dataset (index -1)
            borderColor: '#F85E7D',
            borderWidth: 2,
            tension: 0.1,
            pointStyle: 'circle',
            pointRadius: 1,
            pointBorderColor: '#F85E7D',
            backgroundColor: 'rgba(248, 94, 125, 0.2)', // Light pinkish shading for the filled area
        },
        {
            label: "Average",
            data: dataPoints.map(() => avg), // Constant line at avg
            fill: false, // No fill for the line itself
            borderColor: 'gray', // Gray color for the average line
            borderWidth: 2,
            pointRadius: 0, // No points for the average line
        },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
	  legend: { display: false },
        }
    };

    return <Line data={chartData} options={options} width={width} height={height} />;
};

export default DashboardChart;

