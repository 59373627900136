export const customFilter = (currentFilter, id, header, popup, allChoice, choices, clearFilter, addFilter, popupVisible, setPopupVisible) => {
  let label = allChoice;

  const getName = x => x === undefined ? '' : 'name' in x ? x.name : x.id;

  if (currentFilter.length > 0 && choices) {
    let names = currentFilter.map(fid => getName(choices.find(asset => asset.id === fid)));
    if (names.length > 2)
      names = [...names.slice(0, 2), '...']
    if (names.some(x => x.includes(',')))
      label = names.join('; ');
    else
      label = names.join(', ')
  }

  return <div id={id} key={id} className="dropdown asset-filter"
		onMouseEnter={ev => setPopupVisible(popup)} onMouseLeave={ev => setPopupVisible(0)}>{label}
	       {popupVisible == popup && <div className="dropdown-content">
		   <span>{header}</span>
		   {allChoice && <a href="#" className={currentFilter.length === 0 ? "selected" : ""}
			       onClick={ev => clearFilter()}>{allChoice}</a>}
		   {choices?.map(choice => <a key={choice.id} href="#" className={currentFilter.includes(choice.id) ? 'selected' : ""}
						onClick={ev => addFilter(choice.id, choice)}>{getName(choice)}</a>)}
	       </div>}
	   </div>;
};

export const toggleFilter = (currentFilter, x) =>  {
     if (currentFilter.includes(x))
       return currentFilter.filter(y => x !== y);
     else
       return [...currentFilter, x];
}

export const contextFilter = (context, attrib, id, header, popup, allChoice, choices, popupVisible, setPopupVisible) => {
    const currentFilter = context[attrib] === undefined ? [] : context[attrib];
    const setFilter = x => {
	 context.dispatch({ type: 'setState', attribute: attrib, payload: x });
	 context.dispatch({ type: 'refresh' });
    };
    const clearFilter = () => setFilter([]);
    const addFilter = (x, choice) => {
        if (choice.exclusive)
           setFilter([x]);
	else if (currentFilter.includes(x))
	   setFilter(currentFilter.filter(y => x !== y));
	else
	   setFilter([...currentFilter, x]);
    }
    return customFilter(currentFilter, id, header, popup, allChoice, choices, clearFilter, addFilter, popupVisible, setPopupVisible);
}

