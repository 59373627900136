import React, { useState, useRef, useEffect, useContext } from 'react';
import Map, { Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import type {FeatureCollection} from 'geojson';
import DrawControl from '../draw-control';
import { circle } from "@turf/turf";
import { mapboxToken } from '../util/mapbox.js';

import CircleMode from './mapbox-gl-draw-circle/modes/CircleMode';
import DragCircleMode from './mapbox-gl-draw-circle/modes/DragCircleMode';
import DirectModeOverride from './mapbox-gl-draw-circle/modes/DirectModeOverride';
import SimpleSelectModeOverride from './mapbox-gl-draw-circle/modes/SimpleSelectModeOverride';
import MapboxDraw from '@mapbox/mapbox-gl-draw';


function ZoneModalMap({ geojson, initialViewState, getBounds, initialData, setZoneGeometry, shape }) {
  // can't create zones yet but will add later
  const [createState, setCreateState] = useState(1);
  const [newFeature, setNewFeature] = useState({});

  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const shapeRef = useRef(shape);

  const updatePoints = feature => {
    let newPoints = [];
    const coordArr = feature.geometry.coordinates;
    const drawPolygon = (shapeRef.current != 'PointRadius');
    const coordinates = (drawPolygon) ? coordArr[0] : [feature.properties.center];
    coordinates.forEach(coord => newPoints.push(coord[1], coord[0]));
    if (!drawPolygon)
      setZoneGeometry({ points: newPoints, radius: feature.properties.radiusInKm * 1000 });
    else
      setZoneGeometry({ points: newPoints });
  };

  const onCreate = React.useCallback(e => {
     setNewFeature(e.features[0]);
     updatePoints(e.features[0]);
     setCreateState(2);
  }, []);

  const onUpdate = React.useCallback(e => {
     setNewFeature(e.features[0]);
     updatePoints(e.features[0]);
  }, []);

  const onDelete = React.useCallback(e => {
  }, []);

  const [viewState, setViewState] = useState(initialViewState);

  const new_geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: newFeature && [newFeature]
  };

  const makePolygonFeature = (pts) => {
    let coords = [];
    for (let i = 0; i < pts.length; i += 2)
      coords.push([pts[i+1], pts[i]]);
    return {
	type: 'Feature',
	geometry: {
	  type: 'Polygon',
	  coordinates: [coords]
	}
    };
  }

  const makeCircleFeature = (pts, radius) => {
    let coords = [];
    const center = [pts[1], pts[0]];
    const circleFeature = circle(center, radius/1000);
    return {
	type: 'Feature',
	geometry: {
	  type: 'Polygon',
	  coordinates: circleFeature.geometry.coordinates,
	},
        properties: {
          center: center,
          isCircle: true,
          radiusInKm: radius/1000
        }
    };
  }

  const mapLoaded = ev => {

    const drawPolygon = (shape != 'PointRadius');

    // Initialize the draw control when the map loads
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      defaultMode: initialData.id ? 'simple_select' : drawPolygon ? "draw_polygon" : "draw_circle",
      userProperties: true,
      modes: { ...MapboxDraw.modes,
               draw_circle  : CircleMode,
               drag_circle  : DragCircleMode,
               direct_select: DirectModeOverride,
               simple_select: SimpleSelectModeOverride
             }
    });

    mapRef.current.getMap().addControl(draw);

    drawRef.current = draw;

    //mapRef.current.getMap().on('draw.selectionchange', updateDeleteButtonState);
    mapRef.current.getMap().on('draw.create', onCreate);
    mapRef.current.getMap().on('draw.delete', onDelete);
    mapRef.current.getMap().on('draw.update', onUpdate);

    if (initialData.id) {
      ev.target.fitBounds(getBounds([initialData]), { duration: 0 });
      if (drawPolygon)
        draw.add(makePolygonFeature(initialData.points));
      else
        draw.add(makeCircleFeature(initialData.points, initialData.radius));
    }
  };

  React.useEffect(() => {
    if (shapeRef.current != shape && drawRef.current) {
      shapeRef.current = shape;
      const draw = drawRef.current;
      draw.deleteAll();
      draw.changeMode(shape == 'Polygon' ? 'draw_polygon' : 'draw_circle');
    }
  }, [shape]);

  return <tr>
	   <td colspan="2" className="map">
	     <div id="modal-map">
	       <Map
		   {...viewState}
		   mapboxAccessToken={mapboxToken} ref={mapRef}
		   onMove={evt => setViewState(evt.viewState)}
		   mapStyle="mapbox://styles/mapbox/streets-v9"
		   interactiveLayerIds={['polygon-layer']}
		   onLoad={mapLoaded}
	       >
		  <Source id="polygon-data" type="geojson" data={geojson}>
		    <Layer id="polygon-layer" type="fill" paint={{ 'fill-color': ['get', 'color'], 'fill-opacity': 0.9, }} />
		    <Layer id="polygon-outline" type="line" paint={{ 'line-color': '#000', 'line-width': 2, }} />
		  </Source>
	       </Map>
	     </div>
	   </td>
	 </tr>;

}

export default ZoneModalMap;
