import React, { useState } from "react";
import releaseNotes1 from "./images/version2.1_releasenotes-1.png";
import releaseNotes2 from "./images/version2.1_releasenotes-2.png";

function FleetVersion() {
  const [isVisible, setIsVisible] = useState(false);

  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: "0.5vh",
        left: "2vw",
        textAlign: "center",
        color: "rgb(51, 51, 51)",
        transition: "0.3s",
      }}
    >
      {/* Fleet Version Text (Click to Toggle) */}
      <p
        style={{
          lineHeight: "2.1vh",
          fontSize: "clamp(0.7vi, 12px, 10px)",
          color: "rgb(51, 51, 51)",
          cursor: "pointer",
          fontWeight: "bold",
        }}
        onClick={togglePopup}
      >
        Fleet V2.1.0
      </p>

      {/* Release Notes Popup */}
      {isVisible && (
        <div
          style={{
            position: "fixed",
            top: "10vh",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            padding: "16px",
            borderRadius: "10px",
            boxShadow: "0 8px 16px rgba(0,0,0,0.3)",
            zIndex: 999,
            width: "90vw",
            maxWidth: "1700px",
            maxHeight: "85vh",
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={togglePopup}
            style={{
              position: "absolute",
              top: "8px",
              right: "12px",
              background: "black",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Close ✖
          </button>

          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: "1700px",
            }}
          >
            <img
              src={releaseNotes1}
              alt="Fleet V2.1.0 Release Notes Page 1"
              style={{
                width: "50%",
                borderRadius: "8px",
                maxWidth: "800px",
              }}
            />
            <img
              src={releaseNotes2}
              alt="Fleet V2.1.0 Release Notes Page 2"
              style={{
                width: "50%",
                borderRadius: "8px",
                maxWidth: "800px",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default FleetVersion;
