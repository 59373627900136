import axios from 'axios';

export const getFilters = context => {
    const filterCheck = x => (x !== undefined && x.length == 0) ? undefined : x;
    let fuelTypeFilter = filterCheck(context.fuelTypeFilter);
    let assetFilter = filterCheck(context.assetFilter);
    let groupFilter = filterCheck(context.groupFilter);
    let modelFilter = filterCheck(context.modelFilter);
    const rootId = (context.rootId == -1) ? null : context.rootId;
    if (!fuelTypeFilter && !assetFilter && !modelFilter && !groupFilter)
      return { root: rootId };
    return { root: rootId,
             vehicles: context.assets?.filter(asset => {
		  if (fuelTypeFilter && !fuelTypeFilter.includes(asset.fuelType))
		    return false;
		  if (modelFilter && !modelFilter.includes(asset.make + ' ' + asset.model))
		    return false;
		  if (assetFilter && !assetFilter.includes(asset.id))
		    return false;
		  if (groupFilter && !groupFilter.includes(asset.unitId))
		    return false;
		  return true;
	   }).map(x => x.id).join(',') };
}

export const getVehicles = (root, filters) => {
    if (!filters)
       filters = { root: root };
    return axios.get('/vehicle', { params: filters })
}

export const getVehicle = (id) => axios.get('/vehicle/' + id)
export const updateVehicle = (id, veh) => axios.put('/vehicle/' + id, veh)
export const addVehicle = (veh) => axios.post('/vehicle', veh)
export const getTripsForVehicle = (id) => axios.get('/vehicle/' + id + '/trips?startDate=2024-04-01&endDate=2024-08-01')
export const getNewTripsForVehicle = (id, date1, date2) => axios.get(`/vehicle/${id}/trips?startDate=${date1}&endDate=${date2}`)
export const getTripsForVehicleByDay = (id, date1, date2) => axios.get(`/vehicle/${id}/tripsByDay?startDate=${date1}&endDate=${date2}`)
export const getDailyTotalByDate = (root, date1, date2, filters) =>
    axios.get('/vehicle/dailyTotalByDate/root/' + root, { params: { ...filters, startDate: date1, endDate: date2 }})
export const getDailyTotalByDateForVehicle = (id, date1, date2, filters) =>
    axios.get(`/vehicle/${id}/dailyTotalByDate`, { params: { ...filters, startDate: date1, endDate: date2 }})
export const getDailyTotalForVehicle = (id, date1, date2, filters) =>
    axios.get(`/vehicle/${id}/dailyTotal`, { params: { ...filters, startDate: date1, endDate: date2 }})
export const getDailyTotal = (root, date1, date2, filters) =>
    axios.get('/vehicle/dailyTotal/root/' + root,       { params: { ...filters, startDate: date1, endDate: date2 }})
export const getDailyTotalAll = (root, date1, date2, filters) =>
    axios.get('/vehicle/dailyTotalAll/root/' + root,    { params: { ...filters, startDate: date1, endDate: date2 }})
export const getTrips = (root, date1, date2, filters) => axios.get('/vehicle/trips/root/' + root, { params: { ...filters, startDate: date1, endDate: date2 }})
export const getTripsForZone = (id) => axios.get('/zone/' + id + '/trips')
export const getVehicleZones = (id) => axios.get('/vehicle/' + id + '/zones')

export const getFaults = (root, date1, date2) => axios.get('/fault', { params: { startDate: date1, endDate: date2, root: root == -1 ? null : root }})
export const getAlerts = (root, date1, date2) => axios.get('/alert', { params: { startDate: date1, endDate: date2, root: root == -1 ? null : root }})

// Fetch all AlertConfigs, optionally filtered by rootUnitId
export const getAlertConfigs = (root) => axios.get('/alert/filter', { params: { root: root == -1 ? null : root }});

// Fetch a single AlertConfig by its ID
export const getAlertConfig = (id) => 
  axios.get(`/alert/filter/${id}`);

// Create a new AlertConfig
export const addAlertConfig = (alertConfig) => 
  axios.post('/alert/filter', alertConfig);

// Update an existing AlertConfig by ID
export const updateAlertConfig = (id, updatedAlertConfig) => 
  axios.put(`/alert/filter/${id}`, updatedAlertConfig);

// Delete an AlertConfig by its ID
export const deleteAlertConfig = (id) => 
  axios.delete(`/alert/filter/${id}`);

export const updateAlert = (id, data) => 
  axios.put(`/alert/${id}`, data);

