import React from 'react';
import { Link } from 'react-router-dom';

function Tabs({ selected }) {
  return (
                <ul id="settings-tabs">
                    <li class={selected == 'enterprise' && 'selected'}><Link to='/settings/enterprise'>Enterprise</Link></li>
                    <li class={selected == 'units' && 'selected'}><Link to='/settings/units'>Business Units</Link></li>
                    <li class={selected == 'opcenters' && 'selected'}><Link to='/settings/opcenters'>Op Centers</Link></li>
                    <li class={selected == 'zones' && 'selected'}><Link to='/settings/zones'>Zones</Link></li>
                    <li class={selected == 'users' && 'selected'}><Link to='/settings/users'>Users</Link></li>
                    <li class={selected == 'vehicles' && 'selected'}><Link to='/settings/vehicles'>Assets/Vehicles</Link></li>
                    <li class={selected == 'devices' && 'selected'}><Link to='/settings/devices'>Gateway Devices</Link></li>
                    <li class={selected == 'account' && 'selected'}><Link to='/settings/account'>Account</Link></li>
                </ul>
  );
}

export default Tabs;

