import React, { useState } from 'react';
import axios from 'axios';
import './css/styles.css';
import logo from './images/Accelevate Logo White.svg';
import ebiLogo from './images/EBI.png'; 

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleLogin = async () => {
      axios.post('/auth/login', { username, password }).then(result => {
        const data = result.data;
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('name', data.name);
        localStorage.setItem('unitId', data.unitId);
        localStorage.setItem('rootUnitId', data.rootUnitId);
        localStorage.setItem('role', data.role);
        
	// clear filters
	Object.keys(localStorage)
	  .filter(key => key.endsWith("Filter")) // Find keys ending with "Filter"
	  .forEach(key => localStorage.removeItem(key)); // Remove each matching key

        const token = data.token;
        const payload = token.split('.')[1];
        const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
        const payloadObj = JSON.parse(decodedPayload);
        onLogin(data.token, payloadObj.exp);
        setError(false);
      }).catch(error => {
        setError(true);
      });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const style1 = { color: '#f85e7d', fontSize: '1.5vw', fontFamily: '"Helvetica Neue",Arial,sans-serif', fontWeight: 700, lineHeight: '1.7vw' };
  const style2 = { color: 'white'  , fontSize: '1.5vw', fontFamily: '"Helvetica Neue",Arial,sans-serif', fontWeight: 700, lineHeight: '1.7vw' };

  return (
	    <div id="content" className="login">
		<div className="left">
		    <img src={logo} />
		    <h3><span><strong style={style1}>Start</strong></span><span><strong style={style2}> Where You Are.<br/></strong></span><span><strong style={style1}>Optimize</strong></span><span><strong style={style2}> What You Have.<br/>
                      </strong></span><span><strong style={style1}>Maximize</strong></span><span><strong style={style2}> Where You’re Going.</strong></span></h3>
		</div>
		<div className="right">
                    <img src={ebiLogo} />
                    <div id="error" style={{ visibility: error ? 'visible' : 'hidden' }}>Login failed, please try again.</div>
		    <form id="login" onSubmit={ev => ev.preventDefault()}>
			<label htmlFor="username">Username</label>
                        <input type="text" value={username} onChange={handleUsernameChange} />
			<label htmlFor="password">Password</label>
                        <input type="password" value={password} onChange={handlePasswordChange} />
                        <input type="submit" onClick={handleLogin} name="login" value="Login" />
			{/*<p><a href="#">Forgot Password?</a></p>*/}
		    </form>

		</div>
	    </div>
  );
}

export default Login;

