import React, { useState, useRef } from 'react';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import { LineChart, ResponsiveContainer, Line, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getTelemetry, getSignals } from './api/trip.js';
import { getVehicle } from './api/vehicle.js';
import type {LineLayer} from 'react-map-gl';
import type {FeatureCollection} from 'geojson';
import { useParams } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import moment from "moment";

const RouteView = () => {
    // Generate sample data (sine waves)
/*
    const numPoints = 100;
    const frequency1 = 1; // Frequency of sine wave 1
    const amplitude1 = 10; // Amplitude of sine wave 1
    const frequency2 = 2; // Frequency of sine wave 2
    const amplitude2 = 6; // Amplitude of sine wave 2
*/
    let output = [];
    let timestamps = [];
    const fetchState = useRef({});
    const [vehicle, setVehicle] = useState();
    const [telemetry, setTelemetry] = useState();
    const [selectedTelemetry, setSelectedTelemetry] = useState();
    const [selectedSignal, setSelectedSignal] = useState('speed');
    const signals = ['speed', 'engineSpeed', 'deviceTotalFuel', 'odometer', 'accelerationForwardBraking', 'accelerationSideToSide', 'accelerationUpDown', 'engineCoolantTemperature', 'outsideTemperature', 'crankingVoltage', 'ignition'];

    let latitude = [];
    let longitude = [];
    let { vid, start, end } = useParams();
/* 
    for (let i = 0; i < numPoints; i++) {
        latitude.push(40 + Math.sin((i / numPoints) * frequency1 * Math.PI * 2) * amplitude1 * .005);
        longitude.push(-80 + Math.sin((i / numPoints) * frequency2 * Math.PI * 2) * amplitude2 * .005);
        output.push(Math.sin((i / numPoints) * frequency1 * Math.PI * 2) * amplitude1 +
                    Math.sin((i / numPoints) * frequency2 * Math.PI * 2) * amplitude2);
    }
*/

    const telemetryLoaded = () => {
      if (!selectedTelemetry)
        return false;
      return selectedTelemetry.items.find(x => x.signal === selectedSignal);
    };

    React.useEffect(() => {
      if (!vehicle) {
        getVehicle(vid).then(result => setVehicle(result.data));
      }
      if (!telemetryLoaded() && fetchState.current.fetchingSignal !== selectedSignal) {
        fetchState.current.fetchingSignal = selectedSignal;
        if (selectedSignal === 'speed') {
          if (telemetry)
            setSelectedTelemetry(telemetry);
          else
            getTelemetry(vid, ['latitude', 'longitude', 'speed'], start, end).then(response => {
               setTelemetry(response.data);
               setSelectedTelemetry(response.data);
            });
        } else {
          getTelemetry(vid, [selectedSignal], start, end).then(response => setSelectedTelemetry(response.data));
        }
      }
    });

    const unpack = arr => {
      let ret = [];
      arr.forEach(x => {
        if (x.includes('*')) {
          let q = x.split('*');
          for (let i = 0; i < q[1]; i++)
            ret.push(q[0]);
        } else {
          ret.push(x);
        }
      });
      return ret;
    };

    let ymin = 1e10;
    let ymax = -1e10;

    if (selectedTelemetry && selectedTelemetry.items.length == 3) {
      const items = telemetry.items;
      timestamps = telemetry.timestamps;
      for (let i = 0; i != telemetry.timestamps.length; i++) {
        const val = items[2].values[i];
        if (val < ymin) ymin = val;
        if (val > ymax) ymax = val;
	output.push(val);
        latitude.push(items[0].values[i]);
        longitude.push(items[1].values[i]);
      }
    
    }
    if (selectedTelemetry && selectedTelemetry.items.length == 1) {
      const items = selectedTelemetry.items;
      timestamps = selectedTelemetry.timestamps;
      for (let i = 0; i != selectedTelemetry.timestamps.length; i++) {
        const val = items[0].values[i];
        if (val < ymin) ymin = val;
        if (val > ymax) ymax = val;
	output.push(val);
      }
    }

    // State for storing mouse position
    const [mousePosition, setMousePosition] = useState(null);

    // Mapbox token
    const mapboxToken = 'pk.eyJ1IjoicGZhY2NlbCIsImEiOiJjbHV1a3Y3YmkwMDRpMmlwYm9xN3AwNDI0In0.DgtjwUVyRSS7vq3jaXV4Jg';

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 34, // latitude[0],
        longitude: -118.4, // longitude[0],
        zoom: 10
    });

    // Handler for mouse move event on the graph
    const handleMouseMove = (event) => {
	const activePayload = event.activePayload;
	if (activePayload && activePayload.length > 0) {
           const index = Math.round(activePayload[0].payload.index) + timestamps[0];
            const realIndex = timestamps.findIndex(x => x == index);
            if (realIndex >= 0 && latitude.length > 0)
             setMousePosition({ latitude: latitude[realIndex], longitude: longitude[realIndex], value: output[realIndex], index });
	    else
	      setMousePosition(null); // No data point hovered over, clear mouse position
	} else {
	    setMousePosition(null); // No data point hovered over, clear mouse position
	}
    };

    const handleSignalChange = event => setSelectedSignal(event.target.value);

    // Custom formatter function to convert index to time format
    const formatTime = (index) => {
        // Generate time format based on index (example implementation)
        const mom = moment.unix(index + timestamps[0]);
        // const formattedString = mom.format('YYYY-MM-DD h:mm:ssa');
        const formattedString = mom.format('h:mm:ssa');
        return formattedString;
    };

    const geojson: FeatureCollection = {
      type: 'FeatureCollection',
      features: [
	//{type: 'Feature', geometry: {type: 'LineString', coordinates: latitude.map((lat, index) => [longitude[index], lat]) }}
      ]
    };

    const layerStyle: LineLayer = {
      id: 'route',
      type: 'line',
      source: 'route',
		'layout': {
		    'line-join': 'round',
		    'line-cap': 'round'
		},

      paint: {
	'line-width': 3,
	'line-color': '#007cbf'
      }
    };

    const getStartTime = () => {
        const mom = moment(start);
        return mom.format('YYYY-MM-DD');
    };

    const rounded = val => Math.round(val*1000)/1000;

    return (
        <div>
            <div style={{ height: '600px', width: '100%', marginBottom: '20px' }}>
                <Map
                    {...viewState}
                    mapboxAccessToken={mapboxToken}
                    onMove={evt => setViewState(evt.viewState)}
		    mapStyle="mapbox://styles/mapbox/streets-v9"
                >
		    <Source id="route" type="geojson" data={geojson}>
		      <Layer {...layerStyle} />
		    </Source>
		    {mousePosition && (
			<Marker key={-1} latitude={mousePosition.latitude} longitude={mousePosition.longitude}>
			    <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'yellow', border: '2px solid white' }} />
			</Marker>
		    )}
                </Map>
            </div>
        <div style={{ height: '2em' }}>{vehicle?.name} ({vehicle?.geotabId}), {getStartTime()}</div>
        <Select
          labelId="select-label"
          id="select"
          value={selectedSignal}
          onChange={handleSignalChange}
          label="Select an option"
        >
          {signals && signals.sort().map(x => <MenuItem value={x}>{x}</MenuItem>)}
        </Select>

	    <ResponsiveContainer width="95%" height={300}>
            <LineChart data={output.map((value, index) => ({ index: timestamps[index]-timestamps[0], value: rounded(value) }))} onMouseMove={handleMouseMove}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="index" type="number" tickFormatter={formatTime} />
                <YAxis domain={[rounded(ymin), rounded(ymax)]} />
                <Tooltip labelFormatter={formatTime} isAnimationActive={false} />
                <Line type="linear" dataKey="value" stroke="#ff7300" strokeWidth={2} dot={false} />
            </LineChart>
	    </ResponsiveContainer>
        </div>
    );
};

export default RouteView;

