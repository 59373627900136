import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';
import Form from './Form';
import FilterPanel from '../FilterPanel';
import Tree from 'react-d3-tree';
import deleteIcon from '../images/delete.svg';
import addIcon from '../images/add.svg';
import { unitFields } from './unitFields';
import { addUnit, getUnits, updateUnit, deleteUnit } from '../api/unit';
import { isAdmin } from '../util/security';

function TreePage({ token }) {
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const [showForm, setShowForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [initialData, setInitialData] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [units, setUnits] = useState();

  React.useEffect(() => {
    // don't allow "All Enterprises"
    if (context.rootId === -1) {
       context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
    }
  }, []);

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current) {
      lastRefreshCount.current = context.refreshCount;
      getUnits(context.rootId).then(response => setUnits(response.data));
    }
  });
  
  const typeChoices = [
      { id: 'OpCenter', name: 'Op Center' },
      { id: 'BusinessUnit', name: 'Business Unit' },
  ];

  const addChild = (ev, node) => {
    ev.stopPropagation();
    setInitialData({ parentId: node.unit.id })
    setShowForm(true);
  };
  const deleteNode = (ev, node) => {
    ev.stopPropagation();
    setIdToDelete(node.unit.id);
    setShowConfirm(true);
  };
  const editNode = node => {
    if (node.unit.type == 'Enterprise' || node.unit.readOnly)
      return;
    setInitialData(node.unit);
    setShowForm(true);
  };
  const formFinished = () => setShowForm(false);

  const refresh = () => {
    context.dispatch({ type: 'refreshUnits' });
    lastRefreshCount.current = 0;
  }

  const confirmDelete = ev => {
    ev.preventDefault();
    setShowConfirm(false);
    deleteUnit(idToDelete).then(result => refresh());
  }
  const cancelDelete = ev => {
    ev.preventDefault();
    setShowConfirm(false);
  }

  const renderFn = ({ nodeDatum, toggleNode }) => {
    const nodeData = nodeDatum;
    const unit = nodeData.unit;
    return <React.Fragment>
      <foreignObject width={ 120 } height={ 85 } x={-60} y={-40} >
        <div
          style={{
            height: 70,
            width: 100,
            display: 'inline-block',
            padding: '5px',
	    textAlign: 'center',
            position: 'relative',
            boxShadow: '1px 1px 0px #ddd',
            border: '1px solid #D2D2D8',
            //backgroundColor: 'rgb(248, 248, 255)', // ghostwhite
            backgroundColor: unit.readOnly ? '#E3E2EC' : 'white',
          }}
          onClick={ev => editNode(nodeData)}
        >
          <h2 style={{ fontSize: 16 }}>{nodeData.name}</h2>
          <p style={{ fontSize: 12 }}>{!unit.readOnly && <Link to={"/settings/users/" + unit.rootId + '/' + unit.id}
                                            style={{ fontSize: 12 }}>Users</Link>}
             {nodeData.hasAssets && <> | <Link to={"/settings/vehicles/" + unit.rootId + '/' + unit.id}
					    style={{ fontSize: 12 }}>Assets</Link></>}
          </p>
          {nodeData.canAddChild && <div style={{ bottom: 3, left: 5, paddingLeft: 13, height: 16, paddingTop: 5,
                        background: 'url(' + addIcon + ') no-repeat 0 6px',
                        backgroundSize: 11, fontSize: 12, position: 'absolute' }} onClick={ev => addChild(ev, nodeData)}>Add Child</div>}
          {nodeData.canDelete && <div style={{ background: 'url(' + deleteIcon + ') no-repeat', width: 13, height: 13, bottom: 8,
                        right: 5, backgroundSize: 13, position: 'absolute' }} onClick={ev => deleteNode(ev, nodeData)}></div>}
        </div>
      </foreignObject>
    </React.Fragment>
  };

  const makeOrgChart = () => {
    if (!units)
      return;

    if (!isAdmin())
      units.forEach(u => u.readOnly = true);

    // Step 1: Create a map of units by their id
    const unitMap = new Map();
    units.forEach(unit => unitMap.set(unit.id,
            { name: unit.name,
              children: [],
              canDelete: unit.type != 'OpCenter' && unit.type != 'Enterprise' && !unit.readOnly,
              canAddChild: unit.type != 'OpCenter' && !unit.readOnly,
              hasAssets: unit.type == 'OpCenter',
              unit: unit
    }));
  
    // Step 2: Initialize an array for top-level units (no parentId)
    const hierarchy = [];

    let topLevel;

    // Step 3: Populate the hierarchy
    units.forEach(unit => {
        if (unit.parentId) {
            // Find the parent in the unit map and add this unit to its children
            const parent = unitMap.get(unit.parentId);
            if (parent) {
                parent.children.push(unitMap.get(unit.id));
                parent.canDelete = false;
            }
        } else {
            // No parentId means this is a top-level unit
            topLevel = unitMap.get(unit.id);
        }
    });

    return topLevel;
  };

  const orgChart = makeOrgChart();

  const validateUnit = unit => (unit.type !== undefined && unit.name);

  const handleVeilClick = ev => {
    if (ev.target.id == 'modal')
      setShowForm(false);
  };

  return <>
            <div id="focus">
                <Tabs selected="units" />
                <FilterPanel filters={['enterpriseFilter']} />
                <div id="content">
		    <div id="treeWrapper" style={{ width: '100%' }}>
                       {orgChart && <Tree data={orgChart} orientation="vertical" pathFunc="step" collapsible={false}
                             renderCustomNodeElement={renderFn} nodeSize={{ x: 120, y: 140 }}
			     translate={{ x: 800, y: 100 }} />}
                    </div>
                </div>
            </div>
           {showForm && <div id="modal" className="veil" style={{ display: 'block' }} onClick={handleVeilClick}>
            <div className={"modal-popup units"}>
                <div className="modal-content">
                    <Form fields={unitFields} addItem={addUnit} updateItem={updateUnit} validateItem={validateUnit}
			  headerText={"Business Unit"}
                          formFinished={formFinished} initialData={initialData} refresh={refresh} />
                </div>
            </div>
          </div>}
           {showConfirm && <div id="confirm" className="veil" style={{ display: 'block' }}>
            <div className="modal-popup">
                <div className="modal-content">
                    <form id="confirm" className="settings-form">
                        <table>
                            <tr>
                                <td className="header">Are you sure?</td>
                            </tr>
                            <tr>
                                <td>You're about to delete a business unit. Any users or vehicles associated with this unit
				 will need to be reassigned to another unit.<br/><br/>Do you wish to continue?</td>
                            </tr> 
                            <tr>
                                <td className="submit"><button type="submit" onClick={confirmDelete}>Yes, delete the unit</button>
					<button className="modal-cancel" onClick={cancelDelete}>Cancel</button></td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
           </div>}

         </>;
}

export default TreePage;

