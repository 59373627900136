import React, { useState, useEffect } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AssetList from './AssetList';
import AssetDetails from './AssetDetails';
import TripList from './TripList';
import EventList from './EventList';
import AlertsList from './AlertsList';
import FleetVersion from "./FleetVersion";
import TripDetails from './TripDetails';
import TripMoreDetails from './TripMoreDetails';
import ZonePage from './settings/ZonePage';
import EnterprisePage from './settings/EnterprisePage';
import TreePage from './settings/TreePage';
import UserPage from './settings/UserPage';
import AlertPage from './settings/AlertPage';
import MonitorPage from './MonitorPage';
import AssetPage from './settings/AssetPage';
import DevicePage from './settings/DevicePage';
import AccountPage from './settings/AccountPage';
import OpCenterPage from './settings/OpCenterPage';
import FleetPage from './FleetPage';
import Trending from './Trending';
import axios from 'axios';
import logo from './images/Accelevate_Logo.svg';
import ebiLogo from './images/EBI.png'; 
import help from './images/help.svg';

function FleetManager({ handleLogout }) {
   const name = localStorage.getItem('name');
   const initials = name.split(' ').map(x => x.substr(0, 1)).join('');
   const [isPopupVisible, setPopupVisible] = useState(false);
   const getContent = (selection, cls, content) => (
    <div id="main" className={cls} style={{ display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <div
        id="nav"
        style={{
          width: '4.5vi',
          backgroundColor: '#fff',
          borderRight: '1px solid #ddd',
          height: '100%',
          padding: '2vi 3vi 2vi 1vi', 
          fontFamily: 'Arial, sans-serif',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexShrink: 0,
        }}
      >
        <div>
          <div className="sidebar-section">
            <h4
              style={{
                fontSize: ".78vi",
                color: "#333",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "19px",
                marginTop: '0px',
                whiteSpace: 'nowrap'
              }}
            >
              EBI Smart Hub
            </h4>
            <ul style={{ listStyle: "none", padding: "0", paddingLeft: '.8vh' }}>
              <li className={selection === "monitor" ? "monitor selected" : "monitor"}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    fontWeight: selection === "monitor" ? "bold" : "normal",
                  }}
                >
                  Operations
                </Link>
              </li>
              <li className={selection === "trending" ? "home selected" : "home"}>
                <Link
                  to="/trending"
                  style={{
                    textDecoration: "none",
                    // marginLeft: '10px',
                    fontWeight: selection === "trending" ? "bold" : "normal",
                  }}
                >
                  Trending
                </Link>
              </li>
              <li className={selection === "dashboard" ? "home selected" : "home"}>
                <Link
                  to="/fleet"
                  style={{
                    textDecoration: "none",
                    // marginLeft: '10px',
                    fontWeight: selection === "dashboard" ? "bold" : "normal",
                  }}
                >
                  Fleet
                </Link>
              </li>
            </ul>
            <div
              style={{
                width: "calc(4.5vi + 4vi)",
                height: "1px",
                backgroundColor: "lightgray",
                marginTop: "40px",
                marginLeft: "-2vi",
              }}
            ></div>
          </div>
  
          <div className="sidebar-section">
            <h4
              style={{
                fontSize: ".78vi",
                fontWeight: "bold",
                textTransform: "uppercase",
                whiteSpace: 'nowrap',
                marginTop: "30px",
                marginBottom: "19px",
              }}
            >
              EBI Analytics
            </h4>
            <ul style={{ listStyle: "none", padding: "0", paddingLeft: '.8vh' }}>
              <li className={selection.startsWith("asset") ? "assets selected" : "assets"}>
                <Link
                  to="/assets"
                  style={{
                    textDecoration: "none",
                    fontWeight: selection.startsWith("asset") ? "bold" : "normal",
                  }}
                >
                  Assets
                </Link>
              </li>
              <li className={selection.startsWith("trips") ? "trips selected" : "trips"}>
                <Link
                  to="/trips"
                  style={{
                    textDecoration: "none",
                    fontWeight: selection.startsWith("trips") ? "bold" : "normal",
                  }}
                >
                  Trips
                </Link>
              </li>
              <li className={selection.startsWith("events") ? "events selected" : "events"}>
                <Link
                  to="/events"
                  style={{
                    textDecoration: "none",
                    fontWeight: selection.startsWith("events") ? "bold" : "normal",
                  }}
                >
                  Events
                </Link>
              </li>
              <li className={selection.startsWith("alerts") ? "alerts selected" : "alerts"}>
                <Link
                  to="/alerts"
                  style={{
                    textDecoration: "none",
                    fontWeight: selection.startsWith("alerts") ? "bold" : "normal",
                  }}
                >
                  Alerts
                </Link>
              </li>
            </ul>
          </div>
        </div>
  
        <div style={{ textAlign: "center", paddingBottom: "10px" }}>
          <div className={selection === "settings" ? "settings selected" : "settings"}>
            <Link to="/settings" style={{ textDecoration: "none", color: "#333" }}>
              Settings
            </Link>
          </div>
        </div>
        <FleetVersion />
      </div>
  
      <div id="focus" >
        {content}
      </div>
    </div>
  );

  return (
    <Router>
        <header style={{ 
            display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between", 
            position: "relative"
        }}>
            {/* Left Side - EBI Logo */}
            <div style={{ display: "flex", alignItems: "center", width: "33.3%" }}>
                <Link id='logo' to="/">
                    <img src={ebiLogo} alt="EBI Logo" 
                        style={{ maxWidth: "192px", height: "auto", minWidth: "80px", width: "27vw" }} 
                    />
                </Link>
            </div>

            {/* Centered Main Logo */}
            <div style={{ 
                position: "absolute", 
                left: "50%", 
                transform: "translateX(-50%)"
            }}>
                <Link id='logo' to="/">
                    <img src={logo} alt="Main Logo" />
                </Link>
            </div>

            {/* Right Side - Account & Help */}
            <div style={{ 
                display: "flex", 
                alignItems: "center", 
                gap: "15px", 
                justifyContent: "flex-end", 
                width: "33.3%"
            }}>
                <div id="account" className="dropdown" 
                    onMouseEnter={() => setPopupVisible(true)} 
                    onMouseLeave={() => setPopupVisible(false)}
                >
                    <span>{initials}</span>
                    {isPopupVisible && (
                        <div className="dropdown-content">
                            <span>{name}</span>
			    <Link to="/settings/account">Account</Link>
                            <a href="#" onClick={handleLogout}>Sign out</a>
                        </div>
                    )}
                </div>
                <a id="help">
                    <div class="help-container">
                        <span class="help-tooltip">Coming Soon</span>
                        <img src={help} alt="Help Icon" />
                    </div>
                </a>

            </div>
        </header>
	<Routes>
	  <Route path="/assets/:id" element={getContent('asset', 'asset-details', <AssetDetails />)} />
	  <Route path="/assets"     element={getContent('assetList', 'assets', <AssetList />)} />
	  <Route path="/trips/zone/:zid" element={getContent('tripsList', 'trips',  <TripList />)} />
	  <Route path="/trips/more/:id"  element={getContent('trips',     'trips',  <TripMoreDetails />)} />
	  <Route path="/trips/:id"  element={getContent('trips',     'trips',  <TripDetails />)} />
	  <Route path="/trips"      element={getContent('tripsList', 'trips',  <TripList />)} />
	  <Route path="/events"      element={getContent('eventsList', 'events',  <EventList />)} />
	  <Route path="/alerts"      element={getContent('alertsList', 'alerts',  <AlertsList />)} />
	  <Route path="/settings/units"  element={getContent('settings', 'businessunit',  <TreePage />)} />
	  <Route path="/settings/zones"  element={getContent('settings', 'zones',  <ZonePage />)} />
	  <Route path="/settings/users/:rid?/:id?"  element={getContent('settings', 'users',  <UserPage />)} />
	  <Route path="/settings/alerts"  element={getContent('settings', 'alerts',  <AlertPage />)} />
	  <Route path="/settings/opcenters" element={getContent('settings', 'op-centers',  <OpCenterPage />)} />
	  <Route path="/settings/vehicles/:rid?/:id?"  element={getContent('settings', 'vehicles',  <AssetPage />)} />
	  <Route path="/settings/devices"  element={getContent('settings', 'devices',  <DevicePage />)} />
	  <Route path="/settings/account"  element={getContent('settings', 'account',  <AccountPage />)} />
	  <Route path="/settings/*" element={getContent('settings',  'enterprise',  <EnterprisePage />)} />
	  <Route path="/fleet"      element={getContent('dashboard', 'home',   <FleetPage />)} />
	  <Route path="/trending"   element={getContent('trending', 'home',   <Trending />)} />
	  <Route path="/"        element={getContent('monitor', 'monitor',  <MonitorPage />)} />
	</Routes>
    </Router>
  );
}

export default FleetManager;

