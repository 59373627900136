import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

function AlertSeverityDonut({ alerts }) {
    if (!alerts || alerts.length === 0) {
        return <p></p>;
    }

    // Group alerts by vehicle and sum severities
    const vehicleSeverityCounts = alerts.reduce((acc, alert) => {
        const vehicleId = alert?.vehicle?.name || 'Unknown';
        const severity = alert.severity || 0;
        acc[vehicleId] = (acc[vehicleId] || 0) + severity;
        return acc;
    }, {});

    // Sort vehicles by total severity and get the top 10
    const sortedVehicles = Object.entries(vehicleSeverityCounts)
        .sort(([, aSeverity], [, bSeverity]) => bSeverity - aSeverity)
        .slice(0, 5); // Top 10 vehicles

    // Extract labels and data for the chart
    const labels = sortedVehicles.map(([vehicleId]) => `${vehicleId}`);
    const data = sortedVehicles.map(([, totalSeverity]) => totalSeverity);

    // Define chart data
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Total Severity by Vehicle',
                data: data,
                backgroundColor: ['#5c496c', '#c35c8b', '#e75b75', '#d67740', '#ffcc00', '#3e78b2'],
                borderColor: '#fff',
                borderWidth: 2,
            },
        ],
    };

    // Chart options
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 20,
                    font: {
                        size: 16,
                        weight: 'bold',
                    },
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                titleFont: {
                    size: 18,
                    weight: 'bold',
                },
                bodyFont: {
                    size: 16,
                },
                padding: 10,
                cornerRadius: 8,
            },
            title: {
                display: true,
                text: 'Vehicles with Most Severe Alerts',
                font: {
                    size: 20,
                    weight: 'bold',
                },
            },
        },
    };

    return (
        <div style={{ width: '80%', height: '100%', position: 'relative' }}>
            <Doughnut data={chartData} options={chartOptions} />
        </div>
    );
}

export default AlertSeverityDonut;
