import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';

import { getFilters, getDevices, addDevice, updateDevice, deleteDevice } from '../api/device';
import { getVehicles } from '../api/vehicle';
import Filter from "../Filter";
import { kmToMiles, getVehicleName } from '../util/formulas';
import PagedList from "../PagedList";
import ItemListPage from "./ItemListPage";

function DevicePage({ token }) {
  const context = useContext(Context);

  const columns = [ { key: 'edit', sortable: false },
                    { key: 'id' }, { key: 'serial' },
                    { key: 'type' }, { key: 'mac', header: 'MAC' },
                    { key: 'sim', header: 'SIM' },
                    { key: 'vehicle' }, { key: 'vin', header: 'VIN' }, { key: 'status' } ];
  const fields = [ { key: 'serial' },
                   { key: 'type', choices: [{ id: 'GO9', name: 'GO9' }, { id: 'VCG', name: 'VCG' }] },
                   { key: 'mac' }, { key: 'sim' }, { key: 'vehicle', idObject: true, choices: context.assets } ];

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getDeviceInfo = (device, key) => {
    if (key == 'vehicle')
      return device[key] && getVehicleName(device[key]);
    if (key == 'vin')
      return device.vehicle?.vin;
    return device[key];
  }

  const getDeviceCell = (device, key) => {
    let val = getDeviceInfo(device, key);
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td class="number">{val}</td>;
    }
    return <td class="text">{val}</td>;
  }

  return <ItemListPage columns={columns} fields={fields} addItem={addDevice} updateItem={updateDevice} deleteItem={deleteDevice}
                       getItems={getDevices} getItemInfo={getDeviceInfo} getItemCell={getDeviceCell} selectedTab="devices"
		       addItemText="Add Device" editHoverText="Edit Device" itemType="Devices" />;
}

export default DevicePage;

