import React, { useState, useEffect, useMemo } from 'react';
import { getVehicle, getNewTripsForVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

function TripEfficiencyDonut({ circleText, percent, previousPercent }) {

    const percentValue = percent || 100;
    const previousPercentValue = previousPercent || 100;

    // useMemo to avoid re-animation
    const fleet_efficiency_data = useMemo(() => ({
        datasets: [{
            label: 'This Trip', // Used for legend filtering
            data: [percentValue, 100-percentValue],
            backgroundColor: [
                '#F85E7D','#E3E2EC', '#B14EC5',
                //'#F85E7D','#009245','#29ABE2','#F78957'
            ],
            borderWidth: 4
        },{
            label: 'Day\'s Average', // Used for legend filtering
            data: [previousPercentValue, 100-previousPercentValue],
            backgroundColor: [
                '#B14EC5','#E3E2EC',
                //'#B14EC5','#009245','#29ABE2','#F78957'
            ],
            borderWidth: 4
        }]
    }), [percentValue, previousPercentValue]);

    const overall_grade_label = {
        id: 'doughnutLabel',
        beforeDatasetDraw (chart, args, pluginOptions) {
            const {ctx, data} = chart;

            if (circleText === undefined)
                return;

            ctx.save();
            const width = chart.width;
            const xCoor = chart.getDatasetMeta(0).data[0].x;
            const yCoor = chart.getDatasetMeta(0).data[0].y;
            ctx.fillStyle = '#000000';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle'
            ctx.font = 'bold 1.5vi Helvetica, "Helvetica Neue", Arial, sans-serif';
            ctx.fillText(circleText, xCoor, yCoor);
        }
    }
    
    const large_doughnut_options = {
      cutout: '65%',
      aspectRatio: 1.7,
        plugins: {
            tooltip: {
                enabled: !!percent,
                bodyFont: {
                    size: 12
                },
                filter: (tooltipItem) => {
                    console.log(tooltipItem);
                    return tooltipItem.dataIndex === 0;
                },
            },
            legend: {
                display: false,
            }
        }
    }

    const overall_grade_config = {
        type: 'doughnut',
        data: fleet_efficiency_data,
        options: large_doughnut_options,
        plugins: [overall_grade_label]
    }

    const legendItems = [
        { label: 'This Trip', color: '#F85E7D' },
        { label: "Day's Average", color: '#B14EC5' },
    ];

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <Doughnut
                    key={circleText}
                    data={fleet_efficiency_data}
                    options={large_doughnut_options}
                    plugins={[overall_grade_label]}
                    style={{ width: '100%', height: '100%' }}
                />

            {/* Custom Legend */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.6vh' }}>
                {legendItems.map((item, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '.5vh', width: '6vw', justifyContent: 'flex-start' }}>
                        <span style={{ width: '.6vw', height: '.6vw', backgroundColor: item.color, borderRadius: '2px' }} />
                        <span style={{ fontSize: '1.1vh' }}>{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TripEfficiencyDonut;

