import React, { useState, useEffect, useContext } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import logo from './images/Accelevate_Logo.svg';
import help from './images/help.svg';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import addYears from 'date-fns/addYears';
import { Context } from "./ContextHandler";

const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'Year to Date',
    value: [startOfYear(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last Year',
    value: [startOfYear(addYears(new Date(), -1)), endOfYear(addYears(new Date(), -1))],
    placement: 'left'
  }
];

function DateRangeFilter() {
    const context = useContext(Context);
    const dateRange = context.dateRangeFilter;
    const setDateRange = x => {
	 context.dispatch({ type: 'setState', attribute: 'dateRangeFilter', payload: x });
	 context.dispatch({ type: 'refresh' });
    };

    return <DateRangePicker style={{ float: 'right', width: '12vw', fontSize: '12px' }}
                            ranges={predefinedRanges} placement='bottomEnd' onChange={setDateRange} value={dateRange} />;
}

export default DateRangeFilter;

