import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getTrips, getTripsForZone, getDailyTotalAll, getAlerts, updateAlert } from './api/vehicle';
import { useParams, Link, useNavigate } from 'react-router-dom';
import EventGraph from "./EventGraph";
import EventRadarChart from "./EventRadarChart";
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import { eventMetadataMap } from './util/events';
import { getRiskLevelText } from './util/riskLevels';
import AlertSeverityDonut from "./AlertSeverityDonut";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, mpkwh, co2eInKg, co2PerMile, getVehicleName } from './util/formulas';
import { Context } from "./ContextHandler";
import { Select, MenuItem } from '@mui/material';
import PagedList from "./PagedList";
import { classifyFuelTypes } from './util/fuelTypes';
import AlertIdQuantityDonut from './AlertIdQuantityDonut';
import AlertTypeDonut from './AlertTypeDonut';

function AlertsList({ token }) {
  const { zid } = useParams();
  const [events, setEvents] = useState();
  const [trips, setTrips] = useState([]);
  const [totals, setTotals] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRangeFilter;
  const lastRefreshCount = useRef(-1);
  const [definedFilter, setDefinedFilter] = useState(0);
  const [routeFilter, setRouteFilter] = useState([]);
  const [hoursFilter, setHoursFilter] = useState(0);
  const [resolvedAlerts, setResolvedAlerts] = useState([]);
  const [windowFilter, setWindowFilter] = useState(0);
  const [popupVisible, setPopupVisible] = useState();
  const [showStationaryTrips, setShowStationaryTrips] = useState(false);
  const navigate = useNavigate();

  const toggleRouteFilter = route => {
    if (routeFilter.includes(route))
       setRouteFilter(routeFilter.filter(y => route !== y));
    else
       setRouteFilter([...routeFilter, route]);
  }

  const fixTrips = trips => {
    trips.forEach(t => {
      if (t.fuelConsumed === 0) {
        t.fuelConsumed = t.fuelSpend = t.kwhConsumed = t.efficiency = t.co2Generated = t.co2eGenerated = undefined;
      }
    });
    return trips;
  }

  const fixFaults = (faults) => {
    let filteredFaults = faults;
  
    // Apply groupFilter (unitId)
    if (context.groupFilter && context.groupFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => 
        context.assets.some(vehicle => 
          vehicle.id === fault.vehicleId && context.groupFilter.includes(vehicle.unitId)
        )
      );
    }
  
    // Apply assetFilter (vehicle.id)
    if (context.assetFilter && context.assetFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => context.assetFilter.includes(fault.vehicleId));
    }
  
    // Apply modelFilter (vehicle.model)
    if (context.modelFilter && context.modelFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => 
        context.assets.some(vehicle => 
          vehicle.id === fault.vehicleId && context.modelFilter.includes(vehicle.model)
        )
      );
    }
  
    // Apply fuelTypeFilter (vehicle.fuelType)
    if (context.fuelTypeFilter && context.fuelTypeFilter.length > 0) {
      filteredFaults = filteredFaults.filter(fault => 
        context.assets.some(vehicle => 
          vehicle.id === fault.vehicleId && context.fuelTypeFilter.includes(vehicle.fuelType)
        )
      );
    }
  
    return filteredFaults;
  };


  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
      const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
      const filters = getFilters(context);
      Promise.all([
        getVehicles(context.rootId, filters),
        getTrips(context.rootId, date1, date2, filters),
        getAlerts(context.rootId, date1, date2, filters),
      ])
        .then(([vehiclesResponse, tripsResponse, faultsResponse]) => {
          const vehicles = vehiclesResponse.data;
          const trips = fixTrips(tripsResponse.data);
          const faults = faultsResponse.data;

          const faultsWithDetails = faults.map((fault) => {
            const trip = trips.find((trip) => trip.id === fault.tripId);
            const vehicle = vehicles.find((vehicle) => vehicle.id === fault.vehicleId);

            return {
              ...fault,
              trip: trip || null,
              vehicle: vehicle || null,
            };
          });

          setEvents(fixFaults(faultsWithDetails));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [context.refreshCount, dateRange]);


  React.useEffect(() => {
    // don't allow "All Enterprises"
    if (context.rootId === -1) {
      context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
    }
  }, []);


  const assetMap = context.assets?.reduce((acc, asset) => { acc[asset.id] = asset; return acc; }, {});

  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;
  if (totals) {
    totals.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }
  const allEvents = events;
  let filteredEvents = allEvents;
  if (definedFilter === 1) {
    filteredEvents = filteredEvents?.filter(x => x.trip && x.trip.isDefined === true);
  } else if (definedFilter === 2) {
    filteredEvents = filteredEvents?.filter(x => x.trip && x.trip.isDefined === false);
  }
  
  // Apply hoursFilter
  if (hoursFilter === 1) {
    filteredEvents = filteredEvents?.filter(x => x.trip && x.trip.openHours === true);
  } else if (hoursFilter === 2) {
    filteredEvents = filteredEvents?.filter(x => x.trip && x.trip.openHours === false);
  }

  // Time window filter
  const windowFunctions = [
    null,
    h => (h >= 6 && h < 10),
    h => (h >= 10 && h < 14),
    h => (h >= 14 && h < 18),
    h => (h >= 18 && h < 22),
    h => (h >= 22 || h < 2),
    h => (h >= 2 && h < 6)
  ];

  if (windowFilter > 0) {
    filteredEvents = filteredEvents?.filter(x => windowFunctions[windowFilter](moment(x.timestamp).hour()));
  }

  // Fuel type classification
  const fuelTypes = new Set(filteredEvents?.map(x => assetMap[x.vehicleId]?.fuelType));
  const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
  const energyWord = onlyIce ? "Fuel" : "Energy";

  // Route filtering using events.trip.route
  const routeSet = new Set(events?.map(x => x?.trip?.route).filter(Boolean));
  let routeChoices = [...routeSet].sort();
  const tripChoices = ["All Trips", "Defined", "Undefined"];
  const windowChoices = ["All Time Windows", "6am - 10am", "10am - 2pm", "2pm - 6pm", "6pm - 10pm", "10pm - 2am", "2am - 6am"];
  const routeFilterText = routeFilter.length === 0 ? "All Routes" : routeFilter.join("; ");

  if (routeFilter.length > 0) {
    filteredEvents = filteredEvents.filter(x => x.trip && routeFilter.includes(x.trip.route));
  }

  const hoursChoices = ["All Hours", "Op Hours", "Non-Op Hours"];

  let avgFuelCost, co2PerMile, avgMpg, avgMikwh, avgDistance, distPerDay, avgEff, totalDistance, totalFuelConsumed, chargingMinutes;
  let totalKwhConsumed;

  const noNaN = x => (x === undefined || Number.isNaN(x) ? 0 : x)
  // if (totals && events) {
  //   // use daily totals if there are no trip filters.  otherwise use filtered trips
  //   const list = (filteredEvents == allEvents) ? totals : filteredEvents;
  //   const assetCount = list.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
  //   avgFuelCost = list.reduce((part, a) => part+noNaN(a.fuelSpend),    0)/assetCount;
  //   const totalCo2 = list.reduce((part, a) => part+noNaN(a.co2Generated),    0);
  //   totalDistance = list.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
  //   co2PerMile = totalDistance ? totalCo2/kmToMiles(totalDistance) : 0;
  //   totalFuelConsumed = list.reduce((part, a) => part+noNaN(a.fuelConsumed), 0);
  //   totalKwhConsumed = list.reduce((part, a) => part+noNaN(a.kwhConsumed), 0);
  //   avgDistance = totalDistance/assetCount;
  //   if (list == totals) {
  //     distPerDay  = list.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
  //     chargingMinutes = totals.reduce((part, a) => part+noNaN(a.chargingMinutesPerDay), 0);
  //   } else {
  //     const uniqueDays = new Set()
  //     list.forEach(trip => uniqueDays.add(moment(trip.startTime).format('M-D')))
  //     distPerDay  = totalDistance/uniqueDays.size;
  //     chargingMinutes = list.reduce((part, a) => part+noNaN(a.chargingMinutes), 0) / uniqueDays.size;
  //   }
  //   const assetCountEff = list.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
  //   avgEff      = list.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
  //   avgMpg   = noNaN(kmToMiles(totalDistance)/(litersToGallons(totalFuelConsumed)));
  //   avgMikwh = noNaN(kmToMiles(totalDistance)/(totalKwhConsumed));
  // }
  const eff = noNaN(avgEff*100).toFixed(0);

  const columns = [
    { header: 'Alert ID', key: 'id', width: '5%' },
    { header: 'Vehicle ID', key: 'vehicleId', width: '5%' },
    { header: 'Trip ID', key: 'tripId', width: '5%' },
    { header: 'Time of Alert', key: 'timeOfAlert', width: '5%' },
    { header: 'Risk Level', key: 'riskLevel', width: '5%' },
    // { header: 'Date Opened', key: 'dateOpened', width: '5%' },
    { header: 'Date Resolved', key: 'dateResolved', width: '5%' },
    { header: 'Confirm Resolution Y/N', key: 'confirmResolution', width: '10%' },
  ];

  const confirmResolution = (alertId, isResolved) => {
    // Determine the new dateResolved 
    // value
    const dateResolved = isResolved ? null : new Date().toISOString(); // Unset if resolved, set to now otherwise
  
    updateAlert(alertId, { dateResolved })
      .then(() => {
        // Update local state explicitly
        setResolvedAlerts((prev) => {
          const updated = { ...prev };
          if (isResolved) {
            delete updated[alertId]; // Unset resolved alert
          } else {
            updated[alertId] = true; // Mark as resolved
          }
          return updated;
        });
  
        // Refresh the context to update UI
        context.dispatch({ type: "setRefreshCount", payload: context.refreshCount + 1 });
  
        // Fetch the updated alert data
        const date1 = moment(dateRange[0]).format("YYYY-MM-DD");
        const date2 = moment(dateRange[1]).format("YYYY-MM-DD");
        const filters = getFilters(context);
  
        getAlerts(context.rootId, date1, date2, filters)
          .then((response) => {
            setEvents(response.data); // Update state
          })
          .catch((error) => console.error("Error fetching updated alerts:", error));
      })
      .catch((error) => console.error("Error confirming resolution:", error));
  };
  
  
  

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getTripInfo = (trip, key) => {
    if (key == 'name' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && getVehicleName(asset);
    }
    if (key == 'type' && assetMap)
      return assetMap[trip.vehicleId]?.type;
    if (key == 'primaryUse' && assetMap)
      return assetMap[trip.vehicleId]?.primaryUse;
    if (key == 'year' && assetMap)
      return assetMap[trip.vehicleId]?.year;
    if (key == 'fuel' && assetMap)
      return assetMap[trip.vehicleId]?.fuelType;
    if (key == 'makeModel' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && (asset.make + ' ' + asset.model);
    }
    if (key == 'startTime')
      return moment(trip.startTime).toISOString();
    if (key == 'endTime')
      return moment(trip.endTime).toISOString();
    if (key == 'duration')
      return moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    if (key == 'unitId') {
      const asset = assetMap[trip.vehicleId];
      return context.units?.find(x => x.id == asset?.unitId)?.name;
    }
    if (key == 'upTimeMinutes')
      return trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
    if (key == 'galPerMinute')
      return litersToGallons(trip.fuelConsumed) / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'kwhPerMinute')
      return trip.kwhConsumed / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'milesDriven')
      return kmToMiles(trip.distanceDriven);
    if (key == 'fuelConsumed')
      return litersToGallons(trip.fuelConsumed);
    if (key == 'mpge')
      return mpge(trip);
    if (key == 'mpg')
      return mpg(trip);
    if (key == 'mikwh')
      return mpkwh(trip, false);
    if (key == 'fuelSpend')
      return trip.fuelSpend;
    if (key == 'costPerMile')
      return trip.distanceDriven > 0 && fuelSpendPerMile(trip);
    if (key == 'co2e')
      return co2eInKg(trip);
    if (key == 'co2')
      return trip.distanceDriven > 0 && co2PerMile(trip);
    if (key == 'averageSpeed')
      return kmToMiles(trip.averageSpeed);
    if (key == 'maxSpeed')
      return kmToMiles(trip.maxSpeed);
    if (key == 'openHours')
      return trip[key] === undefined ? '' : trip[key] ? 'yes' : 'no';
    return trip[key];
  }

  const getAlertInfo = (event, key) => {
    if (key === 'timestamp') {
      return moment(event.timestamp).toISOString();
    }
    if (key === 'vehicleId') {
      return event?.vehicle ? event?.vehicle?.name : event.vehicleId;
    }
    if (key === 'dateResolved') {
      return event[key] ? moment(event[key]).format('YYYY-MM-DD HH:mm:ss') : "";
    }
    if (key === 'riskLevel') {
      return getRiskLevelText(eventMetadataMap[event.alertType]?.riskLevel);
    }
    return event[key];
  };


  const getAlertCell = (event, key) => {
    let value = getAlertInfo(event, key);
    if (key === 'timeOfAlert' || key === 'dateResolved') {
      if (!value) return <td key={key} className="text"></td>; // Show nothing if value is undefined/null
      
      const format = 'M-D h:mma';
      const hoverText = moment(value).utcOffset(0).format('M-D H:mm:ss') + ' GMT';
      
      value = moment(value).format(format);
    
      return <td key={key} className="text" title={hoverText}>{value}</td>;
    }
   if (key == 'tripId') {
      const tripClick = ev => { if (ev.shiftKey) { ev.preventDefault(); navigate('/trips/more/' + value); } }
      return <td key={key} className="text"><Link to={'/trips/' + value} onClick={tripClick}>{value}</Link></td>;
    }
    if (key === 'confirmResolution') {
      return (
        <td key={key} className="text">
          <Button
            variant="contained"
            color={event.dateResolved ? "error" : "success"}
            startIcon={event.dateResolved ? <UndoIcon /> : <CheckCircleIcon />}
            onClick={() => confirmResolution(event.id, event.dateResolved)} // ✅ Pass isResolved state
            sx={{ 
              textTransform: 'none', 
              fontWeight: 'bold', 
              borderRadius: '8px', 
              padding: '6px 12px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              "&:hover": {
                boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.3)',
              }
            }}
          >
            {event.dateResolved ? "Undo Resolution" : "Confirm Resolution"}
          </Button>
        </td>
      );
    }
    return <td key={key} className="text">{value}</td>;
  };

  const getDuration = val => {
    const asMin = Math.floor(val/60);
    const hr = Math.floor(asMin/60)
    const min = asMin-hr*60;
    if (hr == 0)
      return `${min}m`;
    return `${hr}h ${min}m`;
  }

  const getTripCell = (trip, key) => {
    if (key == 'utilization') {
      let totalMins = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes + trip.offlineMinutes;
      let offWidth = 100*trip.offlineMinutes / totalMins;
      let idleWidth = 100*trip.idleMinutes / totalMins;
      let decWidth = 100*trip.deceleratingMinutes / totalMins;
      let accWidth = 100*trip.acceleratingMinutes / totalMins;
      return <td key={key} className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, trip.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, trip.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, trip.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, trip.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    let val = getTripInfo(trip, key);
    if (key == 'duration')
      return <td key={key} className="number">{getDuration(val)}</td>;
    if (key == 'efficiency' && val !== undefined)
      return <td key={key} className="number">{(val * 100).toFixed(0)}%</td>;
    if ((key == 'fuelConsumed' || key == 'kwhConsumed') && trip['estimated'])
      return <td key={key} className="number interpolated">{round(val)}</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number">{val}</td>;
    }
    if (key == 'startTime' || key == 'endTime') {
      const format = 'M-D h:mma';
      const hoverText = moment(val).utcOffset(0).format('M-D H:mm:ss') + ' GMT';
      val = moment(val).utcOffset(trip.zoneOffset).format(format);
      return <td key={key} className="text" title={hoverText}>{val}</td>;
    }
    if (key == 'name') {
      const hoverText = trip?.vehicleId;
      const tripClick = ev => { if (ev.shiftKey) { ev.preventDefault(); navigate('/trips/more/' + trip.id); } }
      return <td key={key} className="text" title={hoverText}><Link to={'/trips/' + trip.id} onClick={tripClick}>{val}</Link></td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const selectedIf = x => x ? 'selected' : undefined;

  const routeFilterClicked = ev => {
    if (ev.shiftKey)
      setShowStationaryTrips(!showStationaryTrips);
  }

  const tableActions = <>
                        <div id="trip-filter" className="dropdown asset-filter"
                            onMouseEnter={ev => setPopupVisible(1)} onMouseLeave={ev => setPopupVisible(0)}>{tripChoices[definedFilter]}
                            {popupVisible == 1 && <div className="dropdown-content">
                                <span>Trips</span>
                                {tripChoices.map((x, ix) => <a key={ix} href="#" className={selectedIf(definedFilter == ix)} onClick={ev => setDefinedFilter(ix)}>{x}</a>)}
                            </div>}
                        </div>
                        <div id="route-filter" className="dropdown asset-filter" style={{ width: 300 }}
                            onClick={routeFilterClicked}
                            onMouseEnter={ev => setPopupVisible(2)} onMouseLeave={ev => setPopupVisible(0)}>{routeFilterText}
                            {popupVisible == 2 && <div className="dropdown-content">
                                <span>Routes</span>
				<a href="#" className={selectedIf(routeFilter.length == 0)}
                                   onClick={ev => setRouteFilter([])}>All Routes</a>
                                {routeChoices.map(x =>
                                     <a href="#" key={x} className={selectedIf(routeFilter.includes(x))}
                                        onClick={ev => toggleRouteFilter(x)}>{x}</a>)}
                            </div>}
                        </div>
                        <div id="hours-filter" className="dropdown asset-filter" style={{ width: 150 }}
                            onMouseEnter={ev => setPopupVisible(3)} onMouseLeave={ev => setPopupVisible(0)}>{hoursChoices[hoursFilter]}
                            {popupVisible == 3 && <div className="dropdown-content">
                                <span>Op Hours</span>
                                {hoursChoices.map((x, ix) =>
                                     <a href="#" key={x} className={selectedIf(hoursFilter == ix)}
                                        onClick={ev => setHoursFilter(ix)}>{x}</a>)}
                            </div>}
                        </div>
                        <div id="window-filter" className="dropdown asset-filter" style={{ width: 150 }}
                            onMouseEnter={ev => setPopupVisible(4)} onMouseLeave={ev => setPopupVisible(0)}>{windowChoices[windowFilter]}
                            {popupVisible == 4 && <div className="dropdown-content">
                                <span>Time Windows</span>
                                {windowChoices.map((x, ix) =>
                                     <a href="#" key={x} className={selectedIf(windowFilter == ix)}
                                        onClick={ev => setWindowFilter(ix)}>{x}</a>)}
                            </div>}
                        </div>
                    </>;

  return <>
<div id="focus">
    <FilterPanel filters={['enterpriseFilter', 'fuelTypeFilter', 'groupFilter',
                           'modelFilter', 'assetFilter', 'dateFilter']} />
    <div id="content">
        <div id="charts" style={{ display: 'flex', height: '40%', justifyContent: 'space-between' }}>
            <div className="individual-chart" style={{ width: '33.3%', height: '100%' }}>
                <h4 style={{marginBottom: 2, marginTop: 2}}></h4>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <EventGraph events={filteredEvents} /> */}
                    <AlertSeverityDonut alerts={filteredEvents} />
                </div>
            </div>
            <div className="individual-chart" style={{ width: '33.3%', height: '100%' }}>
              <h4 style={{marginBottom: 2, marginTop: 2}}></h4>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <AlertIdQuantityDonut alerts={filteredEvents} />
                </div>
            </div>
            <div className="individual-chart" style={{ width: '33.3%', height: '100%' }}>
              <h4 style={{marginBottom: 2, marginTop: 2}}></h4>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <AlertTypeDonut alerts={filteredEvents} />
                </div>
            </div>
        </div>
        <PagedList
          items={filteredEvents?.map((event) => ({
            ...event,
            alertType: event.type,
            type: event.id, 
            id: event.id
          }))}
            tableActions={tableActions}
            columns={columns}
            getItemInfo={getAlertInfo}
            getItemCell={getAlertCell}
            itemType="Alerts"
            extendedDownload={true}
        />
    </div>
</div>
         </>;
}

export default AlertsList;

