import React, { useState, useEffect } from 'react';
import { getVehicle, getNewTripsForVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Radar } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, RadialLinearScale, Filler } from 'chart.js';
import { kmToMiles, litersToGallons, co2InKg, mpkwh, mpge, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';

Chart.register(ArcElement, Tooltip, RadialLinearScale, Filler);

const fuelTypeInfo = [
    { id: 'GAS', index: 0, name: 'Gas' },
    { id: 'EV', index: 2, name: 'EV' },
    { id: 'LPG', index: 1, name: 'LPG' },
    { id: 'CNG', index: 3, name: 'CNG' },
    //{ id: 'PROPANE', index: 4, name: 'Propane' },
    { id: 'DIESEL', index: 5, name: 'Diesel' }
];

function EnergyBlendGraph({ data, previousData }) {

    const getData = data => {
	const indices = fuelTypeInfo.map(x => x.index);
	return indices.map(ix => !data?.fuelSpendByType || data.distanceByType[ix] == 0 ? 0 :
		data.fuelSpendByType[ix]/kmToMiles(data.distanceByType[ix]));
    }

    const energy_efficiency_data = {
	labels: fuelTypeInfo.map(x => x.name),
	datasets: [{
	    label: 'Nov 5 - Nov 12',
	    data: getData(data),
	    fill: true,
	    backgroundColor: 'rgba(248, 94, 125, .3)',
	    borderColor: '#F85E7D',
	    pointBackgroundColor: '#F85E7D',
	    pointBorderColor: '#F85E7D',
	},{
	  label: 'Oct 29 - Nov 4',
	    data: getData(previousData),
	    fill: true,
	    backgroundColor: 'rgba(177, 78, 197, .3)',
	    borderColor: '#B14EC5',
	    pointBackgroundColor: '#B14EC5',
	    pointBorderColor: '#B14EC5',
	}]
    }

    const radar_options = {
        // push it to the left a bit so it looks centered
        layout: {
          padding: {
            right: 30
          }
        },
	elements: {
	  line: {
	    borderWidth: 2
	  }
	},
	scales: {
	  r: {
	    grid: {
	      color: '#D2D2D8'
	    },
	    angleLines: {
	      color: '#D2D2D8'
	    }
	  }
	},
	plugins: {
	    tooltip: {
		bodyFont: {
		    size: 12
		}
	    },
	    legend: {
		display: false
	    }
	}
    }

    return (
      <div style={{ width: '18vi', height: '18vi', position: 'relative', margin: '0 auto' }}>
	<Radar data={energy_efficiency_data} options={radar_options} style={{ width: '100%', height: '100%' }} />
      </div> );
}

export default EnergyBlendGraph;

