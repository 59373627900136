import React, { useState, useEffect, useContext, useRef } from 'react';
import { getVehicle, getDailyTotalForVehicle, getDailyTotalByDateForVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, idealMpg, mpkwh, idealMpkwh, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';
import { getDuration } from './util/dates';
import DateRangeFilter from "./DateRangeFilter";
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";
import Map, { Marker, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import mapBus from './images/map-bus.svg';
import DashboardDonut from './DashboardDonut';
import { mapboxToken } from './util/mapbox.js';
import BarGraphic from './BarGraphic';
import { carImage } from './util/carImage.js';
import geotab from './images/geotab.png';

import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement );

function AssetDetails({ token }) {
  const { id } = useParams();
  const [asset, setAsset] = useState();
  const [trips, setTrips] = useState();
  const [totals, setTotals] = useState();
  const navigate = useNavigate();
  const context = useContext(Context);
  const dateRange = context.dateRangeFilter;
  const lastRefreshCount = useRef(-1);
  const mapRef = useRef(null);

  // Mapbox GL initial viewport settings
  const [viewState, setViewState] = useState({
      latitude: 32, // latitude[0],
      longitude: -117, // longitude[0],
      zoom: 10
  });

  const isEV = asset?.fuelType == 'EV';

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!asset?.latitude)
       return;
    const pad = .01;
    const bound1 = [asset.longitude-pad, asset.latitude-pad];
    const bound2 = [asset.longitude+pad, asset.latitude+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds, { duration: 0 });
    }
  }, [asset]);

  useEffect(() => {
     if (context.refreshCount != lastRefreshCount.current && dateRange) {
	getVehicle(id).then(result => setAsset(result.data));
        const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
        const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
        getDailyTotalByDateForVehicle(id, date1, date2).then(result => setTrips(result.data));
        getDailyTotalForVehicle      (id, date1, date2).then(result => setTotals(result.data));
     }
  }, [context.refreshCount, dateRange]);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    return txt + ": " + mins.toFixed(0) + " min";
  };

  const columns = [
    { header: 'Date', key: 'date' },
    { header: 'Trips', key: 'tripCount' },
    { header: 'Distance Traveled', key: 'milesDriven' },
    { header: 'Time Active', key: 'timeActive' },
    { header: 'Utilization', key: 'utilization', sortable: false, displayOnly: true },
    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
    { header: isEV ? 'Energy Consumed' : 'Fuel Consumed', key: isEV ? 'kwhConsumed' : 'fuelConsumed' },
    { header: isEV ? 'kWh per Minute' : 'Gallons per Minute', key: isEV ? 'kwhPerMinute' : 'galPerMinute' },
    { header: isEV ? 'Mi/kWh' : 'MPG', key: isEV ? 'mpkwh' : 'mpg' },
    { header: isEV ? 'Energy Cost' : 'Fuel Cost', key: 'fuelSpend' },
    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
    { header: <>CO<sub>2</sub>/Mile (g)</>, downloadHeader: 'CO2e/mile (g)', key: 'co2' },
    { header: 'Efficiency', key: 'efficiency' },
  ];

  const getTripInfo = (trip, key) => {
    if (key == 'upTimeMinutes')
      return trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
    if (key == 'milesDriven')
      return kmToMiles(trip.distanceDriven);
    if (key == 'fuelConsumed')
      return litersToGallons(trip.fuelConsumed);
    if (key == 'kwhlConsumed')
      return trip.fuelConsumed;
    if (key == 'galPerMinute') {
      const active = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
      return active ? litersToGallons(trip.fuelConsumed)/active : null;
    }
    if (key == 'kwhPerMinute') {
      const active = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
      return active ? trip.fuelConsumed/active : null;
    }
    if (key == 'mpge')
      return mpge(trip);
    if (key == 'mpg')
      return mpg(trip);
    if (key == 'mpkwh')
      return mpkwh(trip, false);
    if (key == 'fuelSpend')
      return trip.fuelSpend;
    if (key == 'costPerMile')
      return trip.distanceDriven > 0 && fuelSpendPerMile(trip);
    if (key == 'co2e')
      return co2eInKg(trip);
    if (key == 'co2')
      return trip.distanceDriven > 0 && co2PerMile(trip);
    if (key == 'averageSpeed')
      return kmToMiles(trip.averageSpeed);
    if (key == 'tripCount')
      return trip.tripCount - trip.stationaryTripCount;
    return trip[key];
  }

  const round = x => x?.toFixed(2);
  const roundFunc = x => Math.round(x*100)/100;

  const getTripCell = (trip, key) => {
    if (key == 'utilization') {
      let totalMins = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes + trip.offlineMinutes;
      let offWidth = 100*trip.offlineMinutes / totalMins;
      let idleWidth = 100*trip.idleMinutes / totalMins;
      let decWidth = 100*trip.deceleratingMinutes / totalMins;
      let accWidth = 100*trip.acceleratingMinutes / totalMins;
      return <td className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, trip.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, trip.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, trip.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, trip.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    let val = getTripInfo(trip, key);
    if (key == 'timeActive')
      return <td className="number">{getDuration(60*(trip.idleMinutes+trip.deceleratingMinutes+trip.acceleratingMinutes))}</td>;
    if (key == 'galPerMinute')
      return <td className="number">{val && val.toFixed(4)}</td>;
    if (key == 'efficiency' && val !== undefined)
      return <td className="number">{(val * 100).toFixed(0)}%</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td className="number">{val}</td>;
    }
    return <td className="text">{val}</td>;
  }

  let eff = totals && (totals.efficiency * 100);
  let mpgValue = mpg(totals);
  let idealMpgValue = idealMpg(totals);
  let mpkwhValue = mpkwh(totals, false);
  let idealMpkwhValue = idealMpkwh(totals, false);
  let emissions = totals?.co2Generated/1000;
  let idealEmissions = totals?.idealCo2Generated/1000;
  const activeHours = tot => (tot.acceleratingMinutes + tot.deceleratingMinutes + tot.idleMinutes)/60;

  const card = (title, value, units) =>
		<div className="card">
		  <div className="title">{title}:</div>
		  <div className="value">{roundFunc(value)} {units}</div>
		</div>;

  let car = carImage(asset);

  return <>
            <div id="focus">
                <div id="filters">
                    <div id="company" onClick={ev => navigate('/assets')} style={{ position: 'relative' }}>
                      <a href="#" className="back-button"></a> {asset?.name} <img src={geotab} style={{ height: 50, position: 'absolute', top: -15 }} />
                        <span style={{ marginLeft: 50 }}>&nbsp;</span>
                        {asset?.geotabId} <span style={{ marginLeft: 180 }}>&nbsp;</span>{asset?.year} {asset?.make} {asset?.model} - {asset?.fuelType}
                    </div>
                    <DateRangeFilter />
                </div>
                <div id="content">
                    <div id="asset-info">
                        <div className="info-content" style={{ width: '60%', position: 'relative' }}>
			    <div class="quadrants">
				<div class="box box-left">VIN: {asset?.vin}</div>
				<div class="box box-right">Mileage: {asset?.odometer && kmToMiles(asset.odometer / 1000).toFixed(0)}</div>
				<div class="box box-left">License Plate: {asset?.licensePlateState} {asset?.licensePlate}</div>
				<div class="box box-right">Group: {context.units?.find(x => x.id == asset?.unitId)?.name}</div>
			    </div>
			    <img src={car} class="overlay-image" />
                        </div>
                        <div id='right' className="horizontal" style={{ width: '40%', marginLeft: 20 }}>
			    <div className="perf-cards-container">
                                {isEV ? card('kWh Consumed', totals?.kwhConsumed) : card('Fuel Consumed', litersToGallons(totals?.fuelConsumed), 'gal')}
                                {card('Active Hours',  totals && activeHours(totals))}
                                {card('Distance Traveled', kmToMiles(totals?.distanceDriven), 'mi')}
			    </div>
			    <div className='perf-summary-container'>
				<BarGraphic leftLabel="Efficiency" rightValue={eff} tooltips={false} segments={[
				  { value: eff },
				  { value: 100-eff },
				]} />
				{!isEV && <BarGraphic leftLabel="MPG" rightValue={mpgValue} segments={[
				  { label: 'MPG', value: mpgValue },
				  { label: 'Ideal MPG', total: true, value: idealMpgValue-mpgValue },
				]} />}
				{isEV && <BarGraphic leftLabel="Mi/kWh" rightValue={mpkwhValue} round={x => Math.round(x*100)/100} segments={[
				  { label: 'Mi/kWh', value: mpkwhValue },
				  { label: 'Ideal Mi/kWh', total: true, value: idealMpkwhValue-mpkwhValue },
				]} />}
				{!isEV && <BarGraphic leftLabel="Emissions" rightValue={emissions} units="kg CO2" segments={[
				  { label: 'Ideal Emissions', value: idealEmissions },
				  { label: 'Emissions', value: emissions - idealEmissions },
				]} />}
			    </div>
                        </div>
                    </div>
		    <PagedList items={trips} columns={columns} getItemInfo={getTripInfo} getItemCell={getTripCell} itemType="Trips" />
                </div>
            </div>
         </>;
}

export default AssetDetails;

