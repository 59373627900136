import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getVehicles, getFilters, getTripsForVehicle, getDailyTotalByDate, getDailyTotal } from './api/vehicle';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DashboardChart from "./DashboardChart";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import Map, { Marker, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { lastSevenDays } from "./util/dates";
import { co2PerMile, mpge, mpg, mpkwh, kmToMiles, litersToGallons } from "./util/formulas";
import { Context } from "./ContextHandler";
import mapboxgl from 'mapbox-gl';
import mapBus from './images/map-bus.svg';
import { FuelTypes, classifyFuelTypes, fuelTypesList } from './util/fuelTypes';
import { mapboxToken } from './util/mapbox.js';

function Dashboard({ token }) {

    const [dailyGraph, setDailyGraph] = useState();
    const [assets, setAssets] = useState();
    const [totals, setTotals] = useState();
    const context = useContext(Context);
    const dateRange = context.dateRange;
    const lastRefreshCount = useRef(-1);
    const [mapRefValue, setMapRefValue] = useState();

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 32, // latitude[0],
        longitude: -117, // longitude[0],
        zoom: 10
    });

    useEffect(() => {
	if (lastRefreshCount.current != context.refreshCount && dateRange) {
	    lastRefreshCount.current = context.refreshCount;
            const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
            const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
	    const filters = getFilters(context);
            getVehicles(context.rootId, filters).then(response => setAssets(response.data));
	    getDailyTotalByDate(context.rootId, date1, date2, filters).then(result => setDailyGraph(result.data));
	    getDailyTotal(context.rootId, date1, date2, filters)      .then(result => setTotals(result.data));
	}
    }, [context.refreshCount, dateRange]);

    React.useEffect(() => {
      // don't allow "All Enterprises"
      if (context.rootId === -1) {
	 context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
      }
    }, []);

    // need this because we want to calculate bounding box after mapRef is set
    const handleMapRef = map => {
      setMapRefValue(map);
    }

    const fuelTypes = new Set(totals?.activeVehicleCountByFuelType?.
		map((value, index) => value === 0 ? null : fuelTypesList[index]).filter(x => x));
    const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
    const energyWord = onlyIce ? 'Fuel' : 'Energy';
    const isIce = x => x == FuelTypes.Gas || x == FuelTypes.Diesel;

    // Calculate bounding box and fit map to marker
    useEffect(() => {
      if (!assets)
	 return;
      let lon1 = 180, lat1 = 90, lon2 = -180, lat2 = -90;
      assets.forEach(asset => {
         if (!asset.latitude) return;
         lon1 = Math.min(lon1, asset.longitude);
         lat1 = Math.min(lat1, asset.latitude);
         lon2 = Math.max(lon2, asset.longitude);
         lat2 = Math.max(lat2, asset.latitude);
      });
      const pad = .03;
      const bound1 = [lon1-pad, lat1-pad];
      const bound2 = [lon2+pad, lat2+pad];
      const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

      if (mapRefValue) {
	mapRefValue.fitBounds(bounds, { duration: 0 });
      }
    }, [assets, mapRefValue]);

    const filters = ['enterpriseFilter', 'fuelTypeFilter', 'groupFilter', 'modelFilter', 'assetFilter', 'dateFilter'];
    if (!totals)
	return <FilterPanel filters={filters} />;

    const round = x => nonan(x).toFixed(0);
    const round2 = x => nonan(x).toFixed(2);

    const nonan = x => Number.isNaN(x) || x === undefined ? 0 : x
    const fuelCost = nonan(Math.round(100 * totals.fuelSpend / kmToMiles(totals.distanceDriven)));
    const co2Value = round2(co2PerMile(totals)/1000);
    const uptimeValue = round(nonan(totals.uptimeMinutes/60 / dailyGraph?.length));
    const mpgValue = round2(mpkwh(totals, onlyIce));
    const totalDistanceInKm = dailyGraph?.reduce((tot, x) => tot+x.distanceDriven, 0);
    const distanceValue = round(nonan(kmToMiles(totalDistanceInKm/dailyGraph?.length)));
    const chargingValue = round(nonan(totals.chargingMinutes/60 / dailyGraph?.length));
    const idleValue = round(nonan(totals.idleMinutes/60 / dailyGraph?.length));
    const eff = nonan(Math.round(totals.efficiency*100))
    const activeCount = assets?.length;
    const addFuels = ice => nonan(totals?.fuelConsumedByType?.reduce((tot, x, index) => isIce(index) == ice ? x+tot : tot, 0));
    const summaryFuelConsumed = addFuels(true);
    const summaryEnergyConsumed = addFuels(false);

    return <>
	<FilterPanel filters={filters} />
        <div id="content">
	<div className="left">
	    <h3>Performance Grades</h3>
	    <div id="grades">
		<div id="overall-grade">
		    <h4>Overall Fleet Efficiency</h4>
		    <div className="doughnut">
			<DashboardDonut circleText={eff + '%'} percent={eff} />
		    </div>
		</div>
		<div id="individual-grades">
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={fuelCost + '¢'} />
			</div>
			<div className="text">
			    <h4>Fuel Cost</h4>
			    <p>{fuelCost}¢/Mile</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={onlyEv ? chargingValue : co2Value} />
			</div>
			{onlyEv || <div className="text">
			    <h4>CO<sub>2</sub> Emissions</h4>
			    <p>{co2Value}kg/Mile</p>
			</div>}
			{onlyEv && <div className="text">
			    <h4>Time Charging</h4>
			    <p>Hours/Day</p>
			</div>}
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={uptimeValue} />
			</div>
			<div className="text">
			    <h4>{onlyIce ? 'Active Engine Time' : 'Active Hours'}</h4>
			    <p>{uptimeValue} Hours/Day</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={mpgValue} />
			</div>
			<div className="text">
			    <h4>{energyWord} Efficiency</h4>
			    <p>{mpgValue} {onlyIce ? 'MPG' : 'Mi/kWh'}</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={distanceValue} />
			</div>
			<div className="text">
			    <h4>Distance Driven</h4>
			    <p>{distanceValue} Miles/Day</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={idleValue} />
			</div>
			<div className="text">
			    <h4>Idle Time</h4>
			    <p>{idleValue} Hours/Day</p>
			</div>
		    </div>
		</div>
		
	    </div>
	    <div className="chart-row">
		<div className="linechart">
		    <h4>Daily Average {energyWord} Cost per Mile (Cents)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => x.distanceDriven == 0 ? 0 : x.fuelSpend / kmToMiles(x.distanceDriven) } />
		</div>
		<div className="linechart">
		    <h4>Daily {onlyIce && 'Fuel'} Efficiency per Asset ({onlyIce ? 'MPG' : 'mi/kWh'})</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => nonan(mpkwh(x, onlyIce))} />
		</div>
	    </div>
	    <div className="chart-row">
		{onlyEv || <div className="linechart">
		    <h4>Daily CO<sub>2</sub> Emissions (g/Mile)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => co2PerMile(x)} />
		</div>}
		{onlyEv && <div className="linechart">
		    <h4>Daily Charge Time (Hours)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => x.chargingMinutes/60} />
		</div>}
		<div className="linechart">
		    <h4>Daily Distance Driven (Miles)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => kmToMiles(x.distanceDriven)} />
		</div>
	    </div>
	    <div className="chart-row">
		<div className="linechart">
		    <h4>Daily Up Time (Hours)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => x.uptimeMinutes/60 } />
		</div>
		<div className="linechart">
		    <h4>Daily Idle Time (Hours)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => x.idleMinutes/60 } />
		</div>
	    </div>
	</div>
	<div className="right" style={{ display: 'flex', flexDirection: 'column' }}>
	  <div id="summary" style={{ flex: '0 0 auto' }}>
	      <table>
		  <tbody>
                      <tr>
                          <td colSpan="2"><h3>Performance Summary</h3></td>
                      </tr>
		      <tr>
			  <td>Available Assets:</td>
			  <td>{assets?.length} Vehicles</td>
		      </tr>
		      <tr>
			  <td>Active Assets:</td>
			  <td>{totals?.activeVehicleCount} Vehicles</td>
		      </tr>
		      {onlyEv && <tr>
			  <td>Energy Consumed:</td>
			  <td>{round(totals.kwhConsumed)} kWh</td>
		      </tr>}
		      {onlyEv || <tr>
			  <td>Fuel Consumed:</td>
			  <td>{round(summaryFuelConsumed)} Gallons</td>
		      </tr>}
		      <tr>
			  <td>Active Hours:</td>
			  <td>{round(totals.uptimeMinutes/60)}</td>
		      </tr>
		       <tr>
			  <td>Total {energyWord} Cost:</td>
			  <td>${round(totals.totalFuelSpend)}</td>
		      </tr>
		      {mixed && <tr>
			  <td># of EV Active Assets</td>
			  <td>{nonan(totals.activeVehicleCountByFuelType?.[FuelTypes.EV])}</td>
		      </tr>}
		  </tbody>
	      </table>
	      <table>
		  <tbody>
		      {mixed && <tr>
			  <td>Energy Consumed:</td>
			  <td>{round(summaryEnergyConsumed)} kWh</td>
		      </tr>}
		      {mixed || <tr>
			  <td>&nbsp;</td>
			  <td>&nbsp;</td>
		      </tr>}
		      <tr>
			  <td>Trips Taken:</td>
			  <td>{nonan(totals.tripCount)} Trips</td>
		      </tr>
		      {onlyEv || <tr>
			  <td>Total Emissions:</td>
			  <td>{round(totals.co2Generated/1000)} kg of CO<sub>2</sub></td>
		      </tr>}
		      {onlyEv && <tr>
			  <td>Time Spent Charging:</td>
			  <td>{round(totals.chargingMinutes/60)} Hours</td>
		      </tr>}
		      <tr>
			  <td>Offline Hours:</td>
			  <td>{round(totals.offlineMinutes/60)}</td>
		      </tr>
		      <tr>
			  <td>Idle Energy/Fuel Cost:</td>
			  <td>${round(totals.totalIdleFuelSpend)}</td>
		      </tr>
		      <tr>
			  <td>Total Miles Driven:</td>
			  <td>{round(kmToMiles(totals.distanceDriven))}</td>
		      </tr>
		      {mixed && <tr>
			  <td># of ICE Active Assets</td>
			  <td>{nonan(totals.activeVehicleCount-totals.activeVehicleCountByFuelType?.[FuelTypes.EV])}</td>
		      </tr>}
		  </tbody>
	      </table>
	  </div>
          <div id="map" style={{ flex: '1 1 auto' }}>
            <Map
                    {...viewState}
                    mapboxAccessToken={mapboxToken} ref={handleMapRef}
                    onMove={evt => setViewState(evt.viewState)}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                >
                  {assets?.map(asset =>
                    asset.latitude && <Marker key={asset.id} latitude={asset.latitude} longitude={asset.longitude}>
                        <div style={{ width: '36px', height: '18px', backgroundImage: 'url(' + mapBus + ')' }} />
                    </Marker>)}
            </Map>
	  </div>
	</div>
    </div></>;
}

export default Dashboard;

