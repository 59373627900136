import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';

import { getFilters } from '../api/vehicle';
import Filter from "../Filter";
import PagedList from "../PagedList";
import Form from "./Form";

function ItemListPage(props) {
  const { getItems, getItemCell, selectedTab, getItem, addItemText, editHoverText, editingStarted, onAddItem } = props;
  const [items, setItems] = useState();
  const [showModal, setShowModal] = useState(false);
  const [initialData, setInitialData] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRange;
  const lastRefreshCount = useRef(-1);

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      const filters = getFilters(context);
      getItems(context.rootId, filters).then(response => setItems(response.data));
    }
  }, [context.refreshCount, dateRange]);

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const handleAddItem = () => {
    if (onAddItem) {
      onAddItem();
      return;
    }
    setInitialData({});
    setShowModal(true);
    editingStarted && editingStarted();
  };

  const editItem = item => {
    const handleItem = item => {
      setInitialData(item);
      setShowModal(true);
      editingStarted && editingStarted();
    }
    console.log(item, item.id);
    if (getItem) {
      getItem(item.id).then(result => handleItem(result.data));
    } else {
      handleItem(item);
    }
  };

  const formFinished = () => {
    setShowModal(false);
  }

  const getItemCellWithEdit = (item, key) => {
    if (key == 'edit')
      return <td class="edit"><a data-title={editHoverText} href="#" onClick={ev => editItem(item)}></a></td>
    return getItemCell(item, key);
  }

  return <>
            <div id="focus">
                <Tabs selected={selectedTab} />
		<Filter enterpriseFilterOnly={true} allowAllEnterprises={true} />
                <div id="content">
                    <PagedList {...props} tableActions={<a href="#" class="add-new" onClick={ev => handleAddItem()}>{addItemText}</a>}
                               items={items} getItemCell={getItemCellWithEdit} />
                </div>
            </div>
        {showModal && <div id="modal" class="veil" style={{ display: 'block' }}>
            <div class={"modal-popup " + selectedTab}>
                <div class="modal-content">
                    <Form {...props} formFinished={formFinished} initialData={initialData} />
                </div>
            </div>
          </div>}
     </>;
}

export default ItemListPage;

