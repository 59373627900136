import React, { useState, useEffect } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AssetList from './AssetList';
import AssetDetails from './AssetDetails';
import TripList from './TripList';
import TripDetails from './TripDetails';
import TripMoreDetails from './TripMoreDetails';
import ZonePage from './settings/ZonePage';
import EnterprisePage from './settings/EnterprisePage';
import TreePage from './settings/TreePage';
import UserPage from './settings/UserPage';
import AssetPage from './settings/AssetPage';
import DevicePage from './settings/DevicePage';
import AccountPage from './settings/AccountPage';
import OpCenterPage from './settings/OpCenterPage';
import Dashboard from './Dashboard';
import axios from 'axios';
import logo from './images/Accelevate_Logo.svg';
import help from './images/help.svg';

function FleetManager({ handleLogout }) {
   const name = localStorage.getItem('name');
   const initials = name.split(' ').map(x => x.substr(0, 1)).join('');
   const [isPopupVisible, setPopupVisible] = useState(false);

   const getContent = (selection, cls, content) => (
          <div id="main" className={cls}>
            <div id="nav">
                <ul>
                    <li className={selection == 'dashboard' ? 'home selected' : 'home'}><Link to="/">Home</Link></li>
                    <li className={selection.startsWith('asset') ? 'assets selected' : 'assets'}><Link to="/assets">Assets</Link></li>
                    <li className={selection.startsWith('trips') ? "trips selected" : "trips"}><Link to="/trips">Trips</Link></li>
                </ul>
                <div className={selection == 'settings' ? 'settings selected' : 'settings'}><Link to="/settings">Settings</Link></div>
            </div>
            <div id="focus">
                {content}
            </div>
         </div>
  );

  return (
    <Router>
        <header>
            <Link id='logo' to="/"><img src={logo} /></Link>
            <div id="account" className="dropdown" onMouseEnter={ev => setPopupVisible(true)} onMouseLeave={ev => setPopupVisible(false)}>
                <span>{initials}</span>
                {isPopupVisible && <div className="dropdown-content">
                    <span>{name}</span>
                    <a href="#">Account</a>
                    <a href="#" onClick={handleLogout}>Sign out</a>
                </div>}
            </div>
            <a id="help" href="help.html"><img src={help} /></a>
        </header>
	<Routes>
	  <Route path="/assets/:id" element={getContent('asset', 'asset-details', <AssetDetails />)} />
	  <Route path="/assets"     element={getContent('assetList', 'assets', <AssetList />)} />
	  <Route path="/trips/zone/:zid" element={getContent('tripsList', 'trips',  <TripList />)} />
	  <Route path="/trips/more/:id"  element={getContent('trips',     'trips',  <TripMoreDetails />)} />
	  <Route path="/trips/:id"  element={getContent('trips',     'trips',  <TripDetails />)} />
	  <Route path="/trips"      element={getContent('tripsList', 'trips',  <TripList />)} />
	  <Route path="/settings/units"  element={getContent('settings', 'businessunit',  <TreePage />)} />
	  <Route path="/settings/zones"  element={getContent('settings', 'zones',  <ZonePage />)} />
	  <Route path="/settings/users/:rid?/:id?"  element={getContent('settings', 'users',  <UserPage />)} />
	  <Route path="/settings/opcenters" element={getContent('settings', 'op-centers',  <OpCenterPage />)} />
	  <Route path="/settings/vehicles/:rid?/:id?"  element={getContent('settings', 'vehicles',  <AssetPage />)} />
	  <Route path="/settings/devices"  element={getContent('settings', 'devices',  <DevicePage />)} />
	  <Route path="/settings/account"  element={getContent('settings', 'account',  <AccountPage />)} />
	  <Route path="/settings/*" element={getContent('settings',  'enterprise',  <EnterprisePage />)} />
	  <Route path="/"           element={getContent('dashboard', 'home',   <Dashboard />)} />
	</Routes>
    </Router>
  );
}

export default FleetManager;

