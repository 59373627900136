import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getDailyTotalAll, getTrips, getDailyTotal } from './api/vehicle';
import { Link } from 'react-router-dom';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, mpkwh, fuelSpendPerMile, co2eInKg, co2PerMile, getVehicleName } from './util/formulas';
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";
import ram1500 from './images/ram1500.png';
import ram3500 from './images/ram3500.png';
import fordTransit from './images/ford-transit.png';
import promaster from './images/ram-promaster.png';
import fordE350 from './images/ford-e350.png';
import chevyEquinox from './images/chevy-equinox.png';
import fordETransit from './images/ford-etransit.png';
import freightlinerM2 from './images/freightliner-m2.png';
import { Bar } from 'react-chartjs-2';
import { FuelTypes, classifyFuelTypes } from './util/fuelTypes';

function AssetList({ token }) {
  const [assets, setAssets] = useState();
  const [totals, setTotals] = useState();
  const [trips, setTrips] = useState([]);
  const [operationalTotals, setOperationalTotals] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  
  // For calculations - always includes ALL vehicles
  const [combinedAssets, setCombinedAssets] = useState();

  // For display - only includes FILTERED vehicles  
  const [displayAssets, setDisplayAssets] = useState([]);
  const context = useContext(Context);
  const dateRange = context.dateRangeFilter;
  const lastRefreshCount = useRef(-1);
  const [filteredRankingAssets, setFilteredRankingAssets] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState('totalRanking');
  const smallDonutStyle = { paddingTop: '.5vi' };
  const [allVehicles, setAllVehicles] = useState([]);  
  
  const metricOptions = [
    { label: 'Total Ranking', key: 'totalRanking' },
    { label: 'Utilization', key: 'utilization' },
    { label: 'Operational Efficiency', key: 'operationalEfficiency' },
    { label: 'Emissions Efficiency', key: 'emissionsEfficiency' },
    { label: 'Cost Efficiency', key: 'costEfficiency' },
    { label: 'Energy Efficiency', key: 'energyEfficiency' }
  ];

  const fixTrips = trips => {
    trips.forEach(t => {
      if (t.fuelConsumed === 0) {
        t.fuelConsumed = t.fuelSpend = t.kwhConsumed = t.efficiency = t.co2Generated = t.co2eGenerated = undefined;
      }
    });
    return trips;
  }

  React.useEffect(() => {
    // don't allow "All Enterprises"
    if (context.rootId === -1) {
      context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
    }
  }, []);

  const fuelTypes = new Set(assets?.map(x => x.fuelType));
  const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
  const energyWord = (onlyIce) ? 'Fuel' : 'Energy';

  let fuelCost, avgMpg, avgMikwh, avgDistance, distPerDay, avgEff, totalDistance, totalFuelConsumed, chargingMinutes;
  let totalKwhConsumed;
  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;

  const noNaN = x => (x === undefined || Number.isNaN(x) ? 0 : x)
  if (totals && trips) {
    // use daily totals if there are no trip filters.  otherwise use filtered trips
    const list = trips.filter(x => x.openHours !== false);
    const assetCount = list.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
    fuelCost = totals.reduce((part, a) => part+noNaN(a.fuelSpend),    0);
    const totalCo2 = list.reduce((part, a) => part+noNaN(a.co2Generated),    0);
    totalDistance = list.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
    // co2PerMile = totalDistance ? totalCo2/kmToMiles(totalDistance) : 0;
    totalFuelConsumed = list.reduce((part, a) => part+noNaN(a.fuelConsumed), 0);
    totalKwhConsumed = list.reduce((part, a) => part+noNaN(a.kwhConsumed), 0);
    avgDistance = totalDistance/assetCount;
    if (list == totals) {
      distPerDay  = list.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
      chargingMinutes = totals.reduce((part, a) => part+noNaN(a.chargingMinutesPerDay), 0);
    } else {
      const uniqueDays = new Set()
      list.forEach(trip => uniqueDays.add(moment(trip.startTime).format('M-D')))
      distPerDay  = totalDistance/uniqueDays.size;
      chargingMinutes = list.reduce((part, a) => part+noNaN(a.chargingMinutes), 0) / uniqueDays.size;
    }
    const assetCountEff = list.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
    avgEff      = list.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
    avgMpg   = noNaN(kmToMiles(totalDistance)/(litersToGallons(totalFuelConsumed)));
    avgMikwh = noNaN(kmToMiles(totalDistance)/(totalKwhConsumed));

    list.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }
  // const eff = nonan(Math.round(totals.efficiency*100))

  useEffect(() => {
    if (context.refreshCount !== lastRefreshCount.current && dateRange) {
        lastRefreshCount.current = context.refreshCount;
        
        const date1 = moment(dateRange[0]).format('YYYY-MM-DD');
        const date2 = moment(dateRange[1]).format('YYYY-MM-DD');

        // Fetch ALL vehicles (ignoring model, fuel type, and asset filters)
        getVehicles(context.rootId).then(response => setAllVehicles(response.data));

        // Fetch only the FILTERED vehicles (based on UI selections)
        var baseFiltersFiltered = getFilters(context);
        getVehicles(context.rootId, baseFiltersFiltered).then(response => setAssets(response.data));
        
        // For ranking calculations, fetch ALL vehicles
        getDailyTotalAll(context.rootId, date1, date2).then(response => setTotals(response.data));
        
        // Reset to fully filtered data for operational totals and trips
        baseFiltersFiltered = getFilters(context, false);
        const operationalFilters = { ...baseFiltersFiltered, operational: true };
        getDailyTotal(context.rootId, date1, date2, operationalFilters).then(response => setOperationalTotals(response.data));

        // Now get trips with operational filters instead of regular filters
        getTrips(context.rootId, date1, date2, operationalFilters).then(response => setTrips(fixTrips(response.data)));
    }
  }, [context.refreshCount, dateRange]);

  // First, combine all vehicles with their totals
  useEffect(() => {
    if (allVehicles && totals) {
      const combined = allVehicles.map(asset => {
        const total = totals?.find(x => x.vehicleId == asset.id);
        return { ...asset, totals: total }
      });
      setCombinedAssets(combined);
    }
  }, [allVehicles, totals]);

  // Then, create a filtered subset for display
  useEffect(() => {
    if (assets && combinedAssets) {
      const filteredForDisplay = combinedAssets.filter(combinedAsset => 
        assets.some(asset => asset.id === combinedAsset.id)
      );
      setDisplayAssets(filteredForDisplay);
    }
  }, [assets, combinedAssets]);


  const columns = [
		    { header: 'Asset', key: 'name' },
		    { header: 'Group', key: 'unitId' },
		    { header: 'Make / Model', key: 'makeModel' },
		    { header: 'Year', key: 'year' },
		    { header: 'Fuel', key: 'fuelType' },
		    { header: 'Active Op Days', key: 'activeDays', downloadOnly: true, extended: true },
		    { header: 'Active Capacity', key: 'activeCapacity', downloadOnly: true, extended: true },
		    { header: 'Trips', key: 'tripCount' },
		    { header: 'Miles Traveled', key: 'distanceDriven' },
		    { header: 'Duration of Trips (Min)', key: 'tripMinutes' },
		    { header: 'Uptime', sortable: false, width: '7%', key: 'utilization', displayOnly: true },
		    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
		    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
		    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
		    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
		    { header: 'Utilization',          key: 'utilizationPct', downloadOnly: true },
		    { header: 'Energy/Fuel Consumed', key: 'fuelOrEnergyConsumed', displayOnly: true },
		    { header: 'Gallons Consumed', key: 'fuelConsumed', downloadOnly: true },
		    { header: 'kWh Consumed', key: 'kwhConsumed', downloadOnly: true },
		    { header: 'MPG / mi/kWh', key: 'mpgOrMpkwh', displayOnly: true },
		    { header: 'MPG', key: 'mpg', downloadOnly: true },
		    { header: 'Mi/kWh', key: 'mikwh', downloadOnly: true },
		    { header: 'Fuel/Energy Cost', key: 'fuelSpend' },
		    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
		    { header: 'Efficiency', key: 'efficiency' },
		    { header: 'Cost Per Mile', key: 'costPerMile', downloadOnly: true },
		    { header: 'CO2 Per Mile', key: 'co2PerMile', downloadOnly: true, extended: true },
		    { header: 'Ideal ' + energyWord + ' Consumed', key: onlyIce ? 'idealFuelConsumed' : 'idealKwhConsumed',
                      downloadOnly: true, extended: true },
		    { header: 'Ideal ' + energyWord + ' Cost', key: 'idealFuelSpend', downloadOnly: true, extended: true },
		    { header: 'Distance Per Day', key: 'distancePerDay', downloadOnly: true, extended: true },
//		    { header: energyWord + ' Cost Per ' + ((onlyIce) ? 'Gallon' : 'kWh'),
//                      key: onlyIce ? 'costPerGallon' : 'costPerKwh', downloadOnly: true, extended: true },
		    { header: 'Recovery Potential', key: 'recoveryPotential', downloadOnly: true, extended: true },
		    { header: 'Primary Use', key: 'primaryUse', downloadOnly: true, extended: true },
		    { header: 'Type', key: 'type', downloadOnly: true, extended: true },
		    { header: 'Accelerating Minutes - Op Hours', key: 'opAcceleratingMinutes', downloadOnly: true, extended: true },
		    { header: 'Decelerating Minutes - Op Hours', key: 'opDeceleratingMinutes', downloadOnly: true, extended: true },
		    { header: 'Idle Minutes - Op Hours',         key: 'opIdleMinutes', downloadOnly: true, extended: true },
		    { header: 'Offline Minutes - Op Hours',      key: 'opOfflineMinutes', downloadOnly: true, extended: true },
		    { header: 'Utilization - Op Hours',      key: 'opUtilizationPct', downloadOnly: true, extended: true },
  ];

  const round = x => noNaN(x).toFixed(0);
  const round1 = x => noNaN(x).toFixed(1);
  const round2 = x => noNaN(x).toFixed(2);
  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getAssetInfo = (asset, key) => {
    if (key == 'name')
      return getVehicleName(asset);
    if (key == 'makeModel')
      return asset.make + ' ' + asset.model;
    if (key == 'unitId')
      return context.units?.find(x => x.id == asset.unitId)?.name;
    if (key == 'upTimeHours')
      return asset.totals?.uptimeMinutes / 60;
    if (key == 'distanceDriven')
      return kmToMiles(asset.totals?.distanceDriven);
    if (key == 'distancePerDay')
      return kmToMiles(asset.totals?.distancePerDay);
    if (key == 'tripCount')
      return asset.totals?.tripCount - asset.totals?.stationaryTripCount;
    if (key == 'fuelConsumed') {
      if (asset.fuelType == 'EV')
        return;
      return litersToGallons(asset.totals?.fuelConsumed);
    }
    if (key === 'activeDays') {
      return asset.totals?.activeOpDays;
    }
    if (key === 'activeCapacity') {
      const totalOperationalDays = asset.totals?.opDays;
      const activeDays = asset.totals?.activeOpDays;
      
      if (totalOperationalDays === 0) return 0;
      return (activeDays / totalOperationalDays);
    }
    if (key == 'kwhConsumed')
      return asset.totals?.kwhConsumed;
    if (key == 'fuelOrEnergyConsumed')
      return (asset.fuelType == 'EV') ? asset.totals?.kwhConsumed : litersToGallons(asset.totals?.fuelConsumed);
    if (key == 'mpgOrMpkwh')
      return (asset.fuelType == 'EV') ? mpkwh(asset.totals, false) : mpg(asset.totals);
    if (key == 'idealFuelConsumed')
      return litersToGallons(asset.totals?.idealFuelConsumed);
    if (key == 'idealKwhConsumed')
      return asset.totals?.idealKwhConsumed;
    if (key == 'recoveryPotential')
      return asset.totals?.fuelSpend - asset.totals?.idealFuelSpend;
    if (key == 'fuelPerTrip')
      return litersToGallons(asset.totals?.fuelConsumed / asset.totals?.tripCount);
    if (key == 'mpge')
      return mpge(asset.totals);
    if (key == 'mpg') {
      if (asset.fuelType == 'EV')
        return;
      return mpg(asset.totals);
    }
    if (key == 'mikwh')
      return kmToMiles(asset.totals?.distanceDriven) / asset.totals?.kwhConsumed;
    if (key == 'costPerMile')
      return asset.totals?.fuelSpend / kmToMiles(asset.totals?.distanceDriven);
    if (key == 'costPerDay')
      return asset.totals?.fuelSpendPerDay;
    if (key == 'costPerTrip')
      return asset.totals?.fuelSpend / asset.totals?.tripCount;
    if (key == 'costPerGallon')
      return asset.totals?.fuelSpend / litersToGallons(asset.totals?.fuelConsumed);
    if (key == 'costPerKwh')
      return asset.totals?.fuelSpend / asset.totals?.kwhConsumed;
    if (key == 'co2e')
      return co2eInKg(asset.totals);
    if (key == 'co2PerMile')
      return co2PerMile(asset.totals);
    if (key == 'averageSpeed')
      return asset.totals?.averageSpeed;
    if (key == 'utilizationPct' && asset.totals) {
      const tot = asset.totals;
      const up = tot.acceleratingMinutes + tot.deceleratingMinutes + tot.idleMinutes;
      return 100*up/(up + tot.offlineMinutes);
    }
    if (key == 'opUtilizationPct' && asset.totals) {
      const tot = asset.totals;
      const up = tot.opAcceleratingMinutes + tot.opDeceleratingMinutes + tot.opIdleMinutes;
      return 100*up/(up + tot.opOfflineMinutes);
    }
    if (key == 'acceleratingMinutes' || key == 'deceleratingMinutes' || key == 'idleMinutes' || key == 'offlineMinutes' ||
        key == 'opAcceleratingMinutes' || key == 'opDeceleratingMinutes' || key == 'opIdleMinutes' || key == 'opOfflineMinutes' ||
        key == 'efficiency' || key == 'tripMinutes' || key == 'fuelSpend' || key == 'idealFuelSpend')
      return asset.totals && asset.totals[key];
    return asset[key];
  }

  const getAssetCell = (asset, key) => {
    if (key == 'utilization') {
      let offWidth = 0, idleWidth = 0, decWidth = 0, accWidth = 0;
      const total = asset.totals;
      if (total) {
	  let totalMins = total.acceleratingMinutes + total.deceleratingMinutes + total.idleMinutes + total.offlineMinutes;
	  offWidth = 100*total.offlineMinutes / totalMins;
	  idleWidth = 100*total.idleMinutes / totalMins;
	  decWidth = 100*total.deceleratingMinutes / totalMins;
	  accWidth = 100*total.acceleratingMinutes / totalMins;
      }
      return <td key={key} className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, total?.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, total?.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, total?.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, total?.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    if (key == 'map')
      return <td key={key} className="map"><a className="moving" data-title="Moving - Map current location" href="asset-details.html"></a></td>
    let val = getAssetInfo(asset, key);
    if (key == 'name')
      return <td key={key} className="text"><Link to={'/assets/' + asset.id}>{val}</Link></td>
    if (key == 'efficiency' && val)
      return <td key={key} className="number">{(val * 100).toFixed(0)}%</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      if (key == 'fuelOrEnergyConsumed' && val)
        val = (asset.fuelType == 'EV') ? val + ' kWh' : val + ' gal';
      return <td key={key} className="number">{val}</td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const calculateUtilization = (tot) => {
    if (!tot) return 0;
  
    const upTime = tot.acceleratingMinutes + tot.deceleratingMinutes + tot.idleMinutes;
    const totalTime = upTime + tot.offlineMinutes;
  
    // Ensure there's no division by zero
    return totalTime ? (100 * upTime / totalTime) : 0;
  };
  

  // const combinedAssets = combinedAssets;
  const calculateAssetGrade = (asset, allVehicles, metricKey = 'totalRanking') => {
    if (!asset?.totals) return 0;
    
    const t = asset.totals;
    
    // Safety function to avoid division by zero or NaN
    const safeValue = (val) => (Number.isFinite(val) && val !== 0 ? val : 1);
    
    // Calculate utilization
    const utilization = safeValue((t.acceleratingMinutes + t.deceleratingMinutes + t.idleMinutes) / 
                                (t.acceleratingMinutes + t.deceleratingMinutes + t.idleMinutes + t.offlineMinutes));
    
    // Calculate cost efficiency (CE)
    const costEfficiency = t.distanceDriven > 0 ? safeValue(t.fuelSpend / t.distanceDriven) : 0;
    
    // Find min/max values for normalization - USE allVehicles INSTEAD OF combinedAssets
    const maxEfficiency = safeValue(Math.max(...allVehicles.map(a => a?.totals?.efficiency || 0)));
    
    const allCostEfficiencies = allVehicles.map(a => safeValue(a?.totals?.fuelSpend / a?.totals?.distanceDriven));
    const minCostEfficiency = Math.min(...allCostEfficiencies);
    const maxCostEfficiency = Math.max(...allCostEfficiencies);
    
    const allEnergyEfficiencies = allVehicles.map(a => {
      if (!a?.totals || a.totals.kwhConsumed === 0) return 0; // Avoid division by zero
      return safeValue(a.totals.distanceDriven / a.totals.kwhConsumed);
    });
  
    const maxEnergyEfficiency = Math.max(...allEnergyEfficiencies);
    
    const allEmissionsEfficiencies = allVehicles.map(a => co2PerMile(a?.totals) || 0);
    const minEmissionsEfficiency = Math.min(...allEmissionsEfficiencies);
    const maxEmissionsEfficiency = Math.max(...allEmissionsEfficiencies);
    
    const allUtilizations = allVehicles.map(a => {
      if (!a?.totals) return 0;
      return safeValue((a.totals.acceleratingMinutes + a.totals.deceleratingMinutes + a.totals.idleMinutes) / 
                      (a.totals.acceleratingMinutes + a.totals.deceleratingMinutes + a.totals.idleMinutes + a.totals.offlineMinutes));
    });
    const maxUtilization = Math.max(...allUtilizations);
    
    // Calculate relative ranks exactly as specified
    const relOperationalEfficiency = (t.efficiency / maxEfficiency) * 5;
    
    const relCostEfficiency = 5 - 4 * ((costEfficiency - minCostEfficiency) / 
                                      (maxCostEfficiency - minCostEfficiency));
    
    const relEnergyEfficiency = t.kwhConsumed > 0 ? 
      5 * ((t.distanceDriven / t.kwhConsumed) / maxEnergyEfficiency) : 0;    
    
    const relEmissionsEfficiency = t.distanceDriven > 0 ? 
      5 - 4 * ((co2PerMile(t) - minEmissionsEfficiency) / 
              (maxEmissionsEfficiency - minEmissionsEfficiency)) : 0;
    
    const relUtilization = (utilization / maxUtilization) * 5;
    
    // Store all relative rankings
    const finalRelativeRanks = {
      operationalEfficiency: relOperationalEfficiency,
      costEfficiency: relCostEfficiency,
      energyEfficiency: relEnergyEfficiency,
      emissionsEfficiency: relEmissionsEfficiency,
      utilization: relUtilization
    };
    
    
    // Weight multipliers from the requirements
    const weights = {
      energyEfficiency: 0.75,   // 15% -> 0.75
      costEfficiency: 2.5,      // 50% -> 2.5
      emissionsEfficiency: 0.5, // 10% -> 0.5
      operationalEfficiency: 1, // 20% -> 1
      utilization: 0.25         // 5%  -> 0.25
    };
    
    const sumOfWeights = Object.values(weights).reduce((sum, weight) => sum + (weight * 5), 0);
    
    // Compute weighted contributions
    const weightedContributions = Object.keys(weights).reduce((acc, key) => {
      acc[key] = finalRelativeRanks[key] * weights[key];  // Apply weight to each ranking
      return acc;
    }, {});
    
    // Calculate total weighted score using the correct formula:
    // (sum of each relative ranking value * its weight) / (sum of weights) * 5
    const sumOfWeightedRankings = Object.values(weightedContributions).reduce((sum, val) => sum + (val), 0);
    const totalRanking = (sumOfWeightedRankings / sumOfWeights);
    // If requesting just one metric, return that specific weighted ranking
    if (metricKey !== 'totalRanking' && finalRelativeRanks[metricKey] !== undefined) {
      return (finalRelativeRanks[metricKey] * weights[metricKey]) / sumOfWeightedRankings * totalRanking * 5;
    }
    // Return both the total ranking and the breakdown of individual contributions
    return {
      totalRanking: totalRanking * 5,
      breakdown: Object.entries(weightedContributions).map(([key, value]) => ({
        label: key,
        value: finalRelativeRanks[key]  // Return the unweighted relative ranking for each metric
      }))
    };
  };


  useEffect(() => {
    // Reset filteredRankingAssets whenever assets or filters change
    setFilteredRankingAssets(null);
  }, [assets, totals, context.refreshCount, displayAssets]);


  const rankingBins = {
    Poor: [0, 2.0],
    Average: [2.0, 3.5],
    Good: [3.5, 5] // Ensure 'Good' captures everything above 3.5
  };
  
  // Reset counts before populating them
  const binCounts = { Poor: 0, Average: 0, Good: 0 };
  
  // Use combinedAssets to ensure all valid assets are considered
  (displayAssets || []).forEach(asset => {
    const rank = calculateAssetGrade(asset, combinedAssets)?.totalRanking || 0; // Ensure we always get a valid number
  console.log(asset);
  console.log(rank);
    // console.log(rank);
    if (rank >= rankingBins.Poor[0] && rank < rankingBins.Poor[1]) {
      binCounts.Poor++;
    } else if (rank >= rankingBins.Average[0] && rank < rankingBins.Average[1]) {
      binCounts.Average++;
    } else if (rank >= rankingBins.Good[0] && rank <= rankingBins.Good[1]) { 
      binCounts.Good++;
    }
  });
  

  const chartData = {
    labels: ['Poor', 'Average', 'Good'],
    datasets: [{
      label: 'Relative Ranking',
      data: [binCounts.Poor, binCounts.Average, binCounts.Good],
      backgroundColor: ['#B14EC5', '#F85E7D', '#F78957'],
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedIndex = elements[0].index;
        const selectedCategory = ['Poor', 'Average', 'Good'][clickedIndex];
        
        const [min, max] = rankingBins[selectedCategory];
        // Generate potential new filtered assets
        const potentialNewFilteredAssets = combinedAssets.filter(asset => {
          const rank = calculateAssetGrade(asset, combinedAssets).totalRanking || 0;
          return rank >= min && rank <= max;
        });
    
        // Check if we're clicking the same category that's already selected
        // by comparing the arrays - if they have the same assets, it means we're toggling
        if (filteredRankingAssets && 
            filteredRankingAssets.length === potentialNewFilteredAssets.length &&
            filteredRankingAssets.every(asset => 
              potentialNewFilteredAssets.some(newAsset => newAsset.id === asset.id)
            )) {
          // We're clicking the same category, so clear the filter
          setFilteredRankingAssets(null);
        } else {
          // Set to the new filtered assets
          setFilteredRankingAssets(potentialNewFilteredAssets);
        }
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Quantity of Vehicles',
          font: { size: 14, weight: 'bold' }
        }
      },
      x: {
        ticks: {
          font: { size: 14, weight: 'bold' }
        }
      }
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            // Add null check for tooltipItem and raw data
            if (tooltipItem && tooltipItem.raw !== undefined) {
              return `QTY of ${tooltipItem.label || 'Unknown'}: ${tooltipItem.raw}`;
            }
            return '';
          }
        }
      }
    }
  };
  
  const weights = {
    energyEfficiency: 0.75,
    costEfficiency: 2.5,
    emissionsEfficiency: 0.5,
    operationalEfficiency: 1,
    utilization: 0.25
  };

  const sortedAssets = (filteredRankingAssets || displayAssets || []).slice().sort((a, b) =>
    (calculateAssetGrade(a, combinedAssets)?.totalRanking || 0) -
    (calculateAssetGrade(b, combinedAssets)?.totalRanking || 0)
  );
  
    
  const colorMap = {
    utilization: "#5c496c",
    operationalEfficiency: "#c35c8b",
    emissionsEfficiency: "#e75b75",
    costEfficiency: "#d67740",
    energyEfficiency: "#29ABE2"
  };

  const getChartData = () => {
    let sortedAssetsCopy = [...sortedAssets];

    if (selectedMetric !== "totalRanking") {
      // Sort assets by selected metric (ascending order, worst to best)
      sortedAssetsCopy.sort((a, b) => {
        const gradeA = calculateAssetGrade(a, combinedAssets, selectedMetric) || 0;
        const gradeB = calculateAssetGrade(b, combinedAssets, selectedMetric) || 0;
        return gradeA - gradeB;
      });
    }

    if (selectedMetric === "totalRanking") {
      return {
        labels: sortedAssetsCopy.map(asset => asset.name),
        datasets: Object.keys(weights).map(metric => ({
          label: metric.charAt(0).toUpperCase() + metric.slice(1).replace(/([A-Z])/g, ' $1'),
          data: sortedAssetsCopy.map(asset => {
            const assetGrade = calculateAssetGrade(asset, combinedAssets);
            console.log(assetGrade);
            if (!assetGrade) return 0;

            const totalRanking = assetGrade.totalRanking;
            const breakdown = assetGrade.breakdown.find(b => b.label === metric);
            const unweightedValue = breakdown ? breakdown.value : 0;

            const sumOfWeightedValues = assetGrade.breakdown.reduce(
              (sum, b) => sum + (b.value * weights[b.label] || 0), 0
            );

            return (unweightedValue * weights[metric]) / sumOfWeightedValues * totalRanking;
          }),
          backgroundColor: colorMap[metric]
        }))
      };
    } else {
      return {
        labels: sortedAssetsCopy.map(asset => asset.name),
        datasets: [
          {
            label: metricOptions.find(option => option.key === selectedMetric)?.label || "Metric",
            data: sortedAssetsCopy.map(asset => calculateAssetGrade(asset, combinedAssets, selectedMetric)),
            backgroundColor: colorMap[selectedMetric] || "#c35c8b"
          }
        ]
      };
    }
  };
  const sortedAssetsCopy = [...sortedAssets];

  if (selectedMetric !== "totalRanking") {
    sortedAssetsCopy.sort((a, b) => {
      const gradeA = calculateAssetGrade(a, combinedAssets, selectedMetric);
      const gradeB = calculateAssetGrade(b, combinedAssets, selectedMetric);
  
      // Ensure missing values are handled properly
      const safeGradeA = Number.isFinite(gradeA) ? gradeA : Infinity; // Move undefined/NaN to the end
      const safeGradeB = Number.isFinite(gradeB) ? gradeB : Infinity;
  
      return safeGradeA - safeGradeB; // Sort ascending (worst to best)
    });
  }
  
  const baseChartWidthVh = 8; // Adjust this to control scaling
  const minChartWidthVh = 90; // Ensures no unnecessary shrinking
  
  const [chartWidth, setChartWidth] = useState(
    `${Math.max(sortedAssetsCopy.length * baseChartWidthVh, minChartWidthVh)}vh`
  );
  
  useEffect(() => {
    setChartWidth(`${Math.max(sortedAssetsCopy.length * baseChartWidthVh, minChartWidthVh)}vh`);
  }, [sortedAssetsCopy.length]);

  return <>
        <div id="focus" style={{ overflowY: 'auto', maxHeight: '90vh' }}>
            <FilterPanel filters={['fuelTypeFilter', 'groupFilter', 'modelFilter', 'assetFilter', 'dateFilter', 'enterpriseFilter']} />
            <div id="content">
            <div id="charts-container" style={{ minHeight: '30vh', maxHeight: '33vh', width: '90vw' }}>
            <div style={{
              padding: '12px',
              background: 'linear-gradient(135deg, #5c496c, #c35c8b, #e75b75, #d67740)',
              color: 'white',
              borderRadius: '15px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
              width: '80%',
              margin: '10px auto',
              textAlign: 'center',
              maxHeight: '200px',
              // position: 'sticky',
              // zIndex: '999',
              top: '0'
                }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', width: '100%' }}>
              <h2 style={{ fontSize: '26px', fontWeight: 'bold', margin: '0' }}>
                  Asset Ranking
              </h2>
             </div>
                    </div>

                    <div id="charts" style={{ paddingBottom: '7vi' }}>
                        <div className="individual-chart small">
                            <h4 style={{ fontWeight: "bold" }}>Efficiency</h4>
                            <div className="doughnut">
                                <AssetListDonut 
                                    mainText={round(noNaN(
                                        (operationalTotals?.efficiency) * 100
                                    )) + '%'} 
                                    subText={"Efficient"} 
                                />
                            </div>
                        </div>
                        
                        <div className="individual-chart">
                            <h4 style={{ fontWeight: "bold", marginBottom: '1.5vh' }}>Fleet Uptime</h4>
                            <div className="doughnut">
                            <UtilizationDonut accelerating={accMin} decelerating={decMin} idle={idleMin} offline={offMin} />
                            </div>
                        </div>

                        <div className="individual-chart small">
                            <h4 style={{ fontWeight: "bold" }}>Fuel/Energy Cost</h4>
                            <div className="doughnut">
                                <AssetListDonut 
                                    mainText={round(noNaN(100 *
                                        operationalTotals?.fuelSpend /
                                        kmToMiles(operationalTotals?.distanceDriven)
                                    )) + '¢'} 
                                    subText={"¢/Mile"} 
                                />
                            </div>
                        </div>

                        <div className="individual-chart small">
                            {!onlyEv && (
                                <>
                                    <h4 style={{ fontWeight: "bold" }}>CO<sub>2</sub> Emissions</h4>
                                    <div className="doughnut">
                                        <AssetListDonut 
                                            mainText={round2(noNaN(
                                                operationalTotals?.co2Generated /
                                                kmToMiles(operationalTotals?.distanceDriven)
                                            ) / 1000)} 
                                            subText={"kg/Mile"} 
                                        />
                                    </div>
                                </>
                            )}

                            {onlyEv && (
                                <>
                                    <h4 style={{ fontWeight: "bold" }}>Charging Time</h4>
                                    <div className="doughnut">
                                        <AssetListDonut 
                                            mainText={round(noNaN(
                                                operationalTotals?.chargingMinutesPerDay
                                            ))} 
                                            subText={"Hours/Day"} 
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="individual-chart small">
                            <h4 style={{ fontWeight: "bold" }}>Energy Efficiency</h4>
                            <div className="doughnut" style={smallDonutStyle}>
                            <DashboardDonut 
                              subText={"Mi/kWh"}
                              circleText={round2(noNaN(operationalTotals?.efficiency))}
                              labels={["Operational", "Overall"]}
                              values={
                                //round2(noNaN(operationalTotals?.efficiency)) === round2(noNaN(avgEff))
                                  //? [round2(noNaN(operationalTotals?.efficiency))] :
                                  [
                                    round2(noNaN(operationalTotals?.efficiency)),
                                    round2(noNaN(avgEff))
                                  ]
                              }
                          />
                    </div>
                  </div>
                  <div className="individual-chart small">
                      <h4 style={{ fontWeight: "bold" }}>Fuel Efficiency</h4>
                      <div className="doughnut">
                          <AssetListDonut 
              mainText={round1(noNaN(
                kmToMiles(operationalTotals?.distanceDriven) /
                litersToGallons(operationalTotals?.fuelConsumed)
              ))}

                              subText={"MPG"} 
                          />
                      </div>
                  </div>
              </div>

                </div>
                {/* <h2 style={{ textAlign: 'center', fontSize: '18px' }}>Relative Ranking</h2> */}
                <div style={{ width: '80vw', margin: '0 auto', height: '40vh' }}>
                    <Bar data={chartData} options={chartOptions} />
                  </div>
                  <div style={{ width: '100%', margin: '0 auto', marginTop: '20px', paddingTop: '.2vi', height: '50%', marginBottom: '1vi', borderTop: '1px solid #E3E2EC', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin:'35px', marginBottom: '0px' }}>
                    <h3 style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Vehicle Performance Ranking</h3>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <select 
                        value={selectedMetric} 
                        onChange={(e) => {
                          setSelectedMetric(e.target.value);
                          setDropdownOpen(false);
                        }}
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        style={{
                          padding: '1vh 2vw',
                          fontSize: '1.6vh',
                          fontWeight: 'bold',
                          borderRadius: '1vw',
                          border: 'none',
                          background: 'linear-gradient(135deg, #B14EC5, #F85E7D)',
                          color: 'white',
                          cursor: 'pointer',
                          boxShadow: '0vw 0.4vh 1vh rgba(0, 0, 0, 0.2)',
                          transition: 'all 0.3s ease-in-out',
                          outline: 'none',
                          color: 'black',
                          appearance: 'none',
                          WebkitAppearance: 'none',
                          MozAppearance: 'none',
                          paddingRight: '3vw',
                        }}
                        onMouseOver={(e) => e.target.style.boxShadow = '0vw 0.6vh 1.5vh rgba(0, 0, 0, 0.3)'}
                        onMouseOut={(e) => e.target.style.boxShadow = '0vw 0.4vh 1vh rgba(0, 0, 0, 0.2)'}
                      >
                        {metricOptions.map(option => (
                          <option 
                            key={option.key} 
                            value={option.key} 
                            style={{
                              background: '#fff',
                              color: '#333',
                              fontWeight: 'bold',
                            }}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div style={{
                        position: 'absolute',
                        top: '50%',
                        right: '14px',
                        transform: `translateY(-50%) ${dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'}`,
                        transition: 'transform 0.3s ease',
                        pointerEvents: 'none',
                        color: 'black',
                        fontSize: '16px',
                        fontWeight: 'bold'
                      }}>
                        ▼
                      </div>
                    </div>


                  </div>
                                      
                  <div 
                    style={{ 
                      width: '100%', 
                      overflowX: 'auto', 
                      paddingBottom: '1vi',
                      position: 'relative',
                    }}
                  >
                    <div style={{ width: `${chartWidth}`, height: '22.5vh', margin: '0 auto' }}>
                      <Bar 
                        data={getChartData(totals)}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            y: {
                              stacked: selectedMetric === "totalRanking",
                              beginAtZero: true,
                              min: 0,
                              grid: { display: true, color: "#ddd", lineWidth: 1 },
                              max: selectedMetric === "totalRanking" ? 5 : undefined,
                            },
                            x: {
                              stacked: selectedMetric === "totalRanking",
                              ticks: { autoSkip: false },
                            },
                          },
                          plugins: {
                            legend: { display: true },
                            tooltip: {
                              callbacks: {
                                title: (tooltipItems) => {
                                  const assetName = sortedAssetsCopy[tooltipItems[0].dataIndex]?.name || '';
                                  if (selectedMetric === "totalRanking") {
                                    const totalRanking = (calculateAssetGrade(
                                      sortedAssetsCopy[tooltipItems[0].dataIndex], 
                                      combinedAssets
                                    )?.totalRanking || 0);
                                    return `${assetName} - Total Ranking: ${totalRanking.toFixed(2)}/5`;
                                  }
                                  return assetName;
                                },
                                label: (tooltipItem) => {
                                  const assetIndex = tooltipItem.dataIndex;
                                  const asset = sortedAssetsCopy[assetIndex];
                                  if (selectedMetric === "totalRanking") {
                                    const assetGrade = calculateAssetGrade(asset, combinedAssets);
                                    const totalRanking = assetGrade?.totalRanking || 0;
                                    const metric = tooltipItem.dataset.label;
                                    const breakdown = assetGrade?.breakdown.find(b => b.label === metric.toLowerCase());
                                    const stackContribution = tooltipItem.raw; 
                                    return `${metric}: ${stackContribution.toFixed(2)}`;
                                  } 
                                  return `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}/5`;
                                }
                              },
                            },
                          },
                        }}
                      />
                    </div>

                    <div 
                      style={{ 
                        display: 'grid',
                        gridTemplateColumns: `repeat(${sortedAssetsCopy.length}, minmax(3.6vi, 1fr))`, 
                        alignItems: 'center',
                        justifyItems: 'center',
                        width: `${chartWidth}`, 
                        margin: '0 auto',
                        marginTop: '10px',
                        paddingLeft: '.5%',
                      }}
                    >
                      {sortedAssetsCopy.map((asset, index) => {
                        let car;
                        switch (asset.make + ' ' + asset.model) {
                          case 'Ram 1500':           car = ram1500; break;
                          case 'Ram 3500':           car = ram3500; break;
                          case 'Ram ProMaster 3500': car = promaster; break;
                          case 'Ford E-350':         car = fordE350; break;
                          case 'Ford E-450':         car = fordE350; break;
                          case 'Chevrolet Equinox EV': car = chevyEquinox; break;
                          case 'Ford E-Transit':     car = fordETransit; break;
                          case 'Freightliner M2':    car = freightlinerM2; break;
                          default:                   car = ram1500;
                        }

                        return (
                          <div 
                            key={`bar-img-${index}`} 
                            style={{ 
                              textAlign: 'center', 
                              display: 'flex', 
                              flexDirection: 'column', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              width: '100%',
                            }}
                          >
                          <img 
                            src={car} 
                            alt={asset.make + ' ' + asset.model} 
                            style={{ width: 'auto', height: '4.2vh', objectFit: 'contain' }} 
                          />

                            <div>
                              <a 
                                href={`${window.location.origin}/assets/${asset.id}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: '#29ABE2', fontSize: '0.65vi' }}
                              >
                                {asset.name}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  </div>
                <PagedList items={displayAssets} columns={columns} getItemInfo={getAssetInfo} getItemCell={getAssetCell}
                    itemType="Assets" extendedDownload={true} />
            </div>
        </div>
         </>;
}

export default AssetList;

