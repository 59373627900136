import React, { useState, useEffect, useMemo } from 'react';
import { getVehicle, getNewTripsForVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

function FleetEfficiencyDonut({ circleText, percent, previousPercent, small }) {

	const percentValue = percent || 100;
	const previousPercentValue = previousPercent || 100;

	// useMemo to avoid re-animation
        const fleet_efficiency_data = useMemo(() => ({
            //labels: [ 'Efficient', 'Driver Loss', 'Ambient Loss', "Vehicle Degredation" ],
            datasets: [{
              data: [percentValue, 100-percentValue],
              backgroundColor: [
                '#F85E7D','#E3E2EC'
                //'#F85E7D','#009245','#29ABE2','#F78957'
              ],
              borderWidth: 4
            },{
              data: [previousPercentValue, 100-previousPercentValue],
              backgroundColor: [
               '#B14EC5','#E3E2EC',
               //'#B14EC5','#009245','#29ABE2','#F78957'
              ],
              borderWidth: 4
            }]
        }), [percentValue, previousPercentValue]);

        const overall_grade_label = {
            id: 'doughnutLabel',
            beforeDatasetDraw (chart, args, pluginOptions) {
                const {ctx, data} = chart;

		if (circleText === undefined)
		    return;

                ctx.save();
                const width = chart.width;
                const xCoor = chart.getDatasetMeta(0).data[0].x;
                const yCoor = chart.getDatasetMeta(0).data[0].y;
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle'

		const maxWidth = width/2;
		let fontSize = Math.min(80, maxWidth); // Initial font size
		fontSize = 40;
            
		while (true) {
		    ctx.font = 'bold ' + fontSize + 'px Helvetica, "Helvetica Neue", Arial, sans-serif';

		    // Measure the width of the text
		    let textWidth = ctx.measureText(circleText).width;
            
		    // Adjust font size until text fits within maxWidth
		    if (textWidth <= maxWidth || fontSize == 1)
			break;
		    fontSize--;
		}

                ctx.fillText(circleText, xCoor, yCoor);
            }
        }
        
        const large_doughnut_options = {
            cutout: '65%',
            responsive: true,
            plugins: {
                tooltip: {
		    enabled: !!percent,
                    bodyFont: {
                        size: 24
                    }
                },
		legend: { display: false }
            }
        }

        const overall_grade_config = {
            type: 'doughnut',
            data: fleet_efficiency_data,
            options: large_doughnut_options,
            plugins: [overall_grade_label]
        }

        const dim = (small) ? '8vi' : '11.5vi';

        return (
          <div style={{ width: dim, height: dim, position: 'relative', maxHeight: '180%', maxWidth: '200px' }}>
            <Doughnut key={circleText} data={fleet_efficiency_data} options={large_doughnut_options} plugins={[overall_grade_label]} style={{ width: '100%', height: '100%' }} />
          </div> );
}

export default FleetEfficiencyDonut;

