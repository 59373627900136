export const lastSevenDays = () => {
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - 7);
  const oneDayAgo = new Date(currentDate);
  oneDayAgo.setDate(currentDate.getDate() - 1);
  return [sevenDaysAgo, oneDayAgo];
}

export const getDuration = val => {
    const asMin = Math.floor(val/60);
    const hr = Math.floor(asMin/60)
    const min = asMin-hr*60;
    if (hr == 0)
      return `${min}m`;
    return `${hr}h ${min}m`;
}

