import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getVehicles, getFilters, getTripsForVehicle, getDailyTotalByDate, getDailyTotal } from './api/vehicle';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import FleetChart from "./FleetChart";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { lastSevenDays } from "./util/dates";
import { co2PerMile, mpge, mpg, mpkwh, kmToMiles, litersToGallons } from "./util/formulas";
import { Context } from "./ContextHandler";
import mapboxgl from 'mapbox-gl';
import mapBus from './images/map-bus.svg';
import mapBusBlue from './images/map-bus-blue.svg';
import mapBusPink from './images/map-bus-pink.svg';
import mapBusPurple from './images/map-bus-purple.svg';
import mapBusOrange from './images/map-bus-orange.svg';
import { FuelTypes, classifyFuelTypes, fuelTypesList } from './util/fuelTypes';
import { mapboxToken } from './util/mapbox.js';
import BarGraphic from "./BarGraphic";

function FleetPage({ token }) {

    const [dailyGraph, setDailyGraph] = useState();
    const [assets, setAssets] = useState();
    const [totals, setTotals] = useState();
    const context = useContext(Context);
    const dateRange = context.dateRangeFilter;
    const lastRefreshCount = useRef(-1);
    const [mapRefValue, setMapRefValue] = useState();
    const [hoveredAsset, setHoveredAsset] = useState(null);

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 32, // latitude[0],
        longitude: -117, // longitude[0],
        zoom: 10
    });

    useEffect(() => {
	if (lastRefreshCount.current != context.refreshCount && dateRange) {
	    lastRefreshCount.current = context.refreshCount;
            const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
            const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
	    const filters = getFilters(context);
            getVehicles(context.rootId, filters).then(response => setAssets(response.data));
	    getDailyTotalByDate(context.rootId, date1, date2, filters).then(result => setDailyGraph(result.data));
	    getDailyTotal(context.rootId, date1, date2, filters)      .then(result => setTotals(result.data));
	}
    }, [context.refreshCount, dateRange]);

    // Separate logic for refreshing `getVehicles` every minute
    useEffect(() => {
      console.log('set up autorefresh');
      const intervalId = setInterval(() => {
	console.log("Refreshing vehicles...");
	const filters = getFilters(context);
        console.log('gv', filters, context.rootId);
	getVehicles(context.rootId, filters).then((response) => setAssets(response.data));
      }, 10000); // 60 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
    }, [context]);

    React.useEffect(() => {
      // don't allow "All Enterprises"
      if (context.rootId === -1) {
	 context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
      }
    }, []);

    // need this because we want to calculate bounding box after mapRef is set
    const handleMapRef = map => {
      setMapRefValue(map);
    }

    const fuelTypes = new Set(totals?.activeVehicleCountByFuelType?.
		map((value, index) => value === 0 ? null : fuelTypesList[index]).filter(x => x));
    const evCount = totals?.activeVehicleCountByFuelType && (totals.activeVehicleCountByFuelType[FuelTypes.EV]);
    const gasCount = totals?.activeVehicleCountByFuelType && (totals.activeVehicleCountByFuelType[FuelTypes.Gas]);
    const dieselCount = totals?.activeVehicleCountByFuelType && (totals.activeVehicleCountByFuelType[FuelTypes.Diesel]);
    const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
    const energyWord = onlyIce ? 'Fuel' : 'Energy';
    const isIce = x => x == FuelTypes.Gas || x == FuelTypes.Diesel;

    // Calculate bounding box and fit map to marker
    useEffect(() => {
      if (!assets)
	 return;
      let lon1 = 180, lat1 = 90, lon2 = -180, lat2 = -90;
      assets.forEach(asset => {
         if (!asset.latitude) return;
         lon1 = Math.min(lon1, asset.longitude);
         lat1 = Math.min(lat1, asset.latitude);
         lon2 = Math.max(lon2, asset.longitude);
         lat2 = Math.max(lat2, asset.latitude);
      });
      const pad = .03;
      const bound1 = [lon1-pad, lat1-pad];
      const bound2 = [lon2+pad, lat2+pad];
      const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

      if (mapRefValue) {
	mapRefValue.fitBounds(bounds, { duration: 0 });
      }
    }, [assets?.length, mapRefValue]);

    const filters = ['enterpriseFilter', 'fuelTypeFilter', 'groupFilter', 'modelFilter', 'assetFilter', 'dateFilter'];
    if (!totals)
	return <FilterPanel filters={filters} />;

    const round = x => nonan(x).toFixed(0);
    const round1 = x => nonan(x).toFixed(1);
    const round2 = x => nonan(x).toFixed(2);

    const nonan = x => Number.isNaN(x) || x === undefined ? 0 : x
    const fuelCost = nonan(Math.round(100 * totals.fuelSpend / kmToMiles(totals.distanceDriven)));
    const co2Value = round2(co2PerMile(totals)/1000);
    const activeHours = tot => (tot.acceleratingMinutes + tot.deceleratingMinutes + tot.idleMinutes)/60;
    const uptimeValue = round1(activeHours(totals) / dailyGraph?.length);
    const mpgValue = round2(mpkwh(totals, onlyIce));
    const totalDistanceInKm = dailyGraph?.reduce((tot, x) => tot+x.distanceDriven, 0);
    const distanceValue = round(kmToMiles(totalDistanceInKm/dailyGraph?.length));
    const chargingValue = round(totals.chargingMinutes/60 / dailyGraph?.length);
    const idleValue = round1(totals.idleMinutes/60 / dailyGraph?.length);
    const eff = nonan(Math.round(totals.efficiency*100))
    const activeCount = assets?.length;
    const addFuels = ice => nonan(totals?.fuelConsumedByType?.reduce((tot, x, index) => isIce(index) == ice ? x+tot : tot, 0));
    const summaryFuelConsumed = litersToGallons(addFuels(true));
    const summaryEnergyConsumed = addFuels(false);
    const getTransform = asset => {
      const bearing = asset.bearing;
      if (bearing == -1)
        return;
      if (bearing >= 0 && bearing < 180) {
	// Flip and rotate
	return `scaleX(-1) rotate(${90-bearing}deg)`;
      } else {
	// Rotate only
	return `rotate(${bearing-270}deg)`;
      }
    }

	const STATUS = {
        ACTIVE: "ACTIVE",
        IDLE: "IDLE",
        OFFLINE: "OFFLINE"
    };

	const OFFLINE_THRESHOLD_HOURS = 24;
    

	const statusIcons = {
        [STATUS.ACTIVE]: { name: "Active", color: "green", bus: mapBusBlue },
        [STATUS.IDLE]: { name: "Idle", color: "yellow", bus: mapBusPink },
        [STATUS.OFFLINE]: { name: "Offline", color: "gray", bus: mapBusOrange },
    };

		const getVehicleStatus = (asset) => {
			if (!asset.lastUpdate) return STATUS.OFFLINE;
		
			const lastUpdateTime = moment(asset.lastUpdate);
			const hoursSinceUpdate = moment().diff(lastUpdateTime, 'hours');
		
			if (hoursSinceUpdate > OFFLINE_THRESHOLD_HOURS) {
				return STATUS.OFFLINE;
			}
		
			return asset.speed === 0 ? STATUS.IDLE : STATUS.ACTIVE;
		};
	
		const updatedAssets = assets?.map(asset => ({
			...asset,
			status: getVehicleStatus(asset)
		}));

    let tripCount = totals.tripCount;
    if (totals.stationaryTripCount !== undefined)
      tripCount -= totals.stationaryTripCount;

    const grayColors = ['#6f7681', '#00188f'];
    const fuelTypeColors = ['#fc9432', '#ba23f6', '#F85E7D'];
    const gasDieselColors = ['#ba23f6', '#F85E7D'];

    return <>
	<FilterPanel filters={filters} />
        <div id="content">
	<div className="left">
	    <div style={{
		padding: '12px',
		background: 'linear-gradient(135deg, #5c496c, #c35c8b, #e75b75, #d67740)',
		color: 'white',
		borderRadius: '15px',
		boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
		width: '80%',
		margin: '10px auto',
		textAlign: 'center',
		maxHeight: '200px',
		// position: 'sticky',
		// zIndex: '999',
		top: '0'
	    }}>
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', width: '100%' }}>
		<h2 style={{ fontSize: '26px', fontWeight: 'bold', margin: '0' }}>
		    Operational Metrics
		</h2>
             </div>
            </div>
	    <div id="grades">
		<div id="overall-grade">
		    <h4>Overall Fleet Efficiency</h4>
		    <div className="doughnut">
			<DashboardDonut circleText={eff + '%'} percent={eff} />
		    </div>
		</div>
		<div id="individual-grades">
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={fuelCost + '¢'} />
			</div>
			<div className="text">
			    <h4>Fuel Cost</h4>
			    <p>{fuelCost}¢/Mile</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={onlyEv ? chargingValue : co2Value} />
			</div>
			{onlyEv || <div className="text">
			    <h4>CO<sub>2</sub> Emissions</h4>
			    <p>{co2Value}kg/Mile</p>
			</div>}
			{onlyEv && <div className="text">
			    <h4>Time Charging</h4>
			    <p>Hours/Day</p>
			</div>}
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={uptimeValue} />
			</div>
			<div className="text">
			    <h4>{onlyIce ? 'Active Engine Time' : 'Active Hours'}</h4>
			    <p>{uptimeValue} Hours/Day</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={mpgValue} />
			</div>
			<div className="text">
			    <h4>{energyWord} Efficiency</h4>
			    <p>{mpgValue} {onlyIce ? 'MPG' : 'Mi/kWh'}</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={distanceValue} />
			</div>
			<div className="text">
			    <h4>Distance Driven</h4>
			    <p>{distanceValue} Miles/Day</p>
			</div>
		    </div>
		    <div className="individual-grade">
			<div className="doughnut">
			    <DashboardDonut circleText={idleValue} />
			</div>
			<div className="text">
			    <h4>Idle Time</h4>
			    <p>{idleValue} Hours/Day</p>
			</div>
		    </div>
		</div>
		
	    </div>
	    <div className="chart-row">
		<div className="linechart">
		    <h4>Daily Average {energyWord} Cost per Mile (Cents)</h4>
		    <FleetChart width={600} height={140} data={dailyGraph} func={x => x.distanceDriven == 0 ? 0 : x.fuelSpend / kmToMiles(x.distanceDriven) } />
		</div>
		<div className="linechart">
		    <h4>Daily {onlyIce && 'Fuel'} Efficiency per Asset ({onlyIce ? 'MPG' : 'mi/kWh'})</h4>
		    <FleetChart width={600} height={140} data={dailyGraph} func={x => nonan(mpkwh(x, onlyIce))} />
		</div>
	    </div>
	    <div className="chart-row">
		{onlyEv || <div className="linechart">
		    <h4>Daily CO<sub>2</sub> Emissions (g/Mile)</h4>
		    <FleetChart width={600} height={140} data={dailyGraph} func={x => co2PerMile(x)} />
		</div>}
		{onlyEv && <div className="linechart">
		    <h4>Daily Charge Time (Hours)</h4>
		    <FleetChart width={600} height={140} data={dailyGraph} func={x => x.chargingMinutes/60} />
		</div>}
		<div className="linechart">
		    <h4>Daily Distance Driven (Miles)</h4>
		    <FleetChart width={600} height={140} data={dailyGraph} func={x => kmToMiles(x.distanceDriven)} />
		</div>
	    </div>
	    <div className="chart-row">
		<div className="linechart">
		    <h4>Daily Up Time (Hours)</h4>
		    <FleetChart width={600} height={140} data={dailyGraph} func={x => activeHours(x) } />
		</div>
		<div className="linechart">
		    <h4>Daily Idle Time (Hours)</h4>
		    <FleetChart width={600} height={140} data={dailyGraph} func={x => x.idleMinutes/60 } />
		</div>
	    </div>
	</div>
	<div className="right" style={{ display: 'flex', flexDirection: 'column' }}>
	    <div style={{
		padding: '12px',
		background: 'linear-gradient(135deg, #5c496c, #c35c8b, #e75b75, #d67740)',
		color: 'white',
		borderRadius: '15px',
		boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
		width: '80%',
		margin: '10px auto',
		textAlign: 'center',
		maxHeight: '200px',
		// position: 'sticky',
		// zIndex: '999',
		top: '0'
	    }}>
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', width: '100%' }}>
		<h2 style={{ fontSize: '26px', fontWeight: 'bold', margin: '0' }}>
		    Usage Summary
		</h2>
             </div>
            </div>
	    <div className="perf-summary-container">
		<BarGraphic colors={grayColors} header="Asset Utilization" segments={[
		  { label: "Utilized", value: totals?.activeVehicleCount },
		  { label: "Not Utilized", value: assets?.length - totals?.activeVehicleCount }
		]} />
		<BarGraphic colors={fuelTypeColors} header="Utilized Fleet Composition" segments={[
		  { label: "EV", value: evCount },
		  { label: "Diesel", value: dieselCount },
		  { label: "Gas", value: gasCount },
		]} />
		<BarGraphic colors={grayColors} header="Fleet Uptime (Hours)" segments={[
		  { label: "Active Hours", value: activeHours(totals) },
		  { label: "Offline Hours", value: totals.offlineMinutes/60 }
		]} />
		<BarGraphic dollars={true} header="Estimated Fuel Costs ($)" colors={grayColors} segments={[
		  { label: "Idle", value: totals.totalIdleFuelSpend },
		  { label: "Active", value: totals.totalFuelSpend - totals.totalIdleFuelSpend }
		]} />
		{totals.distanceByType && <BarGraphic header="Distance Traveled (Mi)" colors={fuelTypeColors} units="Mi" segments={[
		  { label: "EV", value: kmToMiles(totals.distanceByType[FuelTypes.EV]) },
		  { label: "Diesel", value: kmToMiles(totals.distanceByType[FuelTypes.Diesel]) },
		  { label: "Gas", value: kmToMiles(totals.distanceByType[FuelTypes.Gas]) },
		]} />}
		{totals.tripsByType && <BarGraphic header="Trips" colors={fuelTypeColors} segments={[
		  { label: "EV", value: totals.tripsByType[FuelTypes.EV] },
		  { label: "Diesel", value: totals.tripsByType[FuelTypes.Diesel] },
		  { label: "Gas", value: totals.tripsByType[FuelTypes.Gas] },
		]} />}
		{totals.fuelConsumedByType && <BarGraphic units="Gal" colors={gasDieselColors} header="Fuel Consumed (Gal)" segments={[
		  { label: "Diesel", value: litersToGallons(totals.fuelConsumedByType[FuelTypes.Diesel]) },
		  { label: "Gas", value: litersToGallons(totals.fuelConsumedByType[FuelTypes.Gas]) },
		]} />}
		{totals.kwhConsumedByType && <BarGraphic units="kWh" colors={fuelTypeColors} header="Overall Energy Consumed (kWh)" segments={[
		  { label: "EV", value: totals.kwhConsumedByType[FuelTypes.EV] },
		  { label: "Diesel", value: totals.kwhConsumedByType[FuelTypes.Diesel] },
		  { label: "Gas", value: totals.kwhConsumedByType[FuelTypes.Gas] },
		]} />}
		{totals.co2GeneratedByType && <BarGraphic units="kg Co2" colors={gasDieselColors} header="Emissions Produced (kg CO2)" segments={[
		  { label: "Diesel", value: totals.co2GeneratedByType[FuelTypes.Diesel]/1000 },
		  { label: "Gas", value: totals.co2GeneratedByType[FuelTypes.Gas]/1000 },
		]} />}
	    </div>
	</div>
    </div></>;
}

export default FleetPage;

