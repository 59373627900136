import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { getZones, updateZone, addZone, deleteZone } from '../api/zone';
import { Link } from 'react-router-dom';
import FilterPanel from "../FilterPanel";
import { Context } from "../ContextHandler";
import PagedList from "../PagedList";
import Map, { Source, Layer, Popup, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import type {FeatureCollection} from 'geojson';
import { getFeatures } from '../util/zones';
import { getOpCenters } from '../api/unit';
import Tabs from './Tabs';
import Form from './Form';
import moment from 'moment';
import ZoneModalMap from './ZoneModalMap';
import { customFilter, toggleFilter } from '../util/filter';
import { canViewMultipleEnterprises } from '../util/security';
import { mapboxToken, MAP_STYLES, StyleControl } from '../util/mapbox.js';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

function ZonePage({ token }) {
  const [zones, setZones] = useState();

  // can't create zones yet but will add later
  const [newFeature, setNewFeature] = useState({});
  const [opCenters, setOpCenters] = useState([]);
  const [zoneGeometry, setZoneGeometry] = useState({});

  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const mapRef = useRef(null);
  const [hoverInfo, setHoverInfo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [initialData, setInitialData] = useState();
  const [shapeFilter, setShapeFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [popupVisible, setPopupVisible] = useState(-1);
  const [shape, setShape] = useState();
  const [findText, setFindText] = useState();
  const [marker, setMarker] = useState();

  const [mapStyle, setMapStyle] = useState(MAP_STYLES.navNight.url);

  // Handle style change from the control
  const handleStyleChange = (styleKey) => {
    setMapStyle(MAP_STYLES[styleKey].url);
  };

  // Mapbox GL initial viewport settings
  const [viewState, setViewState] = useState({
      latitude: 34, // latitude[0],
      longitude: -118.4, // longitude[0],
      zoom: 10
  });

  const filterCheck = x => (x !== undefined && x.length == 0) ? undefined : x;
  let groupFilter = filterCheck(context.groupFilter);

  const filterFunc = z => (!groupFilter || groupFilter.includes(z.unitId)) &&
			  (shapeFilter.length == 0 || shapeFilter.includes(z.shape)) &&
                          ( typeFilter.length == 0 ||  typeFilter.includes(z.defined ? z.type : 'Undefined'));
  const filteredZones = (zones?.filter(filterFunc));

  const geocodingClient = mbxGeocoding({ accessToken: mapboxToken });

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current) {
      lastRefreshCount.current = context.refreshCount;
      getZones(context.rootId).then(response => setZones(response.data));
    }
  }, [context.refreshCount]);

  const features = getFeatures(filteredZones);
 
  const getBounds = zlist => {
    const pad = (zlist.length == 1) ? .002 : .005;
    const longitudes = zlist.flatMap(zone => zone.points.filter((_, index) => index % 2 == 1));
    const latitudes  = zlist.flatMap(zone => zone.points.filter((_, index) => index % 2 == 0));
    const bound1 = [Math.min(...longitudes)-pad, Math.min(...latitudes)-pad];
    const bound2 = [Math.max(...longitudes)+pad, Math.max(...latitudes)+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);
    return bounds;
  }

  const zoomIn = zlist => {
    if (zlist.length == 0)
      return;
    if (mapRef.current) {
      mapRef.current.fitBounds(getBounds(zlist), { duration: 500 });
    }
  };

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!zones)
       return;
    zoomIn(filteredZones);
  }, [zones, context.groupFilter]);

  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: features
  };

  const onClick = useCallback(e => {
    const pickFeature = features => {
      if (!features)
        return;
      // if mousing over multiple zones, prefer undefined or point-radius zones
      const f = features.find(x => x.properties.defined == false);
      if (f)
        return f;
      const f2 = features.find(x => x.properties.type == 'PointRadius');
      if (f2)
        return f2;
      return features[0];
    }
    const features = e.features;
    const hoveredFeature = pickFeature(features);
    if (hoveredFeature)
      setHoverInfo({
        longitude: e.lngLat.lng,
        latitude: e.lngLat.lat,
        properties: hoveredFeature.properties
      });
    else
      setHoverInfo(null);
  }, []);

  const columns = [
    { key: 'edit', sortable: false },
    { header: 'Zone Name', key: 'name', width: '12%' },
    { header: 'Zone Shape', key: 'shape', width: '8%' },
    { header: 'Zone Type', key: 'type', width: '8%' },
    { header: 'Enterprise', key: 'rootUnitId' },
    { header: 'Op Center', key: 'unitId' },
    { header: 'Address', key: 'address' },
    { header: 'Visits', key: 'visitCount' },
    { header: 'Created', key: 'creationTimestamp', width: '7%' }
  ];

  const mapField = <ZoneModalMap initialViewState={viewState} geojson={geojson} initialData={initialData}
				 getBounds={getBounds} setZoneGeometry={setZoneGeometry} shape={shape} />;

  const enterpriseChanged = ent => {
    getOpCenters(ent).then(result => {
       if (!result.data)
         setOpCenters([]);
       else
         setOpCenters(result.data.filter(c => !c.readOnly));
    });
  };

  const shapeChoices = [{ id: 'PointRadius', name: 'Point-Radius' }, { id: 'Polygon', name: 'Polygon' }];
  const typeCreationChoices = [{ id: 'Tracking', name: 'Tracking' }, { id: 'RemoteHub', name: 'Remote Hub' }];

  const shapeChanged = sh => {
    setShape(sh);
    setZoneGeometry({});  // force user to create new zone after shape is changed
  }

  const fields = [
    { custom: mapField },
    { key: 'name' },
    { key: 'shape', choices: shapeChoices, onChange: shapeChanged }
  ];

  if (!initialData?.id) {
    if (canViewMultipleEnterprises())
      fields.push( { key: 'rootUnitId', label: 'Enterprise',
          choices: context.enterprises?.map(x => ({ name: x.name, id: x.unitId })), onChange: enterpriseChanged });
    fields.push( { key: 'unitId', label: 'Op Center', choices: opCenters } );
  }
  if (initialData?.type != 'OpCenter') {
    fields.push( { key: 'type', choices: typeCreationChoices } );
  }

  React.useEffect(() => {
    // get op centers if can't pick enterprises
    if (showModal && !initialData?.id && !canViewMultipleEnterprises())
      enterpriseChanged(context.rootId);
  }, [showModal]);

  if (!initialData?.defined)
    fields.push({ key: 'defined', choices: [{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }] });

  const getItemInfo = (item, key) => {
    if (key == 'coordinates')
      return item['points'].join(', ');
    if (key == 'unitId' || key == 'rootUnitId')
      return context.units?.find(x => x.id == item[key])?.name;
    if (key == 'shape')
      return item[key] == 'Polygon' ? 'polygon' : 'point-radius';
    if (key == 'creationTimestamp')
       return item[key] ? item[key] : '0';
    return item[key];
  }

  const round = x => x?.toFixed(2)

  const editZone = item => {
    setInitialData(item);
    setShape(item.shape);
    setZoneGeometry({ points: item.points });
    setShowModal(true);
  };

  const formFinished = () => setShowModal(false);

  const creationReason = z => {
    if (!z.creationReason)
      return;
    return '(' + z.creationReason + ' ' + z.creationData + ' min)';
  }

  const getPopupInfo = id => {
    const zone = zones.find(z => z.id == id);
    if (!zone)
      return;
    return <div style={{ fontSize: 15 }}>Name: {zone.name}<br/>Address: {zone.address}<br/>
      Op Center: {context.units?.find(x => x.id == zone.unitId)?.name}<br/>
      Visits: <Link to={"/trips/zone/" + zone.id}>{zone.visitCount}</Link><br/>
      {zone.creationTimestamp && <>Created: {moment(zone.creationTimestamp).format('M-D h:mma')} {creationReason(zone)}<br/></>}
      <a href="#" style={{ fontSize: 15 }} onClick={ev => editZone(zone)}>{zone.defined ? 'Edit/Delete' : 'Confirm/Delete'}</a>
      </div>;
  }

  const getItemCell = (item, key) => {
    let val = getItemInfo(item, key);
    let style;
    if (hoverInfo?.properties.id == item.id)
      style = { backgroundColor: '#ffffa0' };
    if (key == 'edit') {
      if (!item.defined)
        return <td key={key} className="confirm" style={style}><a data-title="Confirm Zone" href="#" onClick={ev => editZone(item)}></a></td>
      return <td key={key} className="edit" style={style}><a data-title="Edit Zone" href="#" onClick={ev => editZone(item)}></a></td>
    }
    if (key == 'visitCount') {
      return <td key={key} className="number" style={style}><Link to={"/trips/zone/" + item.id}>{val}</Link></td>;
    }
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number" style={style}>{val}</td>;
    }
    if (key == 'name')
      return <td key={key} className="text" style={style}><a href="#" onClick={ev => zoomIn([item])}>{val}</a></td>;
    if (key == 'creationTimestamp') {
      if (val == '0')
        val = 'unknown';
      else {
	  const format = 'M-D h:mma';
	  const hoverText = moment(val).utcOffset(0).format('M-D H:mm:ss') + ' GMT';
	  val = moment(val).format(format);
	  return <td key={key} className="text" title={hoverText}>{val}</td>;
      }
    }
    if (key == 'type') {
      if (item.type == 'OpCenter')
        return <td key={key} className="op-center" style={style} />;
      if (item.type == 'RemoteHub')
        return <td key={key} className="remote-hub" style={style} />;
      if (item.defined === false)
        return <td key={key} className="undefined" style={style} />;
      return <td key={key} className="tracking" style={style} />;
    }
    return <td key={key} className="text" style={style}>{val}</td>;
  }

  const initializeUnitId = item => {
    if (context.rootId != -1) {
      item.rootUnitId = context.rootId;
      enterpriseChanged();
      if (context.groupFilter?.length === 1)
        item.unitId = context.groupFilter[0];
    }
    return item;
  };

  const handleAddZone = () => {
    setShowModal(true);
    setZoneGeometry({});
    setInitialData(initializeUnitId({ defined: true, type: 'Tracking' }));
    setNewFeature({});
  }

  const validateZone = zone => zone.name && zone.unitId && zone.type && zoneGeometry.points;

  const canEditItem = z => !context.units?.find(u => u.id == z.unitId)?.readOnly;

  const getItemClass = zone => {
       if (!canEditItem(zone))
	  return 'no-edit';
       if (zone.defined == false)
	  return 'undefined';
  }

  const getExtraData = () => {
    if (!zoneGeometry.points)
      return;

    // we have zone drawing, use it
    const data = { ...zoneGeometry }

    return data;
  }

  const clearShapeFilter = () => setShapeFilter([]);
  const addShapeFilter = shape => setShapeFilter(toggleFilter(shapeFilter, shape));

  const clearTypeFilter = () => setTypeFilter([]);
  const addTypeFilter = type => setTypeFilter(toggleFilter(typeFilter, type));

  const typeChoices = [{ id: 'OpCenter', name: 'Op Center' }, { id: 'Tracking', name: 'Tracking' },
                       { id: 'Undefined', name: 'Undefined' }, { id: 'RemoteHub', name: 'Remote Hub' }];

  const customFilters = [
      customFilter(shapeFilter, 'shape-filter', 'Zone Shapes', 1, 'All Zone Shapes', shapeChoices, clearShapeFilter, addShapeFilter,
             popupVisible, setPopupVisible),
      customFilter(typeFilter, 'type-filter', 'Zone Types', 2, 'All Zone Types', typeChoices, clearTypeFilter, addTypeFilter,
             popupVisible, setPopupVisible)
  ];

  const handleVeilClick = ev => {
    if (ev.target.id == 'modal')
      setShowModal(false);
  };

  const findAddress = ev => {
    console.log(findText);
    geocodingClient.forwardGeocode({
      query: findText,
      limit: 1
    })
      .send()
      .then(response => {
	const match = response.body.features[0];
        setViewState({ latitude: match.geometry.coordinates[1], longitude: match.geometry.coordinates[0], zoom: 15 });
        setMarker({ latitude: match.geometry.coordinates[1], longitude: match.geometry.coordinates[0] });
      });
  }

  const findTextChanged = ev => {
    setFindText(ev.target.value);
    setMarker();
  }

  return <>
            <div id="focus">
                <Tabs selected="zones" />
                <FilterPanel title="Route Zones" filters={['enterpriseFilter', 'groupFilter']} allowAllEnterprises={true}
			     customFilters={customFilters} rightSide={(
                    <ul className="legend">
                        <li className="op-center"><span></span>Op Center</li>
                        <li className="tracking"><span></span>Tracking</li>
                        <li className="remote-hub"><span></span>Remote Hub</li>
                        <li className="undefined-idle"><span></span>Undefined (Idle)</li>
                        <li className="undefined-off"><span></span>Undefined (Off)</li>
                    </ul>
                )} />
                <div id="map">
		    <Map
			{...viewState}
			mapboxAccessToken={mapboxToken} ref={mapRef}
			onMove={evt => setViewState(evt.viewState)}
			mapStyle={mapStyle}
			interactiveLayerIds={['polygon-layer']}
                        onClick={onClick}
		    >
			<StyleControl onStyleChange={handleStyleChange} currentStyle={mapStyle} />
			<Source id="polygon-data" type="geojson" data={geojson}>
			  <Layer id="polygon-layer" type="fill" paint={{ 'fill-color': ['get', 'color'], 'fill-opacity': 0.9, }} />
			  <Layer id="polygon-outline" type="line" paint={{ 'line-color': '#000', 'line-width': 2, }} />
			</Source>
                            {hoverInfo && (
                              <Popup
                                longitude={hoverInfo.longitude}
                                latitude={hoverInfo.latitude}
                                closeButton={false}
                                closeOnClick={false}
                                anchor="top"
                                maxWidth={450}
                              >
                                {getPopupInfo(hoverInfo.properties.id)}
                              </Popup>
                            )}
			    {marker && <Marker
				  latitude={marker.latitude}
				  longitude={marker.longitude}
				  anchor="center"
				>
				  <div
				    style={{
				      width: "20px",
				      height: "20px",
				      backgroundColor: "red",
				      borderRadius: "50%",
				      border: "2px solid white",
				    }}
				  />
				</Marker>}
		    </Map>
		</div>
                <div id="content">
		    <PagedList items={filteredZones} columns={columns} getItemInfo={getItemInfo}
			       getItemClass={getItemClass} getItemCell={getItemCell} itemType="Zones"
                               tableActions={<><a href="#" className="add-new polygon modal-trigger"
                                                           onClick={ev => handleAddZone()}>Add Zone</a>
                                               <input type="search" name="search" id="search" value={findText} style={{ width: 300 }} placeholder={"Address"} onChange={findTextChanged} />
                                               <button style={{ position: 'relative', top: -15, marginRight: 30, marginLeft: 10 }} onClick={findAddress}>Find Address</button>
                                             </>}
                     />
		</div>

        {showModal && <div id="modal" className="veil" style={{ display: 'block' }} onClick={handleVeilClick}>
            <div className="modal-popup vehicles">
                <div className="modal-content">
                    <Form fields={fields} addItem={addZone} updateItem={updateZone}
                          deleteItem={initialData.type != 'OpCenter' && deleteZone}
			  validateItem={validateZone} objectName="zone"
 			  headerText="Zone Info" formFinished={formFinished} initialData={initialData}
                          extraData={getExtraData()} />
                </div>
             </div>
             </div>}
             </div>
         </>;
}

export default ZonePage;
