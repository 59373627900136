import React, { useState, useContext } from 'react';
import { Context } from "../ContextHandler";

function Form({ fields, addItem, updateItem, validateItem, headerText, formFinished, initialData, extraData, deleteItem, refresh }) {
  const context = useContext(Context);
  const [editItemData, setEditItemData] = useState(initialData);
  const [errorMessage, setErrorMessage] = useState();

  function capitalize(string) {
     return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditItemData({ ...editItemData, [name]: value });
    setErrorMessage();
  };

  const getFieldInput = field => {
    if (!field.choices) {
       const type = (field.key.startsWith('password')) ? 'password' : 'text';
       return <input type={type} id={field.key} name={field.key} value={editItemData[field.key] === undefined ? '' : editItemData[field.key]} onChange={handleInputChange} />
    }
    if (field.idObject) {
      const id = editItemData[field.key]?.id;
      console.log('field', id, field);
      return <select value={id}
                     onChange={ev => setEditItemData({ ...editItemData, [field.key]: { id: ev.target.value }})}>
               <option value="">Choose one...</option>
               {field.choices.map(x => <option value={x.id}>{x.name === undefined ? x.id : x.name}</option>)}
             </select>;
    }
    return <select value={editItemData[field.key]} onChange={ev => setEditItemData({ ...editItemData, [field.key]: ev.target.value })}>
             <option value="">Choose one...</option>
               {field.choices.map(x => <option value={x.id}>{x.name === undefined ? x.id : x.name}</option>)}
           </select>;
  }

  const getField = field => {
     if (field.showCondition && !field.showCondition(editItemData))
        return;
     if (field.heading)
        return <tr><td colspan="2" class="header">{field.heading}</td></tr>;
     if (field.custom)
        return field.custom;
     return <tr>
               <td><label>{field.label || capitalize(field.key)}</label></td>
               <td>{getFieldInput(field)}</td>
            </tr>
  };

  const handleCancel = ev => {
    ev.preventDefault();
    formFinished && formFinished();
  }

  const handleSubmitItem = async () => {
    try {
      const data = { ...editItemData, ...extraData };
      const promise = (editItemData.id) ? updateItem(editItemData.id, data) : addItem(data);
      promise.then(result => {
         console.log(result);
         if (refresh)
           refresh();
         else
           context.dispatch({ type: 'refresh' });
         if (formFinished)
           formFinished();
         else
           setErrorMessage('Account info updated.');
      }).catch(error => {
	 console.error(error);
         if (error.response?.data)
           setErrorMessage(error.response.data);
         else if (error.message)
           setErrorMessage(error.message);
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = ev => {
    ev.preventDefault();
    handleSubmitItem();
  }

  const handleDelete = ev => {
    ev.preventDefault();
    try {
      deleteItem(editItemData.id).then(result => {
         console.log(result);
         if (refresh)
           refresh();
         else
           context.dispatch({ type: 'refresh' });
         formFinished && formFinished();
      }).catch(error => { console.error(error); });
    } catch (error) {
      console.error(error);
    }
  }

  const disabled = (validateItem) ? !validateItem(editItemData) : false;

  return (
                    <form class="settings-form">
                        <table>
                            <tr>
                                <td colspan="2" class="header">{headerText}</td>
                            </tr>
                            {fields.map(field => getField(field))}
                            <tr>
                                <td colspan="2" class="submit">
                                   <button class="modal-cancel" onClick={handleCancel}>Cancel</button>
                                   {deleteItem && editItemData.id && <button class="secondary" onClick={handleDelete}>Delete</button>}
                                   <button onClick={handleSubmit} disabled={disabled}>Submit</button></td>
                            </tr>
			    {errorMessage && <tr><td style={{ color: 'red' }}>{errorMessage}</td></tr>}
                        </table>
                    </form> );
}

export default Form;

