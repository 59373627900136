import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, BarElement } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import { kmToMiles, litersToGallons, co2InKg, mpkwh, mpge, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, BarElement, annotationPlugin);

const MileageEmissionsChart = ({ width, height, data, previousData, func, dateSpan1, dateSpan2 }) => {

        if (!data || data.length == 0 || !previousData || previousData.length == 0)
	   return <div style={{ width: width }} />;

        const line_labels = data.map(x => moment(x.date).format('D-MMM'));

        const mileage_emissions_data = {
            labels: line_labels,
              datasets: [{
                type: 'bar',
                label: 'Mileage ' + dateSpan1,
                data: data.map(x => kmToMiles(x.distanceDriven)),
                borderColor: 'rgba(248, 94, 125, .3)',
                backgroundColor: 'rgba(248, 94, 125, .3)',
                order: 3
              },{
                type: 'line',
                label: 'Emissions ' + dateSpan1,
                data: data.map(x => co2PerMile(x)),
                fill: false,
                borderColor: '#F85E7D',
                borderWidth: 2,
                tension: 0.1,
                pointStyle: 'circle',
                pointRadius: 2,
                pointBorderColor: '#F85E7D',
                backgroundColor: '#F85E7D',
                order: 1
              },{
                type: 'bar',
                label: 'Mileage ' + dateSpan2,
                data: previousData.map(x => kmToMiles(x.distanceDriven)),
                borderColor: 'rgba(177, 78, 197, .3)',
                backgroundColor: 'rgba(177, 78, 197, .3)',
                order: 4
              },{
                type: 'line',
                label: 'Emissions ' + dateSpan2,
                data: previousData.map(x => co2PerMile(x)),
                fill: false,
                borderColor: '#B14EC5',
                borderWidth: 2,
                tension: 0.1,
                pointStyle: 'circle',
                pointRadius: 2,
                pointBorderColor: '#B14EC5',
                backgroundColor: '#B14EC5',
                order: 2
              }]
        }

        const mixed_options = {
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                y: {
                    ticks: {
                        font: {
                            size: 10
                        }
                    }
                },
                x: {
                    ticks: {
                        font: {
                            size: 10
                        }
                    }
                }
            }
        }

    return <Line data={mileage_emissions_data} options={mixed_options} width={width} height={height} />;
};

export default MileageEmissionsChart;

