import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { getZones, updateZone, addZone, deleteZone } from '../api/zone';
import Filter from "../Filter";
import { Context } from "../ContextHandler";
import PagedList from "../PagedList";
import Map, { Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import type {FeatureCollection} from 'geojson';
import DrawControl from '../draw-control';
import { getFeatures } from '../util/zones';
import Tabs from './Tabs';
import Form from './Form';

import CircleMode from './mapbox-gl-draw-circle/modes/CircleMode';
import DragCircleMode from './mapbox-gl-draw-circle/modes/DragCircleMode';
import DirectModeOverride from './mapbox-gl-draw-circle/modes/DirectModeOverride';
import SimpleSelectModeOverride from './mapbox-gl-draw-circle/modes/SimpleSelectModeOverride';
import MapboxDraw from '@mapbox/mapbox-gl-draw';


function ZonePage({ token }) {
  const [zones, setZones] = useState();

  // can't create zones yet but will add later
  const [createState, setCreateState] = useState(1);
  const [newFeature, setNewFeature] = useState({});

  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const mapRef = useRef(null);
  const modalMapRef = useRef(null);
  const [hoverInfo, setHoverInfo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [initialData, setInitialData] = useState();
  const drawPolygon = useRef();
  const [points, setPoints] = useState();
  const [radius, setRadius] = useState();

  const updatePoints = feature => {
    let newPoints = [];
    const coordArr = feature.geometry.coordinates;
    const coordinates = (drawPolygon.current) ? coordArr[0] : [feature.properties.center];
    coordinates.forEach(coord => newPoints.push(coord[1], coord[0]));
    if (!drawPolygon.current)
      setRadius(feature.properties.radiusInKm * 1000);
    setPoints(newPoints);
  };

  const onCreate = React.useCallback(e => {
     setNewFeature(e.features[0]);
     updatePoints(e.features[0]);
     setCreateState(2);
  }, []);

  const onUpdate = React.useCallback(e => {
     setNewFeature(e.features[0]);
     updatePoints(e.features[0]);
  }, []);

  const onDelete = React.useCallback(e => {
  }, []);

    // Mapbox token
    const mapboxToken = 'pk.eyJ1IjoicGZhY2NlbCIsImEiOiJjbHV1a3Y3YmkwMDRpMmlwYm9xN3AwNDI0In0.DgtjwUVyRSS7vq3jaXV4Jg';

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 34, // latitude[0],
        longitude: -118.4, // longitude[0],
        zoom: 10
    });

    const [modalViewState, setModalViewState] = useState({
        latitude: 34, // latitude[0],
        longitude: -118.4, // longitude[0],
        zoom: 10
    });

  const filterCheck = x => (x !== undefined && x.length == 0) ? undefined : x;
  let groupFilter = filterCheck(context.groupFilter);
  const filteredZones = (groupFilter) ? (zones?.filter(z => groupFilter.includes(z.unitId))) : zones;

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current) {
      lastRefreshCount.current = context.refreshCount;
      getZones(context.rootId).then(response => setZones(response.data));
    }
  }, [context.refreshCount]);

  const features = getFeatures(filteredZones);
 
  const zoomIn = zlist => {
    if (zlist.length == 0)
      return;
    const pad = (zlist.length == 1) ? .002 : .005;
    const longitudes = zlist.flatMap(zone => zone.points.filter((_, index) => index % 2 == 1));
    const latitudes  = zlist.flatMap(zone => zone.points.filter((_, index) => index % 2 == 0));
    const bound1 = [Math.min(...longitudes)-pad, Math.min(...latitudes)-pad];
    const bound2 = [Math.max(...longitudes)+pad, Math.max(...latitudes)+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds, { duration: 500 });
    }
  };

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!zones)
       return;
    zoomIn(filteredZones);
  }, [zones, context.groupFilter]);

  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: features
  };

  const new_geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: newFeature && [newFeature]
  };

  const onHover = useCallback(e => {
    const pickFeature = features => {
      if (!features)
        return;
      // if mousing over multiple zones, prefer undefined or point-radius zones
      const f = features.find(x => x.properties.defined == false);
      if (f)
        return f;
      const f2 = features.find(x => x.properties.type == 'PointRadius');
      if (f2)
        return f2;
      return features[0];
    }
    const features = e.features;
    const hoveredFeature = pickFeature(features);
    if (hoveredFeature)
      setHoverInfo({
        longitude: e.lngLat.lng,
        latitude: e.lngLat.lat,
        properties: hoveredFeature.properties
      });
    else
      setHoverInfo(null);
  }, []);

  const columns = [
    { key: 'edit', sortable: false },
    { header: 'Zone Name', key: 'name', width: '12%' },
    { header: 'Zone Shape', key: 'shape', width: '8%' },
    { header: 'Zone Type', key: 'type', width: '8%' },
    { header: 'Enterprise', key: 'unit' },
    { header: 'Address', key: 'address' }
  ];

  const mapField = <tr>
                     <td colspan="2" class="map">
                       <div id="modal-map">
			 <Map
			     {...modalViewState}
			     mapboxAccessToken={mapboxToken} ref={modalMapRef}
			     onMove={evt => setModalViewState(evt.viewState)}
			     mapStyle="mapbox://styles/mapbox/streets-v9"
			     interactiveLayerIds={['polygon-layer']}
			 >
			<Source id="polygon-data" type="geojson" data={geojson}>
			  <Layer id="polygon-layer" type="fill" paint={{ 'fill-color': ['get', 'color'], 'fill-opacity': 0.9, }} />
			  <Layer id="polygon-outline" type="line" paint={{ 'line-color': '#000', 'line-width': 2, }} />
			</Source>
			     {createState == 0 && newFeature && <Source id="polygon-data-new" type="geojson" data={new_geojson}>
			       <Layer id="polygon-layer-new" type="fill" paint={{ 'fill-color': '#ff8000', 'fill-opacity': 0.5, }} />
			       <Layer id="polygon-outline-new" type="line" paint={{ 'line-color': '#000', 'line-width': 3, }} />
			     </Source>}
			     {createState > 0 && <DrawControl
			       position="top-left"
			       displayControlsDefault={false}
			       controls={{
				 polygon: true,
				 trash: true
			       }}
                               defaultMode={drawPolygon.current ? "draw_polygon" : "draw_circle"}
			       userProperties={true}
			       modes={drawPolygon.current ? MapboxDraw.modes : {
				 ...MapboxDraw.modes,
				 draw_circle  : CircleMode,
				 drag_circle  : DragCircleMode,
				 direct_select: DirectModeOverride,
				 simple_select: SimpleSelectModeOverride
			       }}
			       onCreate={onCreate}
			       onUpdate={onUpdate}
			       onDelete={onDelete}
			     />}
			 </Map>
                       </div>
                     </td>
                   </tr>;

  const fields = [
    { custom: mapField },
    { key: 'name' },
    { key: 'unitId', label: 'Business Unit', choices: context.units },
    { key: 'type',
      choices: [{ id: 'Tracking', name: 'Tracking' }, { id: 'OpCenter', name: 'OpCenter' }] },
  ];

  if (!initialData?.defined)
    fields.push({ key: 'defined', choices: [{ id: 'true', label: 'Yes' }, { id: 'false', label: 'No' }] });

  const getItemInfo = (item, key) => {
    if (key == 'coordinates')
      return item['points'].join(', ');
    if (key == 'unit')
      return context.units?.find(x => x.id == item['unitId'])?.name;
    if (key == 'shape')
      return item[key] == 'Polygon' ? 'polygon' : 'point-radius';
    return item[key];
  }

  const round = x => x?.toFixed(2)

  const editZone = item => {
    setInitialData(item);
    setShowModal(true);
  };

  const formFinished = () => setShowModal(false);

  const getItemCell = (item, key) => {
    let val = getItemInfo(item, key);
    if (key == 'edit')
      return <td class="edit"><a data-title="Edit Zone" href="#" onClick={ev => editZone(item)}></a></td>
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td class="number">{val}</td>;
    }
    let style;
    if (hoverInfo?.properties.id == item.id)
      style = { backgroundColor: '#ffffa0' };
    if (key == 'name')
      return <td class="text" style={style}><a href="#" onClick={ev => zoomIn([item])}>{val}</a></td>;
    if (key == 'type') {
      if (item.type == 'OpCenter')
        return <td class="op-center" style={style} />;
      if (item.defined === false)
        return <td class="undefined" style={style} />;
      return <td class="tracking" style={style} />;
    }
    return <td class="text" style={style}>{val}</td>;
  }

  const handleAddZone = (addPolygon) => {
    drawPolygon.current = addPolygon;
    setShowModal(true);
    setInitialData({ defined: true });
    setNewFeature({});
    setModalViewState({ ...viewState });
  }

  const validateZone = zone => zone.name && zone.unitId && zone.type;

  return <>
            <div id="focus">
                <Tabs selected="zones" />
		<Filter title="Route Zones" allowAllEnterprises={true} groupFilterOnly={true} rightSide={(
                    <ul class="legend">
                        <li class="op-center"><span></span>Op Center</li>
                        <li class="tracking"><span></span>Tracking</li>
                        <li class="undefined"><span></span>Undefined</li>
                    </ul>
                )} />
                <div id="map">
		    <Map
			{...viewState}
			mapboxAccessToken={mapboxToken} ref={mapRef}
			onMove={evt => setViewState(evt.viewState)}
			mapStyle="mapbox://styles/mapbox/streets-v9"
			interactiveLayerIds={['polygon-layer']}
                        onMouseMove={onHover}
		    >
			<Source id="polygon-data" type="geojson" data={geojson}>
			  <Layer id="polygon-layer" type="fill" paint={{ 'fill-color': ['get', 'color'], 'fill-opacity': 0.9, }} />
			  <Layer id="polygon-outline" type="line" paint={{ 'line-color': '#000', 'line-width': 2, }} />
			</Source>
                            {hoverInfo && (
                              <Popup
                                longitude={hoverInfo.longitude}
                                latitude={hoverInfo.latitude}
                                closeButton={false}
                                closeOnClick={false}
                                anchor="top"
                              >
                                <div>{hoverInfo.properties.name}</div>
                              </Popup>
                            )}
		    </Map>
		</div>
                <div id="content">
		    <PagedList items={filteredZones} columns={columns} getItemInfo={getItemInfo} getItemCell={getItemCell} itemType="Zones"
                               tableActions={<><a href="#" class="add-new polygon modal-trigger"
                                                           onClick={ev => handleAddZone(true) }>Add Polygon</a>
                                               <a href="#" class="add-new radius modal-trigger"
                                                           onClick={ev => handleAddZone(false)}>Add Circle</a></>}
                     />
		</div>

        {showModal && <div id="modal" class="veil" style={{ display: 'block' }}>
            <div class="modal-popup vehicles">
                <div class="modal-content">
                    <Form fields={fields} addItem={addZone} updateItem={updateZone} deleteItem={deleteZone}
			  validateItem={validateZone}
 			  headerText="Zone Info" formFinished={formFinished} initialData={initialData}
                          extraData={initialData.id || { points: points, radius: radius,
                                       shape: drawPolygon.current ? 'Polygon' : 'PointRadius' }} />
                </div>
             </div>
             </div>}
             </div>
         </>;
}

export default ZonePage;
