import React, { useState, useEffect } from 'react';
import { getVehicle, getNewTripsForVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

function AssetListDonut({ mainText, subText, percent }) {

	const percentValue = percent || 100;
        const overall_grade_data = {
            datasets: [{
              data: [percentValue, 100-percentValue],
              backgroundColor: [
                '#F85E7D','#E3E2EC'
              ],
              borderWidth: 0
            }]
        }
        
        const overall_grade_label = {
            id: 'doughnutLabel',
            beforeDatasetDraw (chart, args, pluginOptions) {
                const {ctx, data} = chart;

                ctx.save();
                const width = chart.width;
                const xCoor = chart.getDatasetMeta(0).data[0].x;
                const yCoor = chart.getDatasetMeta(0).data[0].y;
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'alphabetic'

		const maxWidth = width/2;
		let fontSize = Math.min(60, maxWidth); // Initial font size
		fontSize = 30;
		let ascent1;
            
		while (true) {
		    ctx.font = 'bold ' + fontSize + 'px Helvetica, "Helvetica Neue", Arial, sans-serif';

		    // Measure the width of the text
		    let metrics = ctx.measureText(mainText);
		    let textWidth = metrics.width;
		    ascent1 = metrics.actualBoundingBoxAscent;
            
		    // Adjust font size until text fits within maxWidth
		    if (textWidth <= maxWidth || fontSize == 1)
			break;
		    fontSize--;
		}
		const fontSize1 = fontSize;
		const font1 = ctx.font;
		let ascent;

		const maxWidth2 = width * .4;
		//fontSize = Math.min(30, maxWidth2); // Initial font size
		fontSize = 15;
		while (true) {
		    ctx.font = 'normal ' + fontSize + 'px Helvetica, "Helvetica Neue", Arial, sans-serif';

		    // Measure the width of the text
		    let metrics = ctx.measureText(subText);
		    let textWidth = metrics.width;
		    ascent = metrics.actualBoundingBoxAscent;
            
		    // Adjust font size until text fits within maxWidth
		    if (textWidth <= maxWidth2 || fontSize == 1)
			break;
		    fontSize--;
		}

                ctx.textBaseline = 'middle'
		const totalHeight = ascent + ascent1 + 15;
                ctx.fillText(subText,  xCoor, yCoor+totalHeight/2 - fontSize /2);
		ctx.font = font1;
                ctx.fillText(mainText, xCoor, yCoor-totalHeight/2 + fontSize1/2);
            }
        }
        
        const small_doughnut_options = {
            cutout: '68%',
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: false
                }
            }
        }

        const overall_grade_config = {
            type: 'doughnut',
            data: overall_grade_data,
            options: small_doughnut_options,
            plugins: [overall_grade_label]
        }

        return (
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <Doughnut key={mainText} data={overall_grade_data} options={small_doughnut_options} plugins={[overall_grade_label]} style={{ width: '100%', height: '100%' }} />
          </div> );
}

export default AssetListDonut;

