import React, { useState, useRef, useEffect, useContext } from 'react';

function PagedList({ items, columns, getItemInfo, getItemCell, itemType, tableActions, getItemClass, extendedDownload, filter }) {

  const firstSortableColumn = () => columns.find(col => col.sortable !== false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [sortConfig, setSortConfig] = useState({ key: firstSortableColumn().key, direction: 'asc' });
  const [popupVisible, setPopupVisible] = useState(-1);
  const [searchText, setSearchText] = useState('');

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = items && [...items].sort((a, b) => {
    let aval = getItemInfo(a, sortConfig.key);
    let bval = getItemInfo(b, sortConfig.key);
    if (typeof aval === 'number' || typeof bval === 'number') {
      if (isNaN(aval) || aval === undefined)
        aval = 1e8;
      if (isNaN(bval) || bval === undefined)
        bval = 1e8;
    }
    if (sortConfig.direction === 'asc') {
      return aval > bval ? 1 : -1;
    } else {
      return aval < bval ? 1 : -1;
    }
  });

  const lowerSearchText = searchText.toLowerCase();

  const itemMatches = item => columns.some(col => {
      const info = getItemInfo(item, col.key);
      return (info && info.toString().toLowerCase().includes(lowerSearchText));
    });

  let matchingItems = searchText.length > 0 ? sortedItems.filter(item => itemMatches(item)) : sortedItems;
  if (filter)
     matchingItems = matchingItems?.filter(filter);

  function capitalize(string) { return string.charAt(0).toUpperCase() + string.slice(1); }

  const getHeader = col => {
    if (col.header)
      return col.header;
    return capitalize(col.key);
  }

  const download = ext => {
    let downloadCols = columns.filter(col => col.displayOnly !== true);
    if (!ext)
       downloadCols = downloadCols.filter(col => col.extended !== true);
    let aFileParts = matchingItems.map(item => downloadCols.map(col => {
       const info = getItemInfo(item, col.key)
       if (typeof info == 'number' && isNaN(info))
         return '';
       if (typeof info == 'string' && info.includes(','))
         return '"' + info + '"';
       return info;
    }).join(',') + "\n");
    aFileParts.unshift(downloadCols.map(col => col.downloadHeader || getHeader(col)).join(',') + "\n");
    var blob = new Blob(aFileParts, { type: 'text/csv' });
    var url = URL.createObjectURL(blob);
    var anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    const title = 'download';
    anchor.download = itemType + '.csv';
    anchor.click();
  };

  const lastPage = Math.max(0, Math.floor((matchingItems ? matchingItems.length-1 : 0)/pageSize));

  useEffect(() => {
    if (page > lastPage)
      setPage(lastPage);
  });

  const displayColumns = columns.filter(col => col.downloadOnly !== true);

  return <>
                    <div id="table-actions">
			{tableActions}
                        <form id="search-bar">
                            <input type="search" name="search" id="search" value={searchText} placeholder={"Search " + itemType} onChange={ev => setSearchText(ev.target.value)} />
                        </form>

                        {/*<a href="" className="columns">Columns</a>*/}
                        <div className="download-dropdown dropdown" onMouseEnter={ev => extendedDownload && setPopupVisible(1)} onMouseLeave={ev => setPopupVisible(-1)}>
                            <a href="#" onClick={ev => !extendedDownload && download(false)} className="download">Download</a>
                            {popupVisible == 1 && <div className="dropdown-content">
                                <a href="#" onClick={ev => download(false)}>Regular</a>
                                <a href="#" onClick={ev => download(true )}>Advanced</a>
                            </div>}
                        </div>

                    </div>
                    <div id="table">
                        <table className="big-data">
                            <thead className="sortable">
                                <tr>
                                    {displayColumns.map(col => {
                                       let cls = '';
				       const onClick = col.sortable == false ? undefined : (ev => handleSort(col.key));
				       if (col.sortable == false)
                                         cls = 'no-sort';
				       if (sortConfig.key === col.key)
                                         cls = 'sorted ' + sortConfig.direction;
				       if (col.key == 'edit')
				 	 cls = 'no-sort edit';
                                       if (col.width)
                                          return <th key={col.key} className={cls} width={col.width} onClick={onClick}>{getHeader(col)}</th>;
                                       return <th key={col.key} className={cls} onClick={onClick}>{getHeader(col)}</th>;
                                    })}
                                </tr>
                            </thead>

                            <tbody>
                                {matchingItems?.slice(page*pageSize, (page+1)*pageSize).
                                      map(item => <tr key={item.id} className={getItemClass && getItemClass(item)}>
						     {displayColumns.map(col => getItemCell(item, col.key))}
						  </tr>)}
                            </tbody>
                        </table>                        
                    </div>
                    <div id="table-pagination">
                        <div id="page-selector">
                            Show Rows:
                            <div className="dropdown"
                                onMouseEnter={ev => setPopupVisible(0)} onMouseLeave={ev => setPopupVisible(-1)}>{pageSize > 100 ? 'All' : pageSize}
                                {popupVisible == 0 && <div className="dropdown-content">
                                    <a href="#" onClick={ev => setPageSize(15)}>15</a>
                                    <a href="#" onClick={ev => setPageSize(25)}>25</a>
                                    <a href="#" onClick={ev => setPageSize(50)}>50</a>
                                    <a href="#" onClick={ev => setPageSize(100)}>100</a>
                                    <a href="#" onClick={ev => setPageSize(100000)}>All</a>
                                </div>}
                            </div>
                        </div>
                        <div id="page-count">
                            {page*pageSize+1}-{Math.min((page+1)*pageSize, matchingItems?.length)} of {matchingItems?.length}
                        </div>
                        <div id="page-controls">
                            <span className="begin" onClick={ev => setPage(0)}></span>
                            <span className="back" onClick={ev => setPage(Math.max(page-1, 0))}></span>
                            <span className="forward" onClick={ev => setPage(Math.min(lastPage, page+1))}></span>
                            <span className="end" onClick={ev => setPage(lastPage)}></span>
                        </div>
                    </div>
         </>;
}

export default PagedList;
