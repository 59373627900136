import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { eventMetadataMap } from './util/events';

Chart.register(ArcElement, Tooltip, Legend);

function AlertTypeDonut({ alerts }) {
    if (!alerts || alerts.length === 0) {
        return <p></p>;
    }

    // Group alerts by category using alertId and categoryDataMap
    const typeCounts = alerts.reduce((acc, alert) => {
        const category = eventMetadataMap[alert.type]?.category || 'VEHICLE';
        acc[category] = (acc[category] || 0) + 1;
        return acc;
    }, {});    

    // Extract labels and data
    const labels = Object.keys(typeCounts);
    const data = Object.values(typeCounts);

    // Define chart data
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Alert Count by Type',
                data: data,
                backgroundColor: ['#d67740', '#ffcc00', '#3e78b2'],
                borderColor: '#fff',
                borderWidth: 2,
                hoverOffset: 8, // Add some interactivity
            },
        ],
    };

    // Chart options
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 20,
                    font: {
                        size: 16,
                        weight: 'bold',
                    },
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleFont: {
                    size: 18,
                    weight: 'bold',
                },
                bodyFont: {
                    size: 16,
                },
                padding: 10,
                cornerRadius: 10,
            },
            title: {
                display: true,
                text: 'Alert Count by Type',
                font: {
                    size: 20,
                    weight: 'bold',
                },
                padding: {
                    top: 10,
                    bottom: 20,
                },
            },
        },
    };

    return (
        <div style={{ width: '80%', height: '100%', position: 'relative' }}>
            <Doughnut data={chartData} options={chartOptions} />
        </div>
    );
}

export default AlertTypeDonut;
