import React, { useState } from 'react';
import axios from 'axios';
import './css/styles.css';
import logo from './images/Accelevate Logo White.svg';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleLogin = async () => {
      axios.post('/auth/login', { username, password }).then(result => {
        const data = result.data;
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('name', data.name);
        localStorage.setItem('unitId', data.unitId);
        localStorage.setItem('rootUnitId', data.rootUnitId);
        localStorage.setItem('role', data.role);
        const token = data.token;
        const payload = token.split('.')[1];
        const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
        const payloadObj = JSON.parse(decodedPayload);
        onLogin(data.token, payloadObj.exp);
        setError(false);
      }).catch(error => {
        setError(true);
      });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <html>
	<head>
	    <title>Login | Accelevate Solutions</title>
	</head>

	<body>
	    <div id="content" class="login">
		<div class="left">
		    <img src={logo} />
		    <h2>Start Where You Are.<br />
			Maximize What You Have.<br />
			Optimize Where You’re Going.
		    </h2>
		</div>
		<div class="right">
		    <h2>Energy Blend Intelligence<br />
		    Dashboard
		    </h2>
                    <div id="error" style={{ visibility: error ? 'visible' : 'hidden' }}>Login failed, please try again.</div>
		    <form id="login" onSubmit={ev => ev.preventDefault()}>
			<label for="username">Username</label>
                        <input type="text" value={username} onChange={handleUsernameChange} />
			<label for="password">Password</label>
                        <input type="password" value={password} onChange={handlePasswordChange} />
                        <input type="submit" onClick={handleLogin} name="login" value="Login" />
			{/*<p><a href="#">Forgot Password?</a></p>*/}
		    </form>

		</div>
	    </div>
	</body>
    </html>
  );
}

export default Login;

