export const timezones = [
      { id: 'US/Arizona' },
      { id: 'US/Central' },
      { id: 'US/Eastern' },
      { id: 'US/Hawaii' },
      { id: 'US/Mountain' },
      { id: 'US/Pacific' },
];

const typeChoices = [
      { id: 'OpCenter', name: 'Op Center' },
      { id: 'BusinessUnit', name: 'Business Unit' },
];

const opCenterCond = item => item.type == 'OpCenter';

export const unitFields = [
                { key: 'name', label: 'Name' },
                { key: 'type', label: 'Type', choices: typeChoices, showCondition: item => item.id === undefined },
                { key: 'address', label: 'Address', showCondition: opCenterCond },
                { key: 'wifiSSID', label: 'WiFi SSID', showCondition: opCenterCond },
                { key: 'wifiPassword', label: 'WiFi Password', showCondition: opCenterCond },
                { key: 'timeZone', label: 'Time Zone', choices: timezones, showCondition: opCenterCond },
                { key: 'dayStartTime', label: 'Day Start Time', showCondition: opCenterCond },
                { key: 'dayEndTime', label: 'Day End Time', showCondition: opCenterCond }
];

