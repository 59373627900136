import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { getTrip, getTripZones, getTelemetry } from './api/trip';
import { getVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { kmToMiles, litersToGallons, co2eInKg, mpkwh, mpg, mpge, fuelSpendPerMile, co2PerMile } from './util/formulas';
import DateRangeFilter from "./DateRangeFilter";
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";
import { Map as MapGL, Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import TripEfficiencyDonut from "./TripEfficiencyDonut";
import { getFeatures } from './util/zones';
import { getVehicleZones } from './api/vehicle';
import { mapboxToken, MAP_STYLES, StyleControl } from './util/mapbox.js';
import throttle from 'lodash/throttle';
import { carImage } from './util/carImage';
import play from './images/play.svg';
import pause from './images/pause.svg';

import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, TimeScale, CategoryScale, Title, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns'; // Date adapter for time scale
import annotationPlugin from 'chartjs-plugin-annotation';

// Register ChartJS components
ChartJS.register(LineController, LineElement, PointElement, LinearScale, TimeScale, CategoryScale, Title, Legend, Filler, annotationPlugin);

function TripDetails({ token }) {
  const { id } = useParams();
  const [trip, setTrip] = useState();
  const [vehicle, setVehicle] = useState();
  const [gpsTelemetry, setGpsTelemetry] = useState();
  const [speedData, setSpeedData] = useState();
  const [fuelData,  setFuelData]  = useState();
  const [annotations, setAnnotations] = useState();
  const [distanceData, setDistanceData]  = useState();
  const [faults, setFaults] = useState();
  const [zones, setZones] = useState([]);
  const [playbackRate, setPlaybackRate] = useState(40);
  const navigate = useNavigate();
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const [hoverInfo, setHoverInfo] = useState();
  const [playing, setPlaying] = useState(false);
  const [playIndex, setPlayIndex] = useState(0);
  const [mousePosition, setMousePosition] = useState(null);
  const mapRef = useRef(null);
  const isEV = trip?.fuelType == 'EV';
  const throttledSetMousePosition = useMemo(() => throttle(setMousePosition, 25), []);

  const latitudes = gpsTelemetry && (gpsTelemetry.values);
  const longitudes = gpsTelemetry && (gpsTelemetry.values2);

  // Mapbox GL initial viewport settings
  const [viewState, setViewState] = useState({
      latitude: 32, // latitude[0],
      longitude: -117, // longitude[0],
      zoom: 10
  });

  const [mapStyle, setMapStyle] = useState(MAP_STYLES.navNight.url);

  // Handle style change from the control
  const handleStyleChange = (styleKey) => {
    setMapStyle(MAP_STYLES[styleKey].url);
  };

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!gpsTelemetry || latitudes.length == 0)
       return;
    const pad = .01;
    const bound1 = [Math.min(...longitudes)-pad, Math.min(...latitudes)-pad];
    const bound2 = [Math.max(...longitudes)+pad, Math.max(...latitudes)+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds, { duration: 0 });
    }
  }, [gpsTelemetry]);

  useEffect(() => {
     if (context.refreshCount != lastRefreshCount.current) {
	getTrip(id).then(result => {
            const t = result.data;
            setTrip(t);
            const ev = t.fuelType == 'EV';
            getTelemetry(t.id, ['latitude', 'speed', ev ? 'electricEnergyOut' : 'deviceTotalFuel'], true).then(result => {
              if (!result?.data?.items || result.data.items.length < 3) {
                console.error("Telemetry API returned unexpected data:", result);
                return;
              }
		const gps = result?.data?.items?.[0];
                setGpsTelemetry(gps);
		const speedTelemetry = result.data.items[1];
		const fuelTelemetry  = result.data.items[2];
		setSpeedData(speedTelemetry.timestamps.map((ts, ix) => ({ x: ts*1000, y: kmToMiles(speedTelemetry.values[ix]) })));
		setFuelData (fuelTelemetry .timestamps.map((ts, ix) => ({ x: ts*1000, y: ev ? fuelTelemetry.values[ix] : litersToGallons(fuelTelemetry.values[ix]) })));
		setDistanceData(createDistanceTimeArray(gps.values, gps.values2, gps.timestamps));
		const faults = result.data.faults;
		setFaults(faults);
		const newAnnotations = faults.reduce((acc, fault, index) => {
		    acc[`line${index}`] = {
		      type: 'line',
		      xMin: fault.unixTime*1000,
		      xMax: fault.unixTime*1000,
		      borderColor: 'red', // item.col,
		      borderWidth: 3
		    };
		    return acc;
		  }, {});
		setAnnotations(newAnnotations);
            });
	    getTripZones(id)
              .then(result => setZones(result.data))
              .catch(error => getVehicleZones(t.vehicleId).then(response => setZones(response.data)));
	    getVehicle(t.vehicleId).then(response => setVehicle(response.data));
        });
     }
  }, [context.refreshCount]);

  const playHeadAnnotation = playing && {
    type: 'line',
    xMin: gpsTelemetry.timestamps[playIndex]*1000,
    xMax: gpsTelemetry.timestamps[playIndex]*1000,
    borderColor: 'black',
    borderWidth: 1
  }

  const getPopupInfo = () => {
    const getType = prop => {
      if (prop.type == 'OpCenter') return "Op Center";
      if (prop.type == 'RemoteHub') return "Remote Hub";
      if (prop.defined) return "Tracking";
      return "Undefined";
    };
    return <div style={{ fontSize: 15 }}>
             {hoverInfo.properties.name}<br />
             {getType(hoverInfo.properties)}
           </div>;
  }

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    return txt + ": " + mins.toFixed(0) + " min";
  };

  const avgCo2 = 0, avgMpge = 0, avgDistance = 0;
  const nonan = x => Number.isNaN(x) || x === undefined ? 0 : x
  const round0 = x => nonan(x).toFixed(0)
  const round = x => nonan(x).toFixed(2);
  const round1 = x => nonan(x).toFixed(1);

        const overall_grade_data = {
            datasets: [{
              data: [78, 22],
              backgroundColor: [
                '#F85E7D','#E3E2EC'
              ],
              borderWidth: 0
            }]
        }
        
        const overall_grade_label = {
            id: 'doughnutLabel',
            beforeDatasetDraw (chart, args, pluginOptions) {
                const {ctx, data} = chart;

                ctx.save();
                const xCoor = chart.getDatasetMeta(0).data[0].x;
                const yCoor = chart.getDatasetMeta(0).data[0].y;
                ctx.font = 'bold 3vi Helvetica, "Helvetica Neue", Arial, sans-serif';
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle'
                ctx.fillText('C+', xCoor, yCoor);
            }
        }
        
        const large_doughnut_options = {
            cutout: '65%',
            responsive: false,
            plugins: {
                tooltip: {
                    bodyFont: {
                        size: 24
                    }
                }
            }
        }

        const overall_grade_config = {
            type: 'doughnut',
            data: overall_grade_data,
            options: large_doughnut_options,
            plugins: [overall_grade_label]
        }

  const tripTime = trip && moment(trip.startTime).utcOffset(trip.zoneOffset).format('M-D @ h:mmA')
  let tripLength;
  if (trip) {
    const dur = moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    tripLength = (dur / 60).toFixed(0) + 'm ' + (dur % 60).toFixed(0) + 's';
  }

  const tripStartMoment = trip && moment(trip.startTime).utcOffset(trip.zoneOffset);
  const tripEndMoment   = trip && moment(trip.endTime  ).utcOffset(trip.zoneOffset);
  let tripDuration;
  const getDuration = ms => moment.utc(ms).format('HH:mm:ss');
  if (trip) {
    const dur = moment.duration(tripEndMoment.diff(tripStartMoment));
    tripDuration = getDuration(dur.asMilliseconds());
  }

  const geojson: FeatureCollection = gpsTelemetry && {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: {type: 'LineString', coordinates: latitudes.map((lat, index) => [longitudes[index], lat]) }}
    ]
  };

  const layerStyle: LineLayer = {
    id: 'route',
    type: 'line',
    source: 'route',
	      'layout': {
		  'line-join': 'round',
		  'line-cap': 'round'
	      },

    paint: {
      'line-width': 3,
      'line-color': '#66c9ff'
    }
  };

  const features = getFeatures(zones);
 
  const zone_geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: features
  };

  const onHover = useCallback(e => {
    const features = e.features;
    const hoveredFeature = features && features[0];
    if (hoveredFeature)
      setHoverInfo({
	longitude: e.lngLat.lng,
	latitude: e.lngLat.lat,
	properties: hoveredFeature.properties
      });
    else
      setHoverInfo(null);
  }, []);

  const playButtonPressed = () => {
    setPlaying(!playing);
    setPlayIndex(0);
  }

  // Step through data when playing
  useEffect(() => {
    let interval;
    if (playing) {
      const minDelay = 10;   // Minimum delay in ms (fastest)
      const maxDelay = 1000;  // Maximum delay in ms (slowest)
      const exponent = .05;    // Adjust this exponent for sensitivity
      const delay = maxDelay - (maxDelay-minDelay)*Math.pow(playbackRate/100, exponent)
      //console.log(playbackRate, delay);
      interval = setInterval(() => {
        setPlayIndex((prev) => {
          const nextIndex = prev+1;
          if (nextIndex < latitudes.length) {
            return nextIndex;
          } else {
            setPlaying(false); // Stop when all data is shown
            return prev;
          }
        });
      }, delay);
    }
    return () => clearInterval(interval); // Cleanup on unmount or stop
  }, [playing, playbackRate]);

  // Function to calculate distance between two lat/lng points in kilometers
  function calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Earth's radius in kilometers
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const a = 
	  Math.sin(dLat/2) * Math.sin(dLat/2) +
	  Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
	  Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
  }

  // Function to create the cumulative distance array
  function createDistanceTimeArray(latitudes, longitudes, timestamps) {
      // Calculate running total of distances
      const distances = [0]; // Start with 0
      for (let i = 1; i < latitudes.length; i++) {
	  const distance = calculateDistance(
	      latitudes[i-1], 
	      longitudes[i-1],
	      latitudes[i],
	      longitudes[i]
	  );
	  distances.push(distances[i-1] + distance);
      }

      // Create the final array with timestamps and cumulative distances
      return timestamps.map((ts, ix) => ({
	  x: ts * 1000,
	  y: kmToMiles(distances[ix])
      }));
  }

  const operation_line_data = {
    datasets: [
      {
        label: 'Speed',
        data: speedData,
        fill: 'origin',
        spanGaps: true,
        borderColor: '#F85E7D',
        borderWidth: 1,
        tension: 0,
        pointStyle: 'circle',
        pointRadius: 3,
        pointBorderColor: '#F85E7D',
        backgroundColor: 'rgb(248, 94, 125, 0.15)',
        yAxisID: 'y',
      },
      {
        label: isEV ? 'Energy' : 'Fuel',
        data: fuelData,
        fill: 'origin',
        spanGaps: true,
        borderColor: '#B14EC5',
        borderWidth: 1,
        tension: 0,
        pointStyle: 'circle',
        pointRadius: 3,
        pointBorderColor: '#B14EC5',
        backgroundColor: 'rgb(177, 78, 197, 0.15)',
        yAxisID: 'y1',
      },
      {
        label: 'Distance',
        data: distanceData,
        fill: 'origin',
        spanGaps: true,
        borderColor: '#F78957',
        borderWidth: 1,
        tension: 0,
        pointStyle: 'circle',
        pointRadius: 3,
        pointBorderColor: '#F78957',
        backgroundColor: 'rgb(247, 137, 87, 0.15)',
        yAxisID: 'y2',
      }
    ]
  };

  const operation_line_options = useMemo(() => ({
    plugins: {
      legend: {
        display: true
      },
      tooltip: {
        enabled: true, // Enable tooltips for points
        animation: false, // Disable animation
        mode: 'nearest', // Tooltip shows for nearest item
        intersect: false, // Allows tooltip to trigger even if not exactly on the line
        callbacks: {
          // Custom tooltip logic
          label: (context) => {
            const chart = context.chart;
            const xValue = context.parsed.x;

            // Check if the tooltip is near an annotation
            const fault = faults.find(
              (f) => Math.abs(f.unixTime*1000 - xValue) < 1000*60
            );

            if (fault) {
              return fault.name; // Display custom tooltip text
            }
            // Fallback to dataset label if not near an annotation
            return context.dataset.label + ': ' + context.parsed.y.toFixed(1);
          },
        },
      },
      annotation: {
	annotations: !playing ? annotations : {...annotations, playHead: playHeadAnnotation }
      },
    },
    onHover: (event, elements, chart) => {
      const xValue = chart.scales.x.getValueForPixel(event.x); // Get timestamp at mouse x-position
      if (!gpsTelemetry?.timestamps)
        return;

        let nearestIndex = -1;
        let minDifference = Infinity;
	const timestamp = xValue/1000; // dataPoint.x/1000;
        gpsTelemetry.timestamps?.forEach((value, index) => {
 	    const difference = Math.abs(value - timestamp);
	    if (difference < minDifference) {
	      minDifference = difference;
	      nearestIndex = index;
	    }
        });
 	if (nearestIndex >= 0 && latitudes.length > 0)
 	  throttledSetMousePosition({ latitude: latitudes[nearestIndex], longitude: longitudes[nearestIndex], index: timestamp });
	else
	  throttledSetMousePosition(null); // No data point hovered over, clear mouse position
      /*} else {
        // Clear hover info when not hovering over a point
        setMousePosition(null);
      }*/
    },
    animation: false,
    scales: {
      x: {
        type: 'time', // Use time scale
        time: {
          unit: 'minute', // Granularity of ticks (adjust as needed: 'second', 'hour', etc.)
          displayFormats: {
            minute: 'h:mm', // Format for display (e.g., 00:00, 01:00)
          },
        },
        ticks: {
          font: { size: 10 }
        }
      },
      y: {
        beginAtZero: false,
        ticks: {    
          color: '#F85E7D',
          font: { size: 12 }
        },
        title: {
          display: true,
          text: 'MPH',
        }
      },
      y1: {
        position: 'right',
        beginAtZero: false,
        ticks: {    
          color: '#B14EC5',
          font: { size: 12 }
        },
        title: {
          display: true,
          text: (isEV) ? 'kWh' : 'Gallons',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y2: {
        position: 'right',
        beginAtZero: false,
        ticks: {    
          color: '#F78957',
          font: { size: 10 }
        },
        title: {
          display: true,
          text: 'Distance',
        },
        grid: {
          drawOnChartArea: false,
        },
      }
    }
  }));

  const eff = Math.round(trip?.efficiency * 100);

  function getSpeedAtX(inputX, points) {
    if (!points || points.length === 0) return null;
    let largestY = null;
    for (const point of points) {
      if (point.x < inputX) {
	largestY = point.y;
      } else {
	break; // Since array is sorted, we can stop once x >= inputX
      }
    }
    return largestY;
  }

  let markerPosition = mousePosition;
  let markerColor = 'green'
  if (playing) {
    markerPosition = { latitude: latitudes[playIndex], longitude: longitudes[playIndex] }
    if (getSpeedAtX(gpsTelemetry.timestamps[playIndex]*1000, speedData) == 0)
      markerColor = 'yellow';
  }

  const download = () => {
    console.log('download');
    // Combine all data points into a single array
    const allData = [
      ...speedData.map(item => ({ time: item.x, speed: item.y })),
      ...fuelData.map(item => ({ time: item.x, fuel: item.y })),
      ...distanceData.map(item => ({ time: item.x, distance: item.y }))
    ];

    // Create a map to merge entries with the same timestamp
    console.log('d0');
    const mergedData = new Map();
    console.log('d1');
    
    allData.forEach(item => {
      const timestamp = item.time;
      if (!mergedData.has(timestamp)) {
        mergedData.set(timestamp, { time: timestamp });
      }
      const existing = mergedData.get(timestamp);
      if (item.speed !== undefined) existing.speed = item.speed;
      if (item.fuel !== undefined) existing.fuel = item.fuel;
      if (item.distance !== undefined) existing.distance = item.distance;
    });

    // Convert to array and sort by timestamp
    const sortedData = Array.from(mergedData.values()).sort((a, b) => a.time - b.time);

    // Format the data into CSV rows
    const headers = 'Time,Speed,Fuel,Distance\n';
    const rows = sortedData.map(item => {
      // Format timestamp to hh:mm:ss AM/PM
      const date = new Date(item.time);
      const timeString = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      
      // Use empty string for missing values
      const speed = item.speed !== undefined ? item.speed : '';
      const fuel = item.fuel !== undefined ? item.fuel : '';
      const distance = item.distance !== undefined ? item.distance : '';
      
      return `${timeString},${speed},${fuel},${distance}`;
    });

    // Create CSV content
    const csvContent = headers + rows.join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'trip' + id + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return <>
            <div id="focus">
                <div id="filters">
                    <div id="company"><a href="#" className="back-button" onClick={ev => navigate('/trips')} />{trip?.vehicleName} | Trip {tripTime}, {tripLength}</div>
                    <span>Route: {trip?.route}</span>
                </div>
                <div id="content" className="trip-details">
                    <div className="left">
			<div id="map" style={{ position: 'relative', height: '48vh' }}>
			    <MapGL
				    {...viewState}
				    mapboxAccessToken={mapboxToken} ref={mapRef}
				    onMove={evt => setViewState(evt.viewState)}
				    mapStyle={mapStyle} interactiveLayerIds={['polygon-layer']}
				    onMouseMove={onHover}>
			      <StyleControl onStyleChange={handleStyleChange} currentStyle={mapStyle} />
			      <Source id="route" type="geojson" data={geojson}>
				<Layer {...layerStyle} />
			      </Source>
			      <Source id="polygon-data" type="geojson" data={zone_geojson}>
				<Layer id="polygon-layer" type="fill" paint={{ 'fill-color': ['get', 'color'], 'fill-opacity': 0.9, }} />
				<Layer id="polygon-outline" type="line" paint={{ 'line-color': '#000', 'line-width': 2, }} />
			      </Source>
				{markerPosition && (
				    <Marker key={-1} latitude={markerPosition.latitude} longitude={markerPosition.longitude}>
					<div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: markerColor, border: '2px solid white' }} />
				    </Marker>
				)}
				{hoverInfo && (
				  <Popup
				    longitude={hoverInfo.longitude}
				    latitude={hoverInfo.latitude}
				    closeButton={false}
				    closeOnClick={false}
				    anchor="top"
				  >
				    {getPopupInfo()}
				  </Popup>
				)}
			    </MapGL>
			</div>
                        <div id="table-actions" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                              <a href="#" id="play" data-title="Play Trip" style={{ backgroundImage: `url(${playing ? pause : play})` }}
                                 onClick={ev => playButtonPressed()}></a>
                              <span style={{ marginLeft: '2vw', height: '3.5vh', display: 'inline-flex', alignItems: 'center' }}>
                                 Playback Rate:
                                 <input type="range" id="speedSlider"
                                        value={playbackRate}
                                        onChange={ev => setPlaybackRate(ev.target.value)}></input></span>
                             </div>
                             <a style={{ textAlign: 'right' }} href="#" className="download" onClick={download}>Download</a>
                        </div>
                        <div className="scrollable-charts">
                            <div className="chart" style={{ position: 'relative', width: '50vw', height: '20vw' }}>
			      <Line
				data={operation_line_data}
				options={operation_line_options}
				width={100}
				height={20}
			      />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="data summary">
                            <div>
                                <label htmlFor="asset">Asset</label>
                                <span id="asset">{trip?.vehicleName}</span>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <img src={carImage(vehicle)} style={{ position: 'absolute', left: 0, height: '8vh' }} />
                            </div>
                            <div>
                                <label htmlFor="end-date">Make/Model</label>
                                <span id="end-date">{vehicle?.make} {vehicle?.model} - {vehicle?.fuelType}</span>
                            </div>
                            <div>
                                <label htmlFor="start-time">Start</label>
                                <span id="start-time">{tripStartMoment?.format('h:mmA')}</span>
                                <i>{tripStartMoment?.format('MM/DD/YY')}</i>
                            </div>
                            <div>
                                <label htmlFor="end-time">End</label>
                                <span id="end-time">{tripEndMoment?.format('h:mmA')}</span>
                                <i>{tripEndMoment?.format('MM/DD/YY')}</i>
                            </div>
                            <div>
                                <label htmlFor="duration">Duration</label>
                                <span id="duration">{tripDuration}</span>
                            </div>
                        </div>
			<div id="charts">
			    <div className="individual-chart" style={{ paddingLeft: '4vh', paddingRight: '4vh' }}>
				<h4 style={{ fontWeight: 'bold' }}>Trip Utilization</h4>
				<div className="doughnut">
				    <UtilizationDonut accelerating={trip?.acceleratingMinutes} decelerating={trip?.deceleratingMinutes} idle={trip?.idleMinutes} offline={trip?.offlineMinutes} showIdle={true} />
				</div>
			    </div>
			    <div className="individual-chart" style={{ paddingLeft: '10vh' }}>
				<h4 style={{ fontWeight: 'bold' }}>Trip Efficiency</h4>
				<div className="doughnut">
				    <TripEfficiencyDonut circleText={eff + '%'} percent={eff} previousPercent={Math.round(100*trip?.dailyAverageEfficiency)} />
				</div>
			    </div>
			</div>
                        <div className="data">
                            <div>
                                <label htmlFor="unused"></label>
                                <span id="unused"></span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Op Center</label>
                                <span id="duration">{trip && context.units?.find(x => x.id == trip.unitId)?.name}</span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Op Hours</label>
                                <span id="duration">{trip?.openHours ? 'Yes' : 'No'}</span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Estimated Cost</label>
                                <span id="duration">${round(trip?.fuelSpend)}</span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Max Speed</label>
                                <span id="duration">{round1(kmToMiles(trip?.maxSpeed))} MPH</span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Average Speed</label>
                                <span id="duration">{round1(kmToMiles(trip?.averageSpeed))} MPH</span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Distance</label>
                                <span id="duration">{round(kmToMiles(trip?.distanceDriven))} miles</span>
                            </div>
                            {!isEV && <div>
                                <label htmlFor="start-date">Fuel Consumed</label>
                                <span id="duration">{round(litersToGallons(trip?.fuelConsumed))} gallons</span>
                            </div>}
                            {isEV && <div>
                                <label htmlFor="start-date">Energy Consumed</label>
                                <span id="duration">{round(trip?.fuelConsumed)} kWh</span>
                            </div>}
                            {!isEV && <div>
                                <label htmlFor="start-date">Fuel Efficiency</label>
                                <span id="duration">{round(mpg(trip))} MPG</span>
                            </div>}
                            {isEV && <div>
                                <label htmlFor="start-date">Energy Efficiency</label>
                                <span id="duration">{round(mpkwh(trip, false))} Mi/kWh</span>
                            </div>}
                            <div>
                                <label htmlFor="start-date">CO<sub>2</sub>e</label>
                                <span id="duration">{round(co2eInKg(trip))} kg</span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Idle Time</label>
                                <span id="duration">{getDuration(trip?.idleMinutes * 60000)}</span>
                            </div>
                            <div>
                                <label htmlFor="start-date">Offline Time</label>
                                <span id="duration">{getDuration(trip?.offlineMinutes * 60000)}</span>
                            </div>
                        </div>
		    </div>
                </div>
            </div>
         </>;
}

export default TripDetails;

