import React, { useState } from 'react';
import AutoHideText from './AutoHideText';

const BarGraphic = ({ segments, header, units, dollars, leftLabel, rightValue, tooltips, colors, round, isMonitor }) => {
  const total = segments.reduce((acc, segment) => acc + segment.value, 0);
  const [showTooltip, setShowTooltip] = useState(null);
  const roundFunc = round ? round : (x=> Math.round(x).toLocaleString());
  const defaultColors = ['#fc9432', '#d916a8'];
  const colorsArr = colors || defaultColors;
  const showText = false;
  const nonan = x => (x === undefined || Number.isNaN(x) ? 0 : x);

  const getTooltipLeft = () => {
    const index = showTooltip.side;
    const segment = segments[index];
    const percentage = (segment.value / total) * 100;
    let cumulativePercentage = segments.slice(0, index).reduce((sum, seg) => sum + seg.value, 0) / total * 100;
    return `calc(${cumulativePercentage + percentage / 2}%)`;
  };

  return (
    <div className="bar-container" style={isMonitor ? { height: '100%' } : {}}>
      {!leftLabel && <div className="bar-labels">
        {header ? <span className="bar-header">{header}</span> : segments.map((segment, index) => (
          <span key={index} className={`bar-label bar-${index === 0 ? 'left' : index === segments.length - 1 ? 'right' : 'middle'}-label`}>
            {segment.label}
          </span>
        ))}
      </div>}

      <div className="bar-wrapper" onMouseLeave={() => setShowTooltip(null)}>
        {leftLabel && <span style={{ width: 110 }}>{leftLabel}</span>}
        <div className="bar" style={isMonitor ? { flexGrow: 1, height: '100%' } : {}}>
          {segments.map((segment, index) => {
            const percentage = (segment.value / total) * 100;
            let cumulativePercentage = segments.slice(0, index).reduce((sum, seg) => sum + seg.value, 0) / total * 100;
            return (
              <AutoHideText
                key={index}
                text={showText ? segment.label : " "}
                className="bar-fill"
                style={{ 
                  backgroundColor: colorsArr[index % colorsArr.length],
                  width: `${percentage}%`, 
                  left: `${cumulativePercentage}%`,
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  height: isMonitor ? '1.3vi' : 'auto'
                }}
                onMouseEnter={() => 
                     setShowTooltip({ side: index,
                                      label: segment.label,
                                      value: (dollars ? '$' : '') + roundFunc(nonan(segment.total ? total : segment.value)) + (units ? ' ' + units : '') })}
              />
            );
          })}
          {showTooltip !== null && tooltips !== false && (
            <div className="tooltip" style={{ left: getTooltipLeft() }}>
              {showTooltip.label}: {showTooltip.value}
            </div>
          )}
        </div>
	  <span style={{ minWidth: "5.5vh", textAlign: "right", display: "inline-block" }}>
	    <span
	      style={{
		display: "inline-block",
		width: "5vh",
		textAlign: "right",
		border: "1px solid black",
		padding: "5px",
		fontSize: '1.5vh',
		fontWeight: 'bold',
		borderRadius: '10px'
	      }}
	    >
		  {roundFunc(nonan(rightValue ? rightValue : total))}
	    </span>
	  </span>
      </div>
    </div>
  );
};

export default BarGraphic;
