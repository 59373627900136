import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getTrips, getTripsForZone, getDailyTotalAll } from './api/vehicle';
import { useParams, Link, useNavigate } from 'react-router-dom';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, mpkwh, co2eInKg, co2PerMile, getVehicleName } from './util/formulas';
import { Context } from "./ContextHandler";
import { Select, MenuItem } from '@mui/material';
import PagedList from "./PagedList";
import { classifyFuelTypes } from './util/fuelTypes';

function TripList({ token }) {
  const { zid } = useParams();
  const [trips, setTrips] = useState();
  const [totals, setTotals] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRange;
  const lastRefreshCount = useRef(-1);
  const [definedFilter, setDefinedFilter] = useState(0);
  const [routeFilter, setRouteFilter] = useState([]);
  const [hoursFilter, setHoursFilter] = useState(0);
  const [popupVisible, setPopupVisible] = useState();
  const navigate = useNavigate();

  const toggleRouteFilter = route => {
    if (routeFilter.includes(route))
       setRouteFilter(routeFilter.filter(y => route !== y));
    else
       setRouteFilter([...routeFilter, route]);
  }

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
      const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
      const filters = getFilters(context);
      if (zid)
        getTripsForZone(zid).then(response => setTrips(response.data));
      else
        getTrips(context.rootId, date1, date2, filters).then(response => setTrips(response.data));
      getDailyTotalAll(context.rootId, date1, date2, filters).then(response => setTotals(response.data));
    }
  }, [context.refreshCount, dateRange]);

  React.useEffect(() => {
    // don't allow "All Enterprises"
    if (context.rootId === -1) {
      context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
    }
  }, []);

  const assetMap = context.assets?.reduce((acc, asset) => { acc[asset.id] = asset; return acc; }, {});

  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;
  if (totals) {
    totals.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }

  let filteredTrips = trips;
  if (definedFilter == 1)
    filteredTrips = trips.filter(x => x.isDefined == true)
  if (definedFilter == 2)
    filteredTrips = trips.filter(x => x.isDefined == false)
  if (hoursFilter == 1)
    filteredTrips = trips.filter(x => x.openHours == true)
  if (hoursFilter == 2)
    filteredTrips = trips.filter(x => x.openHours == false)

  const fuelTypes = new Set(filteredTrips?.map(x => assetMap[x.vehicleId]?.fuelType));
  const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
  const energyWord = (onlyIce) ? 'Fuel' : 'Energy';

  const tripChoices = ["All Trips", "Defined", "Undefined"];
  const routeSet = trips ? new Set(trips.map(x => x.route)) : [];
  let routeChoices = [...routeSet];
  routeChoices.sort();

  const routeFilterText = (routeFilter.length == 0) ? "All Routes" : routeFilter.join('; ');
  if (routeFilter.length > 0)
    filteredTrips = filteredTrips.filter(x => routeFilter.includes(x.route))

  const hoursChoices = ["All Trips", "Op Hours", "Non-Op Hours"];

  let avgFuelCost, co2PerMile, avgMpg, avgMikwh, avgDistance, distPerDay, avgEff, totalDistance, totalFuelConsumed, chargingMinutes;
  let totalKwhConsumed;

  const noNaN = x => (x === undefined || Number.isNaN(x) ? 0 : x)
  if (totals && trips) {
    // use daily totals if there are no trip filters.  otherwise use filtered trips
    const list = (filteredTrips == trips) ? totals : filteredTrips;
    const assetCount = list.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
    avgFuelCost = list.reduce((part, a) => part+noNaN(a.fuelSpend),    0)/assetCount;
    const totalCo2 = list.reduce((part, a) => part+noNaN(a.co2Generated),    0);
    totalDistance = list.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
    co2PerMile = totalDistance ? totalCo2/kmToMiles(totalDistance) : 0;
    totalFuelConsumed = list.reduce((part, a) => part+noNaN(a.fuelConsumed), 0);
    totalKwhConsumed = list.reduce((part, a) => part+noNaN(a.kwhConsumed), 0);
    avgDistance = totalDistance/assetCount;
    if (list == totals) {
      distPerDay  = list.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
      chargingMinutes = totals.reduce((part, a) => part+noNaN(a.chargingMinutesPerDay), 0);
    } else {
      const uniqueDays = new Set()
      list.forEach(trip => uniqueDays.add(moment(trip.startTime).format('M-D')))
      distPerDay  = totalDistance/uniqueDays.size;
      chargingMinutes = trips.reduce((part, a) => part+noNaN(a.chargingMinutes), 0) / uniqueDays.size;
    }
    const assetCountEff = list.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
    avgEff      = list.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
    avgMpg   = noNaN(kmToMiles(totalDistance)/(litersToGallons(totalFuelConsumed)));
    avgMikwh = noNaN(kmToMiles(totalDistance)/(totalKwhConsumed));
  }
  const eff = noNaN(avgEff*100).toFixed(0);

  const columns = [
    { header: 'Asset ID', key: 'name' },
    { header: 'Group', key: 'unitId' },
    { header: 'Make / Model', key: 'makeModel', width: '9%' },
    { header: 'Year', key: 'year' },
    { header: 'Fuel', key: 'fuel' },
    { header: 'Route', key: 'route', width: '10%' },
    { header: 'Trip Start', key: 'startTime', width: '6%' },
    { header: 'Trip End', key: 'endTime', width: '6%' },
    { header: 'Trip Duration', key: 'duration' },
    { header: 'Distance Traveled', key: 'milesDriven' },
    { header: 'Op Hours', key: 'openHours' },
    { header: 'Utilization', key: 'utilization', sortable: false, displayOnly: true },
    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
    { header: energyWord + ' Consumed', key: onlyIce ? 'fuelConsumed' : 'kwhConsumed' },
    { header: onlyIce ? 'Gal / Minute' : 'kWh / Minute', key: onlyIce ? 'galPerMinute' : 'kwhPerMinute' },
    { header: 'Max Speed', key: 'maxSpeed' },
    { header: 'Avg MPH', key: 'averageSpeed' },
    { header: onlyIce ? 'MPG' : 'Mi/kWh', key: onlyIce ? 'mpg' : 'mikwh' },
    { header: energyWord + ' Cost', key: 'fuelSpend' },
    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
    { header: 'Efficiency', key: 'efficiency' },
    { header: 'Estimated', key: 'estimated', downloadOnly: true },
    { header: 'Ambient Temp', key: 'ambientTemperature', downloadOnly: true, extended: true },
    { header: '# of Powertrain Faults', key: 'powertrainFaultsCount', downloadOnly: true, extended: true },
    { header: '# of HVAC Faults', key: 'hvacFaultsCount', downloadOnly: true, extended: true },
    { header: '# of General Faults', key: 'generalFaultsCount', downloadOnly: true, extended: true },
    { header: '# of Tire Pressure Faults', key: 'tirePressureAlertsCount', downloadOnly: true, extended: true },
    { header: '# of EBI Device Alerts', key: 'ebiDeviceAlertsCount', downloadOnly: true, extended: true },
    { header: 'Max Engine RPM', key: 'maxRpm', downloadOnly: true, extended: true },
    { header: 'Min Engine RPM', key: 'minRpm', downloadOnly: true, extended: true },
    { header: 'Average Engine RPM', key: 'averageRpm', downloadOnly: true, extended: true },
    { header: '# of Harsh Cornering Events', key: 'harshCorneringCount', downloadOnly: true, extended: true },
    { header: '# of Harsh Acceleration Events', key: 'harshAccelCount', downloadOnly: true, extended: true },
    { header: '# of Idles > 5 Minutes', key: 'longIdlingCount', downloadOnly: true, extended: true },
    { header: 'Oil Life Remaining', key: 'oilLifeRemaining', downloadOnly: true, extended: true },
    { header: 'Average Oil Pressure', key: 'oilPressure', downloadOnly: true, extended: true },
    { header: 'Oil Temperature', key: 'oilTemperature', downloadOnly: true, extended: true },
  ];

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getTripInfo = (trip, key) => {
    if (key == 'name' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && getVehicleName(asset);
    }
    if (key == 'year' && assetMap)
      return assetMap[trip.vehicleId]?.year;
    if (key == 'fuel' && assetMap)
      return assetMap[trip.vehicleId]?.fuelType;
    if (key == 'makeModel' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && (asset.make + ' ' + asset.model);
    }
    if (key == 'startTime')
      return moment(trip.startTime).toISOString();
    if (key == 'endTime')
      return moment(trip.endTime).toISOString();
    if (key == 'duration')
      return moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    if (key == 'unitId') {
      const asset = assetMap[trip.vehicleId];
      return context.units?.find(x => x.id == asset?.unitId)?.name;
    }
    if (key == 'upTimeMinutes')
      return trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
    if (key == 'galPerMinute')
      return litersToGallons(trip.fuelConsumed) / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'kwhPerMinute')
      return trip.kwhConsumed / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'milesDriven')
      return kmToMiles(trip.distanceDriven);
    if (key == 'fuelConsumed')
      return litersToGallons(trip.fuelConsumed);
    if (key == 'mpge')
      return mpge(trip);
    if (key == 'mpg')
      return mpg(trip);
    if (key == 'mikwh')
      return mpkwh(trip, false);
    if (key == 'fuelSpend')
      return trip.fuelSpend;
    if (key == 'costPerMile')
      return trip.distanceDriven > 0 && fuelSpendPerMile(trip);
    if (key == 'co2e')
      return co2eInKg(trip);
    if (key == 'co2')
      return trip.distanceDriven > 0 && co2PerMile(trip);
    if (key == 'averageSpeed')
      return kmToMiles(trip.averageSpeed);
    if (key == 'maxSpeed')
      return kmToMiles(trip.maxSpeed);
    if (key == 'openHours')
      return trip[key] === undefined ? '' : trip[key] ? 'yes' : 'no';
    return trip[key];
  }

  const getDuration = val => {
    const asMin = Math.floor(val/60);
    const hr = Math.floor(asMin/60)
    const min = asMin-hr*60;
    if (hr == 0)
      return `${min}m`;
    return `${hr}h ${min}m`;
  }

  const getTripCell = (trip, key) => {
    if (key == 'utilization') {
      let totalMins = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes + trip.offlineMinutes;
      let offWidth = 100*trip.offlineMinutes / totalMins;
      let idleWidth = 100*trip.idleMinutes / totalMins;
      let decWidth = 100*trip.deceleratingMinutes / totalMins;
      let accWidth = 100*trip.acceleratingMinutes / totalMins;
      return <td key={key} className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, trip.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, trip.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, trip.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, trip.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    let val = getTripInfo(trip, key);
    if (key == 'duration')
      return <td key={key} className="number">{getDuration(val)}</td>;
    if (key == 'efficiency' && val !== undefined)
      return <td key={key} className="number">{(val * 100).toFixed(0)}%</td>;
    if ((key == 'fuelConsumed' || key == 'kwhConsumed') && trip['estimated'])
      return <td key={key} className="number interpolated">{round(val)}</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number">{val}</td>;
    }
    if (key == 'startTime' || key == 'endTime') {
      const format = 'M-D h:mma';
      const hoverText = moment(val).utcOffset(0).format('M-D H:mm:ss') + ' GMT';
      val = moment(val).utcOffset(trip.zoneOffset).format(format);
      return <td key={key} className="text" title={hoverText}>{val}</td>;
    }
    if (key == 'name') {
      const hoverText = trip?.vehicleId;
      const tripClick = ev => { if (ev.shiftKey) { ev.preventDefault(); navigate('/trips/more/' + trip.id); } }
      return <td key={key} className="text" title={hoverText}><Link to={'/trips/' + trip.id} onClick={tripClick}>{val}</Link></td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const selectedIf = x => x ? 'selected' : undefined;

  const tableActions = <>
                        <div id="trip-filter" className="dropdown asset-filter"
                            onMouseEnter={ev => setPopupVisible(1)} onMouseLeave={ev => setPopupVisible(0)}>{tripChoices[definedFilter]}
                            {popupVisible == 1 && <div className="dropdown-content">
                                <span>Trips</span>
                                {tripChoices.map((x, ix) => <a key={ix} href="#" className={selectedIf(definedFilter == ix)} onClick={ev => setDefinedFilter(ix)}>{x}</a>)}
                            </div>}
                        </div>
                        <div id="route-filter" className="dropdown asset-filter" style={{ width: 400 }}
                            onMouseEnter={ev => setPopupVisible(2)} onMouseLeave={ev => setPopupVisible(0)}>{routeFilterText}
                            {popupVisible == 2 && <div className="dropdown-content">
                                <span>Routes</span>
				<a href="#" className={selectedIf(routeFilter.length == 0)}
                                   onClick={ev => setRouteFilter([])}>All Routes</a>
                                {routeChoices.map(x =>
                                     <a href="#" key={x} className={selectedIf(routeFilter.includes(x))}
                                        onClick={ev => toggleRouteFilter(x)}>{x}</a>)}
                            </div>}
                        </div>
                        <div id="hours-filter" className="dropdown asset-filter" style={{ width: 150 }}
                            onMouseEnter={ev => setPopupVisible(3)} onMouseLeave={ev => setPopupVisible(0)}>{tripChoices[hoursFilter]}
                            {popupVisible == 3 && <div className="dropdown-content">
                                <span>Op Hours</span>
                                {hoursChoices.map((x, ix) =>
                                     <a href="#" key={x} className={selectedIf(hoursFilter == ix)}
                                        onClick={ev => setHoursFilter(ix)}>{x}</a>)}
                            </div>}
                        </div>
                    </>;

  return <>
            <div id="focus">
                <FilterPanel filters={['fuelTypeFilter', 'groupFilter',
                                       'modelFilter', 'assetFilter', 'dateFilter']} />
                <div id="content">
                    <div id="charts">
                        <div className="individual-chart small">
                            <h4>Efficiency</h4>
                            <div className="doughnut">
                                  <DashboardDonut circleText={eff + '%'} percent={eff} />
                            </div>
                        </div>
                        <div className="individual-chart">
                            <h4>Fleet Utilization</h4>
                            <div className="doughnut">
                                <UtilizationDonut accelerating={accMin} decelerating={decMin} idle={idleMin} offline={offMin} />
                            </div>
                        </div>
                        <div className="individual-chart small">
                            <h4>Fuel Cost</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round0(noNaN(100*avgFuelCost/kmToMiles(avgDistance))) + '¢'} subText={"¢/Mile"} />
                            </div>
                        </div>
                        {onlyEv || <div className="individual-chart small">
                            <h4>CO<sub>2</sub> Emissions</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round(co2PerMile/1000)} subText={"kg/Mile"} />
                            </div>
                        </div>}
                        {onlyEv && <div className="individual-chart small">
                            <h4>Charging Time</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round(chargingMinutes/60)} subText={"Hours/Day"} />
                            </div>
                        </div>}
                        <div className="individual-chart small">
                            <h4>{energyWord} Efficiency</h4>
                            <div className="doughnut">
                                {onlyIce && <AssetListDonut mainText={round(avgMpg)} subText={"MPG"} />}
                                {onlyIce || <AssetListDonut mainText={round(avgMikwh)} subText={"Mi/kWh"} />}
                            </div>
                        </div>
                        <div className="individual-chart small">
                            <h4>Distance Driven</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round0(kmToMiles(distPerDay))} subText={"Miles/Day"} />
                            </div>
                        </div>
                    </div>
                    <PagedList items={filteredTrips} columns={columns} getItemInfo={getTripInfo} getItemCell={getTripCell} tableActions={tableActions} itemType="Trips" extendedDownload={true} />
                </div>
            </div>
         </>;
}

export default TripList;

