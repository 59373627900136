import React, { useState, useRef, useEffect, useContext } from 'react';
import { canViewMultipleEnterprises } from '../util/security';
import EnterpriseListPage from './EnterpriseListPage';
import EnterpriseFormPage from './EnterpriseFormPage';

function EnterprisePage({ token }) {
  if (canViewMultipleEnterprises())
     return <EnterpriseListPage />;
  else
     return <EnterpriseFormPage />;
}

export default EnterprisePage;

