import React, { useState, useEffect, useContext } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import RouteView from './RouteView';
import Dashboard from './Dashboard';
import axios from 'axios';
import logo from './images/Accelevate_Logo.svg';
import help from './images/help.svg';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Context } from "./ContextHandler";
import DateRangeFilter from './DateRangeFilter';
import { canViewMultipleEnterprises } from './util/security';

function Filter({ enterpriseFilterOnly, groupFilterOnly, allowAllEnterprises, title, rightSide }) {

    const context = useContext(Context);
    const fuelTypeFilter = context.fuelTypeFilter === undefined ? [] : context.fuelTypeFilter;
    const assetFilter = context.assetFilter === undefined ? [] : context.assetFilter;
    const modelFilter = context.modelFilter === undefined ? [] : context.modelFilter;
    const groupFilter = context.groupFilter === undefined ? [] : context.groupFilter;
    const groups = context.units || [];
    const setFuelTypeFilter = x => {
         context.dispatch({ type: 'setState', attribute: 'fuelTypeFilter', payload: x });
         context.dispatch({ type: 'refresh' });
    };
    const setAssetFilter = x => {
         context.dispatch({ type: 'setState', attribute: 'assetFilter', payload: x });
         context.dispatch({ type: 'refresh' });
    };
    const setModelFilter = x => {
         context.dispatch({ type: 'setState', attribute: 'modelFilter', payload: x });
         context.dispatch({ type: 'refresh' });
    };
    const setGroupFilter = x => {
         context.dispatch({ type: 'setState', attribute: 'groupFilter', payload: x });
         context.dispatch({ type: 'refresh' });
    };

    const [popupVisible, setPopupVisible] = useState(-1);

    const addFuelTypeFilter = x => {
        if (fuelTypeFilter.includes(x))
           setFuelTypeFilter(fuelTypeFilter.filter(y => x !== y));
        else
           setFuelTypeFilter([...fuelTypeFilter, x]);
    }
    const addAssetFilter = x => {
        if (assetFilter.includes(x))
           setAssetFilter(assetFilter.filter(y => x !== y));
        else
           setAssetFilter([...assetFilter, x]);
    }
    const addGroupFilter = x => {
        if (groupFilter.includes(x))
           setGroupFilter(groupFilter.filter(y => x !== y));
        else
           setGroupFilter([...groupFilter, x]);
    }
    const addModelFilter = x => {
        if (modelFilter.includes(x))
           setModelFilter(modelFilter.filter(y => x !== y));
        else
           setModelFilter([...modelFilter, x]);
    }
    let fuelTypeFilterLabel = 'All Fuel Types';
    const fuelTypes = [ { label: 'Gas', key: 'GAS' }, { label: 'LPG', key: 'LPG' }, { label: 'Electric', key: 'EV' },
                        { label: 'CNG', key: 'CNG' }, { label: 'Propane', key: 'PROPANE' }, { label: 'Diesel', key: 'DIESEL' } ];
    if (fuelTypeFilter.length > 0) {
      fuelTypeFilterLabel = fuelTypeFilter.map(t => fuelTypes.find(ft => ft.key === t).label).join(', ');
    }

    let modelFilterLabel = 'All Makes / Models'
    if (modelFilter.length > 0)
      modelFilterLabel = modelFilter.join(', ')

    const makeFilterLabel = (def, filter, list) => {
      let label = def;
      if (filter.length > 0 && list) {
	let names = filter.map(fid => list.find(asset => asset.id === fid)?.name);
	if (names.length > 2)
	  names = [...names.slice(0, 2), '...']
	label = names.join(', ')
      }
      return label;
    }

    const assetFilterLabel  = makeFilterLabel('All Vehicles', assetFilter,  context.assets);

    const models = [...new Set(context.assets?.map(x => x.make + ' ' + x.model))].sort();

    const groupFilterLabel = makeFilterLabel('All Groups', groupFilter, groups);

    const enterpriseId = context.rootId;
    const enterpriseName = enterpriseId == -1 ? 'All Enterprises' : context.enterprises?.find(x => x.unitId == enterpriseId)?.name;
    const setEnterprise = e => context.dispatch({ type: 'setEnterprise', payload: e });
    const showAll = !(enterpriseFilterOnly || groupFilterOnly);

    return <div id="filters">
	       <div id="company">{title === undefined ? 'DEMO' : title}</div>
	       {canViewMultipleEnterprises() && <div id="group-customer-filter" class="dropdown asset-filter"
                    onMouseEnter={ev => setPopupVisible(5)} onMouseLeave={ev => setPopupVisible(0)}>{enterpriseName}
		   {popupVisible == 5 && <div class="dropdown-content">
		       <span>Enterprise</span>
                       {allowAllEnterprises && <a href="#" class={enterpriseId == -1 ? 'selected' : ""}
                        onClick={ev => setEnterprise(-1)}>All Enterprises</a>}
                       {context.enterprises.map(enterprise => <a href="#" class={enterpriseId == enterprise.unitId ? 'selected' : ""}
                                                           onClick={ev => setEnterprise(enterprise.unitId)}>{enterprise.name}</a>)}
		   </div>}
	       </div>}

               {showAll && <div id="fuel-type-filter" class="dropdown asset-filter"
                    onMouseEnter={ev => setPopupVisible(1)} onMouseLeave={ev => setPopupVisible(0)}>{fuelTypeFilterLabel}
		   {popupVisible == 1 && <div class="dropdown-content">
		       <span>Fuel Type</span>
		       <a href="#" class={fuelTypeFilter.length === 0 ? "selected" : ""} onClick={ev => setFuelTypeFilter([])}>All Fuel Types</a>
                       {fuelTypes.map(type => <a href="#" class={fuelTypeFilter.includes(type.key) ? 'selected' : ""}
                                                           onClick={ev => addFuelTypeFilter(type.key)}>{type.label}</a>)}
		   </div>}
	       </div>}

	       {enterpriseFilterOnly || <div id="group-customer-filter" class="dropdown asset-filter"
                    onMouseEnter={ev => setPopupVisible(2)} onMouseLeave={ev => setPopupVisible(0)}>{groupFilterLabel}
		   {popupVisible == 2 && <div class="dropdown-content">
		       <span>Group</span>
		       <a href="#" class={groupFilter.length === 0 ? "selected" : ""} onClick={ev => setGroupFilter([])}>All Groups</a>
                       {groups.map(group => <a href="#" class={groupFilter.includes(group.id) ? 'selected' : ""}
                                                           onClick={ev => addGroupFilter(group.id)}>{group.name}</a>)}
		   </div>}
	       </div>}

	       {showAll && <div id="vehicle-type-filter" class="dropdown asset-filter"
                    onMouseEnter={ev => setPopupVisible(3)} onMouseLeave={ev => setPopupVisible(0)}>{modelFilterLabel}
		   {popupVisible == 3 && <div class="dropdown-content">
		       <span>Make / Model</span>
		       <a href="#" class={modelFilter.length == 0 ? "selected" : ""} onClick={ev => setModelFilter([])}>All Makes / Models</a>
                       {models?.map(x => <a href="#" class={modelFilter.includes(x) ? "selected" : ""}
					    onClick={ev => addModelFilter(x)}>{x}</a>)}
		   </div>}
	       </div>}

	       {showAll && <div id="vehicle-type-filter" class="dropdown asset-filter"
                    onMouseEnter={ev => setPopupVisible(4)} onMouseLeave={ev => setPopupVisible(0)}>{assetFilterLabel}
		   {popupVisible == 4 && <div class="dropdown-content">
		       <span>Vehicles</span>
		       <a href="#" class={assetFilter.length == 0 ? "selected" : ""} onClick={ev => setAssetFilter([])}>All Vehicles</a>
                       {context.assets?.map(x => x.name && <a href="#" class={assetFilter.includes(x.id) ? "selected" : ""}
							      onClick={ev => addAssetFilter(x.id)}>{x.name}</a>)}
		   </div>}
	       </div>}

	       {showAll && <DateRangeFilter />}
	       {rightSide}
	  </div>;
}

export default Filter;

