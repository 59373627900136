import React, { useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import {
  Chart,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { eventMetadataMap } from './util/events';

Chart.register(LinearScale, PointElement, Tooltip, Legend, Title);

const heatmapPlugin = {
  id: 'backgroundHeatmap',
  beforeDraw: (chart) => {
    const { ctx, chartArea } = chart;
    if (!chartArea) return;

    const gradient = ctx.createLinearGradient(chartArea.left, chartArea.bottom, chartArea.right, chartArea.top);
    gradient.addColorStop(0, 'green');
    gradient.addColorStop(0.5, 'yellow');
    gradient.addColorStop(1, 'red');

    ctx.save();
    ctx.fillStyle = gradient;
    ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
    ctx.restore();
  }
};

const EventRiskChart = ({ events, totalDays }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (events?.length > 0) {
      const timeout = setTimeout(() => setLoaded(true), 300);
      return () => clearTimeout(timeout);
    }
  }, [events]);

  if (!events || events.length === 0) {
    return <p style={{ opacity: 0.5, transition: "opacity 0.5s ease-in" }}></p>;
  }

  const getSeverityLevel = (eventId) => {
    return eventMetadataMap[eventId]?.riskLevel;
  };

  const vehicleMap = {};
  const driverMap = {};

  const uniqueColors = (() => {
    const colors = [];
    for (let i = 0; i < events.length; i++) {
      colors.push(`hsl(${(i * 137.508) % 360}, 70%, 50%)`);
    }
    return colors;
  })();

  let colorIndex = 0;


// Calculate the severity score for each vehicle
events.forEach(event => {
  const vehicleName = event.vehicle?.name || 'Unknown Vehicle';
  const severity = getSeverityLevel(event.id);
  const category = eventMetadataMap[event.id]?.category || 'VEHICLE';

  if (category === "DEVICE") return; // Skip device events

  const targetMap = category === "DRIVER" ? driverMap : vehicleMap;
  if (!targetMap[vehicleName]) {
      targetMap[vehicleName] = {
          name: vehicleName,
          totalSeverity: 0,
          totalEvents: 0,
          color: uniqueColors[colorIndex++ % uniqueColors.length]
      };
  }

  const riskScoreMapping = {
      1: 32,
      2: 16,
      3: 8,
      4: 4,
      5: 1
  };
  
  const riskLevel = eventMetadataMap[event.id]?.riskLevel || 1;
  const riskScore = riskScoreMapping[riskLevel] || 1;
  
  // Accumulate severity score
  targetMap[vehicleName].totalSeverity += riskScore;
  targetMap[vehicleName].totalEvents++;
});

  // Compute max total severity for vehicles and drivers separately
  const maxVehicleTotalScore = Math.max(
    ...Object.values(vehicleMap).map(v => v.totalSeverity),
    1  // Prevent division by zero
  );

  const maxDriverTotalScore = Math.max(
    ...Object.values(driverMap).map(d => d.totalSeverity),
    1  // Prevent division by zero
  );

  // Function to process data and normalize severity scores
  const processData = (map, maxTotalScore) =>
    Object.values(map).map(entity => ({
        ...entity,
        severityScore: Math.min(5 * (entity.totalSeverity / maxTotalScore), 5), // Normalize to scale 0-5
        frequencyScore: (entity.totalEvents / totalDays) * 100
    }));

  // Process vehicles and drivers separately
  const processedVehicles = processData(vehicleMap, maxVehicleTotalScore);
  const processedDrivers = processData(driverMap, maxDriverTotalScore);


  const createScatterData = (processedData) => ({
    datasets: processedData.map(vehicle => ({
      label: vehicle.name,
      data: [{
        x: vehicle.severityScore,  
        y: Math.min(vehicle.frequencyScore, 30),  // Clamp y-value to 30 for display
        realY: vehicle.frequencyScore  // Store actual value for tooltip
      }],
      backgroundColor: vehicle.color,
      pointRadius: loaded ? 10 : 0, // Animated growth effect
      pointHoverRadius: loaded ? 20 : 0, // Animated hover effect
      transition: "pointRadius 1s ease-out"
    }))
  });
  

  const vehicleScatterData = createScatterData(processedVehicles);
  const driverScatterData = createScatterData(processedDrivers);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 20
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          font: { size: 13 },
          usePointStyle: true,
          padding: 10,
          maxWidth: 200,
          fullSize: true,
        }
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        bodyFont: { size: 16 },
        titleFont: { size: 16, weight: 'bold' },
        callbacks: {
          label: function (context) {
            const vehicleName = context.dataset.label;
            const x = context.raw.x.toFixed(2);
            const y = context.raw.realY.toFixed(2);
      
            return `${vehicleName}: Severity ${x}, Frequency ${y}%`;
          }
        }
      },      
      title: {
        display: false,
        font: { size: 16, weight: 'bold' }
      }
    },
    scales: {
      x: {
        title: { 
          display: true, 
          text: 'Risk Impact Score',
          font: { size: 20 }
        },
        type: 'linear',
        ticks: {
          font: { size: 18 }
        },
        min: 0,
        max: 6
      },
      y: {
        title: { 
          display: true, 
          text: 'Frequency Score (%)',
          font: { size: 20 }
        },
         type: 'linear',
        ticks: { 
          callback: value => value + '%',
          font: { size: 18 },
          stepSize: 10
        },
        min: 0,
        max: 30,
        reverse: false
      }
    }
  };

  return (
    <div style={{
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      gap: '10px', 
      width: '100%', 
      height: '98%', 
      maxHeight: '620px', 
      maxWidth: '90%',
      opacity: loaded ? 1 : 0,
      transform: loaded ? 'translateY(0)' : 'translateY(-20px)',
      transition: 'opacity 1s ease-out, transform 1s ease-out'
    }}>
      
      {/* Vehicle Risk Chart */}
      <div style={{ width: '50%', height: '100%' }}>
      <div style={{
            padding: '12px',
            background: 'linear-gradient(135deg, #5c496c, #c35c8b, #e75b75, #d67740)',
            color: 'black',
            borderRadius: '15px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            width: '80%',
            margin: '10px auto',
            textAlign: 'center',
            maxHeight: '200px',
            marginLeft: '70px',
            fontWeight: 'bold',
            top: '0'
        }}>
          Vehicle Risk Assessment
        </div>
        <Scatter data={vehicleScatterData} options={chartOptions} plugins={[heatmapPlugin]} />
      </div>

      {/* Driver Risk Chart */}
      <div style={{ width: '50%', height: '100%' }}>
        <div style={{
            padding: '12px',
            background: 'linear-gradient(135deg, #5c496c, #c35c8b, #e75b75, #d67740)',
            color: 'black',
            borderRadius: '15px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            width: '80%',
            margin: '10px auto',
            textAlign: 'center',
            maxHeight: '200px',
            marginLeft: '70px',
            fontWeight: 'bold',
            top: '0'
        }}>
          Driver Risk Assessment
          </div>
        <Scatter data={driverScatterData} options={chartOptions} plugins={[heatmapPlugin]} />
      </div>
    </div>
  );
};

export default EventRiskChart;
