import React from 'react';

function OpenAlertsSphere({ alerts }) {
    if (!alerts || alerts.length === 0) {
        return <p></p>;
    }

    // Filter alerts that do not have a dateResolved
    const openAlerts = alerts.filter(alert => !alert.dateResolved);
    const openAlertsCount = openAlerts.length;

    const sphereStyle = {
        width: '15vi',
        height: '15vi',
        maxWidth: '151px',
        maxHeight: '151px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(180deg, #5b62f9, #ee9ae5)',
        color: 'white',
        fontSize: '28px',
        fontWeight: 'bold',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        margin: '5px auto',
        marginLeft: "25px"
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '0', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <div style={{
                padding: '12px',
                background: 'linear-gradient(135deg, #5c496c, #c35c8b, #e75b75, #d67740)',
                color: 'white',
                borderRadius: '15px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                width: '100%',
                textAlign: 'center',
                marginBottom: '20px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', width: '100%' }}>
                    <h2 style={{ fontSize: '21px', fontWeight: 'bold', margin: '0', whiteSpace: 'nowrap' }}>
                        Open Alerts
                    </h2>
                </div>
            </div>
            <div style={sphereStyle}>{openAlertsCount}</div>
        </div>
    );
}

export default OpenAlertsSphere;
