import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getTrips, getTripsForZone, getDailyTotalAll } from './api/vehicle';
import { useParams, Link, useNavigate } from 'react-router-dom';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import { customFilter, contextFilter } from './util/filter';
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, mpkwh, co2eInKg, co2PerMile, getVehicleName } from './util/formulas';
import { Context } from "./ContextHandler";
import { Select, MenuItem } from '@mui/material';
import PagedList from "./PagedList";
import { classifyFuelTypes } from './util/fuelTypes';

function TripList({ token }) {
  const { zid } = useParams();
  const [trips, setTrips] = useState([]);
  const [totals, setTotals] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRangeFilter;
  const lastRefreshCount = useRef(-1);
  const [popupVisible, setPopupVisible] = useState();
  const [hiddenFilter, setHiddenFilter] = useState([0]);
  const navigate = useNavigate();

  const fixTrips = trips => {
    trips.forEach(t => {
      if (t.fuelConsumed === 0) {
        t.fuelConsumed = t.fuelSpend = t.kwhConsumed = t.efficiency = t.co2Generated = t.co2eGenerated = undefined;
      }
    });
    return trips;
  }

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
      const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
      const filters = getFilters(context);
      if (zid)
        getTripsForZone(zid).then(response => setTrips(fixTrips(response.data)));
      else
        getTrips(context.rootId, date1, date2, filters).then(response => setTrips(fixTrips(response.data)));
      getDailyTotalAll(context.rootId, date1, date2, filters).then(response => setTotals(response.data));
    }
  }, [context.refreshCount, dateRange]);

  React.useEffect(() => {
    // don't allow "All Enterprises"
    if (context.rootId === -1) {
      context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
    }
  }, []);

  const assetMap = context.assets?.reduce((acc, asset) => { acc[asset.id] = asset; return acc; }, {});

  const windowChoices = ["6am - 10am", "10am - 2pm", "2pm - 6pm", "6pm - 10pm", "10pm - 2am", "2am - 6am"];
  const windowFunctions = [      h => (h >= 6 && h < 10), h => (h >= 10 && h < 14), h => (h >= 14 && h < 18),
				 h => (h >= 18 && h < 22), h => (h >= 22 || h < 2), h => (h >= 2 && h < 6)];
  let allTrips = trips;
  const firstElement = x => Array.isArray(x) ? x[0] : undefined;
  if (firstElement(hiddenFilter) == 0)
    allTrips = trips?.filter(x => x.stationary !== true);
  if (firstElement(hiddenFilter) == 1)
    allTrips = trips?.filter(x => x.stationary == true);
  let filteredTrips = allTrips;
  const definedFilter = firstElement(context.tripTypeFilter);
  if (definedFilter == 0)
    filteredTrips = filteredTrips.filter(x => x.isDefined !== false)
  if (definedFilter == 1)
    filteredTrips = filteredTrips.filter(x => x.isDefined == false)
  const hoursFilter = firstElement(context.tripHoursFilter);
  if (hoursFilter == 0)
    filteredTrips = filteredTrips.filter(x => x.openHours !== false)
  if (hoursFilter == 1)
    filteredTrips = filteredTrips.filter(x => x.openHours == false)
  const windowFilter = context.tripWindowFilter;
  if (windowFilter?.length)
    filteredTrips = filteredTrips.filter(x => windowFilter.some(f => windowFunctions[f](moment(x.startTime).utcOffset(x.zoneOffset).hour())));

  const fuelTypes = new Set(filteredTrips?.map(x => assetMap[x.vehicleId]?.fuelType));
  const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
  const energyWord = (onlyIce) ? 'Fuel' : 'Energy';

  const tripChoices = ["Defined", "Undefined"];
  const routeSet = trips ? new Set(trips.map(x => x.route)) : [];
  let routeChoices = [...routeSet];
  routeChoices.sort();

  const routeFilter = context.tripRouteFilter;
  if (routeFilter?.length > 0)
    filteredTrips = filteredTrips.filter(x => routeFilter.includes(x.route))

  const hoursChoices = ["Op Hours", "Non-Op Hours"];
  const hiddenChoices = ['Non-Hidden Trips', 'Hidden Trips'];

  let fuelCost, co2PerMile, avgMpg, avgMikwh, avgDistance, distPerDay, avgEff, totalDistance, totalFuelConsumed, chargingMinutes;
  let totalKwhConsumed;

  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;

  const noNaN = x => (x === undefined || Number.isNaN(x) ? 0 : x)
  if (totals && trips) {
    // use daily totals if there are no trip filters.  otherwise use filtered trips
    const list = (filteredTrips == allTrips) ? totals : filteredTrips;
    const assetCount = list.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
    fuelCost = totals.reduce((part, a) => part+noNaN(a.fuelSpend),    0);
    const totalCo2 = list.reduce((part, a) => part+noNaN(a.co2Generated),    0);
    totalDistance = list.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
    co2PerMile = totalDistance ? totalCo2/kmToMiles(totalDistance) : 0;
    totalFuelConsumed = list.reduce((part, a) => part+noNaN(a.fuelConsumed), 0);
    totalKwhConsumed = list.reduce((part, a) => part+noNaN(a.kwhConsumed), 0);
    avgDistance = totalDistance/assetCount;
    if (list == totals) {
      distPerDay  = list.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
      chargingMinutes = totals.reduce((part, a) => part+noNaN(a.chargingMinutesPerDay), 0);
    } else {
      const uniqueDays = new Set()
      list.forEach(trip => uniqueDays.add(moment(trip.startTime).format('M-D')))
      distPerDay  = totalDistance/uniqueDays.size;
      chargingMinutes = list.reduce((part, a) => part+noNaN(a.chargingMinutes), 0) / uniqueDays.size;
    }
    const assetCountEff = list.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
    avgEff      = list.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
    avgMpg   = noNaN(kmToMiles(totalDistance)/(litersToGallons(totalFuelConsumed)));
    avgMikwh = noNaN(kmToMiles(totalDistance)/(totalKwhConsumed));

    list.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }
  const eff = noNaN(avgEff*100).toFixed(0);

  const dateFormat = (val, trip, col) => {
      return moment(val).utcOffset(trip.zoneOffset).toISOString(true);
  }

  const columns = [
    { header: 'Asset ID', key: 'name' },
    { header: 'Group', key: 'unitId' },
    { header: 'Make / Model', key: 'makeModel', width: '9%' },
    { header: 'Year', key: 'year' },
    { header: 'Fuel', key: 'fuel' },
    { header: 'Route', key: 'route', width: '10%' },
    { header: 'Trip Start', key: 'startTime', width: '6%', downloadFormat: dateFormat },
    { header: 'Trip End', key: 'endTime', width: '6%', downloadFormat: dateFormat },
    { header: 'Trip Duration', key: 'duration' },
    { header: 'Distance Traveled', key: 'milesDriven' },
    { header: 'Op Hours', key: 'openHours' },
    { header: 'Utilization', key: 'utilization', sortable: false, displayOnly: true },
    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
    { header: energyWord + ' Consumed', key: onlyIce ? 'fuelConsumed' : 'kwhConsumed', displayOnly: true },
    { header: 'Fuel Consumed', key: 'fuelConsumed', downloadOnly: true },
    { header: 'Energy Consumed', key: 'kwhConsumed', downloadOnly: true },
    { header: onlyIce ? 'Gal / Minute' : 'kWh / Minute', key: onlyIce ? 'galPerMinute' : 'kwhPerMinute', displayOnly: true },
    { header: 'Gal / Minute', key: 'galPerMinute', downloadOnly: true },
    { header: 'kWh / Minute', key: 'kwhPerMinute', downloadOnly: true },
    { header: 'Max Speed', key: 'maxSpeed' },
    { header: 'Avg MPH', key: 'averageSpeed' },
    { header: onlyIce ? 'MPG' : 'Mi/kWh', key: onlyIce ? 'mpg' : 'mikwh', displayOnly: true },
    { header: 'MPG', key: 'mpg', downloadOnly: true },
    { header: 'Mi/kWh', key: 'mikwh', downloadOnly: true },
    { header: energyWord + ' Cost', key: 'fuelSpend' },
    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
    { header: 'Efficiency', key: 'efficiency' },
    { header: 'Estimated', key: 'estimated', downloadOnly: true },
    { header: 'Ambient Temp', key: 'ambientTemperature', downloadOnly: true, extended: true },
    { header: '# of Powertrain Faults', key: 'powertrainFaultsCount', downloadOnly: true, extended: true },
    { header: '# of HVAC Faults', key: 'hvacFaultsCount', downloadOnly: true, extended: true },
    { header: '# of General Faults', key: 'generalFaultsCount', downloadOnly: true, extended: true },
    { header: '# of Tire Pressure Faults', key: 'tirePressureAlertsCount', downloadOnly: true, extended: true },
    { header: '# of EBI Device Alerts', key: 'ebiDeviceAlertsCount', downloadOnly: true, extended: true },
    { header: 'Max Engine RPM', key: 'maxRpm', downloadOnly: true, extended: true },
    { header: 'Min Engine RPM', key: 'minRpm', downloadOnly: true, extended: true },
    { header: 'Average Engine RPM', key: 'averageRpm', downloadOnly: true, extended: true },
    { header: '# of Harsh Cornering Events', key: 'harshCorneringCount', downloadOnly: true, extended: true },
    { header: '# of Harsh Acceleration Events', key: 'harshAccelCount', downloadOnly: true, extended: true },
    { header: '# of Idles > 5 Minutes', key: 'longIdlingCount', downloadOnly: true, extended: true },
    { header: 'Oil Life Remaining', key: 'oilLifeRemaining', downloadOnly: true, extended: true },
    { header: 'Average Oil Pressure', key: 'oilPressure', downloadOnly: true, extended: true },
    { header: 'Oil Temperature', key: 'oilTemperature', downloadOnly: true, extended: true },
    { header: 'Utilization %', key: 'upTimePct', downloadOnly: true, extended: true },
    { header: 'Uptime Minutes', key: 'upTimeMinutes', downloadOnly: true, extended: true },
    { header: 'Vehicle Primary Use', key: 'primaryUse', downloadOnly: true, extended: true },
    { header: 'Vehicle Type', key: 'type', downloadOnly: true, extended: true },
    { header: 'Time Window', key: 'window', downloadOnly: true, extended: true },
    { header: 'Ideal Energy Cost', key: 'idealEnergyCost', downloadOnly: true, extended: true },
    { header: 'Ideal Energy Cost', key: 'idealFuelSpend', downloadOnly: true, extended: true },
    { header: 'Recovery Potential', key: 'recoveryPotential', downloadOnly: true, extended: true },
    { header: 'Day of Week', key: 'dayOfWeek', downloadOnly: true, extended: true },
  ];

  const nonan = x => Number.isNaN(x) || x === undefined ? 0 : x
  const round  = x => nonan(x).toFixed(2);
  const round0 = x => nonan(x).toFixed(0);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getTripInfo = (trip, key) => {
    if (key == 'name' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && getVehicleName(asset);
    }
    if (key == 'type' && assetMap)
      return assetMap[trip.vehicleId]?.type;
    if (key == 'primaryUse' && assetMap)
      return assetMap[trip.vehicleId]?.primaryUse;
    if (key == 'year' && assetMap)
      return assetMap[trip.vehicleId]?.year;
    if (key == 'fuel' && assetMap)
      return assetMap[trip.vehicleId]?.fuelType;
    if (key == 'makeModel' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && (asset.make + ' ' + asset.model);
    }
    if (key == 'startTime')
      return moment(trip.startTime).toISOString();
    if (key == 'endTime')
      return moment(trip.endTime).toISOString();
    if (key == 'duration')
      return moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    if (key == 'unitId') {
      const asset = assetMap[trip.vehicleId];
      return context.units?.find(x => x.id == asset?.unitId)?.name;
    }
    if (key == 'upTimeMinutes')
      return trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
    if (key == 'upTimePct') {
      const up = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
      return 100*up/(up + trip.offlineMinutes);
    }
    if (key == 'galPerMinute') {
      if (assetMap[trip.vehicleId]?.fuelType == 'EV')
        return;
      return litersToGallons(trip.fuelConsumed) / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    }
    if (key == 'kwhPerMinute')
      return trip.kwhConsumed / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'milesDriven')
      return kmToMiles(trip.distanceDriven);
    if (key == 'fuelConsumed') {
      if (assetMap[trip.vehicleId]?.fuelType == 'EV')
        return;
      return litersToGallons(trip.fuelConsumed);
    }
    if (key == 'mpge')
      return mpge(trip);
    if (key == 'mpg') {
      if (assetMap[trip.vehicleId]?.fuelType == 'EV')
        return;
      return mpg(trip);
    }
    if (key == 'mikwh')
      return mpkwh(trip, false);
    if (key == 'fuelSpend')
      return trip.fuelSpend;
    if (key == 'costPerMile')
      return trip.distanceDriven > 0 && fuelSpendPerMile(trip);
    if (key == 'co2e')
      return co2eInKg(trip);
    if (key == 'co2')
      return trip.distanceDriven > 0 && co2PerMile(trip);
    if (key == 'averageSpeed')
      return kmToMiles(trip.averageSpeed);
    if (key == 'maxSpeed')
      return kmToMiles(trip.maxSpeed);
    if (key == 'openHours')
      return trip[key] === undefined ? '' : trip[key] ? 'yes' : 'no';
    if (key == 'window') {
      const hr = moment(trip.startTime).utcOffset(trip.zoneOffset).hour();
      return windowChoices[windowFunctions.findIndex(x => x(hr))];
    }
    if (key == 'recoveryPotential')
      return trip['fuelSpend']-trip['idealFuelSpend'];
    if (key == 'dayOfWeek')
      return moment(trip.startTime).utcOffset(trip.zoneOffset).format('ddd');
    return trip[key];
  }

  const getDuration = val => {
    const asMin = Math.floor(val/60);
    const hr = Math.floor(asMin/60)
    const min = asMin-hr*60;
    if (hr == 0)
      return `${min}m`;
    return `${hr}h ${min}m`;
  }

  const getTripCell = (trip, key) => {
    if (key == 'utilization') {
      let totalMins = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes + trip.offlineMinutes;
      let offWidth = 100*trip.offlineMinutes / totalMins;
      let idleWidth = 100*trip.idleMinutes / totalMins;
      let decWidth = 100*trip.deceleratingMinutes / totalMins;
      let accWidth = 100*trip.acceleratingMinutes / totalMins;
      return <td key={key} className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, trip.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, trip.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, trip.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, trip.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    let val = getTripInfo(trip, key);
    if (key == 'duration')
      return <td key={key} className="number">{getDuration(val)}</td>;
    if (key == 'efficiency' && val !== undefined)
      return <td key={key} className="number">{(val * 100).toFixed(0)}%</td>;
    if ((key == 'fuelConsumed' || key == 'kwhConsumed') && trip['estimated'])
      return <td key={key} className="number interpolated">{round(val)}</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number">{val}</td>;
    }
    if (key == 'startTime' || key == 'endTime') {
      const format = 'M-D h:mma';
      const hoverText = moment(val).utcOffset(0).format('M-D H:mm:ss') + ' GMT';
      val = moment(val).utcOffset(trip.zoneOffset).format(format);
      return <td key={key} className="text" title={hoverText}>{val}</td>;
    }
    if (key == 'name') {
      const hoverText = trip?.vehicleId;
      const tripClick = ev => { if (ev.shiftKey) { ev.preventDefault(); navigate('/trips/more/' + trip.id); } }
      return <td key={key} className="text" title={hoverText}><Link to={'/trips/' + trip.id} onClick={tripClick}>{val}</Link></td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const selectedIf = x => x ? 'selected' : undefined;

  const makeIds = list => list.map(x => ({ id: x, name: x }));
  const makeNumIds = list => list.map((x, ix) => ({ id: ix, name: x }));
  const makeExclusiveIds = list => list.map((x, ix) => ({ id: ix, name: x, exclusive: true }));

  const clearHiddenFilter = () => setHiddenFilter([]);
  const addHiddenFilter = x => setHiddenFilter([x]);
  const rootUnit = localStorage.getItem('rootUnitId')
  const showHiddenFilter = rootUnit == 1;

  const tableActions = <>
			{contextFilter(context, 'tripTypeFilter', 'type-filter', 'Types', 1, 'All Trips', makeExclusiveIds(tripChoices), popupVisible, setPopupVisible)}
			{contextFilter(context, 'tripRouteFilter', 'route-filter', 'Routes', 2, 'All Routes', makeIds(routeChoices), popupVisible, setPopupVisible)}
			{contextFilter(context, 'tripHoursFilter', 'hours-filter', 'Op Hours', 3, 'All Hours', makeExclusiveIds(hoursChoices), popupVisible, setPopupVisible)}
			{contextFilter(context, 'tripWindowFilter', 'window-filter', 'Time Windows', 4, 'All Windows', makeNumIds(windowChoices), popupVisible, setPopupVisible)}
			{showHiddenFilter && customFilter(hiddenFilter, 'hidden-filter', 'Hidden', 5, 'All Trips', makeExclusiveIds(hiddenChoices), clearHiddenFilter, addHiddenFilter, popupVisible, setPopupVisible)}
                    </>;

  return <>
            <div id="focus">
                <FilterPanel filters={['fuelTypeFilter', 'groupFilter',
                                       'modelFilter', 'assetFilter', 'dateFilter']} />
                <div id="content">
                    <div id="charts">
                        <div className="individual-chart small">
                            <h4>Efficiency</h4>
                            <div className="doughnut">
                                  <DashboardDonut circleText={eff + '%'} percent={eff} />
                            </div>
                        </div>
                        <div className="individual-chart">
                            <h4>Fleet Utilization</h4>
                            <div className="doughnut">
                                <UtilizationDonut accelerating={accMin} decelerating={decMin} idle={idleMin} offline={offMin} />
                            </div>
                        </div>
                        <div className="individual-chart small">
                            <h4>Fuel Cost</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round0(noNaN(100*fuelCost/kmToMiles(totalDistance))) + '¢'} subText={"¢/Mile"} />
                            </div>
                        </div>
                        {onlyEv || <div className="individual-chart small">
                            <h4>CO<sub>2</sub> Emissions</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round(co2PerMile/1000)} subText={"kg/Mile"} />
                            </div>
                        </div>}
                        {onlyEv && <div className="individual-chart small">
                            <h4>Charging Time</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round(chargingMinutes/60)} subText={"Hours/Day"} />
                            </div>
                        </div>}
                        <div className="individual-chart small">
                            <h4>{energyWord} Efficiency</h4>
                            <div className="doughnut">
                                {onlyIce && <AssetListDonut mainText={round(avgMpg)} subText={"MPG"} />}
                                {onlyIce || <AssetListDonut mainText={round(avgMikwh)} subText={"Mi/kWh"} />}
                            </div>
                        </div>
                        <div className="individual-chart small">
                            <h4>Distance Driven</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round0(kmToMiles(distPerDay))} subText={"Miles/Day"} />
                            </div>
                        </div>
                    </div>
                    <PagedList items={filteredTrips} columns={columns} getItemInfo={getTripInfo} getItemCell={getTripCell} tableActions={tableActions} itemType="Trips" extendedDownload={true} />
                </div>
            </div>
         </>;
}

export default TripList;

