import React, { useState, useEffect } from 'react';
import { getVehicle, getNewTripsForVehicle } from './api/vehicle';
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

function DashboardDonut({ circleText, subText, percent, color, labels, values }) {

	const percentValue = percent || 100;
        const overall_grade_data = {
	    labels: labels || [],
            datasets: [{
              data: values === undefined ? [percentValue, 100-percentValue] : values,
              backgroundColor: [
                color ? color : '#F85E7D',(labels) ? '#B14EC5' : '#E3E2EC'
              ],
              borderWidth: 0
            }]
        }
        
        const overall_grade_label = {
            id: 'doughnutLabel',
            beforeDatasetDraw (chart, args, pluginOptions) {
                const {ctx, data} = chart;

		if (circleText === undefined && subText === undefined)
		    return;

                ctx.save();
                const width = chart.width;
                const xCoor = chart.getDatasetMeta(0).data[0].x;
                const yCoor = chart.getDatasetMeta(0).data[0].y;
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'alphabetic';

		const maxWidth = width/2;
		let fontSize = Math.min(80, maxWidth); // Initial font size
		fontSize = 40;
                let ascent1;
            
		while (true) {
		    ctx.font = 'bold ' + fontSize + 'px Helvetica, "Helvetica Neue", Arial, sans-serif';

		    // Measure the width of the text
		    let metrics = ctx.measureText(circleText || "");
		    let textWidth = metrics.width;
                    ascent1 = metrics.actualBoundingBoxAscent;

		    // make font size approximately the same for all donuts
		    textWidth = Math.max(textWidth, ctx.measureText("0.55").width);
            
		    // Adjust font size until text fits within maxWidth
		    if (textWidth <= maxWidth || fontSize == 1)
			break;
		    fontSize--;
		}
                const fontSize1 = fontSize;
                const font1 = ctx.font;
                let ascent;

                // Only process subText if it exists
                if (subText) {
                    const maxWidth2 = width * .4;
                    fontSize = 15;
                    while (true) {
                        ctx.font = 'normal ' + fontSize + 'px Helvetica, "Helvetica Neue", Arial, sans-serif';

                        // Measure the width of the text
                        let metrics = ctx.measureText(subText);
                        let textWidth = metrics.width;
                        ascent = metrics.actualBoundingBoxAscent;
                
                        // Adjust font size until text fits within maxWidth
                        if (textWidth <= maxWidth2 || fontSize == 1)
                            break;
                        fontSize--;
                    }
                    
                    const totalHeight = (ascent || 0) + (ascent1 || 0) + 15;
                    
                    ctx.textBaseline = 'middle';
                    ctx.fillText(subText, xCoor, yCoor + totalHeight/2 - fontSize/2);
                    ctx.font = font1;
                    
                    if (circleText) {
                        ctx.fillText(circleText, xCoor, yCoor - totalHeight/2 + fontSize1/2);
                    }
                } else if (circleText) {
                    // If only circleText is provided, center it vertically
                    ctx.textBaseline = 'middle';
                    ctx.fillText(circleText, xCoor, yCoor);
                }
            }
        }
        
        const large_doughnut_options = {
            cutout: '65%',
            responsive: true,
            plugins: {
                tooltip: {
		    enabled: !!percent || values !== undefined,
                    bodyFont: {
                        size: 24
                    }
                },
                legend: {
                    display: false,
		}
            }
        }

        const overall_grade_config = {
            type: 'doughnut',
            data: overall_grade_data,
            options: large_doughnut_options,
            plugins: [overall_grade_label]
        }

        return (
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <Doughnut key={`${circleText}-${subText}`} data={overall_grade_data} options={large_doughnut_options} plugins={[overall_grade_label]} style={{ width: '100%', height: '100%' }} />
          </div> );
}

export default DashboardDonut;