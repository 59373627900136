import React, { useState, useEffect } from "react";
import moment from "moment";
import arrow from './images/up-arrow.png'; 

const AnimatedSpan = ({ dataWidth, className, label, isTotal = false, maxRiskValue, parentWidth }) => {
  const [width, setWidth] = useState("0%");
  const [hovered, setHovered] = useState(false);
  const numericWidth = Number(dataWidth);
  
  // Calculate width based on the type of span
  let calculatedWidth;
  
  if (isTotal) {
    // Scale the total width relative to the max risk value
    calculatedWidth = (numericWidth / maxRiskValue) * 100;
  } else {
    // For component bars (driver, vehicle), set as percentage of parent's actual width
    // parentWidth is the percentage that the total bar takes up (e.g. 85% of max width)
    // We want the component bars to fill exactly that same width
    calculatedWidth = (numericWidth / 100) * parentWidth;
  }
    
  const pct = !isNaN(calculatedWidth) ? `${Math.min(Math.max(calculatedWidth, 0), 100)}%` : "0%";

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWidth(pct);
    }, 100);
    return () => clearTimeout(timeout);
  }, [pct]);

  return (
    <span
      className={className}
      style={{
        width: width,
        transition: "width 1s ease-in-out, background-color 0.3s ease-in-out",
        marginLeft: '0px',
        backgroundColor: hovered ? "#ff8c00" : "",
        position: "relative"
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && (
        <div
          style={{
            position: "absolute",
            top: "-25px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#333",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "12px",
            whiteSpace: "nowrap"
          }}
        >
          {isTotal 
            ? `${label}: ${dataWidth}` 
            : `${label}: ${dataWidth}% of Risk`}
        </div>
      )}
    </span>
  );
};

// Updated RiskComparisonChart component with proportional risk bars
const RiskComparisonChart = ({
  priorPeriod,
  currentPeriod,
  date1,
  date2,
  date3,
  date4,
}) => {
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    const timeout = setTimeout(() => setLoaded(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  // Calculate the maximum risk value to scale the bars
  const maxRiskValue = Math.max(
    Number(priorPeriod.riskPerDay),
    Number(currentPeriod.riskPerDay)
  );

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center", // Center the content horizontally
        padding: "0 20px" // Add padding on both sides
      }}
    >
      {/* Risk Bars - Stacked vertically */}
      <div style={{ 
        display: "flex", 
        flexDirection: "column", 
        width: "80%",
        maxWidth: "900px" // Prevent the chart from being too wide on large screens
      }}>
        {/* Prior Period - Top */}
        <div className="cost-graph-group" style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginBottom: '1.2vi', 
          marginTop: '1.2vi' 
        }}>
          <span
            className="label"
            style={{
              display: "inline-block",
              width: "10vh",
              fontSize: "1.3vh",
              textAlign: "center",
              marginRight: "5vi",
              minWidth: "120px" // Ensure consistent width for labels
            }}
          >
            <div
              style={{
                backgroundColor: "#ba23f6",
                color: "white",
                padding: ".3vi",
                borderRadius: "10px",
                lineHeight: "1.7vi",
                width: "100%", // Use full width of the container
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: "fit-content",
                maxWidth: "100%",
              }}
            >
              Risk / Day: {priorPeriod.riskPerDay}
              <br />
              {moment(date3).format("MMM DD")} - {moment(date4).format("MMM DD")}
            </div>
          </span>

          <div className="cost-graph-line" style={{ flex: 1 }}>
            {/* Calculate the percentage width of total bar relative to max */}
            {(() => {
              const priorTotalWidthPct = (Number(priorPeriod.riskPerDay) / maxRiskValue) * 100;
              
              return (
                <>
                  <AnimatedSpan 
                    className="total current" 
                    dataWidth={Number(priorPeriod.riskPerDay)} 
                    label="Total Risk/Day"
                    isTotal={true}
                    maxRiskValue={maxRiskValue}
                  />
                  <AnimatedSpan 
                    className="ideal current" 
                    dataWidth={Number(priorPeriod.driverPercent)} 
                    label="Driver"
                    parentWidth={priorTotalWidthPct}
                  />
                  <AnimatedSpan 
                    className="loss current" 
                    dataWidth={Number(priorPeriod.vehiclePercent)} 
                    label="Vehicle"
                    parentWidth={priorTotalWidthPct}
                  />
                </>
              );
            })()}
          </div>
        </div>
        
        {/* Current Period - Bottom */}
        <div className="cost-graph-group" style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginBottom: '1.2vi' 
        }}>
          <span
            className="label"
            style={{
              display: "inline-block",
              width: "10vh",
              fontSize: "1.3vh",
              textAlign: "center",
              marginRight: "5vi",
              minWidth: "120px" // Ensure consistent width for labels
            }}
          >
            <div
              style={{
                backgroundColor: "#ba23f6",
                color: "white",
                padding: ".3vi",
                borderRadius: "10px",
                lineHeight: "1.7vi",
                width: "100%", // Use full width of the container
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: "fit-content",
                maxWidth: "100%",
              }}
            >
              Risk / Day: {currentPeriod.riskPerDay}
              <br />
              {moment(date1).format("MMM DD")} - {moment(date2).format("MMM DD")}
            </div>
          </span>
          <div className="cost-graph-line" style={{ flex: 1 }}>
            {(() => {
              const currentTotalWidthPct = (Number(currentPeriod.riskPerDay) / maxRiskValue) * 100;
              
              return (
                <>
                  <AnimatedSpan 
                    className="total current" 
                    dataWidth={Number(currentPeriod.riskPerDay)} 
                    label="Total Risk/Day"
                    isTotal={true}
                    maxRiskValue={maxRiskValue}
                  />
                  <AnimatedSpan 
                    className="ideal current" 
                    dataWidth={Number(currentPeriod.driverPercent)} 
                    label="Driver"
                    parentWidth={currentTotalWidthPct}
                  />
                  <AnimatedSpan 
                    className="loss current" 
                    dataWidth={Number(currentPeriod.vehiclePercent)} 
                    label="Vehicle"
                    parentWidth={currentTotalWidthPct}
                  />
                </>
              );
            })()}
          </div>
        </div>
      </div>
      
      {/* Trending Indicator - Right side, vertically centered */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "8vi",
          margin: "0 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "8vi",
            height: "8vi",
          }}
        >
          <img
            src={arrow}
            alt="Trend Arrow"
            style={{
              width: "100%",
              height: "auto",
              transform: Number(currentPeriod.riskPerDay) > Number(priorPeriod.riskPerDay) ? "rotate(0deg)" : "rotate(180deg)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RiskComparisonChart;