import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';

function EnterpriseFormPage({ token }) {
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);

  return <>
            <div id="focus">
                <Tabs selected="enterprise" />
                <div id="content">
                    <form id="enterprise" class="settings-form">
                        <table>
                            <tr>
                                <td colspan="2" class="header">Company Info</td>
                            </tr>
                            <tr>
                                <td><label for="enterprise-name">Enterprise Name</label></td>
                                <td><input id="enterprise-name" name="enterprise-name" placeholder="Company Name" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="subscription-tier">Subscription Tier</label></td>
                                <td class="read-only">EBIx</td>
                            </tr>
                            <tr>
                                <td><label for="enterprise-address">Enterprise Address</label></td>
                                <td><textarea id="enterprise-address" name="enterprise-address" placeholder="Company Mailing Address"></textarea></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="submit"><button type="submit">Submit</button></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="header">Contact Info</td>
                            </tr>
                            <tr>
                                <td><label for="contact-name">Contact Name</label></td>
                                <td><input id="contact-name" name="contact-name" placeholder="John Doe" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="contact-title">Contact Title</label></td>
                                <td><input id="contact-title" name="contact-title" placeholder="Fleet Manager" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="contact-email">Contact Email</label></td>
                                <td><input id="contact-email" name="contact-email" placeholder="johndoe@company.com" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="contact-phone">Contact Phone</label></td>
                                <td><input id="contact-phone" name="contact-phone" placeholder="123-456-7890" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="contact-address">Contact Address</label></td>
                                <td><textarea id="contact-address" name="contact-address" placeholder="Contact Mailing Address"></textarea></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="submit"><button type="submit">Submit</button></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="header">Billing Info</td>
                            </tr>
                            <tr>
                                <td><label for="billing-name">Contact Name</label></td>
                                <td><input id="billing-name" name="billing-name" placeholder="John Doe" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="billing-email">Billing Email</label></td>
                                <td><input id="billing-email" name="billing-email" placeholder="johndoe@company.com" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="billing-phone">Billing Phone</label></td>
                                <td><input id="billing-phone" name="billing-phone" placeholder="123-456-7890" type="text" /></td>
                            </tr>
                            <tr>
                                <td><label for="billing-address">Billing Address</label></td>
                                <td><textarea id="billing-address" name="billing-address" placeholder="Billing Mailing Address"></textarea></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="submit"><button type="submit">Submit</button></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="header">License Info</td>
                            </tr>
                            <tr>
                                <td><label for="license1">Device Licence</label></td>
                                <td class="read-only">Type: GO-9<br />
                                    Quantity: 23<br />
                                    Price: $12 per license
                                </td>
                            </tr>
                            <tr>
                                <td><label for="license2">Device License</label></td>
                                <td class="read-only">Type: Nexus<br />
                                    Quantity: 44<br />
                                    Price: $16 per license
                                </td>
                            </tr>
                            <tr>
                                <td><label for="license3">Feature License</label></td>
                                <td class="read-only">Type: Fuel Savings<br />
                                    Quantity: 23<br />
                                    Price: $5 per license
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
         </>;
}

export default EnterpriseFormPage;

