import React from 'react';
import { co2PerMile, mpge, mpg, mpkwh, kmToMiles, litersToGallons } from "./util/formulas";
import { FuelTypes } from './util/fuelTypes';

function Scorecard({ wtdData, mtdData, operationalWtdData, operationalMtdData }) {
    // Format the data for display
    console.log(wtdData);
    
    const isIce = (index) => index === FuelTypes.Gas || index === FuelTypes.Diesel;
    
    const formatData = (data, operationalData, days = 7) => {
        if (!data) return null;
        
        // Ensure data arrays exist
        const fuelByType = data.fuelConsumedByType || [];
        const distanceByType = data.distanceByType || [];
        const kwhByType = data.kwhConsumedByType || [];
        
        // Sum ICE distance (Gas + Diesel) and convert km to miles
        const iceDistanceKm = distanceByType.reduce((sum, val, idx) => sum + (isIce(idx) ? val : 0), 0);
        const iceDistanceMiles = kmToMiles(iceDistanceKm);
        
        // Sum ICE fuel consumption (convert liters to gallons)
        const iceFuelLiters = fuelByType.reduce((sum, val, idx) => sum + (isIce(idx) ? val : 0), 0);
        const iceFuelGallons = litersToGallons(iceFuelLiters);
        
        // Sum EV distance and kWh consumption
        const evDistanceKm = distanceByType.reduce((sum, val, idx) => sum + (idx === FuelTypes.EV ? val : 0), 0);
        const evDistanceMiles = kmToMiles(evDistanceKm);
        const evKwh = kwhByType.reduce((sum, val, idx) => sum + (idx === FuelTypes.EV ? val : 0), 0);
        
        // Efficiency Calculations
        const mpgIce = iceFuelGallons > 0 ? (iceDistanceMiles / iceFuelGallons).toFixed(1) : '-';
        const miPerKwhEv = evKwh > 0 ? (evDistanceMiles / evKwh).toFixed(1) : '-';
        const kWhToGal = evKwh > 0 ? (evKwh / 33.7).toFixed(2) : '-';
        
        // Duration of Operational Trips calculation
        // Use operational data if available, otherwise use regular data
        const opData = operationalData || data;
        
        // Total trip duration during operational windows
        const operationalTripDuration = opData.tripMinutes ? opData.tripMinutes / 60 : 0;
        
        // Utilization per day calculation - operational trip time divided by days in period
        const utilizationPerDay = operationalTripDuration / days;
        
        // Fuel Cost Per Mile
        const totalDistanceMiles = kmToMiles(data.distanceDriven || 0);
        const format = num => (Math.round(num*10)/10).toLocaleString();
        
        return {
            distance: `${format(totalDistanceMiles)} mi`,
            timeInOpTrips: `${format(operationalTripDuration)} hr`,
            timeActive: `${format((data.uptimeMinutes || 0) / 60)} hr`,
            timeIdle: `${format((data.idleMinutes || 0) / 60)} hr`,
            timeOffline: `${format((data.offlineMinutes || 0) / 60)} hr`,
            fuel: `${format(iceFuelGallons)} gal`,
            milesPerDay: `${format(totalDistanceMiles / days)} mi`,
            tripsPerDay: `${format((data.tripCount || 0) / days)}`,
            utilizationPerDay: `${format(utilizationPerDay)} hr`,
            uptimePerDay: `${format((data.uptimeMinutes || 0) / 60 / days)} hr`,
            downtimePerDay: `${format((data.offlineMinutes || 0) / 60 / days)} hr`,
            mpgIce,
            miPerKwhEv,
            kWhToGal,
        };
    };

    if (!wtdData || !mtdData) {
        return (
            <p style={{ color: '#777', textAlign: 'center', fontSize: '18px' }}>
                No data available.
            </p>
        );
    }

    const formattedWtdData = formatData(wtdData, operationalWtdData);
    const formattedMtdData = formatData(mtdData, operationalMtdData, 30);

    // Helper function to render a single scorecard
    const renderCard = (title, data) => (
        <div className="scorecard">
            <h3 className="scorecard-title">{title}</h3>
            <div className="scorecard-section">
                <div className="section-title">Totals</div>
                {renderRow('Distance', data.distance)}
                {renderRow('Duration of Operational Trips', data.timeInOpTrips)}
                {renderRow('Time Active', data.timeActive)}
                {renderRow('Time Idle', data.timeIdle)}
                {renderRow('Time Offline', data.timeOffline)}
                {renderRow('Fuel', data.fuel)}
            </div>
            <div className="scorecard-section">
                <div className="section-title">Daily Avg</div>
                {renderRow('mi/day - ALL', data.milesPerDay)}
                {renderRow('Trips/Day - ALL', data.tripsPerDay)}
                {renderRow('Utilization/Day', data.utilizationPerDay)}
                {renderRow('Uptime/Day', data.uptimePerDay)}
                {renderRow('Downtime/Day', data.downtimePerDay)}
                {renderRow('MPG - ICE', data.mpgIce)}
                {renderRow('mi/kWh - EV', data.miPerKwhEv)}
            </div>
        </div>
    );

    // Render a single row with label and value
    const renderRow = (label, value) => (
        <div className="row">
            <span className="row-label">{label}</span>
            <span className="row-value">{value}</span>
        </div>
    );

    return (
        <div className="scorecard-container">
            {renderCard('Last 7 Days - Scorecard', formattedWtdData)}
            {renderCard('Last 30 Days - Scorecard', formattedMtdData)}
        </div>
    );
}

export default Scorecard;