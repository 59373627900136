import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import { Context } from "../ContextHandler";
import { Select, MenuItem } from '@mui/material';
import Tabs from './Tabs';
import { getOpCenters } from '../api/unit';
import { getFilters, getVehicles, addVehicle, updateVehicle } from '../api/vehicle';
import { kmToMiles, getVehicleName } from '../util/formulas';
import PagedList from "../PagedList";
import ItemListPage from "./ItemListPage";
import { customFilter, toggleFilter } from '../util/filter';
import { canViewMultipleEnterprises } from '../util/security';

function AssetPage({ token }) {
  const context = useContext(Context);
  const [opCenters, setOpCenters] = useState([]);
  const { rid, id } = useParams();
  const navigate = useNavigate();
  const [popupVisible, setPopupVisible] = useState(-1);
  const [yearFilter, setYearFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  React.useEffect(() => {
     if (rid && context.rootId != rid)
        context.dispatch({ type: 'setEnterprise', payload: parseInt(rid) });
     else if (id  && !(context.groupFilter?.length == 1 && context.groupFilter[0] == id))
        context.dispatch({ type: 'setState', attribute: 'groupFilter', payload: [parseInt(id)] });
     else if (rid)
        navigate('/settings/vehicles');
  });

  const primaryUseChoices = [
         { id: 'General', name: 'General' },
         { id: 'LocalDelivery', name: 'Local Delivery' },
         { id: 'Shuttle', name: 'Passenger Shuttle' },
         { id: 'Sales', name: 'Sales' }
  ];

  const typeChoices = [
         { id: 'Passenger', name: 'General' },
         { id: 'CommercialLD', name: 'Commercial - LD' },
         { id: 'CommercialMD', name: 'Commercial - MD' },
         { id: 'CommercialHD', name: 'Commercial - HD' }
  ];

  const columns = [
		    { header: 'Edit', key: 'edit', sortable: false },
		    { header: 'Asset Name', key: 'name', width: '12%' },
		    { header: 'Enterprise', key: 'rootUnitId' },
		    { header: 'Op Center', key: 'unitId' },
		    { header: 'Primary Use', key: 'primaryUse' },
		    { header: 'Installed Devices', key: 'devices' },
		    { header: 'Make / Model', key: 'makeModel' },
		    { header: 'Year', key: 'year' },
		    { header: 'Type', key: 'type' },
		    { header: 'Geotab ID', key: 'geotabId' },
		    { header: 'Fuel', key: 'fuelType' },
		    { header: 'License Plate', key: 'licensePlate' },
		    { header: 'VIN', key: 'vin' },
		    { header: 'Mileage', key: 'mileage' },
		    { header: 'Accelevate Licenses', key: 'licenses' },
		    { header: 'Status', key: 'status' },
  ];

  const statusList = [{ id: 'InInventory', name: 'In Inventory' }, { id: 'Installed' }, { id: 'Maintenance' }, { id: 'Inactive' }];

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getAssetInfo = (asset, key) => {
    if (key == 'name')
      return getVehicleName(asset);
    if (key == 'unitId' || key == 'rootUnitId')
      return context.units?.find(x => x.id == asset[key])?.name;
    if (key == 'makeModel')
      return asset.make + ' ' + asset.model;
    if (key == 'primaryUse')
      return primaryUseChoices.find(x => x.id == asset[key])?.name;
    if (key == 'type')
      return typeChoices.find(x => x.id == asset[key])?.name;
    if (key == 'group')
      return asset.groups?.map(x => x.name).join(', ')
    if (key == 'mileage')
      return kmToMiles(asset.odometer/1000);
    if (key == 'status') {
      const s = statusList.find(x => x.id == asset[key]);
      return s?.name ? s.name : asset[key];
    }
    return asset[key];
  }

  const getAssetCell = (asset, key) => {
    let val = getAssetInfo(asset, key);
    if (key == 'name')
      return <td key={key} className="text"><Link to={'/assets/' + asset.id}>{val}</Link></td>
    if (key == 'mileage' && val < 0)
      val = 'Odometer Error';
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number">{val}</td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  const enterpriseChanged = ent => {
    getOpCenters(ent).then(result => {
       if (!result.data)
         setOpCenters([]);
       else
         setOpCenters(result.data.filter(c => !c.readOnly));
    });
  };

  const fields = [
       { key: 'name' },
       { key: 'rootUnitId', label: 'Enterprise',
         choices: context.enterprises?.map(x => ({ name: x.name, id: x.unitId })), onChange: enterpriseChanged,
         showCondition: x => !x.id && canViewMultipleEnterprises() },
       { key: 'unitId', label: 'Op Center', choices: opCenters,
	 makeChoices: () => enterpriseChanged(context.rootId),
         showCondition: x => !x.id },
       { key: 'make' },
       { key: 'model' },
       { key: 'year' },
       { key: 'fuelType', choices: [
         { id: 'GAS', name: 'Gas' },
         { id: 'LPG', name: 'LPG' },
         { id: 'EV', name: 'EV' },
         { id: 'CNG', name: 'CNG' },
         { id: 'PROPANE', name: 'Propane' },
         { id: 'DIESEL', name: 'Diesel' }
       ]},
       { key: 'licensePlate' },
       { label: 'VIN', key: 'vin' },
       { label: 'VCG ID', key: 'vcgId' },
       { label: 'Geotab Id', key: 'geotabId' },
       { key: 'primaryUse', choices: [
         { id: 'General', name: 'General' },
         { id: 'LocalDelivery', name: 'Local Delivery' },
         { id: 'Shuttle', name: 'Passenger Shuttle' },
         { id: 'Sales', name: 'Sales' }
       ]},
       { key: 'type', choices: [
         { id: 'Passenger', name: 'General' },
         { id: 'CommercialLD', name: 'Commercial - LD' },
         { id: 'CommercialMD', name: 'Commercial - MD' },
         { id: 'CommercialHD', name: 'Commercial - HD' }
       ]},
       { key: 'status', choices: statusList }
  ];

  const validateVehicle = vehicle => vehicle.name && vehicle.unitId && vehicle.vin && vehicle.fuelType;

  const filters = ['enterpriseFilter', 'groupFilter', 'fuelTypeFilter', 'modelFilter' ];

  const clearYearFilter = () => setYearFilter([]);
  const addYearFilter = year => setYearFilter(toggleFilter(yearFilter, year));

  const clearStatusFilter = () => setStatusFilter([]);
  const addStatusFilter = status => setStatusFilter(toggleFilter(statusFilter, status));

  const yearChoices = [...new Set(context.assets?.map(v => v.year).filter(x => x))].sort().map(x => ({ id: x }));

  const customFilters = [
      customFilter(yearFilter, 'year-filter', 'Years', 1, 'All Years', yearChoices, clearYearFilter, addYearFilter,
             popupVisible, setPopupVisible),
      customFilter(statusFilter, 'status-filter', 'Status', 2, 'All Statuses', statusList, clearStatusFilter, addStatusFilter,
             popupVisible, setPopupVisible)
  ];

  const filterFunc = v => (statusFilter.length == 0 || statusFilter.includes(v.status)) &&
                          (  yearFilter.length == 0 ||   yearFilter.includes(v.year));

  const canEditItem = v => !context.units?.find(u => u.id == v.unitId)?.readOnly;

  return <ItemListPage columns={columns} fields={fields} addItem={addVehicle} updateItem={updateVehicle} getItems={getVehicles}
                       getItemInfo={getAssetInfo} getItemCell={getAssetCell} validateItem={validateVehicle}
		       selectedTab="vehicles" addItemText="Add Vehicle" filter={filterFunc} canEditItem={canEditItem}
                       editHoverText="Edit Vehicle" itemType="Assets" filters={filters} customFilters={customFilters} />;
}

export default AssetPage;

