import React from 'react';
import { Link } from 'react-router-dom';

function Tabs({ selected }) {
  const getClass = x => (selected == x ? 'selected' : undefined);
  return (
                <ul id="settings-tabs">
                    <li className={getClass('enterprise')}><Link to='/settings/enterprise'>Enterprise</Link></li>
                    <li className={getClass('units')}><Link to='/settings/units'>Business Units</Link></li>
                    <li className={getClass('opcenters')}><Link to='/settings/opcenters'>Op Centers</Link></li>
                    <li className={getClass('zones')}><Link to='/settings/zones'>Zones</Link></li>
                    <li className={getClass('users')}><Link to='/settings/users'>Users</Link></li>
                    <li className={getClass('alerts')}><Link to='/settings/alerts'>Alerts</Link></li>
                    <li className={getClass('vehicles')}><Link to='/settings/vehicles'>Assets/Vehicles</Link></li>
                    <li className={getClass('devices')}><Link to='/settings/devices'>Gateway Devices</Link></li>
                    <li className={getClass('account')}><Link to='/settings/account'>Account</Link></li>
                </ul>
  );
}

export default Tabs;

