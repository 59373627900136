import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Legend, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

function UtilizationDonut({ accelerating, decelerating, idle, offline, showIdle }) {
    
    const calculateOperationalUptime = () => {
        const totalUptime = accelerating + decelerating + idle;
        const totalOperationalTime = totalUptime + offline;
        
        
        return (totalUptime / totalOperationalTime) * 100;
    };

    const uptimePercent = Math.round(calculateOperationalUptime());
    const idlePercent = Math.round((idle * 100) / (accelerating + decelerating + idle + offline));
    
    const fleet_utilization_data = {
        labels: ['Accelerating', 'Decelerating', 'Idle', 'Offline'],
        datasets: [{
            data: [accelerating, decelerating, idle, offline],
            backgroundColor: ['#F85E7D', '#B14EC5', '#F78957', '#7C7C7F'],
            borderWidth: 0
        }]
    };

    const fleet_utilization_number = {
        id: 'doughnutNumber',
        beforeDatasetDraw(chart) {
            const { ctx } = chart;
            ctx.save();
            const xCoor = chart.getDatasetMeta(0).data[0].x;
            const yCoor = chart.getDatasetMeta(0).data[0].y;
            ctx.font = 'bold 1.5vi Helvetica, "Helvetica Neue", Arial, sans-serif';
            ctx.fillStyle = '#000000';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText((showIdle ? idlePercent : uptimePercent) + '%', xCoor, yCoor);
        }
    };

    const large_doughnut_options = {
        cutout: '65%',
        aspectRatio: 1.7,
        plugins: {
            tooltip: {
                bodyFont: { size: 24 },
                callbacks: {
                    label(context) {
                        const dataset = context.chart.data.datasets[context.datasetIndex];
                        const currentValue = dataset.data[context.dataIndex];
                        const total = dataset.data.reduce((acc, value) => acc + value, 0);
                        const percentage = ((currentValue / total) * 100).toFixed(1);
                        return `${context.chart.data.labels[context.dataIndex]}: ${percentage}%`;
                    }
                }
            },
            legend: {
                display: true,
                position: 'right',
                labels: { boxWidth: 10, boxHeight: 10 }
            }
        }
    };

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <Doughnut
                key={idlePercent + uptimePercent}
                data={fleet_utilization_data}
                options={large_doughnut_options}
                plugins={[fleet_utilization_number]}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
}

export default UtilizationDonut;

