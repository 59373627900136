import React, { useState, useRef, useEffect, useContext } from 'react';
import moment from "moment";
import { Context } from "../ContextHandler";
import Tabs from './Tabs';
import { isSuperAdmin } from '../util/security';

import { getEnterpriseSummaryList, addEnterprise, updateEnterprise, getEnterprise } from '../api/enterprise';
import PagedList from "../PagedList";
import ItemListPage from "./ItemListPage";

function EnterprisePage({ token }) {
  const context = useContext(Context);
  const [vehicleChoices, setVehicleChoices] = useState();

  const columns = [ { key: 'edit', sortable: false },
                    { key: 'name', header: 'Enterprise' },
		    { key: 'subscriptionTier', header: 'Tier' },
                    { key: 'userCount', header: 'Users' },
                    { key: 'opCenterCount', header: 'Op Centers' },
                    { key: 'vehicleCount', header: 'Vehicles' },
                    { key: 'go9licensed', header: 'GO-9 licensed' },
                    { key: 'go9unused', header: 'GO-9 unused' },
                    { key: 'nexuslicensed', header: 'Nexus licensed' },
                    { key: 'nexusunused', header: 'Nexus unused' },
                    { key: 'fuelfeaturelicensed', header: 'Fuel Feature licensed' },
                    { key: 'fuelfeatureunused', header: 'Fuel Feature unused' },
                    { key: 'status' } ];
  const allFields = [
		{ key: 'enterpriseInfo', heading: 'Enterprise Info' },
		{ key: 'name', label: 'Enterprise Name' },
		{ key: 'subscriptionTier', label: 'Subscription Tier', choices: [{ id: 'EBI' }, { id: 'EBIx' }] },
		{ key: 'status', label: 'Enterprise Status', choices: [{ id: 'Active' }, { id: 'Inactive' }] },
		{ key: 'address', label: 'Enterprise Address', multiline: true },
		{ key: 'contactInfo', heading: 'Contact Info' },
		{ key: 'contactName', label: 'Contact Name' },
		{ key: 'contactTitle', label: 'Contact Title' },
		{ key: 'contactEmail', label: 'Contact Email' },
		{ key: 'contactPhone', label: 'Contact Phone' },
		{ key: 'contactAddress', label: 'Contact Address', multiline: true },
		{ key: 'billingInfo', heading: 'Billing Info' },
		{ key: 'billingContactName', label: 'Contact Name' },
		{ key: 'billingEmail', label: 'Billing Email' },
		{ key: 'billingPhone', label: 'Billing Phone' },
		{ key: 'billingAddress', label: 'Billing Address', multiline: true },
  ];

  const fields = (isSuperAdmin()) ? allFields : allFields.filter(x => x.key != 'subscriptionTier');

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getEnterpriseInfo = (device, key) => {
    if (key.includes('unused')) return 0;
    if (key.includes('licensed')) return 0;
    return device[key];
  }

  const getEnterpriseCell = (device, key) => {
    let val = getEnterpriseInfo(device, key);
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td key={key} className="number">{val}</td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  return <ItemListPage columns={columns} fields={fields} addItem={addEnterprise} updateItem={updateEnterprise}
		       getItem={getEnterprise} getItems={getEnterpriseSummaryList} getItemInfo={getEnterpriseInfo}
		       getItemCell={getEnterpriseCell} selectedTab="enterprise" addItemText="Add Enterprise" 
		       itemType="Enterprises" editHoverText="Edit Enterprise" filters={[]} />;
}

export default EnterprisePage;

