import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getVehicles, getFilters, getTripsForVehicle, getDailyTotalByDate, getDailyTotal, getAlerts, getFaults } from './api/vehicle';
import { eventMetadataMap } from './util/events'; 
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DashboardDonut from "./DashboardDonut";
import AlertSummary from "./AlertSummary";
import Scorecard from "./ScoreCard.js";
import FilterPanel from "./FilterPanel";
import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { lastSevenDays } from "./util/dates";
import { co2PerMile, mpge, mpg, mpkwh, kmToMiles, litersToGallons } from "./util/formulas";
import FleetEfficiencyDonut from "./FleetEfficiencyDonut";
import { Context } from "./ContextHandler";
import mapboxgl from 'mapbox-gl';
import mapBus from './images/map-bus.svg';
import mapBusBlue from './images/map-bus-blue.svg';
import mapBusPink from './images/map-bus-pink.svg';
import mapBusPurple from './images/map-bus-purple.svg';
import mapBusOrange from './images/map-bus-orange.svg';
import { FuelTypes, classifyFuelTypes, fuelTypesList } from './util/fuelTypes';
import { mapboxToken, MAP_STYLES, StyleControl } from './util/mapbox.js';
import BarGraphic from "./BarGraphic";

function getPreviousDateRange(moments) {
  if (!moments)
     return;
  const date1 = moment(moments[0]);
  const date2 = moment(moments[1]);

  // Calculate the number of days between
  const daysBetween = Math.abs(date2.diff(date1, 'days'));

  // Calculate the previous interval
  const previousStart = date1.clone().subtract(daysBetween+1, 'days');
  const previousEnd = date2.clone().subtract(daysBetween+1, 'days');

  return [previousStart, previousEnd];
}

function getDateSpan(dates) {
  if (!dates)
    return;
  const fmt = 'MMM D';
  return moment(dates[0]).format(fmt) + ' - ' + moment(dates[1]).format(fmt);
}

function MonitorPage({ token }) {

  const nonan = x => Number.isNaN(x) || x === undefined ? 0 : x
    const [dailyGraph, setDailyGraph] = useState();
    const [assets, setAssets] = useState();
	const [alerts, setAlerts] = useState();
    const [totals, setTotals] = useState();
    const [previousTotals, setPreviousTotals] = useState();
    const context = useContext(Context);
    const [operationalTotals, setOperationalTotals] = useState();
    const [operationalPreviousTotals, setOperationalPreviousTotals] = useState();
    const dateRange = context.dateRangeFilter;
    const previousDateRange = getPreviousDateRange(context.dateRangeFilter);
    const lastRefreshCount = useRef(-1);
    const [mapRefValue, setMapRefValue] = useState();
    const wtdDates = [moment().subtract(7, 'days'), moment()]; // Week-to-Date
    const mtdDates = [moment().subtract(1, 'month'), moment()]; // Month-to-Date
    const STATUS = {
      ACTIVE: "ACTIVE",
      IDLE: "IDLE",
      OFFLINE: "OFFLINE"
    };
    const [selectedStatuses, setSelectedStatuses] = useState(Object.keys(STATUS));
    const dateSpan1 = getDateSpan(wtdDates); // "Jan 22 - Jan 29"
    const dateSpan2 = getDateSpan(mtdDates); 
    const [hoveredAsset, setHoveredAsset] = useState(null);

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 32, // latitude[0],
        longitude: -117, // longitude[0],
        zoom: 10
    });    

  const [mapStyle, setMapStyle] = useState(MAP_STYLES.navNight.url);

  // Handle style change from the control
  const handleStyleChange = (styleKey) => {
    setMapStyle(MAP_STYLES[styleKey].url);
  };

  const toggleStatus = (status) => {
    setSelectedStatuses(prev =>
        prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
    );
  };


	const fixFaults = (faults) => {
		let filteredFaults = faults;
	  
		// Apply groupFilter (unitId)
		if (context.groupFilter && context.groupFilter.length > 0) {
		  filteredFaults = filteredFaults.filter(fault => 
			context.assets?.some(vehicle => 
			  vehicle.id === fault.vehicleId && context.groupFilter.includes(vehicle.unitId)
			)
		  );
		}
	  
		// Apply assetFilter (vehicle.id)
		if (context.assetFilter && context.assetFilter.length > 0) {
		  filteredFaults = filteredFaults.filter(fault => context.assetFilter.includes(fault.vehicleId));
		}
	  
		// Apply modelFilter (vehicle.model)
		if (context.modelFilter && context.modelFilter.length > 0) {
		  filteredFaults = filteredFaults.filter(fault => 
			context.assets?.some(vehicle => 
			  vehicle.id === fault.vehicleId && context.modelFilter.includes(vehicle.model)
			)
		  );
		}
	  
		// Apply fuelTypeFilter (vehicle.fuelType)
		if (context.fuelTypeFilter && context.fuelTypeFilter.length > 0) {
		  filteredFaults = filteredFaults.filter(fault => 
			context.assets?.some(vehicle => 
			  vehicle.id === fault.vehicleId && context.fuelTypeFilter.includes(vehicle.fuelType)
			)
		  );
		}
	  
		return filteredFaults;
	  };

    useEffect(() => {
      if (lastRefreshCount.current !== context.refreshCount && dateRange) {
          lastRefreshCount.current = context.refreshCount;
      
          // Define today and calculate relative dates
          const today = moment(); // Current date
          const oneWeekBefore = moment().subtract(7, 'days'); // One week before today
          const oneMonthBefore = moment().subtract(1, 'month'); // One month before today
      
          // Format the dates for API requests
          const date2 = today.format('YYYY-MM-DD'); // Today
          const date1 = oneWeekBefore.format('YYYY-MM-DD'); // One week before today
          const date4 = today.format('YYYY-MM-DD'); // Today
          const date3 = oneMonthBefore.format('YYYY-MM-DD'); // One month before today
      
          const filters = getFilters(context);
          const operationalFilters = { ...filters, operational: true };
      
          getVehicles(context.rootId, filters).then((response) => setAssets(response.data));
          getFaults(context.rootId, date1, date2, filters)
          .then((response) => {
              const level1Faults = response.data.filter(fault => {
                  const metadata = eventMetadataMap[fault.id];
                  return metadata && metadata.riskLevel === 1;
              });
              setAlerts(fixFaults(level1Faults));
          });
          
          getDailyTotalByDate(context.rootId, date1, date2, filters).then((result) => setDailyGraph(result.data));
          getDailyTotal(context.rootId, date1, date2, filters).then((result) => setTotals(result.data));
          getDailyTotal(context.rootId, date3, date4, filters).then((result) => setPreviousTotals(result.data));
          
          // Add these new state variables for operational efficiency data only
          getDailyTotal(context.rootId, date1, date2, operationalFilters).then((result) => 
              setOperationalTotals(result.data));
          getDailyTotal(context.rootId, date3, date4, operationalFilters).then((result) => 
              setOperationalPreviousTotals(result.data));
      }
  }, [context.refreshCount, dateRange]);

  

  //   if (lastRefreshCount.current != context.refreshCount && dateRange) {
	//     lastRefreshCount.current = context.refreshCount;
  //           const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
  //           const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
  //           const date3 = (previousDateRange[0]).format('YYYY-MM-DD')
  //           const date4 = (previousDateRange[1]).format('YYYY-MM-DD')
	//     const filters = getFilters(context);
  //           getVehicles(context.rootId, filters).then(response => setAssets(response.data));
	// 		getAlerts(context.rootId, date1, date2, filters) .then((response) => setAlerts(fixFaults(response.data)));
	//     getDailyTotalByDate(context.rootId, date1, date2, filters).then(result => setDailyGraph(result.data));
	//     getDailyTotal(context.rootId, date1, date2, filters)      .then(result => setTotals(result.data));
  //     // getDailyTotal(context.rootId, date1, date2, filters)      .then(result => setMonthlytotals(result.data));
  //     getDailyTotal(context.rootId, date3, date4, filters)      .then(result => setPreviousTotals(result.data));
	// }




    // Separate logic for refreshing `getVehicles` every minute
    useEffect(() => {
      console.log('set up autorefresh');
      const intervalId = setInterval(() => {
	console.log("Refreshing vehicles...");
	const filters = getFilters(context);
        console.log('gv', filters, context.rootId);
	getVehicles(context.rootId, filters).then((response) => setAssets(response.data));
      }, 10000); // 10 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
    }, [context]);

    React.useEffect(() => {
      // don't allow "All Enterprises"
      if (context.rootId === -1) {
	 context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
      }
    }, []);

    // need this because we want to calculate bounding box after mapRef is set
    const handleMapRef = map => {
      setMapRefValue(map);
    }

    const fuelTypes = new Set(totals?.activeVehicleCountByFuelType?.
		map((value, index) => value === 0 ? null : fuelTypesList[index]).filter(x => x));
    const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
    const energyWord = onlyIce ? 'Fuel' : 'Energy';
    const isIce = x => x == FuelTypes.Gas || x == FuelTypes.Diesel;

    const header = title => <div style={{
            padding: '12px',
            background: 'linear-gradient(135deg, #5c496c, #c35c8b, #d67740, #d67740)',
            color: 'white',
            borderRadius: '15px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            width: '97%',
            margin: '10px auto',
            textAlign: 'center',
            maxHeight: '200px',
            // position: 'sticky',
            // zIndex: '999',
            top: '0'
        }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', width: '100%' }}>
            <h2 style={{ fontSize: '26px', fontWeight: 'bold', margin: '0' }}>
                {title}
            </h2>
            </div>
            </div>;

    // Calculate bounding box and fit map to marker
    useEffect(() => {
      if (!assets)
	 return;
      let lon1 = 180, lat1 = 90, lon2 = -180, lat2 = -90;
      assets.forEach(asset => {
         if (!asset.latitude) return;
         lon1 = Math.min(lon1, asset.longitude);
         lat1 = Math.min(lat1, asset.latitude);
         lon2 = Math.max(lon2, asset.longitude);
         lat2 = Math.max(lat2, asset.latitude);
      });
      const pad = .03;
      const bound1 = [lon1-pad, lat1-pad];
      const bound2 = [lon2+pad, lat2+pad];
      const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

      if (mapRefValue) {
	mapRefValue.fitBounds(bounds, { duration: 0 });
      }
    }, [assets?.length, mapRefValue]);

    const filters = ['enterpriseFilter', 'fuelTypeFilter', 'groupFilter', 'modelFilter', 'assetFilter'];
    if (!totals)
	return <FilterPanel filters={filters} className="slide-in-left" />;

    const round = x => nonan(x).toFixed(0);
    const round2 = x => nonan(x).toFixed(2);

    const fuelCost = nonan(Math.round(100 * totals.fuelSpend / kmToMiles(totals.distanceDriven)));
    const co2Value = round2(co2PerMile(totals)/1000);
    const uptimeValue = round(nonan(totals.uptimeMinutes/60 / dailyGraph?.length));
    const mpgValue = round2(mpkwh(totals, onlyIce));
    const totalDistanceInKm = dailyGraph?.reduce((tot, x) => tot+x.distanceDriven, 0);
    const distanceValue = round(nonan(kmToMiles(totalDistanceInKm/dailyGraph?.length)));
    const chargingValue = round(nonan(totals.chargingMinutes/60 / dailyGraph?.length));
    const idleValue = round(nonan(totals.idleMinutes/60 / dailyGraph?.length));
    const eff = nonan(Math.round(operationalTotals?.efficiency*100))
    const previousEff = nonan(Math.round(operationalPreviousTotals?.efficiency*100))
    const activeCount = assets?.length;
    const addFuels = ice => nonan(totals?.fuelConsumedByType?.reduce((tot, x, index) => isIce(index) == ice ? x+tot : tot, 0));
    const summaryFuelConsumed = litersToGallons(addFuels(true));
    const summaryEnergyConsumed = addFuels(false);
    const getTransform = asset => {
      const bearing = asset.bearing;
      if (bearing == -1)
        return;
      if (bearing >= 0 && bearing < 180) {
	// Flip and rotate
	return `scaleX(-1) rotate(${90-bearing}deg)`;
      } else {
	// Rotate only
	return `rotate(${bearing-270}deg)`;
      }
    }


	const OFFLINE_THRESHOLD_HOURS = 24;

  const grayColors = ['#6f7681', '#00188f'];
  const fuelTypeColors = ['#fc9432', '#ba23f6', '#d916a8'];
  const gasDieselColors = ['#ba23f6', '#d916a8'];
    const evCount = totals?.activeVehicleCountByFuelType && (totals.activeVehicleCountByFuelType[FuelTypes.EV]);
    const gasCount = totals?.activeVehicleCountByFuelType && (totals.activeVehicleCountByFuelType[FuelTypes.Gas]);
    const dieselCount = totals?.activeVehicleCountByFuelType && (totals.activeVehicleCountByFuelType[FuelTypes.Diesel]);
    const activeHours = tot => (tot.acceleratingMinutes + tot.deceleratingMinutes + tot.idleMinutes)/60;


	const statusIcons = {
        [STATUS.ACTIVE]: { name: "Active", color: "green", bus: mapBusBlue },
        [STATUS.IDLE]: { name: "Idle", color: "yellow", bus: mapBusPink },
        [STATUS.OFFLINE]: { name: "Offline", color: "gray", bus: mapBusOrange },
    };

		const getVehicleStatus = (asset) => {
			if (!asset.lastUpdate) return STATUS.OFFLINE;
		
			const lastUpdateTime = moment(asset.lastUpdate);
			const hoursSinceUpdate = moment().diff(lastUpdateTime, 'hours');
		
			if (hoursSinceUpdate > OFFLINE_THRESHOLD_HOURS) {
				return STATUS.OFFLINE;
			}
		
			return asset.speed === 0 ? STATUS.IDLE : STATUS.ACTIVE;
		};
	
		const updatedAssets = assets?.map(asset => ({
			...asset,
			status: getVehicleStatus(asset)
		}));

    let tripCount = totals.tripCount;
    if (totals.stationaryTripCount !== undefined)
      tripCount -= totals.stationaryTripCount;

    return (
      <>
        <FilterPanel filters={filters} />
        <div id="content" className="slide-in-left" style={{ 
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100vh - 60px)',
          maxHeight: '100%'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: 'auto',
          }}>
            <div id="left-panel" style={{ 
              width: '66.7%',
              display: 'flex', 
              flexDirection: 'column',
              paddingRight: '2vh',
              paddingLeft: '2vh',
              paddingBottom: '2vh'
            }}>
              <div style={{ marginBottom: '15px' }}>
                {header('Critical Event Summary')}
              </div>
              
              {/* Map Container */}
              <div id="map" className="slide-in-left" style={{ 
                // height: '35vh', /* Fixed height */
                height: '63vh',
                borderRadius: '10px',
                overflow: 'hidden',
              }}>
                <Map
                  {...viewState}
                  mapboxAccessToken={mapboxToken}
                  ref={handleMapRef}
                  onMove={evt => setViewState(evt.viewState)}
                  mapStyle={mapStyle}
                  style={{ width: '100%', height: '100%' }}
                >
                  <StyleControl onStyleChange={handleStyleChange} currentStyle={mapStyle} />
    
                  {updatedAssets?.filter(asset => selectedStatuses.includes(asset.status)).map(asset =>
                    asset.latitude && (
                      <Marker key={asset.id} latitude={asset.latitude} longitude={asset.longitude}>
                        <div
                          style={{
                            width: '26px',
                            height: '13px',
                            backgroundImage: `url(${statusIcons[asset.status].bus})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            transform: getTransform(asset),
                            opacity: selectedStatuses.includes(asset.status) ? 1 : 0.3
                          }}
                          onMouseEnter={() => setHoveredAsset(asset)}
                          onMouseLeave={() => setHoveredAsset(null)}
                        />
                      </Marker>
                    )
                  )}
                  
                  {hoveredAsset && (
                    <Popup latitude={hoveredAsset.latitude} longitude={hoveredAsset.longitude} closeButton={false} anchor="top">
                      <div style={{ fontSize: 15 }}>
                        {hoveredAsset.name}
                        <br />
                        Speed: {round(kmToMiles(hoveredAsset.speed))} mph
                        <br />
                        Last Update: {hoveredAsset.lastUpdate ? moment(hoveredAsset.lastUpdate).fromNow() : 'unknown'}
                      </div>
                    </Popup>
                  )}
                </Map>
              </div>
              
              {/* Map Legend */}
              <ul className='legend map'>
                {Object.entries(statusIcons).map(([status, info]) => {
                  const count = updatedAssets?.filter(a => a.status === status).length || 0;
                  const isSelected = selectedStatuses.includes(status);
                  return (
                    <li 
                      key={status} 
                      className={isSelected ? info.color : 'gray'} 
                      onClick={() => toggleStatus(status)} 
                      style={{ 
                        cursor: 'pointer', 
                        opacity: isSelected ? 1 : 0.5,
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginRight: '15px'
                      }}
                    >
                      <img 
                        src={info.bus} 
                        alt={info.name} 
                        style={{ width: '40px', height: '32px', marginRight: '8px' }} 
                      />
                      {info.name}: {count}
                    </li>
                  );
                })}
              </ul>
            </div>
    
            <div id="right-panel" className="perf-summary-container" style={{ 
              width: '33.3%', 
              display: 'flex', 
              flexDirection: 'column',
              paddingLeft: '15px',
              paddingTop: '0px',
              paddingBottom: '0px'
            }}>
              <div id="wtd-summary">
                {header('Past Week Summary')}
              </div>
              
              {/* Operational Efficiency Donut */}
              <div style={{ width: '100%' }}>
                <div id="op-efficiency" className="stacked fifty" style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  width: '100%',
                  padding: '0'
                }}>
                  <h3 style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    textAlign: 'center',
                    width: '100%'
                  }}>
                    Operational Efficiency (%)
                    <span style={{ fontSize: '0.8em', marginTop: '4px' }}>Past Week vs Past Month (Operational)</span>
                  </h3>
                  <FleetEfficiencyDonut 
                    circleText={eff + '%'} 
                    percent={eff} 
                    previousPercent={previousEff} 
                    small={true} 
                    isOperational={true}
                  />
                  <ul className='legend' style={{ 
                    fontSize: '2vi',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 0,
                    listStyle: 'none'
                  }}>
                    <li className="magenta" style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                      {/* <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#d916a8', marginRight: '5px', borderRadius: '2px' }}></span> */}
                      Efficiency {dateSpan1} (Operational)
                    </li>
                    <li className="purple" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#ba23f6', marginRight: '5px', borderRadius: '2px' }}></span> */}
                      Efficiency {dateSpan2} (Operational)
                    </li>
                  </ul>
                </div>
              </div>
              
              <div style={{ marginBottom: '20px' }}>
                <div style={{ 
                  marginBottom: '19px',
                  borderRadius: '10px',
                  padding: '5px',
                }}>
                  <BarGraphic 
                    isMonitor={true} 
                    colors={grayColors} 
                    header="Usage" 
                    segments={[
                      { label: "Utilized", value: totals?.activeVehicleCount },
                      { label: "Not Utilized", value: assets?.length - totals?.activeVehicleCount }
                    ]} 
                  />
                </div>
                
                <div style={{ 
                  marginBottom: '19px',
                  borderRadius: '10px',
                  padding: '5px',
                }}>
                  <BarGraphic 
                    isMonitor={true} 
                    colors={grayColors} 
                    header="Operational Uptime (Hours)" 
                    segments={[
                      { label: "Active Hours", value: activeHours(totals) },
                      { label: "Offline Hours", value: totals.offlineMinutes/60 }
                    ]} 
                  />
                </div>
                
                <div style={{ 
                  marginBottom: '19px',
                  borderRadius: '10px',
                  padding: '5px',
                }}>
                  <BarGraphic 
                    isMonitor={true} 
                    dollars={true} 
                    header="Fuel Costs ($)" 
                    colors={grayColors} 
                    segments={[
                      { label: "Idle", value: totals.totalIdleFuelSpend },
                      { label: "Active", value: totals.totalFuelSpend - totals.totalIdleFuelSpend }
                    ]} 
                  />
                </div>
                
                <div style={{ 
                  marginBottom: '19px',
                  borderRadius: '10px',
                  padding: '5px',
                }}>
                  <BarGraphic 
                    isMonitor={true} 
                    colors={fuelTypeColors} 
                    header="Fleet Composition" 
                    segments={[
                      { label: "EV", value: evCount },
                      { label: "Diesel", value: dieselCount },
                      { label: "Gas", value: gasCount },
                    ]} 
                  />
                </div>
                
                {totals.distanceByType && (
                  <div style={{ 
                    marginBottom: '19px',
                    borderRadius: '10px',
                    padding: '5px',
                  }}>
                    <BarGraphic 
                      isMonitor={true} 
                      header="Distance Traveled (Mi)" 
                      colors={fuelTypeColors} 
                      units="Mi" 
                      segments={[
                        { label: "EV", value: kmToMiles(totals.distanceByType[FuelTypes.EV]) },
                        { label: "Diesel", value: kmToMiles(totals.distanceByType[FuelTypes.Diesel]) },
                        { label: "Gas", value: kmToMiles(totals.distanceByType[FuelTypes.Gas]) },
                      ]} 
                    />
                  </div>
                )}
                
                {totals.tripsByType && (
                  <div style={{ 
                    marginBottom: '19px',
                    borderRadius: '10px',
                    padding: '5px',
                  }}>
                    { operationalTotals?.tripsByType && (
                      <BarGraphic 
                        isMonitor={true} 
                        header="Operational Trips" 
                        colors={fuelTypeColors} 
                        segments={[
                          { label: "EV", value: operationalTotals.tripsByType[FuelTypes.EV] ?? 0 },
                          { label: "Diesel", value: operationalTotals.tripsByType[FuelTypes.Diesel] ?? 0 },
                          { label: "Gas", value: operationalTotals.tripsByType[FuelTypes.Gas] ?? 0 },
                        ]} 
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          <div style={{ 
            padding: '0 15px',
            width: '100%',
            marginBottom: '6vi'
          }}>
            <div style={{
              padding: '12px',
              background: 'linear-gradient(135deg, #5c496c, #c35c8b, #d67740, #d67740)',
              color: 'white',
              borderRadius: '15px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
              width: '50%', // Changed from 97% to 50% to make it narrower
              margin: '10px auto',
              textAlign: 'center',
              maxHeight: '200px',
            }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', width: '100%' }}>
                <h2 style={{ fontSize: '26px', fontWeight: 'bold', margin: '0' }}>
                  Fleet Scorecards
                </h2>
              </div>
            </div>
            <Scorecard 
              wtdData={totals} 
              mtdData={previousTotals} 
              operationalWtdData={operationalTotals} 
              operationalMtdData={operationalPreviousTotals} 
              className="zoom-in" 
            />
          </div>
        </div>
      </>
    );
}

export default MonitorPage;

