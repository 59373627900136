import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function EventGraph({ events }) {
    if (!events || events.length === 0) {
        return <p>No event data available.</p>;
    }

    // Define the data map (Vehicle and Driver only)
    const dataMap = {
        "DiagnosticDeviceHasBeenUnpluggedId": "DEVICE",
        "DiagnosticDeviceRestartedBecauseAllPowerWasRemovedId": "DEVICE",
        "DiagnosticDeviceRestartedBecauseOfLowVoltageInPowerSupplyId": "DEVICE",
        "DiagnosticAccidentLevelAccelerationEventId": "DRIVER",
        "DiagnosticVehicleBatteryLowVoltageId": "VEHICLE",
        "DiagnosticLowPriorityWarningLightId": "VEHICLE",
        "DiagnosticExcessiveAccelerometerEventsOverAccidentThresholdId": "DRIVER",
        "awtSaua-9c06ewJ__HdXlBQ": "DEVICE",
        "apKlYlK6B5UKemXM3WE5CqA": "VEHICLE",
        "aNv4akT-hHk2f7x5Qz5Gjww": "DEVICE",
        "aipGZwHSihU2SLOhKLrjpwA": "DEVICE",
        "aKIT5YrtELUuKNJG35RgaJg": "DRIVER"
    };

    // Filter events to include only Vehicle and Driver categories
    const filteredEvents = events.filter(event => dataMap[event.id]);

    // Process event data to count occurrences by asset ID and event type
    const assetEventCounts = filteredEvents.reduce((acc, event) => {
        const assetId = event.vehicle?.name || 'Unknown';
        const eventCategory = dataMap[event.id];
    
        if (!acc[assetId]) {
            acc[assetId] = {};
        }
    
        // Only count relevant categories (Vehicle and Driver)
        if (eventCategory === "VEHICLE" || eventCategory === "DRIVER") {
            acc[assetId][eventCategory] = (acc[assetId][eventCategory] || 0) + 1;
        }
    
        return acc;
    }, {});

    
    


    // Sort asset IDs based on total event count and take the top 10
    const sortedAssets = Object.keys(assetEventCounts)
        .sort((a, b) => {
            const totalA = Object.values(assetEventCounts[a]).reduce((sum, count) => sum + count, 0);
            const totalB = Object.values(assetEventCounts[b]).reduce((sum, count) => sum + count, 0);
            return totalB - totalA;
        })
        .slice(0, 10); // Top 10 assets

    // Define event categories
    const categories = ["VEHICLE", "DRIVER"];

    sortedAssets.forEach(assetId => {
        if (!assetEventCounts[assetId]) {
            assetEventCounts[assetId] = {};
        }
        categories.forEach(category => {
            if (!assetEventCounts[assetId][category]) {
                assetEventCounts[assetId][category] = 0; // Default to 0 if the category is missing
            }
        });
    });
    // Generate datasets for each event category
    const datasets = categories.map((category, index) => ({
        label: category === "VEHICLE" ? "Vehicle" : "Driver",
        data: sortedAssets.map(assetId => assetEventCounts[assetId][category] || 0),
        backgroundColor: index === 0 ? '#33A1FF' : '#FF5733', // Vehicle: Blue, Driver: Red
    }));

    console.log("Filtered Events:", filteredEvents);
    console.log("Asset Event Counts:", assetEventCounts);
    console.log("Sorted Assets:", sortedAssets);
    console.log("Datasets:", datasets);

    // Define the chart data
    const chartData = {
        labels: sortedAssets,
        datasets: datasets
    };

    // Chart options for stacked bar chart
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 20,
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                }
            },
            tooltip: {
                enabled: true,
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                titleFont: {
                    size: 18,
                    weight: "bold"
                },
                bodyFont: {
                    size: 16
                },
                padding: 10,
                cornerRadius: 8,
            }
        },
        scales: {
            x: {
                stacked: true, // Enable stacking
                title: {
                    display: true,
                    text: 'Asset ID',
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                },
                ticks: {
                    autoSkip: false,
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            },
            y: {
                stacked: true, // Enable stacking
                title: {
                    display: true,
                    text: 'Event Quantity',
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                },
                ticks: {
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                },
                beginAtZero: true
            }
        }
    };

    return (
        <div style={{ width: '100%', height: '98%', maxHeight: '620px', margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
}

export default EventGraph;
