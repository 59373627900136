export const timezones = [
      { id: 'US/Arizona' },
      { id: 'US/Central' },
      { id: 'US/Eastern' },
      { id: 'US/Hawaii' },
      { id: 'US/Mountain' },
      { id: 'US/Pacific' },
];

const typeChoices = [
      { id: 'OpCenter', name: 'Op Center' },
      { id: 'BusinessUnit', name: 'Business Unit' },
];

const opCenterCond = item => item.type == 'OpCenter';

const days = [ { id: 0, name: 'Mon' }, { id: 1, name: 'Tue' }, { id: 2, name: 'Wed' }, { id: 3, name: 'Thu' }, { id: 4, name: 'Fri' }, { id: 5, name: 'Sat' }, { id: 6, name: 'Sun' } ];

export const unitFields = [
                { key: 'name', label: 'Name' },
                { key: 'type', label: 'Type', choices: typeChoices, showCondition: item => item.id === undefined },
                { key: 'address', label: 'Address', showCondition: opCenterCond, multiline: true },
                { key: 'wifiSSID', label: 'WiFi SSID', showCondition: opCenterCond },
                { key: 'wifiPassword', label: 'WiFi Password', showCondition: opCenterCond },
                { key: 'timeZone', label: 'Time Zone', choices: timezones, showCondition: opCenterCond },
                { key: 'dayStartTime', label: 'Day Start Time', showCondition: opCenterCond },
                { key: 'dayEndTime', label: 'Day End Time', showCondition: opCenterCond },
		{ key: 'minimumIdleTimeForZoneCreation', label: 'Minimum Idle Time For Zone Creation (minutes)', showCondition: opCenterCond },
		{ key: 'opDays', label: 'Operational Days', choices: days, multiple: true },
		//{ key: 'minimumKeyOffTimeForZoneCreation', label: 'Minimum Key-Off Time For Zone Creation (minutes)', showCondition: opCenterCond },
];

