import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getDailyTotalAll } from './api/vehicle';
import { Link } from 'react-router-dom';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import DashboardDonut from "./DashboardDonut";
import FilterPanel from "./FilterPanel";
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, mpkwh, fuelSpendPerMile, co2eInKg, co2PerMile, getVehicleName } from './util/formulas';
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";
import { FuelTypes, classifyFuelTypes } from './util/fuelTypes';

function AssetList({ token }) {
  const [assets, setAssets] = useState();
  const [totals, setTotals] = useState();
  const [combinedAssets, setCombinedAssets] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRangeFilter;
  const lastRefreshCount = useRef(-1);

  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;
  if (totals) {
    totals.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }

  React.useEffect(() => {
    // don't allow "All Enterprises"
    if (context.rootId === -1) {
      context.dispatch({ type: 'setEnterprise', payload: parseInt(localStorage.getItem('rootUnitId')) });
    }
  }, []);

  const fuelTypes = new Set(assets?.map(x => x.fuelType));
  const { onlyIce, onlyEv, mixed } = classifyFuelTypes(fuelTypes);
  const energyWord = (onlyIce) ? 'Fuel' : 'Energy';

  let fuelCost, co2PerMileAvg, avgMpg, distPerDay, avgEff, totalDistance, totalFuelConsumed, chargingMinutes, avgMikWh;
  const noNaN = x => (x === undefined || Number.isNaN(x) ? 0 : x)
  console.log(totals);
  if (assets && totals && combinedAssets) {
    const assetCount = totals.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
    fuelCost = totals.reduce((part, a) => part+noNaN(a.fuelSpend),    0);
    const totalCo2 = totals.reduce((part, a) => part+noNaN(a.co2Generated),    0);
    totalDistance = totals.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
    co2PerMileAvg = totalDistance ? totalCo2/kmToMiles(totalDistance) : 0;
    const totalFuelConsumed = combinedAssets.reduce((part, a) => part+noNaN(a.fuelType == 'EV' ? 0 : a.totals?.fuelConsumed), 0);
    const totalFuelDistance = combinedAssets.reduce((part, a) => part+noNaN(a.fuelType == 'EV' ? 0 : a.totals?.distanceDriven), 0);
    const totalKwhConsumed = combinedAssets.reduce((part, a) => part+noNaN(a.fuelType == 'EV' ? a.totals?.kwhConsumed : 0), 0);
    const totalEVDistance = combinedAssets.reduce((part, a) => part+noNaN(a.fuelType == 'EV' ? a.totals?.distanceDriven : 0), 0);
    distPerDay  = totals.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
    chargingMinutes = totals.reduce((part, a) => part+noNaN(a.chargingMinutesPerDay), 0);
    const assetCountEff = totals.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
    avgEff      = totals.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
    avgMpg   = noNaN(kmToMiles(totalFuelDistance)/(litersToGallons(totalFuelConsumed)));
    avgMikWh = noNaN(kmToMiles(totalEVDistance)/totalKwhConsumed);
  }
  const eff = noNaN(avgEff*100).toFixed(0);

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      const filters = getFilters(context);
      getVehicles(context.rootId, filters).then(response => setAssets(response.data));
      const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
      const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
      getDailyTotalAll(context.rootId, date1, date2, filters).then(response => setTotals(response.data));
    }
  }, [context.refreshCount, dateRange]);

  useEffect(() => {
    if (assets && totals) {
      const combined = assets.map(asset => {
        const total = totals?.find(x => x.vehicleId == asset.id);
        return { ...asset, totals: total }
      });
      setCombinedAssets(combined);
    }
  }, [assets, totals]);

  const columns = [
		    { header: 'Asset', key: 'name' },
		    { header: 'Group', key: 'unitId' },
		    { header: 'Make / Model', key: 'makeModel' },
		    { header: 'Year', key: 'year' },
		    { header: 'Fuel', key: 'fuelType' },
		    { header: 'Trips', key: 'tripCount' },
		    { header: 'Miles Traveled', key: 'distanceDriven' },
		    { header: 'Duration of Trips (Min)', key: 'tripMinutes' },
		    { header: 'Utilization', sortable: false, width: '7%', key: 'utilization', displayOnly: true },
		    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
		    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
		    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
		    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
		    { header: 'Utilization',          key: 'utilizationPct', downloadOnly: true },
		    { header: 'Energy/Fuel Consumed', key: 'fuelOrEnergyConsumed', displayOnly: true },
		    { header: 'Gallons Consumed', key: 'fuelConsumed', downloadOnly: true },
		    { header: 'kWh Consumed', key: 'kwhConsumed', downloadOnly: true },
		    { header: 'MPG / mi/kWh', key: 'mpgOrMpkwh', displayOnly: true },
		    { header: 'MPG', key: 'mpg', downloadOnly: true },
		    { header: 'Mi/kWh', key: 'mikwh', downloadOnly: true },
		    { header: 'Fuel/Energy Cost', key: 'fuelSpend' },
		    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
		    { header: 'Efficiency', key: 'efficiency' },
		    { header: 'Cost Per Mile', key: 'costPerMile', downloadOnly: true },
		    { header: 'CO2 Per Mile', key: 'co2PerMile', downloadOnly: true, extended: true },
		    { header: 'Ideal ' + energyWord + ' Consumed', key: onlyIce ? 'idealFuelConsumed' : 'idealKwhConsumed',
                      downloadOnly: true, extended: true },
		    { header: 'Ideal ' + energyWord + ' Cost', key: 'idealFuelSpend', downloadOnly: true, extended: true },
		    { header: 'Distance Per Day', key: 'distancePerDay', downloadOnly: true, extended: true },
//		    { header: energyWord + ' Cost Per ' + ((onlyIce) ? 'Gallon' : 'kWh'),
//                      key: onlyIce ? 'costPerGallon' : 'costPerKwh', downloadOnly: true, extended: true },
		    { header: 'Recovery Potential', key: 'recoveryPotential', downloadOnly: true, extended: true },
		    { header: 'Primary Use', key: 'primaryUse', downloadOnly: true, extended: true },
		    { header: 'Type', key: 'type', downloadOnly: true, extended: true },
		    { header: 'Accelerating Minutes - Op Hours', key: 'opAcceleratingMinutes', downloadOnly: true, extended: true },
		    { header: 'Decelerating Minutes - Op Hours', key: 'opDeceleratingMinutes', downloadOnly: true, extended: true },
		    { header: 'Idle Minutes - Op Hours',         key: 'opIdleMinutes', downloadOnly: true, extended: true },
		    { header: 'Offline Minutes - Op Hours',      key: 'opOfflineMinutes', downloadOnly: true, extended: true },
		    { header: 'Utilization - Op Hours',      key: 'opUtilizationPct', downloadOnly: true, extended: true },
  ];

  const round  = x => x?.toFixed(2);
  const round1 = x => x?.toFixed(1);
  const round0 = x => x?.toFixed(0);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getAssetInfo = (asset, key) => {
    if (key == 'name')
      return getVehicleName(asset);
    if (key == 'makeModel')
      return asset.make + ' ' + asset.model;
    if (key == 'unitId')
      return context.units?.find(x => x.id == asset.unitId)?.name;
    if (key == 'upTimeHours')
      return asset.totals?.uptimeMinutes / 60;
    if (key == 'distanceDriven')
      return kmToMiles(asset.totals?.distanceDriven);
    if (key == 'distancePerDay')
      return kmToMiles(asset.totals?.distancePerDay);
    if (key == 'tripCount')
      return asset.totals?.tripCount - asset.totals?.stationaryTripCount;
    if (key == 'fuelConsumed') {
      if (asset.fuelType == 'EV')
        return;
      return litersToGallons(asset.totals?.fuelConsumed);
    }
    if (key == 'kwhConsumed')
      return asset.totals?.kwhConsumed;
    if (key == 'fuelOrEnergyConsumed')
      return (asset.fuelType == 'EV') ? asset.totals?.kwhConsumed : litersToGallons(asset.totals?.fuelConsumed);
    if (key == 'mpgOrMpkwh')
      return (asset.fuelType == 'EV') ? mpkwh(asset.totals, false) : mpg(asset.totals);
    if (key == 'idealFuelConsumed')
      return litersToGallons(asset.totals?.idealFuelConsumed);
    if (key == 'idealKwhConsumed')
      return asset.totals?.idealKwhConsumed;
    if (key == 'recoveryPotential')
      return asset.totals?.fuelSpend - asset.totals?.idealFuelSpend;
    if (key == 'fuelPerTrip')
      return litersToGallons(asset.totals?.fuelConsumed / asset.totals?.tripCount);
    if (key == 'mpge')
      return mpge(asset.totals);
    if (key == 'mpg') {
      if (asset.fuelType == 'EV')
        return;
      return mpg(asset.totals);
    }
    if (key == 'mikwh')
      return kmToMiles(asset.totals?.distanceDriven) / asset.totals?.kwhConsumed;
    if (key == 'costPerMile')
      return asset.totals?.fuelSpend / kmToMiles(asset.totals?.distanceDriven);
    if (key == 'costPerDay')
      return asset.totals?.fuelSpendPerDay;
    if (key == 'costPerTrip')
      return asset.totals?.fuelSpend / asset.totals?.tripCount;
    if (key == 'costPerGallon')
      return asset.totals?.fuelSpend / litersToGallons(asset.totals?.fuelConsumed);
    if (key == 'costPerKwh')
      return asset.totals?.fuelSpend / asset.totals?.kwhConsumed;
    if (key == 'co2e')
      return co2eInKg(asset.totals);
    if (key == 'co2PerMile')
      return co2PerMile(asset.totals);
    if (key == 'averageSpeed')
      return asset.totals?.averageSpeed;
    if (key == 'utilizationPct' && asset.totals) {
      const tot = asset.totals;
      const up = tot.acceleratingMinutes + tot.deceleratingMinutes + tot.idleMinutes;
      return 100*up/(up + tot.offlineMinutes);
    }
    if (key == 'opUtilizationPct' && asset.totals) {
      const tot = asset.totals;
      const up = tot.opAcceleratingMinutes + tot.opDeceleratingMinutes + tot.opIdleMinutes;
      return 100*up/(up + tot.opOfflineMinutes);
    }
    if (key == 'acceleratingMinutes' || key == 'deceleratingMinutes' || key == 'idleMinutes' || key == 'offlineMinutes' ||
        key == 'opAcceleratingMinutes' || key == 'opDeceleratingMinutes' || key == 'opIdleMinutes' || key == 'opOfflineMinutes' ||
        key == 'efficiency' || key == 'tripMinutes' || key == 'fuelSpend' || key == 'idealFuelSpend')
      return asset.totals && asset.totals[key];
    return asset[key];
  }

  const getAssetCell = (asset, key) => {
    if (key == 'utilization') {
      let offWidth = 0, idleWidth = 0, decWidth = 0, accWidth = 0;
      const total = asset.totals;
      if (total) {
	  let totalMins = total.acceleratingMinutes + total.deceleratingMinutes + total.idleMinutes + total.offlineMinutes;
	  offWidth = 100*total.offlineMinutes / totalMins;
	  idleWidth = 100*total.idleMinutes / totalMins;
	  decWidth = 100*total.deceleratingMinutes / totalMins;
	  accWidth = 100*total.acceleratingMinutes / totalMins;
      }
      return <td key={key} className="chart"><span className="offline" data-title={getLabel("Offline", offWidth, total?.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span className="idle" data-title={getLabel("Idle", idleWidth, total?.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span className="decelerating" data-title={getLabel("Decelerating", decWidth, total?.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span className="accelerating" data-title={getLabel("Accelerating", accWidth, total?.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    if (key == 'map')
      return <td key={key} className="map"><a className="moving" data-title="Moving - Map current location" href="asset-details.html"></a></td>
    let val = getAssetInfo(asset, key);
    if (key == 'name')
      return <td key={key} className="text"><Link to={'/assets/' + asset.id}>{val}</Link></td>
    if (key == 'efficiency' && val)
      return <td key={key} className="number">{(val * 100).toFixed(0)}%</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      if (key == 'fuelOrEnergyConsumed' && val)
        val = (asset.fuelType == 'EV') ? val + ' kWh' : val + ' gal';
      return <td key={key} className="number">{val}</td>;
    }
    return <td key={key} className="text">{val}</td>;
  }

  return <>
            <div id="focus">
                <FilterPanel filters={['fuelTypeFilter', 'groupFilter',
                                       'modelFilter', 'assetFilter', 'dateFilter']} />
                <div id="content">
                    <div id="charts">
                        <div className="individual-chart small">
			    <h4>Efficiency</h4>
			    <div className="doughnut">
				  <AssetListDonut mainText={eff + '%'} subText={"Efficient"} percent={eff} />
			    </div>
			</div>
                        <div className="individual-chart">
                            <h4>Fleet Utilization</h4>
                            <div className="doughnut">
                                <UtilizationDonut accelerating={accMin} decelerating={decMin} idle={idleMin} offline={offMin} />
                            </div>
                        </div>
                        <div className="individual-chart small">
                            <h4>Fuel/Energy Cost</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round0(noNaN(100*fuelCost/kmToMiles(totalDistance))) + '¢'} subText={"¢/Mile"} />
                            </div>
                        </div>
                        <div className="individual-chart small">
                            {onlyEv || <><h4>CO<sub>2</sub> Emissions</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round(co2PerMileAvg/1000)} subText={"kg/Mile"} />
                            </div></>}
                            {onlyEv && <><h4>Charging Time</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round(chargingMinutes/60)} subText={"Hours/Day"} />
                            </div></>}
                        </div>
                        <div className="individual-chart small">
                            <h4>Energy Efficiency</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round(avgMikWh)} subText={"Mi/kWh"} />
                            </div>
                        </div>
                        <div className="individual-chart small">
                            <h4>Fuel Efficiency</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round1(avgMpg)} subText={"MPG"} />
                            </div>
                        </div>
                        {/*<div className="individual-chart small">
                            <h4>Distance Driven</h4>
                            <div className="doughnut">
                                <AssetListDonut mainText={round0(kmToMiles(distPerDay))} subText={"Miles/Day"} />
                            </div>
                        </div>*/}
                    </div>
                    <PagedList items={combinedAssets} columns={columns} getItemInfo={getAssetInfo} getItemCell={getAssetCell}
			       itemType="Assets" extendedDownload={true} />
                </div>
            </div>
         </>;
}

export default AssetList;

