import React from 'react';
import { eventMetadataMap } from './util/events';


function AlertSummary({ alerts }) {
    // Group alerts by category using alertId and eventMetadataMap
    const typeCounts = alerts?.reduce((acc, alert) => {
        const category = eventMetadataMap[alert.id]?.category || 'VEHICLE';
        acc[category] = (acc[category] || 0) + 1;
        return acc;
    }, {});    

    return (
        <div style={{
            padding: '12px',
            background: 'linear-gradient(135deg, #5c496c, #c35c8b, #e75b75, #d67740)',
            color: 'white',
            borderRadius: '15px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            width: '80%',
            margin: '10px auto',
            textAlign: 'center',
            maxHeight: '200px',
            // position: 'sticky',
            // zIndex: '999',
            top: '0'
        }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', width: '100%' }}>
            <h2 style={{ fontSize: '26px', fontWeight: 'bold', margin: '0' }}>
                Critical Event Summary
            </h2>

                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                    {Object.entries(typeCounts).map(([category, count]) => (
                        <div key={category} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            minWidth: '150px',
                            background: 'rgba(255, 255, 255, 0.2)',
                            padding: '12px 20px',
                            borderRadius: '10px',
                            fontSize: '20px',
                            fontWeight: '500'
                        }}>
                            <span style={{ fontWeight: '700', fontSize: '20px' }}>{category}:</span>
                            <span style={{ fontWeight: '700', fontSize: '20px' }}>{count}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AlertSummary;
