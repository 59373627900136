import React, { createContext, useReducer, useEffect, useRef } from 'react';
import FleetManager from "./FleetManager";
import { lastSevenDays } from "./util/dates";
import { getVehicles, getDailyTotalAll } from './api/vehicle';
import { getEnterpriseShortList } from './api/enterprise';
import { getUnitsShort } from './api/unit';
import { isAdmin } from './util/security';

export const Context = createContext(null);

const ContextHandler = ({ handleLogout }) => {

  const rootUnit = localStorage.getItem('rootUnitId')

  const fixUnits = units => {
     units = units.sort((a, b) => a.name?.localeCompare(b.name));
     if (!isAdmin())
         units.forEach(u => u.readOnly = true);
     return units;
  }

  const loadUnits = root => {
    getEnterpriseShortList().then(result => {
        dispatch({ type: 'setState', attribute: 'enterprises', payload: result.data.sort((a, b) => a.name?.localeCompare(b.name)) });
        getUnitsShort(root).then(result => {
           const units = result.data;
           dispatch({ type: 'setState', attribute: 'units', payload: fixUnits(result.data) });
        });
    });
    getVehicles(root).then(result => dispatch({ type: 'setState', attribute: 'assets', payload: result.data.sort((a, b) => a.name?.localeCompare(b.name)) }));
  }

  const isPersistentState = k => k.endsWith('Filter') || k == 'listState';

  const reducer = (state, action) => {
    console.log("reducer " + action.type + " " + JSON.stringify(action).substring(0, 300));
    switch (action.type) {
      case "refresh":
        return { ...state, refreshCount: state.refreshCount+1 };
      case "setEnterprise": {
        loadUnits(action.payload);

        // reset filters in state
        const newState = Object.fromEntries(Object.entries(state).filter(([key]) => key == 'dateRangeFilter' || !key.endsWith("Filter")));
        // reset filters in local storage
        Object.keys(localStorage)
          .filter(key => key.endsWith("Filter") && key != 'dateRangeFilter') // Find keys ending with "Filter"
          .forEach(key => localStorage.removeItem(key)); // Remove each matching key

        localStorage.setItem('enterpriseFilter', action.payload);
        return { ...newState, groupFilter: undefined, refreshCount: state.refreshCount+1, rootId: action.payload };
      }
      case "refreshUnits":
        loadUnits(state.rootId);
        return state;
      case "setState": {
        if (isPersistentState(action.attribute))
           localStorage.setItem(action.attribute, JSON.stringify(action.payload));
        const newState = { ...state };
        console.log(action.attribute);
        if (['groupFilter', 'fuelTypeFilter'].includes(action.attribute)) {
	   // need to clear model filter
           localStorage.removeItem('modelFilter');
           delete newState.modelFilter;
        }
        if (['groupFilter', 'fuelTypeFilter', 'modelFilter'].includes(action.attribute)) {
	   // need to clear asset filter
           localStorage.removeItem('assetFilter');
           delete newState.assetFilter;
        }
        if (action.attribute == 'assets' || action.attribute == 'groupFilter')
           return { ...newState, [action.attribute]: action.payload, refreshCount: state.refreshCount+1 };
        return { ...newState, [action.attribute]: action.payload };
      }
      default:
        return state;
    }
  };
  const getInitialState = () => {
    let st = { refreshCount: 0, dateRangeFilter: lastSevenDays(), rootId: rootUnit };
    const filterKeys = Object.keys(localStorage).filter(key => isPersistentState(key));
    const fixDate = (k, o) => (k == 'dateRangeFilter') ? o.map(x => new Date(x)) : o;
    filterKeys.forEach(key => { st[key == 'enterpriseFilter' ? 'rootId' : key] = fixDate(key, JSON.parse(localStorage.getItem(key))); });
    return st;
  }
  const [state, dispatch] = useReducer(reducer, getInitialState());

  useEffect(() => {
    if (state.enterprises === undefined)
      loadUnits(state.rootId || rootUnit);
  }, []);
  
  return (
    <Context.Provider value={ { ...state, dispatch } }>
      <FleetManager handleLogout={handleLogout} />
    </Context.Provider>
  );
};

export default ContextHandler;

